/* eslint-disable no-console */
import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DynatraceService } from './dynatrace.service';

export enum LoggingServicesEnum {
	appInsights,
	googleTagManager,
}
@Injectable({
	providedIn: 'root',
})
export class LoggingService {
	private loggingActive = false;

	constructor(private dynatraceService: DynatraceService, @Inject(PLATFORM_ID) private platformId: Object) {}

	debugToConsole(name: string, data: {}, type: string): void {
		const runningInTest = typeof process !== 'undefined' && !!process?.env?.JEST_WORKER_ID;
		if (isPlatformServer(this.platformId) || !isDevMode() || runningInTest) {
			return;
		}

		console.group(`${type}: ${name}`);
		console.log(this.loggingActive ? 'Logging is active' : 'Logging is inactive');
		console.log('Data', data);
		console.groupEnd();
	}

	log(str: string): void {
		if (this.shouldSuppressLogging()) {
			return;
		}
		console.log(str);
	}

	warn(str: string): void {
		if (this.shouldSuppressLogging()) {
			return;
		}
		console.warn(str);
	}

	error(str: string): void {
		if (this.shouldSuppressLogging()) {
			return;
		}
		console.error(str);
	}

	shouldSuppressLogging(): boolean {
		const runningInTest = typeof process !== 'undefined' && !!process?.env?.JEST_WORKER_ID;
		return runningInTest || !isDevMode();
	}

	getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
		if (snapshot.firstChild) {
			return this.getActivatedComponent(snapshot.firstChild);
		}

		return snapshot;
	}

	getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
		let path = '';
		if (snapshot.routeConfig) {
			path += snapshot.routeConfig.path;
		}

		if (snapshot.firstChild) {
			return path + this.getRouteTemplate(snapshot.firstChild);
		}

		return path;
	}

	getRouteData(snapshot: ActivatedRouteSnapshot): { [key: string]: string } {
		const data = snapshot.data;

		if (snapshot.firstChild) {
			return { ...data, ...this.getRouteData(snapshot.firstChild) };
		}

		return data;
	}

	/**
	 *
	 * @param name Should be written in the following format: UI:<Area or service caller>:<Device>:<Event> or SSR:<Area or service caller>:<Device>:<Event>
	 * @param data Object
	 */
	trackEvent(name: string, data: {}): void {
		this.debugToConsole(name, data, 'TrackEvent');
		this.dynatraceService.reportBizEvent(`Trader-${name}`, data);
	}

	trackException(error: { error: Error }): void {
		this.debugToConsole('error', error, 'TrackException');
		this.dynatraceService.reportError(error.error);
	}
}
