import { BoostOfferResponse, CTA, RewardValue } from '@woolworthsnz/trader-api';
import { EDRProduct, PartnerProduct } from '../components/card-boost-offer/card-boost-offer.models';
import { CtaProps } from '../../../models';
import { BoostOfferStatus, BoostProps } from '../models';
import { Device } from '@woolworthsnz/styleguide';
import { EDRRoundelText } from '../../../components';

const partners: { [id: string]: PartnerProduct } = {
	['2010']: { slug: 'countdown', name: 'Countdown' },
};
/**
 * Regex to match markdown style links with format `[some text](link url)`
 */
const markdownLinkRegex = /(?:__|[*#])|\[(.*?)\]\((.*?)\)/gm;

export const buttonCtaProps: { [type: string]: CtaProps } = {
	boosted: { text: 'Shop range', style: 'secondary', styleReversed: true, linkUrl: '#' },
	noBoosted: { text: 'Boost', style: 'primary', styleReversed: false, linkUrl: '#' },
};

function mapProduct(offer: BoostOfferResponse): EDRProduct {
	return {
		name: offer.backgroundImage?.altText ?? '',
		imageUrl: offer.backgroundImage?.desktop ?? '',
		imageLargeUrl: offer.backgroundImage?.desktop ?? '',
	};
}

function mapPartner(offer: BoostOfferResponse): PartnerProduct {
	let partner = partners['2010'];
	if (offer.divisionPartnerIds) {
		partner = partners[offer.divisionPartnerIds[0]];
	}
	return partner;
}

function hasBeenBoosted(offer: BoostOfferResponse): boolean {
	return offer.offerStatus === BoostOfferStatus.Activated || offer.offerStatus === BoostOfferStatus.Redeemed;
}

function mapButtonCta(isBoosted: boolean, cta: CTA | undefined): CtaProps {
	const ctaProps: CtaProps = isBoosted ? buttonCtaProps['boosted'] : buttonCtaProps['noBoosted'];
	return { ...ctaProps, linkUrl: cta?.destinationUrl ?? ctaProps.linkUrl };
}

function getRedemptionProps(offer: BoostOfferResponse): [number, number, Date] {
	let limit = 0;
	let amountBought = 0;
	let endDate: Date = new Date();
	if (offer.redemption) {
		limit = offer.redemption.total ? offer.redemption.total : limit;
		amountBought = (offer.redemption.total || 0) - (offer.redemption.remaining || 0);
		endDate = offer.redemption.endDate as Date;
	}
	return [limit, amountBought, endDate];
}

function formatAsHtml(text: string): string {
	if (!text) {
		return text;
	}

	let formattedText = text;

	// Replace markdown links
	const markdownLinkMatches = formattedText.matchAll(markdownLinkRegex);
	for (const match of markdownLinkMatches) {
		if (match.length !== 3) {
			continue;
		}

		const anchorTag = `<a href="${match[2]}" target="__blank">${match[1]}</a>`;
		formattedText = formattedText.replace(match[0], anchorTag);
	}

	return formattedText;
}

export function mapBoostCardProps({
	offerResponse,
	isBoosting,
	hasError,
}: {
	offerResponse: BoostOfferResponse;
	isBoosting: boolean;
	hasError: boolean;
}): BoostProps {
	const partner = mapPartner(offerResponse);
	const product = mapProduct(offerResponse);
	const isBoosted = hasBeenBoosted(offerResponse);
	const buttonCta = mapButtonCta(isBoosted, offerResponse.cta);
	const [limit, amountBought, endDate] = getRedemptionProps(offerResponse);

	return {
		partnerProductBoost: {
			id: offerResponse.id,
			campaignId: offerResponse.campaignId,
			title: offerResponse.heading,
			description: {
				list: offerResponse.description?.list ?? '',
				listShort: offerResponse.description?.listShort ?? '',
				detailed: offerResponse.description?.detailed ?? '',
			},
			termsAndConditions: offerResponse.termsAndConditions,
			termsAndConditionsHtml: formatAsHtml(offerResponse.termsAndConditions),
			product,
			productCount: offerResponse.productCount,
			partner,
			limit,
			amountBought,
			endDate,
			isBoosted,
			isBoosting,
			hasError,
			points: offerResponse.rewardValue?.points,
			value: offerResponse.rewardValue?.value,
			displayLocation: offerResponse.displayLocation,
			rewardType: offerResponse.rewardValue?.type ?? RewardValue.TypeEnum.PointsFixed,
			isCompleted: offerResponse.offerStatus === BoostOfferStatus.Redeemed,
		},
		buttonCta,
	};
}

export function mapRewardTypeToBoostStrap(rewardValue: RewardValue, device: Device | undefined): string {
	switch (rewardValue.type) {
		case RewardValue.TypeEnum.PointsMultiplier:
			return device === Device.MOBILE ||
				device === Device.TABLETPORTRAIT ||
				device === Device.TABLET ||
				device === Device.DESKTOP
				? `${rewardValue.points}x points`
				: `Collect ${rewardValue.points}x points`;

		case RewardValue.TypeEnum.DiscountProductsPercentageOff:
		case RewardValue.TypeEnum.DiscountBasketPercentageOff:
			return `Save ${rewardValue.value !== 0 ? Math.trunc(rewardValue.value) : Math.trunc(rewardValue.points)}%`;

		case RewardValue.TypeEnum.DiscountProductsDollarsOff:
		case RewardValue.TypeEnum.DiscountBasketDollarsOff:
		case RewardValue.TypeEnum.DiscountProductsFixedPrice:
		case RewardValue.TypeEnum.BogoFixedPrice:
		case RewardValue.TypeEnum.BogoDollarsOff:
			return `Save $${(rewardValue.value !== 0 ? rewardValue.value : rewardValue.points) / 100}`;

		default:
			return device === Device.MOBILE ||
				device === Device.TABLETPORTRAIT ||
				device === Device.TABLET ||
				device === Device.DESKTOP
				? `${rewardValue.points} points`
				: `Collect ${rewardValue.points} points`;
	}
}

export function mapRewardTypeToRoundelText({ points, value, type }: Omit<RewardValue, 'display'>): EDRRoundelText {
	switch (type) {
		case RewardValue.TypeEnum.PointsMultiplier:
			return {
				heading: `${points}x`,
				subText: 'points',
			};

		case RewardValue.TypeEnum.DiscountProductsPercentageOff:
		case RewardValue.TypeEnum.DiscountBasketPercentageOff:
			return {
				heading: `${value !== 0 ? Math.trunc(value) : Math.trunc(points)}%`,
				subText: 'off',
			};

		case RewardValue.TypeEnum.DiscountProductsDollarsOff:
		case RewardValue.TypeEnum.DiscountBasketDollarsOff:
		case RewardValue.TypeEnum.DiscountProductsFixedPrice:
		case RewardValue.TypeEnum.BogoFixedPrice:
		case RewardValue.TypeEnum.BogoDollarsOff:
			return {
				heading: `$${(value !== 0 ? value : points) / 100}`,
				subText: 'off',
			};

		default:
			return {
				heading: `${points}`,
				subText: 'points',
			};
	}
}

export function mapRewardTypeToBoostDetailsCard({ points, value, type }: Omit<RewardValue, 'display'>): string {
	switch (type) {
		case RewardValue.TypeEnum.PointsMultiplier:
			return `${points}x points`;

		case RewardValue.TypeEnum.DiscountProductsPercentageOff:
		case RewardValue.TypeEnum.DiscountBasketPercentageOff:
			return `${value !== 0 ? Math.trunc(value) : Math.trunc(points)}% off`;

		case RewardValue.TypeEnum.DiscountProductsDollarsOff:
		case RewardValue.TypeEnum.DiscountBasketDollarsOff:
		case RewardValue.TypeEnum.DiscountProductsFixedPrice:
		case RewardValue.TypeEnum.BogoFixedPrice:
		case RewardValue.TypeEnum.BogoDollarsOff:
			return `$${(value !== 0 ? value : points) / 100} off`;

		default:
			return `${points} points`;
	}
}
