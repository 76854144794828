<fieldset
	[formGroup]="formGroup"
	cdxFieldset
	[divider]="false"
	size="fullWidth"
	legend="Choose a time slot"
	[hideLegend]="true"
	[i18n]="'@@howWhereWhen-chooseatime'"
>
	<fulfilment-express-slot-selection
		*ngIf="isExpressPickup && !displayExpressSlotOfferings"
		[expressSlotStatus]="expressSlotStatus"
		[slot]="firstExpressSlot"
		[controlName]="controlName"
		[date]="date"
		[formGroup]="formGroup"
	></fulfilment-express-slot-selection>

	<div *ngIf="isExpressPickup && displayExpressSlotOfferings">
		<form-timeslot-selection
			inputId="{{ date }}~{{ firstExpressSlot?.id }}"
			attr.data-cy="timeslot-express"
			attr.data-ft="fullstory-express-pickup"
			inputValue="{{ date }}~{{ firstExpressSlot?.id }}"
			[isDisabled]="isExpressDisabled"
			[formControlName]="controlName"
			[successIcon]="false"
			[label]="expressSlotlabel"
			[description]="expressSlotDescription"
		>
			<div pickupType class="pickupType pickupOfferings">
				<form-radio-group
					*ngIf="firstExpressSlot && displayPickupOfferingsSelector(firstExpressSlot)"
					[options]="firstExpressSlot && pickupOfferingOptions(firstExpressSlot)"
					[defaultValue]="firstExpressSlot && pickupOfferingsDefaultSelection(firstExpressSlot)"
					groupName="pickupType"
				></form-radio-group>
			</div>
			<img
				details
				alt="Online Supermarket: Online Grocery Shopping &amp; Free Recipes at countdown.co.nz"
				height="40"
				src="/assets/images/express/logo-wapple-green.svg"
				width="40"
			/>
			<div
				pickupInfo
				class="pickupInfo"
				*ngIf="
					firstExpressSlot &&
					pickupOfferingOptions(firstExpressSlot)?.length &&
					displayPickupOfferingsSelector(firstExpressSlot)
				"
				i18n="@@pickupTypeInformation-helpText"
			>
				<button
					cdxButton
					(click)="openPickupPointInfoModal()"
					fillStyle="link"
					size="small"
					inline="true"
					type="button"
					class="openPickupHelpButton"
					endSlotSize="extra-small"
					endSlotActive="true"
				>
					How does Pick up work?
				</button>
			</div>
		</form-timeslot-selection>
		<h4 class="heading--5">
			{{ timeSlotsHeadingText }}
			<span class="bookTimeSlot-slotInfo">{{ slotsHeadingPricingText }}</span>
			<button
				cdxButton
				(click)="openFeesModal(false)"
				fillStyle="link"
				size="small"
				[inline]="true"
				type="button"
				[endSlotActive]="true"
				class="bookTimeSlot-deliveryInfoButton"
				endSlotSize="extra-small"
			>
				<span class="sr-only">{{ slotsHeadingPricingText }} information</span>
				<cdx-svg-icon name="hint" fill="dark" size="small"></cdx-svg-icon>
			</button>
		</h4>

		<form-timeslot-selection
			inputId="{{ date }}~{{ slot.id }}"
			attr.data-cy="timeslot-{{ i }}"
			*ngFor="let slot of standardSlots; let i = index"
			inputValue="{{ date }}~{{ slot.id }}"
			[isDisabled]="slot.status === 'Closed'"
			[formControlName]="controlName"
			[successIcon]="true"
			[label]="getLabelForSlot(slot)"
		>
			<span class="tile-slotStatus" details
				>{{ getSlotStatus(slot) }}
				<cdx-svg-icon
					*ngIf="getSlotStatusIcon(slot)"
					[name]="getSlotStatusIcon(slot)"
					[fill]="getSlotStatusIconFill(slot)"
				>
				</cdx-svg-icon>
			</span>
			<div pickupType class="pickupType pickupOfferings">
				<form-radio-group
					*ngIf="displayPickupOfferingsSelector(slot)"
					[options]="pickupOfferingOptions(slot)"
					[defaultValue]="pickupOfferingsDefaultSelection(slot)"
					groupName="pickupType"
				></form-radio-group>
			</div>
			<div
				pickupInfo
				class="pickupInfo"
				*ngIf="pickupOfferingOptions(slot)?.length && displayPickupOfferingsSelector(slot)"
				i18n="@@pickupTypeInformation-helpText"
			>
				<button
					cdxButton
					(click)="openPickupPointInfoModal()"
					fillStyle="link"
					size="small"
					inline="true"
					type="button"
					class="openPickupHelpButton"
					endSlotSize="extra-small"
					endSlotActive="true"
				>
					How does Pick up work?
				</button>
			</div>
			<span *ngIf="slot.discount" badge class="timeslotDiscount" i18n="@@timeslotDiscount-discountText">{{
				getSlotDiscountText(slot)
			}}</span>
		</form-timeslot-selection>
	</div>

	<div *ngIf="isLockerPickupFeatureEnabled && !isExpressPickup && !displaySlotOfferings">
		<form-timeslot-selection
			inputId="{{ date }}~{{ slot.id }}"
			attr.data-cy="timeslot-{{ i }}"
			*ngFor="let slot of standardSlots; let i = index"
			inputValue="{{ date }}~{{ slot.id }}"
			[isDisabled]="slot.status === 'Closed'"
			[formControlName]="controlName"
			[successIcon]="false"
			[label]="getLabelForSlot(slot)"
		>
			<span class="tile-slotStatus" details
				>{{ getSlotStatus(slot) }}
				<cdx-svg-icon
					*ngIf="getSlotStatusIcon(slot)"
					[name]="getSlotStatusIcon(slot)"
					[fill]="getSlotStatusIconFill(slot)"
				></cdx-svg-icon>
			</span>
			<div pickupType class="pickupType">
				<form-radio-group
					*ngIf="displayPickupTypeSelector(slot)"
					[options]="pickupTypeOptions(slot)"
					[defaultValue]="pickupTypeDefaultSelection(slot)"
					groupName="pickupType"
				></form-radio-group>
			</div>
			<div
				pickupInfo
				class="pickupInfo"
				*ngIf="displayPickupTypeSelector(slot)"
				i18n="@@pickupTypeInformation-helpText"
			>
				How does Pick up work?
				<button
					cdxButton
					(click)="openPickupHelpModal()"
					fillStyle="link"
					size="small"
					inline="true"
					type="button"
					class="openPickupHelpButton"
					endSlotSize="extra-small"
					endSlotActive="true"
				>
					<span class="sr-only">Pick up information</span>
					<cdx-svg-icon name="hint-light" fill="dark" size="small" end></cdx-svg-icon>
				</button>
			</div>
			<span *ngIf="slot.discount" badge class="timeslotDiscount" i18n="@@timeslotDiscount-discountText">{{
				getSlotDiscountText(slot)
			}}</span>
			<span *ngIf="displayLockerAvailableBadge(slot)" badge class="lockerAvailable">eLockers available</span>
		</form-timeslot-selection>
	</div>
	<div *ngIf="!isLockerPickupFeatureEnabled && !isExpressPickup && !displaySlotOfferings">
		<form-selection-tile
			class="vertical"
			inputId="{{ date }}~{{ slot.id }}"
			attr.data-cy="timeslot-{{ i }}"
			*ngFor="let slot of standardSlots; let i = index"
			inputValue="{{ date }}~{{ slot.id }}"
			i18n="@@selection-time"
			[isDisabled]="slot.status === 'Closed'"
			[formControlName]="controlName"
			[successIcon]="false"
			[label]="getLabelForSlot(slot)"
		>
			<span class="tile-slotStatus" details
				>{{ getSlotStatus(slot) }}
				<cdx-svg-icon
					*ngIf="getSlotStatusIcon(slot)"
					[name]="getSlotStatusIcon(slot)"
					[fill]="getSlotStatusIconFill(slot)"
				></cdx-svg-icon>
			</span>
			<span *ngIf="slot.discount" badge class="timeslotDiscount">{{ getSlotDiscountText(slot) }}</span>
		</form-selection-tile>
	</div>
	<div *ngIf="displaySlotOfferings && !isExpressPickup">
		<form-timeslot-selection
			inputId="{{ date }}~{{ slot.id }}"
			attr.data-cy="timeslot-{{ i }}"
			*ngFor="let slot of standardSlots; let i = index"
			inputValue="{{ date }}~{{ slot.id }}"
			[isDisabled]="slot.status === 'Closed'"
			[formControlName]="controlName"
			[successIcon]="true"
			[label]="getLabelForSlot(slot)"
		>
			<span class="tile-slotStatus" details
				>{{ getSlotStatus(slot) }}
				<cdx-svg-icon
					*ngIf="getSlotStatusIcon(slot)"
					[name]="getSlotStatusIcon(slot)"
					[fill]="getSlotStatusIconFill(slot)"
				></cdx-svg-icon>
			</span>
			<div pickupType class="pickupType pickupOfferings">
				<form-radio-group
					*ngIf="displayPickupOfferingsSelector(slot)"
					[options]="pickupOfferingOptions(slot)"
					[defaultValue]="pickupOfferingsDefaultSelection(slot)"
					groupName="pickupType"
				></form-radio-group>
			</div>
			<div
				pickupInfo
				class="pickupInfo"
				*ngIf="pickupOfferingOptions(slot)?.length && displayPickupOfferingsSelector(slot)"
				i18n="@@pickupTypeInformation-helpText"
			>
				<button
					cdxButton
					(click)="openPickupPointInfoModal()"
					fillStyle="link"
					size="small"
					inline="true"
					type="button"
					class="openPickupHelpButton"
					endSlotSize="extra-small"
					endSlotActive="true"
				>
					How does Pick up work?
				</button>
			</div>
			<span *ngIf="slot.discount" badge class="timeslotDiscount" i18n="@@timeslotDiscount-discountText">{{
				getSlotDiscountText(slot)
			}}</span>
		</form-timeslot-selection>
	</div>
</fieldset>
