import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SelectionTileComponent } from '../selection-tile/selection-tile.component';
import { SvgIconComponent } from '@woolworthsnz/styleguide';
import { NgClass, NgIf } from '@angular/common';

@UntilDestroy()
@Component({
	selector: 'form-timeslot-selection',
	template: `<div class="timeslotSelector">
			<ng-content select="[badge]"></ng-content>
			<input
				[id]="inputId"
				[name]="formControlName"
				type="radio"
				(change)="onChange()"
				(blur)="onTouched($event)"
				[checked]="checked"
				[disabled]="isDisabled"
			/>
			<label [for]="inputId" [ngClass]="{ tick: successIcon }" class="pickupTypeSelectorLabel">
				<cdx-svg-icon *ngIf="iconName" [name]="iconName" fill="currentColor" size="default"></cdx-svg-icon>
				<div>
					<h2>{{ label }}</h2>
					<p *ngIf="description">{{ description }}</p>
				</div>			
				<ng-content select="[details]"></ng-content>
			</label>
			<ng-content select="[pickupType]" class="pickupTypeSelector"></ng-content>
		</div>
		<ng-content select="[pickupInfo]"></ng-content>`,
	styleUrls: ['./timeslot-selection.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: forwardRef(() => TimeslotSelectionComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [NgClass, NgIf, SvgIconComponent]
})
export class TimeslotSelectionComponent extends SelectionTileComponent { }
