import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HistoryService, RoutedModalBaseComponent, SlottedModalComponent, SvgIconComponent } from '@woolworthsnz/styleguide';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FulfilmentStoreService } from '../../services/fulfilment-store.service';
import equal from 'fast-deep-equal';
import { Observable } from 'rxjs';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
	selector: 'fulfilment-pickup-type-modal',
	templateUrl: './pickup-type-modal.component.html',
	styleUrls: ['./pickup-type-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SlottedModalComponent, NgIf, SvgIconComponent, AsyncPipe]
})
export class PickupTypeModalComponent extends RoutedModalBaseComponent {
	isInReviewOrder: boolean;
	supportsByob$: Observable<boolean>;

	constructor(
		protected router: Router,
		protected historyService: HistoryService,
		protected activatedRoute: ActivatedRoute,
		private fulfilmentStoreService: FulfilmentStoreService
	) {
		super(router, activatedRoute, historyService);
		const state = this.router.getCurrentNavigation()?.extras.state;

		this.isInReviewOrder = state?.isInReviewOrderPage;

		this.supportsByob$ = this.fulfilmentStoreService.state$.pipe(
			distinctUntilChanged((a, b) => equal(a, b)),
			map((storeState) => storeState.locker?.lockerSupportsByob ?? false)
		);
	}
}
