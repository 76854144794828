import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { queryParamsStringToObject } from '../helpers';

export interface CanonicalInfo extends ProductDetailsCanonicalInfo {
	canonicalLink?: string;
}

class ProductDetailsCanonicalInfo {
	productName: string;
}

@Injectable({
	providedIn: 'root',
})
export class MetaDataService {
	canonicalElem: HTMLLinkElement;

	constructor(@Inject(DOCUMENT) private document: Document, private titleService: Title, private metaService: Meta) {}

	setPageMeta(
		title: string,
		metaTitle: string,
		metaDescription: string,
		image: string,
		url: string,
		canonicalInfo?: CanonicalInfo
	): void {
		this.titleService.setTitle(title);
		this.setPageSEO(metaTitle, metaDescription, image, url, canonicalInfo);
	}

	setPageSEO(title: string, description: string, image: string, url: string, canonicalInfo?: CanonicalInfo): void {
		this.metaService.updateTag({ name: 'title', content: title });
		this.metaService.updateTag({ property: 'og:title', content: title });

		this.metaService.updateTag({ name: 'description', content: description });
		this.metaService.updateTag({ property: 'og:description', content: description });

		this.metaService.updateTag({ property: 'og:image', content: image });
		this.metaService.updateTag({ property: 'og:url', content: url });

		this.updateCanonical(canonicalInfo);
	}

	addCanonical(canonicalInfo?: CanonicalInfo): void {
		const canonical: HTMLLinkElement = this.document.createElement('link');
		canonical.setAttribute('rel', 'canonical');
		this.document.head.appendChild(canonical);
		canonical.setAttribute('href', this.getDocumentUrl(canonicalInfo));
	}

	removeCanonical(): void {
		const canonical: HTMLLinkElement | null = this.document.head.querySelector("link[rel='canonical']");

		if (canonical) {
			this.document.head.removeChild(canonical);
		}
	}

	getDocumentUrl = (canonicalInfo?: CanonicalInfo): string => {
		if (canonicalInfo?.canonicalLink) {
			return canonicalInfo.canonicalLink;
		}

		const url = this.document.URL.split('?');
		let params: any;
		const isSearchProductsPage = url[0].indexOf('shop/searchproducts') > -1;
		const isProductDetailPage = url[0].indexOf('shop/productdetails') > -1;
		const isRecipePage = url[0].indexOf('shop/recipe') > -1;

		if (url.length > 1) {
			params = queryParamsStringToObject(url[1]);
		}

		if (isSearchProductsPage) {
			// https://www.countdown.co.nz/shop/searchproducts?search=cheese
			return [url[0], `search=${params?.search || ''}`].join('?');
		}

		if (isProductDetailPage) {
			// https://www.countdown.co.nz/shop/productdetails?stockcode=133211&name=fresh-produce-bananas-yellow
			return `${url[0]}?stockcode=${params?.stockcode}&name=${canonicalInfo?.productName}`;
		}

		if (isRecipePage) {
			// https://www.countdown.co.nz/shop/recipe/1234?name=cheese-sandwich
			return this.document.URL;
		}

		//  https://www.countdown.co.nz/shop/checkout
		return url[0];
	};

	updateCanonical(canonicalInfo?: CanonicalInfo): void {
		this.removeCanonical();
		this.addCanonical(canonicalInfo);
	}
}
