import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalSize, RoutedModalBaseComponent } from '@woolworthsnz/styleguide';

@Component({
	selector: 'ss-change-personal-details-modal',
	template: `
        <cdx-slotted-modal (close)="close()" [size]="modalSize">
            <div modal-content>
                <p i18n="@@changePersonalDetailsModal-message">
                    To change the name or date of birth associated with your account, please email us (<a
                        href="mailto:onlineshop@woolworths.co.nz"
                > onlineshop{{'@'}}woolworths.co.nz</a
                >) or call <a href="tel:0800 40 40 40">0800&nbsp;40&nbsp;40&nbsp;40</a>.
                </p>
            </div>
            <button
                    modal-footer-primary
                    cdxButton
                    fillStyle="outline"
                    (click)="close()"
                    i18n="@@changePersonalDetailsModal-closeButton"
            >
                Close
            </button>
        </cdx-slotted-modal>
	`,
	styleUrls: ['./change-personal-details-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePersonalDetailsModalComponent extends RoutedModalBaseComponent {
	modalSize = ModalSize.Default;
}
