import { CurrencyPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { GlobalNavLibModule } from '@woolworthsnz/global-nav';
import { ContextInterceptor } from '@woolworthsnz/shop';
import {
	ACCOUNT_BASE_URL,
	ANALYTICS_PROVIDER,
	APP_SETTINGS,
	EDR_BASE_URL,
	SSO_BASE_URL,
	StyleguideModule,
	TRADER_BASE_URL,
} from '@woolworthsnz/styleguide';

import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../environments/environment';
import { AccountModule } from './account/account.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';
import {
	AuthEffects,
	authFeatureKey,
	authReducer,
	CredentialInterceptor,
	EnvService,
	EnvServiceProvider,
	featuresFeature,
} from './common';
import { StartupServiceProvider } from './common/services/startup.service';
import { EDRStateModule } from '@woolworthsnz/everyday-rewards';

export function localStorageSyncReducer(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
	return localStorageSync({ keys: ['loyalty'], rehydrate: true, restoreDates: true })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		HttpClientModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		StoreModule.forRoot({}, { metaReducers }),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
		StoreModule.forFeature(authFeatureKey, authReducer),
		StoreModule.forFeature(featuresFeature),
		EffectsModule.forFeature([AuthEffects]),
		AccountModule,
		StyleguideModule.forRoot(),
		GlobalNavLibModule,
		EDRStateModule,
	],
	providers: [
		StartupServiceProvider,
		{ provide: APP_SETTINGS, useValue: AppSettings },
		EnvServiceProvider,
		{ provide: Window, useValue: window },
		{
			provide: TRADER_BASE_URL,
			useFactory: (envService: EnvService) => envService.countdownBaseUrl,
			deps: [EnvService],
		},
		{ provide: SSO_BASE_URL, useFactory: (envService: EnvService) => envService.ssoServiceUrl, deps: [EnvService] },
		{
			provide: SSO_BASE_URL,
			useFactory: (envService: EnvService) => envService.ssoServiceUrl,
			deps: [EnvService],
		},
		{
			provide: ACCOUNT_BASE_URL,
			useFactory: (envService: EnvService) => envService.baseUrl,
			deps: [EnvService],
		},
		{
			provide: EDR_BASE_URL,
			useFactory: (envService: EnvService) => envService.edrBaseUrl,
			deps: [EnvService],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ContextInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CredentialInterceptor,
			multi: true,
		},
		CurrencyPipe,
		DatePipe,
		{
			provide: ANALYTICS_PROVIDER,
			useExisting: TealiumUtagService,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
