import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeDetectionStrategy, Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EnvService } from '../../../common';
import { CustomWindow, WINDOW } from '@woolworthsnz/styleguide';
import { filter, first, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectProfile, selectProfileLoading, getProfile, saveProfile } from '../../state/profile';
import { Observable } from 'rxjs';
import {
	selectPreviousEmail,
	selectUpdateSuccess,
	selectUpdateEmailFailure,
} from '../../state/profile/profile.selectors';

@UntilDestroy()
@Component({
	selector: 'ss-manage-email-password',
	templateUrl: './manage-email-password.component.html',
	styleUrls: ['./manage-email-password.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageEmailPasswordComponent implements OnInit {
	emailPasswordForm!: UntypedFormGroup;
	enableEmail = false;
	previousEmail?: string;
	resetPasswordRoute: string;
	showParagraph = true;
	hasError = false;
	currentValue = '';

	updateSuccess$: Observable<boolean>;
	updateEmailFailed$: Observable<boolean>;

	changeEmailSuccessTitle = 'Successful';
	changeEmailSuccessMsg =
		'Your email has been updated successfully. Updating your email address here will update your login email for Woolworths.';
	changeEmailErrorTitle = 'Unable to update email address';
	changeEmailErrorMsg =
		'Sorry, we were unable to update your email address at this time. Please try again later or contact our Customer Service team on 0800 40 40 40.';

	constructor(
		private fb: UntypedFormBuilder,
		private envService: EnvService,
		private store: Store,
		private cdr: ChangeDetectorRef,
		@Inject(WINDOW) private window: CustomWindow
	) {
		this.resetPasswordRoute = `${
			this.envService.ssoServiceUrl
		}/?action=resetPassword&client=cdx&redirectUrl=${encodeURIComponent(this.envService.countdownBaseUrl)}`;
		this.updateSuccess$ = this.store.select(selectUpdateSuccess);
		this.updateEmailFailed$ = this.store.select(selectUpdateEmailFailure);

		this.store.select(selectPreviousEmail).subscribe((email) => {
			this.previousEmail = email;
			this.validateEmailButton();
		});
	}

	ngOnInit() {
		this.store.dispatch(getProfile());

		const { required, email, maxLength } = Validators;
		const formControls = {
			changeEmailInput: ['', [required, email, maxLength(50)]],
		};
		this.emailPasswordForm = this.fb.group(formControls, { updateOn: 'change' });

		this.emailPasswordForm.controls['changeEmailInput'].valueChanges.subscribe((value) => {
			this.hasError = this.emailPasswordForm.controls['changeEmailInput'].status === 'INVALID';
			this.currentValue = value;
			this.validateEmailButton();
		});
		this.setInitialFormState();
	}

	validateEmailButton() {
		this.enableEmail = !this.hasError && Boolean(this.previousEmail && this.previousEmail !== this.currentValue);
	}

	save() {
		this.store
			.select(selectProfile)
			.pipe(
				untilDestroyed(this),
				switchMap(() => this.store.select(selectProfile)),
				first()
			)
			.subscribe((profile) => {
				const customerProfile = {
					...profile,
					email: this.emailPasswordForm?.get('changeEmailInput')?.value,
				};
				this.store.dispatch(saveProfile({ profile: customerProfile }));
				this.showParagraph = false;
				this.cdr.markForCheck();
			});
	}

	private setInitialFormState() {
		this.store
			.select(selectProfileLoading)
			.pipe(
				untilDestroyed(this),
				filter((loading) => !loading),
				switchMap(() => this.store.select(selectProfile)),
				first()
			)
			.subscribe((profile) => {
				this.emailPasswordForm.setValue({
					changeEmailInput: profile?.email || '',
				});
				this.cdr.markForCheck();
			});
	}

	resetPassword() {
		this.window.location.assign(this.resetPasswordRoute);
	}
}
