import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DocumentEventService {
	/**
	 * Use this when wanting to register an @HostListener for 'document:click' so that there is only one setup in
	 * any given app, for performance.
	 * E.g.
	 * ```
	 * // In component
	 * constructor(private documentEventService: DocumentEventService) {}
	 *
	 * ngOnInit() {
	 *   this.documentEventService.documentClick$.subscribe(...)
	 * }
	 * ```
	 */
	documentClick$ = new Subject<MouseEvent>();

	constructor(@Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		this.document.addEventListener('click', ($event) => this.documentClick$.next($event));
	}
}
