import { ɵComponentType as ComponentType } from '@angular/core';
import { isObservable, Observable, Subscription } from 'rxjs';
import { AppSettingsService, MetaDataService, RouterNavigationService, SiteManagementService } from '../services';
import { InjectorContainerModule } from './injector-container.module';
import { MetaTagSettingResponse } from '@woolworthsnz/trader-api';
import { switchMap } from 'rxjs/operators';

const DEFAULT_META_NAME = 'default';

export interface PageMeta {
	asyncMeta$: Observable<MetaOptions>;
}

function setMetaFromOptions(
	metaDataService: MetaDataService,
	appSettingsService: AppSettingsService,
	metaOptions: MetaOptions,
	metaTagSetting: MetaTagSettingResponse | null
): void {
	// Configured SOE meta tag settings takes precedence over the detail meta tag options
	if (metaTagSetting !== null) {
		metaDataService.setPageMeta(
			metaTagSetting.title ||
			appSettingsService.getPageTitle(metaOptions.pageTitle || DEFAULT_META_NAME, metaOptions.pageTitleName),
			metaTagSetting.title ||
			appSettingsService.getMetaTitle(metaOptions.metaTitle || DEFAULT_META_NAME, metaOptions.metaTitleName),
			metaTagSetting.description ||
			appSettingsService.getMetaDescription(
				metaOptions.metaDescription || DEFAULT_META_NAME,
				metaOptions.metaDescriptionName
			),
			metaTagSetting.image ?? appSettingsService.getMetaImage(metaOptions.metaImage || DEFAULT_META_NAME),
			appSettingsService.getMetaUrl(metaOptions.metaUrl || DEFAULT_META_NAME)
		);
	} else {
		metaDataService.setPageMeta(
			appSettingsService.getPageTitle(metaOptions.pageTitle || DEFAULT_META_NAME, metaOptions.pageTitleName),
			appSettingsService.getMetaTitle(metaOptions.metaTitle || DEFAULT_META_NAME, metaOptions.metaTitleName),
			appSettingsService.getMetaDescription(
				metaOptions.metaDescription || DEFAULT_META_NAME,
				metaOptions.metaDescriptionName
			),
			appSettingsService.getMetaImage(metaOptions.metaImage || DEFAULT_META_NAME),
			appSettingsService.getMetaUrl(metaOptions.metaUrl || DEFAULT_META_NAME)
		);
	}
}

function setPageMetaInOnInit<T>(type: ComponentType<T>, metaOptions: MetaOptions = {}): void {
	// Grab the original ngOnInit function
	const originalNgOnInit = type.prototype.ngOnInit;
	// Grab the original ngOnDestroy function
	const originalNgOnDestroy = type.prototype.ngOnDestroy;
	let asyncMetaSub: Subscription;
	let metaTagOptionsOverride: any = null;
	let pageContentChangeSub: Subscription;
	let fetchInitialMetaTagSettings: Subscription;

	type.prototype.ngOnInit = function (args: any) {
		const metaDataService = InjectorContainerModule.injector.get(MetaDataService);
		const appSettingsService = InjectorContainerModule.injector.get(AppSettingsService);
		const siteManagementService = InjectorContainerModule.injector.get(SiteManagementService);
		const routerNavigationService = InjectorContainerModule.injector.get(RouterNavigationService);

		const setMetaDataFromMetaTagSettings = (metaTagSettingsResponse: MetaTagSettingResponse | null): void => {
			setMetaFromOptions(
				metaDataService,
				appSettingsService,
				metaTagOptionsOverride || metaOptions,
				metaTagSettingsResponse
			);
		};

		// Check if the component has defined the observable (so implemented the interface)
		const asyncMeta$ = this['asyncMeta$'];

		// If the component is using the observable we want to set the meta when it emits
		if (asyncMeta$ && isObservable(asyncMeta$)) {
			asyncMetaSub = asyncMeta$.subscribe((asyncMetaOptions) => {
				metaTagOptionsOverride = asyncMetaOptions;
				setMetaFromOptions(metaDataService, appSettingsService, <MetaOptions>asyncMetaOptions, null);
			});
		} else {
			setMetaFromOptions(metaDataService, appSettingsService, metaOptions, null);
		}

		// Call the original ngOnInit
		if (originalNgOnInit) {
			originalNgOnInit.apply(this, args);
		}

		// Reload meta tag settings for every new base URL (page URL)
		pageContentChangeSub = routerNavigationService.baseUrl$
			.pipe(switchMap(() => siteManagementService.fetchCurrentPageMetaTagSettings$()))
			.subscribe((metaTagSettingsResponse) => {
				setMetaDataFromMetaTagSettings(<MetaTagSettingResponse>metaTagSettingsResponse);
			});

		// Load meta tag settings on initial component load
		fetchInitialMetaTagSettings = siteManagementService
			.fetchCurrentPageMetaTagSettings$()
			.subscribe((metaTagSettingsResponse) => {
				setMetaDataFromMetaTagSettings(metaTagSettingsResponse);
			});
	};

	// Unsubscribe if the component is using the async method
	type.prototype.ngOnDestroy = function (args: any) {
		asyncMetaSub?.unsubscribe();
		pageContentChangeSub?.unsubscribe();
		fetchInitialMetaTagSettings?.unsubscribe();

		// Call the original ngOnDestroy
		if (originalNgOnDestroy) {
			originalNgOnDestroy.apply(this, args);
		}
	};
}

export interface MetaOptions {
	pageTitle?: string;
	pageTitleName?: string;
	metaTitle?: string;
	metaTitleName?: string;
	metaDescription?: string;
	metaDescriptionName?: string;
	metaUrl?: string;
	metaImage?: string;
	delayedSet?: string;
}

/**
 * Class Decorator to set page title, meta title and meta description.
 * Uses appSettingsService and metaDataService under the hood so make sure to setup the corresponding values in app.settings.ts
 *
 * usage:
 * ```
 * @WithPageMeta('pageTitle', 'metaTitle', 'metaDescription')
 * @Component({
 *     ...
 * })
 * ```
 * @param metaOptions The keys to pass to AppSettingsService to look up the meta with
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function WithPageMeta(metaOptions?: MetaOptions): ClassDecorator {
	return (type: any) => {
		setPageMetaInOnInit(type, metaOptions);
	};
}
