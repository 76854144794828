import { ProductResponse } from '@woolworthsnz/trader-api';
import { ImageResponse, ProductType } from '.';

export interface ProductImpressionData extends ProductResponse {
	productType?: ProductType;
	position?: string | number;
	searchValue?: string;
	searchResultsConunt?: string;
}

export interface PromoTileImpressionData {
	itemId: string;
	itemName: string;
	index: string | number;
	itemCategory: string;
}
export interface PromoImpressionArgs {
	id: string;
	name: string;
	creative: string;
	position?: string | number;
	carouselName?: string | null;
	imagePath?: string;
	link?: string;
}

export interface PromoBannerImpressionArgs {
	creativeName?: string;
	creativeSlot?: string;
	bannerPromotionId: string;
	bannerPromotionName?: string | number;
	creativeName360: string;
	carouselName?: string | null;
	imagePath?: string;
	link?: string;
}

export interface PromoTileImpressionArgs {
	creativeName: string;
	creativeSlot: string;
	promotionId: string;
	promotionName: string | number;
}

export interface PromoBasicArgs {
	name: string;
	itemId: number;
	tileIndex: number;
}
export interface PromoCPPImpressionArgs {
	creativeName: string;
	creativeSlot: string;
	promotionId: string;
	promotionName: string | number;
	items: PromoCPPImpressionData[];
}
export interface PromoCPPImpressionData {
	itemId?: string;
	itemName?: string;
	index?: string | number;
	itemBrand?: string;
	itemCategory?: string;
	itemCategory2?: string;
	itemCategory3?: string;
	itemListId?: string;
	itemListName?: string;
	itemVariant?: string;
}
export interface ProductImpressionArgs {
	product: ProductImpressionData | PromotionalCarouselResponseModel;
	carouselName?: string;
}

export interface ProductImpressionAndViewItemListArgs {
	productImpressionList: ProductImpressionArgs;
	viewItemList: ViewItemListArgs;
	viewPromotionCPP?: PromoCPPImpressionArgs;
}

export interface ViewItemListArgs {
	pageItemListId?: string;
	pageItemListName?: string;
	items: ViewItemData[];
	carouselName?: string;
}

export interface ViewItemDataBase {
	type?: ProductType;
	index: number;
	promotionProductTags?: string[];
	discount?: number;
	itemBrand?: string;
	itemCategory: string;
	itemCategory2: string;
	itemCategory3: string;
	itemListId?: string;
	itemListName?: string;
	itemVariant?: string;
	price?: number;
	hasShopperNotes?: boolean;
	filterTypes?: string[];
	quantity?: number;
	eachKg?: string;
	availabilityStatus?: string;
}

export interface ViewItemData extends ViewItemDataBase {
	itemId: string;
	itemName: string;
}

export interface ViewItemListImpressionData extends ViewItemDataBase {
	id: string | undefined;
	name: string | undefined;
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface RecipeImpressionArgs {
	tealium_event: string;
	interaction_source: string;
	recipe_name: string[];
	recipe_id: string[];
	recipe_category: string[];
	recipe_prep_time: string[];
	recipe_cook_time: string[];
	recipe_servings: string[];
	position: number;
	carouselName: string;
}

export enum ImpressionTrackingBehaviourType {
	TrackOnFirstIntersectionOnly = 'TrackOnFirstIntersectionOnly',
	TrackOnEachIntersection = 'TrackOnEachIntersection',
	Disabled = 'Disabled',
}

export interface PromotionalCarouselResponseModel {
	type: string;
	id: number;
	name: string;
	image: ImageResponse;
	mobileImage: ImageResponse;
	link: string;
	positionIndex: number;
	headingText: string;
	paragraphText: string;
	products: ProductResponse[];
}
