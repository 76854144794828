import { Injectable } from '@angular/core';
import { ApiService, AppSettingsService, StatefulService } from '@woolworthsnz/styleguide';
import {
	AddressesSearchResponse,
	AreaResponse,
	StoreAddress,
	StoreAddressesResponse,
	StoreArea,
} from '@woolworthsnz/trader-api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

export class AddressState implements StoreAddressesResponse {
	storeAreas: StoreArea[];
	recentlyPickedUpStoreAddresses: StoreAddress[];
}

@Injectable({
	providedIn: 'root',
})
export class AddressService extends StatefulService<AddressState> {
	// TODO: [CO-7517] Migrate to state$
	addressSelected$: BehaviorSubject<boolean> = new BehaviorSubject(<boolean>false);
	result: Subject<any> = new Subject();
	areaResult: Subject<AreaResponse> = new Subject();
	error: Subject<any> = new Subject();
	areaError: Subject<any> = new Subject();

	result$: Observable<AddressesSearchResponse> = this.result.asObservable();
	areaResult$: Observable<AreaResponse> = this.areaResult.asObservable();
	error$: Observable<any> = this.error.asObservable();
	areaError$: Observable<any> = this.areaError.asObservable();

	constructor(private apiService: ApiService, private appSettingsService: AppSettingsService) {
		super(new AddressState());
	}

	get addressEndpoint(): string {
		return this.appSettingsService.getEndpoint('address');
	}

	get pickupAddressEndpoint(): string {
		return this.appSettingsService.getEndpoint('pickupAddresses');
	}

	get externalAddressEndpoint(): string {
		return this.appSettingsService.getEndpoint('externalAddress');
	}

	fetchAddress = (typedValue: string): void => {
		this.unselectAddress();
		this.apiService
			.get(this.addressEndpoint, {
				params: {
					query: typedValue,
				},
			})
			.pipe(take(1))
			.subscribe(
				(response: AddressesSearchResponse) => {
					this.result.next(response);
				},
				(error) => {
					this.error.next(error);
				}
			);
	};

	fetchAddressArea = (addressId: string): void => {
		this.apiService
			.get(this.externalAddressEndpoint, {
				params: {
					addressId: addressId,
				},
			})
			.pipe(take(1))
			.subscribe(
				(response: AreaResponse) => {
					this.areaResult.next(response);
				},
				(error) => {
					this.areaError.next(error);
				}
			);
	};

	getPickupAddresses = (): Observable<any> =>
		this.apiService.get(this.pickupAddressEndpoint).pipe(
			take(1),
			tap((r) =>
				this.setState({
					storeAreas: r.storeAreas,
					recentlyPickedUpStoreAddresses: r.recentlyPickedUpStoreAddresses,
				})
			)
		);

	selectAddress = (): void => this.addressSelected$.next(true);

	unselectAddress = (): void => this.addressSelected$.next(false);
}
