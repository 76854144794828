import { Injectable } from '@angular/core';
import { BrandSuggestionDetails } from '../ui-models';

/**
 * Promotion service
 */
@Injectable({
	providedIn: 'root',
})
export class PromotionService {
	brandSuggestionDetails: BrandSuggestionDetails;

	onBrandSuggestionClick(details: BrandSuggestionDetails): BrandSuggestionDetails {
		this.brandSuggestionDetails = details;
		return this.brandSuggestionDetails;
	}

	trackConversion(quantity: number, url: string): string | undefined {
		if (this.brandSuggestionDetails?.linkUrl === undefined || quantity === 0) {
			return undefined;
		}

		if (url !== this.brandSuggestionDetails.linkUrl) {
			this.reinitBrandSuggestionDetail();
		}

		return this.brandSuggestionDetails.id;
	}

	private reinitBrandSuggestionDetail(): void {
		this.brandSuggestionDetails = {
			linkUrl: undefined,
			id: undefined,
		};
	}
}
