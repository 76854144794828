import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
	AbstractControl,
	FormGroup,
	FormGroupDirective,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiService } from '@woolworthsnz/styleguide';
import { SuburbResult, SuburbsSearchResponse } from '@woolworthsnz/trader-api';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FormService, SuburbService } from '../../services';
import { InputSize, InputComponent } from '../input/input.component';
import { InvalidTypeDirective } from '../../directives/invalid-type.directive';
import { InputErrorComponent } from '../input-error/input-error.component';
import { FormHelpTextComponent } from '../form-help-text/form-help-text.component';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';

@UntilDestroy()
@Component({
	selector: 'form-suburb-autocomplete',
	template: `
		<form-autocomplete
			(typedValue)="fetchSuburb($event)"
			[searchResults]="searchResults"
			[formGroup]="parentFormGroup"
			displayKey="text"
			valueKey="id"
			(optionSelect)="handleSuburbSelection($event)"
			[idPrefix]="suggestionIdentifierPrefix"
			(optionFocus)="handleSuburbFocus($event)"
		>
			<form-input
				formControlName="suburb"
				maxlength="255"
				[size]="size"
				[successIcon]="true"
				[autocomplete]="identifier"
				[id]="identifier"
				[name]="identifier"
				[activeDescendant]="focussedSuggestion"
				placeholder="Start typing your suburb/town"
			>
				<label i18n="@@register-suburbLabel" [for]="identifier" label> Delivery zone </label>
				<p cdxFormHelpText i18n="@@register-suburbHelperText">
					Please choose the area closest to your address. This allows us to show you the right products and
					delivery options.
				</p>
				<form-input-error validation control="suburb">
					<p [cdxInvalidType]="'suburbNotSelected'" i18n="@@register-suburbRequiredErrorMessage">
						Please enter your Delivery zone.
					</p>
				</form-input-error>
			</form-input>
		</form-autocomplete>
	`,
	providers: [ApiService, FormService, SuburbService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AutocompleteComponent,
		FormsModule,
		ReactiveFormsModule,
		InputComponent,
		FormHelpTextComponent,
		InputErrorComponent,
		InvalidTypeDirective,
	],
})
export class SuburbAutocompleteComponent implements OnInit, OnDestroy {
	@Input() size: InputSize;

	searchResults: Array<SuburbResult> = [];
	parentFormGroup: FormGroup;
	typedValue: string;
	identifier: string;
	focussedSuggestion: string;
	suggestionIdentifierPrefix = 'suburbSuggestion_';

	constructor(
		private cdr: ChangeDetectorRef,
		public suburbService: SuburbService,
		private formService: FormService,
		public parent: FormGroupDirective,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.identifier = `chrome-bust-${dayjs().unix()}-suburb`;
		this.parentFormGroup = this.parent.form;
		this.formService.addControlToParent('suburb', [], this.parentFormGroup, this.validateSuburb);
		this.formService.addControlToParent('suburbId', [Validators.required], this.parentFormGroup);

		this.suburbService.result$.pipe(untilDestroyed(this)).subscribe(this.onFetchSuccess);

		this.suburbService.error$.pipe(untilDestroyed(this)).subscribe(this.onFetchFailure);
	}

	ngOnDestroy(): void {
		this.parentFormGroup.removeControl('suburb');
		this.parentFormGroup.removeControl('suburbId');
	}

	fetchSuburb = (typedValue: string): void => {
		this.typedValue = typedValue;
		this.parentFormGroup.controls['suburbId'].patchValue('');
		this.searchResults = [];
		this.suburbService.fetchSuburb(typedValue);
	};

	handleSuburbSelection(suburb: { id: number }): void {
		this.suburbService.selectSuburb();
		this.typedValue = '';
		this.parentFormGroup.controls['suburbId'].patchValue(suburb.id);
		this.parentFormGroup.controls['suburb'].updateValueAndValidity();
	}

	handleSuburbFocus(event: any): void {
		this.focussedSuggestion = `${this.suggestionIdentifierPrefix}${event}`;
	}

	validateSuburb = (
		control: AbstractControl
	): Observable<{
		suburbNotSelected: boolean;
	} | null> =>
		this.suburbService.suburbSelected$.pipe(
			map((isSelected) => (isSelected && control.value ? null : { suburbNotSelected: true })),
			take(1)
		);

	onFetchSuccess = (result: SuburbsSearchResponse): void => {
		const { suburbResults } = result;
		this.searchResults = suburbResults || [];
		this.cdr.markForCheck();
	};

	onFetchFailure = (error: any): void => {
		this.searchResults = [];

		throw Error(error.message);
	};
}
