import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { StoresService } from '../../services/stores/stores.service';
import { preferencesPageLoaded } from '../preferences';
import { getStores, getStoresCompleted } from './stores.actions';

@Injectable()
export class StoresEffects {
	onGetStores$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(getStores),
			mergeMap(() => this.storesService.getStores()),
			map((res) => getStoresCompleted({ stores: res.siteDetail.map((s) => s.site) }))
		);
	});

	onPreferencesPageLoaded$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(preferencesPageLoaded),
			map(() => getStores())
		);
	});

	constructor(private actions$: Actions, private storesService: StoresService) {}
}
