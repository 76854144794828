import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

/*
 * This is a work around for a known bug.
 * only present in Safari.
 * https://github.com/angular/angular/issues/21782#issuecomment-461075135
 */

@Directive({
	selector: '[formInputText]',
	standalone: true,
})
export class InputAccessorDirective {
	private currentValue: string | null = null;

	constructor(private elRef: ElementRef, private renderer: Renderer2) {}

	@Input() set value(value: string | null) {
		if (value !== this.currentValue) {
			this.renderer.setProperty(this.elRef.nativeElement, 'value', value);
		}
	}

	@HostListener('input', ['$event']) onInput(event: any): void {
		if (this.currentValue !== event.target.value) {
			this.currentValue = event.target.value;
		}
	}
}
