import { AsyncPipe, DecimalPipe, I18nPluralPipe, isPlatformServer, NgIf, NgTemplateOutlet } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
	ViewChild,
} from '@angular/core';
import { animationFrameScheduler, endWith, interval, map, Observable, of, switchMap, take } from 'rxjs';
import {
	CutoutModalTargetDirective,
	PopupComponent,
	PopupTargetDirective,
	TruncatedCurrencyPipe,
} from '@woolworthsnz/styleguide';
import { edrConfig } from '../../config';

@Component({
	selector: 'edr-points-dial',
	templateUrl: './points-dial.component.html',
	styleUrls: ['./points-dial.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgIf,
		AsyncPipe,
		DecimalPipe,
		I18nPluralPipe,
		TruncatedCurrencyPipe,
		NgTemplateOutlet,
		PopupComponent,
		CutoutModalTargetDirective,
		PopupTargetDirective,
	],
})
export class EDRPointsDialComponent implements OnInit, AfterViewInit {
	@ViewChild(PopupComponent) popup: PopupComponent;
	@ViewChild(PopupTargetDirective) popupTarget: PopupTargetDirective;
	@Input() public pointsBalance = 0;
	@Input() public totalPointsRequired = 2000;
	@Input() public mini = false;
	@Input() public reversed = false;
	@HostBinding('attr.isError') @Input() public isError = false;

	@ViewChild('progressDial') public progressDial: ElementRef | undefined;

	public readonly outerCircleDashedStroke: string;
	public pointsAndActivityUrl = `${edrConfig.url}/${edrConfig.routes.account.base}/${edrConfig.routes.account.pointsAndActivity}`;

	public animatingCount$: Observable<number> | undefined;
	public inView$ = new EventEmitter<boolean>();

	public minTextWidthBreakpointReached$: Observable<boolean> | undefined;

	private readonly _outerCircleTotal = 75;
	private readonly _outerCircleOffset = 25;

	constructor(@Inject(PLATFORM_ID) private platformId: object) {
		this.outerCircleDashedStroke = `${this._outerCircleTotal},${this._outerCircleOffset}`;
	}

	public get pointsRemaining(): number {
		return this.totalPointsRequired - this.pointsBalance;
	}

	public get dashedStrokeValues(): string {
		let filledTotal = 0;

		filledTotal = this.pointsBalance / this.totalPointsRequired;
		filledTotal = filledTotal > 1 ? 1 : filledTotal;

		const innerStrokeSize = filledTotal * this._outerCircleTotal;

		return `${innerStrokeSize},${100 - innerStrokeSize}`;
	}

	public ngOnInit(): void {
		if (this.noIntersectionObserver()) {
			this.inView$.next(true);
			this.animatingCount$ = this.setupCounterAnimation();
			return;
		}

		// wait until its in-view then switch map to the animation events
		this.animatingCount$ = this.inView$.pipe(switchMap(() => this.setupCounterAnimation()));

		// Points balance can never be more than total points required
		if (this.pointsBalance > this.totalPointsRequired) {
			this.pointsBalance = this.totalPointsRequired;
		}
	}

	public ngAfterViewInit(): void {
		if (this.progressDial?.nativeElement) {
			this.minTextWidthBreakpointReached$ = of(false);
		}

		if (this.noIntersectionObserver()) {
			return;
		}

		const threshold = 0.5; // when 20% of the dial is in view start animating
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						this.inView$.next(true);
						observer.disconnect();
					}
				});
			},
			{ threshold }
		);
		observer.observe(this.progressDial?.nativeElement);
	}

	public noIntersectionObserver(): boolean {
		return isPlatformServer(this.platformId);
	}

	public showTooltip(): void {
		this.popup.target = this.popupTarget;
		setTimeout(() => {
			this.popup.openModal();
		}, 0);
	}

	private setupCounterAnimation(): Observable<number> {
		const animationTime = 2000; // ms
		const intervalTime = 13; // runs every 13ms

		// number of updates required to keep it within the desired animation time
		const numberOfUpdates = animationTime / intervalTime;

		return interval(intervalTime, animationFrameScheduler).pipe(
			map((frameNumber) => {
				// creates an ease from 0 - 1 based on the interval
				let t = frameNumber / numberOfUpdates;
				const ease = --t * t * t + 1; // ease out cubic function

				return Math.round(this.pointsBalance * ease);
			}),
			take(numberOfUpdates),
			endWith(this.pointsBalance)
		);
	}
}
