import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { v4 as uuidv4 } from 'uuid';
import { LocalStorageService } from './local-storage.service';
import { CustomWindow, WINDOW } from './window.service';

export const cookieAppSettingsConstants = {
	namespace: 'appsettings',
	browserSessionId: 'browserSessionId',
} as const;

export const oneYearInTheFuture = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

@Injectable({
	providedIn: 'root',
})
export class CookieAppSettingsService {
	constructor(
		private cookieService: CookieService,
		private localStorageService: LocalStorageService,
		@Inject(WINDOW) private window: CustomWindow,
		@Inject(PLATFORM_ID) private platform: any
	) {}

	getCookieSetting(settingKey: string): string {
		return this.cookieService.get(`${cookieAppSettingsConstants.namespace}-${settingKey}`);
	}

	setCookieSetting(settingKey: string, settingValue: string, expires?: number | Date | undefined): void {
		const domain = this.getOrganizationLevelDomain();
		return this.cookieService.set(
			`${cookieAppSettingsConstants.namespace}-${settingKey}`,
			settingValue,
			expires,
			'/',
			domain
		);
	}

	/**
	 * The browser session id is not actually bound to the users' session, but is an identifier that's valid
	 * for a year after having been set and is used to identify a user.
	 */
	setBrowserSessionId(): string {
		const cookieBrowserSessionId = this.getCookieSetting(cookieAppSettingsConstants.browserSessionId);

		const browserSessionId = cookieBrowserSessionId ? cookieBrowserSessionId : uuidv4();

		this.setCookieSetting(cookieAppSettingsConstants.browserSessionId, browserSessionId, oneYearInTheFuture);
		this.setBrowserSessionIdInLocalStorage(browserSessionId);

		return browserSessionId;
	}

	getBrowserSessionId(): string {
		const browserSessionId =
			this.getBrowserSessionIdFromLocalStorage() || this.getCookieSetting(cookieAppSettingsConstants.browserSessionId);

		/**
		 * Because the session id in the localStorage takes precedence over the value in the cookie and because the cookie,
		 * might be deleted we should set the cookie if the value differs from the value in localStorage
		 */
		if (browserSessionId && browserSessionId !== this.getCookieSetting(cookieAppSettingsConstants.browserSessionId)) {
			this.setCookieSetting(cookieAppSettingsConstants.browserSessionId, browserSessionId, oneYearInTheFuture);
		}

		return browserSessionId ? browserSessionId : this.setBrowserSessionId();
	}

	/**
	 * We're backing up the browser session id in local storage because of an obscure bug that in some scenarios
	 * sometimes deletes the cookie resulting in an inconsistent user experience when running Optimizely experiments.
	 * This solutions will only work in production as lower environments have trader and CSX
	 * running on differnt domains and localStorage is domain-specific.
	 */
	private setBrowserSessionIdInLocalStorage(browserSessionId: string): string {
		const key = `${cookieAppSettingsConstants.namespace}-${cookieAppSettingsConstants.browserSessionId}`;
		const backedUpBrowserSessionId = this.localStorageService.getItem(key);
		if (!!backedUpBrowserSessionId) {
			return backedUpBrowserSessionId;
		}
		this.localStorageService.setItem(key, browserSessionId);
		return browserSessionId;
	}

	private getBrowserSessionIdFromLocalStorage(): string {
		return (
			this.localStorageService.getItem(
				`${cookieAppSettingsConstants.namespace}-${cookieAppSettingsConstants.browserSessionId}`
			) || ''
		);
	}

	private getOrganizationLevelDomain(): string | undefined {
		if (isPlatformServer(this.platform)) {
			return;
		}
		const randomCookieName = 'countdown_random_cookie_name=cookie',
			hostname = this.window?.document?.location.hostname.split('.');
		for (let i = hostname.length - 1; i >= 0; i--) {
			const h = hostname.slice(i).join('.');
			document.cookie = randomCookieName + ';domain=.' + h + ';';
			if (document.cookie.indexOf(randomCookieName) > -1) {
				document.cookie = randomCookieName.split('=')[0] + '=;domain=.' + h + ';expires=Thu, 01 Jan 1970 00:00:01 GMT;';
				return h;
			}
		}
	}
}
