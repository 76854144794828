<ng-container *ngIf="rewardsAvailable; else noVouchers">
	<div class="voucher-notification" [attr.data-mini]="mini">
		@if (!mini) {
			<h2 class="voucher-notification__title--large">{{ rewardsAvailable | truncatedCurrency }}</h2>
		}
		<div class="voucher-notification__subtitle">
			@if (mini) {
				<span class="voucher-notification__title--small">{{ rewardsAvailable | truncatedCurrency }}&nbsp;</span>
			}
			<span class="voucher-notification__subtitle--text">{{ vouchersText }}</span>
		</div>
	</div>
</ng-container>

<ng-template #noVouchers>
	<div class="no-voucher-notification" [attr.data-mini]="mini">
		<p>
			You're
			<span
				class="no-voucher-notification__points-required">{{
                    pointsRequired | i18nPlural: {
                        '=1': '# point',
                        other: '# points'
                    }
				}}</span>
			away from your next reward
		</p>
	</div>
</ng-template>
