import { isDevMode, ɵComponentType as ComponentType } from '@angular/core';
import { PerformanceTrackingService } from '../services';
import { InjectorContainerModule } from './injector-container.module';

/**
 * Applied to definitions when `manualMode` is set and informs that class has called `endPaintTracking`
 */
const MANUAL_PAINT_TRACKING_ENDED: unique symbol = Symbol('__manualPaintTrackingEnded');

function markAsManuallyTracking<T>(cmpType: ComponentType<T>): void {
	cmpType.prototype[MANUAL_PAINT_TRACKING_ENDED] = false;
}

function markAsManuallyTracked<T>(cmpType: ComponentType<T>): void {
	cmpType.prototype[MANUAL_PAINT_TRACKING_ENDED] = true;
}

function hasManualPaintTrackingEnded<T>(cmpType: ComponentType<T>): boolean {
	return MANUAL_PAINT_TRACKING_ENDED in cmpType.prototype && cmpType.prototype[MANUAL_PAINT_TRACKING_ENDED] === true;
}

function addTrackingToOnInit<T>(cmpType: ComponentType<T>, componentName: string): void {
	// Grab the original lifecycle hook if there is one
	const originalNgOnInit = cmpType.prototype.ngOnInit;

	// Override with our own one
	cmpType.prototype.ngOnInit = function (args: any) {
		// Get a reference to our service from the injector and start tracking
		const performanceTrackingService = InjectorContainerModule.injector.get(PerformanceTrackingService);
		performanceTrackingService.startTracking(`${componentName}Paint`);

		// Call the original lifecycle function
		if (originalNgOnInit) {
			originalNgOnInit.apply(this, args);
		}
	};
}

function addTrackingToAfterViewInit<T>(cmpType: ComponentType<T>, componentName: string): void {
	// Grab the original lifecycle hook if there is one
	const originalNgAfterViewInit = cmpType.prototype.ngAfterViewInit;

	// Override with our own one
	cmpType.prototype.ngAfterViewInit = function (args: any) {
		// Get a reference to our service from the injector and end tracking
		const performanceTrackingService = InjectorContainerModule.injector.get(PerformanceTrackingService);
		performanceTrackingService.endPaintTracking(`${componentName}Paint`);

		// Call the original lifecycle function
		if (originalNgAfterViewInit) {
			originalNgAfterViewInit.apply(this, args);
		}
	};
}

function addEndPaintTrackingFnToPrototype<T>(cmpType: ComponentType<T>, componentName: string): void {
	cmpType.prototype.endPaintTracking = (customProperties?: any) => {
		// Get a reference to our service from the injector and end tracking
		const performanceTrackingService = InjectorContainerModule.injector.get(PerformanceTrackingService);
		performanceTrackingService.endPaintTracking(`${componentName}Paint`, customProperties);

		markAsManuallyTracked(cmpType);
	};
}

function checkPaintTrackingIsStoppedBeforeComponentDestroyed<T>(
	cmpType: ComponentType<T>,
	componentName: string
): void {
	// Grab the original lifecycle hook if there is one
	const originalNgOnDestroy = cmpType.prototype.ngOnDestroy;

	// Override with our own one
	cmpType.prototype.ngOnDestroy = function (args: any) {
		// Check if `endPaintTracking` has been called on the instance before being destroyed
		if (!hasManualPaintTrackingEnded(cmpType)) {
			// eslint-disable-next-line no-console
			console.error(
				`${componentName} is using WithPerformanceTracking with manualMode enabled but endPaintTracking was not called before the component was destroyed`
			);

			// Call the original lifecycle function
			if (originalNgOnDestroy) {
				originalNgOnDestroy.apply(this, args);
			}
		}
	};
}

function addTrackingToLifecycleHooks<T>(cmpType: ComponentType<T>, componentName: string, manualMode: boolean): ComponentType<T> {
	// Start tracking
	addTrackingToOnInit(cmpType, componentName);

	if (!manualMode) {
		// End tracking automatically in AfterViewInit
		addTrackingToAfterViewInit(cmpType, componentName);
	} else {
		// Attach endPaintTracking fn to class prototype and check if it's called before component is destroyed
		markAsManuallyTracking(cmpType);
		addEndPaintTrackingFnToPrototype(cmpType, componentName);
		if (isDevMode()) {
			checkPaintTrackingIsStoppedBeforeComponentDestroyed(cmpType, componentName);
		}
	}

	return cmpType;
}

/**
 * Class Decorator which, by default, automatically starts performance tracking in ngOnInit and stops it in ngAfterViewInit.
 * Setting `manualMode` to true will still start tracking automatically, but won't stop tracking until the component calls
 * `this.endPaintTracking()`
 *
 * **Note:** manualMode attaches the above function to the class prototype, but can't modify the interface so you need to call `endPaintTracking()`
 * with a `// @ts-ignore` for now like so:
 * ```ts
 * 		this.endPaintTracking({ trolleyItemCount: this.basketService.state.totalItems });
 * ```
 * @param componentName The name of the component to track paint time for
 * @param manualMode If set paint tracking won't complete until `this.endPaintTracking()` is called
 */
export function WithPerformanceTracking(componentName: string, manualMode = false): ClassDecorator {
	return (type: any) => {
		addTrackingToLifecycleHooks(type, componentName, manualMode);
	};
}
