import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, FeaturesState, name } from './features.reducer';

export const selectFeatures = createFeatureSelector<FeaturesState>(name);

export const { selectEntities, selectAll } = adapter.getSelectors(selectFeatures);

export const selectFeaturesLoading = createSelector(selectFeatures, (state) => state.loading);

export const selectFeature = (id: string) => createSelector(selectEntities, (features) => features[id]);
export const selectFeatureEnabled = (id: string) =>
	createSelector(selectEntities, (features) => features[id]?.enabled ?? false);
