<h3 i18n="@@express-pickup" class="expressPickup">Express {{fulfilmentMethodLabel}}</h3>
<h5 i18n="@@pricing" class="pricing">Pricing</h5>

<div class="expressFee">
    <span i18n="@@countdown-now-heading" class="heading">Express {{fulfilmentMethodLabel}} ({{expressReadyTime}}
        minutes)</span>
    <span i18n="@@countdown-now-price" class="price">{{expressFee | currency}}</span>
</div>

<div class="bagFee">
    <span i18n="@@countdown-now-heading" class="bagFee-heading">Bag Charge
        <ng-container *ngIf="fulfilmentMethod === deliveryMethodEnum.Pickup">(Free when BYO bags)</ng-container>
    </span>
    <span i18n="@@countdown-now-price" class="bagFee-price">$1.50</span>
</div>

<div class="orderSize">
    <h5 i18n="@@countdown-now-order-size" class="orderSize-heading">Order Size</h5>
    <span i18n="@@countdown-now-order-size-message">Express {{fulfilmentMethodLabel }} orders are limited to
        {{expressFulfilmentSettings?.maxItemQuantityForExpressOrders}} items per order.</span>
</div>

<div *ngIf="fulfilmentMethod === deliveryMethodEnum.Courier" class="deliveryMethod">
    <h5 i18n="@@countdown-now-delivery-method" class="deliveryMethodHeading">Delivery Method</h5>
    <ul>
        <li i18n="@@countdown-now-delivery-partners">Express deliveries are handled by our rideshare partners.</li>
        <li i18n="@@countdown-now-delivery-tracking">You will get an SMS with live tracking when your order is on the way.</li>
    </ul>
</div>

<div class="availability">
    <h5 i18n="@@countdown-now-availability" class="availabilityHeading">Availablilty</h5>
    <ul>
        <li i18n="@@countdown-now-timing">Place your order between 8:00 am - 9:00 pm, 7 days a week.</li>
        <li i18n="@@countdown-now-busy">Our team can handle a limited number of orders at any given time. If we
            are currently busy, check again a bit later or try a standard option instead.</li>
    </ul>
</div>