import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DatalayerService, TrackingEvent } from '../../../services';
import { NotificationType } from '../../../ui-models';
import { NgIf } from '@angular/common';

@Component({
	selector: 'cdx-accordion-item',
	template: `
		<header class="accordion-header" [attr.id]="id">
			<h5 class="mb-0">
				<button
					class="accordion-button btn btn-link"
					data-toggle="close"
					[attr.aria-expanded]="isOpened"
					(click)="toggle()"
				>
					<div *ngIf="heading" class="accordion-header-title">
						{{ heading }}
					</div>
					<ng-content select="[heading]"></ng-content>
					<i class="accordion-toggleIcon" [class.active]="isOpened"></i>
				</button>
			</h5>
		</header>
		<div class="accordion-item" [class.active]="isOpened" [attr.aria-labelledby]="id">
			<div class="body">
				<ng-content></ng-content>
			</div>
		</div>
	`,
	styleUrls: ['./accordion-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf]
})
export class AccordionItemComponent {
	@Input() heading?: string;
	@Input() isOpened = false;
	// properties for customizing analytics
	@Input() analyticsID: string;
	@Input() analyticsValue?: string;
	@Input() analyticsNotificationType?: NotificationType;
	@Output() headingButtonToggle: EventEmitter<boolean> = new EventEmitter();

	constructor(private cdr: ChangeDetectorRef, private datalayerService: DatalayerService) { }

	toggle(): void {
		this.isOpened = !this.isOpened;

		this.headingButtonToggle.emit(this.isOpened);

		if (this.isOpened) {
			this.datalayerService.trackEvent(
				TrackingEvent.NotificationEvent,
				this.analyticsNotificationType ?? NotificationType.Accordion,
				this.analyticsID,
				this.analyticsValue ?? this.heading
			);
		}

		this.cdr.markForCheck();
	}

	get id(): string {
		return (this.heading || '').replace(/\s+/g, '');
	}
}
