import { Injectable } from '@angular/core';
import { ApiService, AppSettingsService } from '@woolworthsnz/styleguide';
import { SuburbsSearchResponse } from '@woolworthsnz/trader-api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class SuburbService {
	suburbSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	result: Subject<any> = new Subject();
	error: Subject<any> = new Subject();

	result$: Observable<SuburbsSearchResponse> = this.result.asObservable();
	error$: Observable<any> = this.error.asObservable();

	constructor(private apiService: ApiService, private appSettingsService: AppSettingsService) {}

	get suburbEndpoint(): string {
		return this.appSettingsService.getEndpoint('suburbs');
	}

	fetchSuburb = (typedValue: string): void => {
		this.unselectSuburb();

		this.apiService
			.get(this.suburbEndpoint, {
				params: {
					query: typedValue,
				},
			})
			.pipe(take(1))
			.subscribe(
				(response: SuburbsSearchResponse) => {
					this.result.next(response);
				},
				(error) => {
					this.error.next(error);
				}
			);
	};

	selectSuburb = (): void => this.suburbSelected$.next(true);

	unselectSuburb = (): void => this.suburbSelected$.next(false);
}
