import { ElementRef, Inject, Injectable } from '@angular/core';
import { CustomWindow, WINDOW } from './window.service';

@Injectable()
export class IntersectionService {
	el: ElementRef;
	observer?: IntersectionObserver;

	constructor(@Inject(WINDOW) private window: CustomWindow) { }

	initIntersect({
		cb,
		condition,
		el,
		options,
	}: {
		cb: () => void;
		condition: boolean;
		el: ElementRef;
		options?: IntersectionObserverInit;
	}): void {
		if (!this.canIntersect()) {
			return cb();
		}

		this.el = el;

		this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
			this.checkForIntersection(entries, cb, condition);
		}, options ?? {});
		this.observer.observe(this.el.nativeElement);
	}

	canIntersect(): boolean {
		return this.window && 'IntersectionObserver' in this.window;
	}

	checkForIntersection = (entries: IntersectionObserverEntry[], cb: () => void, condition: boolean): void => {
		entries.forEach((e: IntersectionObserverEntry) => {
			if (this.checkIfIntersecting(e) === condition) {
				cb();
				this.disconnect();
			}
		});
	};

	checkIfIntersecting = (entry: IntersectionObserverEntry): boolean => entry.isIntersecting;

	disconnect(): void {
		if (this.observer) {
			this.observer.disconnect();
			this.observer.unobserve(<Element>this.el.nativeElement);
			this.observer = undefined;
		}
	}
}
