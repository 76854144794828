export const fulfilmentChangeCommon = {
	tealium_event: 'fulfilment_change',
	interaction_source: 'page',
} as const;

export const customerDetailsUpdated = {
	tealium_event: 'customer_details_update',
	interaction_source: 'page',
	tealium_event_label: 'details updated',
	tealium_event_step: 2,
} as const;
