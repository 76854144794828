import { createAction, props } from '@ngrx/store';

export const userLogOut = createAction('[Authentication] User initiated log out');
export const userLogOutComplete = createAction('[Authentication] User initiated log out complete');
export const userLoggedOut = createAction('[Authentication] User Logged  Out');

export const userIsShopper = createAction('[Authentication] User Is a Shopper');

export const authInitialise = createAction('[Authentication] Initialise');
export const authRefresh = createAction('[Authentication] Refresh');
export const authInitialiseComplete = createAction(
	'[Authentication] Initialise complete',
	props<{ isAuthenticated: boolean; isShopper: boolean }>()
);
