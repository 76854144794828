import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
	ButtonState,
	NotificationType,
	TrackingEvent,
	resetButtonStateOnTrigger,
	WithPageMeta,
} from '@woolworthsnz/styleguide';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { routeNames } from '../../../app.routes';
import {
	getProfile,
	selectFullName,
	selectProfile,
	selectProfileLoading,
	saveProfile,
	selectSaveComplete,
	selectSaveFailure,
	selectDob,
} from '../../state/profile';
import { validateHomePhoneWithMask, validateMobileWithMask } from '../../../common/helpers';

@WithPageMeta({ pageTitle: 'personalDetails', metaTitle: 'personalDetails' })
@UntilDestroy()
@Component({
	selector: 'ss-personal-details',
	templateUrl: './personal-details.component.html',
	styleUrls: ['./personal-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalDetailsComponent implements OnInit {
	formGroup?: UntypedFormGroup;
	buttonState$ = new BehaviorSubject(ButtonState.default);
	buttonState = ButtonState;

	dob$: Observable<Date | string | undefined>;

	name$: Observable<string>;

	modalRoute = routeNames.modals.changePersonalDetails;
	showErrorNotification$ = new BehaviorSubject<boolean>(false);

	trackingEvent = TrackingEvent;
	notificationType = NotificationType;

	constructor(private fb: UntypedFormBuilder, private store: Store, private cdr: ChangeDetectorRef) {
		this.name$ = this.store.select(selectFullName);
		this.dob$ = this.store.select(selectDob);

		this.store
			.select(selectSaveComplete)
			.pipe(untilDestroyed(this))
			.subscribe(() => this.buttonState$.next(ButtonState.completed));
		this.store
			.select(selectSaveFailure)
			.pipe(untilDestroyed(this))
			.subscribe((errorMessage) => {
				this.showErrorNotification$.next(errorMessage !== '');
				this.buttonState$.next(ButtonState.default);
			});
	}

	ngOnInit() {
		this.store.dispatch(getProfile());

		this.formGroup = this.fb.group({
			mobilePhone: ['', [Validators.required, validateMobileWithMask()]],
			homePhone: ['', validateHomePhoneWithMask()],
		});

		this.setInitialFormState();

		resetButtonStateOnTrigger(this.buttonState$, this.formGroup.valueChanges, this);
	}

	save() {
		this.store
			.select(selectProfile)
			.pipe(
				untilDestroyed(this),
				switchMap(() => this.store.select(selectProfile)),
				first()
			)
			.subscribe((profile) => {
				const customerProfile = {
					...profile,
					homePhoneNumber: this.formGroup?.get('homePhone')?.value,
					mobilePhoneNumber: this.formGroup?.get('mobilePhone')?.value,
				};

				this.store.dispatch(saveProfile({ profile: customerProfile }));
				this.buttonState$.next(ButtonState.busy);
			});
	}

	private setInitialFormState() {
		this.store
			.select(selectProfileLoading)
			.pipe(
				untilDestroyed(this),
				filter((loading) => !loading),
				switchMap(() => this.store.select(selectProfile)),
				first()
			)
			.subscribe((profile) => {
				this.formGroup?.setValue({
					mobilePhone: profile?.mobilePhoneNumber || '',
					homePhone: profile?.homePhoneNumber || '',
				});
				this.cdr.markForCheck();
			});

		this.buttonState$.next(ButtonState.default);
	}
}
