import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { SelectableAddress } from '../../ui-models';
import { ButtonComponent, SvgIconComponent } from '@woolworthsnz/styleguide';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
	selector: 'fulfilment-address-selector',
	templateUrl: './address-selector.component.html',
	styleUrls: ['./address-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, NgFor, ButtonComponent, NgIf, SvgIconComponent]
})
export class AddressSelectorComponent {
	@Input() shopperAddresses: SelectableAddress[] = [];
	@Input() withScroll = false;

	@Output() change = new EventEmitter<string>();
	@Input() bottomSlotActive = false;

	changeAddress(address: SelectableAddress): void {
		if (address.isSelected) {
			return;
		}
		this.change.emit(address.addressId);
	}
}
