import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePersonalDetailsModalComponent, UnsubscribeComponent } from './account/components';
import {
	EmailPreferencesModalComponent
} from './account/components/email-preferences-modal/email-preferences-modal.component';
import { routeNames } from './app.routes';
import { AuthGuard } from './common';

const rewardsRoutes = [routeNames.everydayRewards.base].map((path) => ({
	path: path,
	loadChildren: () => import('./rewards/rewards.module').then((m) => m.RewardsModule),
}));

const routes: Routes = [
	...rewardsRoutes,
	{
		path: routeNames.account,
		loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
	},
	{
		path: routeNames.shop,
		loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
		canActivate: [AuthGuard],
	},
	{
		path: routeNames.unsubscribe,
		component: UnsubscribeComponent,
	},
	{
		path: '',
		redirectTo: 'account',
		pathMatch: 'full',
	},
	{
		path: '**',
		redirectTo: 'account',
		pathMatch: 'full',
	},
	{
		path: '',
		outlet: routeNames.modals.outlet,
		children: [
			{
				path: routeNames.modals.emailPreferences,
				component: EmailPreferencesModalComponent,
				data: {
					name: 'email-preferences',
				},
			},
			{
				path: routeNames.modals.changePersonalDetails,
				component: ChangePersonalDetailsModalComponent,
				data: {
					name: 'change-personal-details',
				},
			},
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
