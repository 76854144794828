<ng-container *ngIf="!isLoading; else loading">
	<ng-container *ngIf="isShopper; then addressSelection; else suburbSelection"></ng-container>
</ng-container>

<ng-template #loading>
	<cdx-wapple-loading></cdx-wapple-loading>
</ng-template>

<ng-template #addressSelection>
	<fulfilment-address-selector
		[bottomSlotActive]="true"
		[shopperAddresses]="addresses$ | async"
		(change)="changeAddress($event)"
	>
		<button bottom cdxButton (click)="onAddNewAddress()" data-cy="add-new-address" fillStyle="outline">
			Add new address
		</button>
	</fulfilment-address-selector>
</ng-template>

<ng-template #suburbSelection>
	<form id="suburb-selection-form" [formGroup]="suburbSelectForm" cdxForm #form>
		<form-suburb-autocomplete [size]="inputSize.fullWidth"></form-suburb-autocomplete>
		<button
			cdxButton
			(click)="handleSuburbSelection()"
			i18n="@@suburbSelection-submitBtn"
			[disabled]="!form.canSubmit"
		>
			Save and Continue Shopping
		</button>
	</form>
</ng-template>
