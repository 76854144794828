import { OverlayRef } from '@angular/cdk/overlay';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ModalOverlayRef {
	backdropClick$: Observable<MouseEvent>;

	constructor(@Optional() private overlayRef: OverlayRef) {}

	close(): void {
		this.overlayRef.detach();
	}

	dispose(): void {
		this.overlayRef.dispose();
	}

	hasAttached(): boolean {
		return this.overlayRef.hasAttached();
	}

	detachments(): Observable<void> {
		return this.overlayRef.detachments();
	}
}
