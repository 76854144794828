import { Inject, Injectable } from '@angular/core';
import { UserAttributes } from '@optimizely/optimizely-sdk';
import equal from 'fast-deep-equal';
import { combineLatest, distinctUntilChanged, filter, map, Observable } from 'rxjs';
import { AppSettingsService } from '../app-settings.service';
import { BreakPointService } from '../break-point.service';
import { ShopperService } from '../shopper.service';
import { CustomWindow, WINDOW } from '../window.service';
import { FlagKey } from './flag-key.enum';
import { FlagUserAttributes, ForcedVariation } from './flag-user-attributes.interface';

@Injectable({
	providedIn: 'root',
})
export class UserAttributesService {
	constructor(
		private shopperService: ShopperService,
		private breakPointService: BreakPointService,
		private appSettingsService: AppSettingsService,
		@Inject(WINDOW) private window: CustomWindow
	) {}

	public getAttributes(): Observable<FlagUserAttributes> {
		const shopper$ = this.shopperService.state$.pipe(
			// Wait until we get a response from the shopperContext api so we know if the user is logged in or logged out.
			filter(({ isLoggedIn }) => typeof isLoggedIn !== 'undefined'),
			map(({ isLoggedIn, hasOnecard, sessionGroups }) => ({
				isLoggedIn: !!isLoggedIn,
				hasOnecard: !!hasOnecard,
				sessionGroups: `,${sessionGroups},`,
			}))
		);
		const breakpoint$ = this.breakPointService.device$;

		return combineLatest([shopper$, breakpoint$]).pipe(
			map(
				([shopper, breakpoint]): FlagUserAttributes => ({
					...shopper,
					breakpoint,
					isEmbeddedInApp: !!this.appSettingsService.getSetting('isEmbeddedInApp'),
					...this.getForcedUserAttributes(),
				})
			),
			distinctUntilChanged(equal)
		);
	}

	public getForcedVariations(): ForcedVariation[] {
		return this.getQueryParamValue('force-variations')
			.split(',')
			.map((experimentAndVariation) => {
				const arr = window.decodeURIComponent(experimentAndVariation).split('/');
				return { flagKey: arr[0] as FlagKey, variationKey: arr[1] };
			})
			.filter(
				(forcedVariation) => Object.values(FlagKey).includes(forcedVariation.flagKey) && !!forcedVariation.variationKey
			);
	}

	public getLogLevel(): number {
		const userDefinedLevel = this.getQueryParamValue('optimizely-log-level');
		switch (userDefinedLevel) {
			case 'NOTSET':
				return 0;
			case 'DEBUG':
				return 1;
			case 'INFO':
				return 2;
			case 'WARNING':
				return 3;
			default:
				return 4;
		}
	}

	private getForcedUserAttributes(): UserAttributes {
		return this.getQueryParamValue('force-attributes')
			.split(',')
			.reduce((acc, attributeKeyAndValue) => {
				const attribute = this.window.decodeURIComponent(attributeKeyAndValue).split('/');
				const value = this.tryParseToBoolean(attribute[1]);
				return attribute.length === 2 ? { ...acc, [attribute[0]]: value } : acc;
			}, {});
	}

	private getQueryParamValue(param: string): string {
		return (
			this.window.location.search
				.slice(1)
				.split('&')
				.find((queryParam: string) => queryParam.startsWith(param)) || ''
		).replace(`${param}=`, '');
	}

	private tryParseToBoolean(value: string): string | boolean {
		return value === 'true' ? true : value === 'false' ? false : value;
	}
}
