import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ListItem } from '@woolworthsnz/form';
import {
	ButtonState,
	HistoryService,
	ModalSize,
	NotificationType,
	RoutedModalBaseComponent,
	TrackingData,
	TrackingEvent,
} from '@woolworthsnz/styleguide';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { height } from '../../../common/animations/height';
import {
	ONLINE_STORE_ID,
	selectIsOnlineStore,
	selectPreferredDayOfWeek,
	selectPreferredStoreId,
	selectUpdatingPreference,
	updatePreferences,
} from '../../state/preferences';
import {
	selectAllStores,
	selectAreas,
	selectStoresByAreaId,
	selectStoresLoading,
} from '../../state/stores/stores.selectors';
import { PreferenceType } from '../../types';

export enum ShopType {
	Online = 'Online',
	InStore = 'InStore',
}

@UntilDestroy()
@Component({
	selector: 'ss-email-preferences-modal',
	templateUrl: './email-preferences-modal.component.html',
	styleUrls: ['./email-preferences-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [height],
})
export class EmailPreferencesModalComponent extends RoutedModalBaseComponent implements OnInit {
	formGroup: UntypedFormGroup;
	modalSize = ModalSize.Medium;
	buttonState = ButtonState;
	updating$: Observable<boolean>;
	storeType = ShopType;

	storesLoading$: Observable<boolean>;
	areas$: Observable<ListItem[]>;
	stores$: Observable<ListItem[]>;

	days$: Observable<ListItem[]> = of([
		{ value: 'Monday', text: 'Monday' },
		{ value: 'Tuesday', text: 'Tuesday' },
		{ value: 'Wednesday', text: 'Wednesday' },
		{ value: 'Thursday', text: 'Thursday' },
		{ value: 'Friday', text: 'Friday' },
	]);

	get trackingData(): TrackingData {
		const shopType = this.formGroup.get('shopType')?.value;
		const name = `${shopType} ${this.formGroup.get('dayOfWeek')?.value}`;

		// Don't track store when shopping online
		const store = shopType === ShopType.InStore ? this.formGroup.get('store')?.value : undefined;
		return {
			event: TrackingEvent.NotificationEvent,
			type: NotificationType.Button,
			name,
			value: store,
		};
	}

	constructor(
		private fb: UntypedFormBuilder,
		private store: Store,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected historyService: HistoryService
	) {
		super(router, activatedRoute, historyService);

		this.formGroup = this.fb.group({
			shopType: [],
			area: [],
			store: ['', Validators.required],
			dayOfWeek: ['Tuesday', Validators.required],
		});

		this.updating$ = this.store.select(selectUpdatingPreference);
		this.storesLoading$ = this.store.select(selectStoresLoading);
		this.areas$ = this.store.select(selectAreas);
		this.stores$ = this.store.select(selectStoresByAreaId(this.formGroup.get('area')?.value));
	}

	ngOnInit() {
		this.formGroup
			.get('area')
			?.valueChanges.pipe(untilDestroyed(this))
			.subscribe((areaId) => (this.stores$ = this.store.select(selectStoresByAreaId(areaId))));

		this.formGroup
			.get('shopType')
			?.valueChanges.pipe(untilDestroyed(this))
			.subscribe((shopType: ShopType) => {
				if (shopType === ShopType.Online) {
					this.formGroup.patchValue({
						store: ONLINE_STORE_ID,
						area: undefined,
					});
					return;
				}

				// clear store and area if type is in-store but store id is the online store id
				if (shopType === ShopType.InStore && this.formGroup.get('store')?.value === ONLINE_STORE_ID) {
					this.formGroup.patchValue({
						store: undefined,
						area: undefined,
					});
				}
			});

		combineLatest([
			this.store.select(selectPreferredStoreId),
			this.store.select(selectPreferredDayOfWeek),
			this.store.select(selectIsOnlineStore),
			this.store.select(selectAllStores),
		])
			.pipe(untilDestroyed(this))
			.subscribe(([storeId, dayOfWeek, isOnlineStore, stores]) => {
				this.formGroup.patchValue({
					shopType: isOnlineStore ? ShopType.Online : ShopType.InStore,
					area: stores.find((s) => s.simplifiedStoreId === storeId)?.state,
					store: storeId,
					dayOfWeek: dayOfWeek,
				});
			});
	}

	save() {
		this.store.dispatch(
			updatePreferences({
				preferencesToUpdated: [
					{ id: PreferenceType.PreferredDayOfWeek, value: this.formGroup.get('dayOfWeek')?.value },
					{ id: PreferenceType.PreferredStore, value: Number.parseInt(this.formGroup.get('store')?.value, 10) },
				],
			})
		);

		// close modal once  updating is complete
		this.updating$
			.pipe(
				untilDestroyed(this),
				filter((updating) => !updating),
				first()
			)
			.subscribe(() => this.close());
	}
}
