import { StyleGuideConfig } from '@woolworthsnz/styleguide';
import { FormService } from '../../services';
import { passwordValidator } from '../../validators';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
	FormGroup,
	Validators,
	FormGroupDirective,
	ValidatorFn,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { PasswordInputComponent } from '../password-input/password-input.component';
import { InvalidTypeDirective } from '../../directives/invalid-type.directive';
import { InputErrorComponent } from '../input-error/input-error.component';
import { InputComponent } from '../input/input.component';

@Component({
	selector: 'form-email-password-input',
	templateUrl: './email-password-input.component.html',
	styleUrls: ['./email-password-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [FormService],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		InputComponent,
		InputErrorComponent,
		InvalidTypeDirective,
		PasswordInputComponent,
	],
})
export class EmailPasswordInputComponent implements OnInit {
	@Input() emailLabel = 'Email address';
	@Input() legend: string;
	@Input() legendVisible = false;
	@Input() page: string;
	@Input() passwordLabel = 'Password';
	@Input() showPasswordHelpText = true;
	@Input() hasLoginAsParent = false;
	@Input() singInIdControlName = 'email';

	parentFormGroup: FormGroup;

	constructor(
		public parent: FormGroupDirective,
		public formService: FormService,
		private styleguideConfig: StyleGuideConfig
	) {}

	ngOnInit(): void {
		this.parentFormGroup = this.parent.form;

		this.formService.addControlToParent(this.singInIdControlName, this.emailValidators, this.parentFormGroup);

		this.formService.addControlToParent('password', this.passwordValidators, this.parentFormGroup);
	}

	get passwordValidationMessage(): string {
		return this.hasLoginAsParent ? 'Please enter a password' : 'Please enter a valid password';
	}

	get emailValidationMessage(): string {
		return this.hasLoginAsParent ? 'Please enter an email address' : 'Please enter a valid email address';
	}

	get passwordValidators(): ValidatorFn[] {
		return this.hasLoginAsParent
			? [Validators.required]
			: [
					passwordValidator(
						this.styleguideConfig.password.minLength,
						this.styleguideConfig.password.requiredCharGroups
					),
			  ];
	}

	get emailValidators(): ValidatorFn[] {
		return this.hasLoginAsParent ? [Validators.required] : [Validators.required, Validators.email];
	}
}
