import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import {
	ACCOUNT_BASE_URL,
	MaybeExternalLinkDirective,
	NotificationType,
	TrackEventDirective,
	TrackingData,
	TrackingEvent,
} from '@woolworthsnz/styleguide';
import { EDRRewardsNotificationComponent } from '../rewards-notification/rewards-notification.component';
import { EDRPointsDialComponent } from '../points-dial/points-dial.component';
import { EDR_DASHBOARD_PATH } from '@woolworthsnz/shop';
import { EdrBalanceResponseV2 } from '@woolworthsnz/trader-api';

export enum PointsBalanceHeaderType {
	GlobalNavigation = 'global-navigation',
	DesktopHeader = 'desktop-header',
}

@Component({
	selector: 'edr-rewards-header',
	templateUrl: './rewards-header.component.html',
	styleUrls: ['./rewards-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [EDRRewardsNotificationComponent, EDRPointsDialComponent, TrackEventDirective, MaybeExternalLinkDirective],
})
export class EDRRewardsHeaderComponent implements OnInit, OnChanges {
	@Input() rewardsAvailable = 0;
	@Input() totalPointsRequired = 2000;
	@Input() pointsBalance = 0;
	@Input() externalLinksInCurrentTab = false;
	@Input() headerType: PointsBalanceHeaderType = PointsBalanceHeaderType.GlobalNavigation;
	@Input() backgroundColor = 'transparent';
	@Input() redemptionPreference: EdrBalanceResponseV2.RedemptionPreferenceEnum =
		EdrBalanceResponseV2.RedemptionPreferenceEnum.SpendNow;

	public pointsRequired = 0;
	public pointsBalanceHeaderType = PointsBalanceHeaderType;

	public analyticsEvent: TrackingData = {
		event: TrackingEvent.NotificationEvent,
		type: NotificationType.Global,
		name: 'rewards_header',
		value: '/edr/dashboard',
	};

	public edrDashboardUrl = '';
	public trackingEvent = TrackingEvent;
	public notificationType = NotificationType;

	constructor(@Inject(ACCOUNT_BASE_URL) private accountBaseUrl?: string) {}

	public ngOnInit(): void {
		this.edrDashboardUrl = `${this.accountBaseUrl}/${EDR_DASHBOARD_PATH}`;
		this.analyticsEvent.value = `/${EDR_DASHBOARD_PATH}`;
		this.initPointsRequired();
	}

	public ngOnChanges(): void {
		this.initPointsRequired();
	}

	private initPointsRequired(): void {
		this.pointsRequired = this.totalPointsRequired - this.pointsBalance;
	}
}
