import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.reducer';

export const authFeatureKey = 'authentication';

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectLoggedIn = createSelector(selectAuthState, (state) => state.isLoggedIn);
export const selectEmail = createSelector(selectAuthState, (state) => state.email);
export const selectAuthInitialising = createSelector(selectAuthState, (state) => state.initialising);
export const selectIsShopper = createSelector(selectAuthState, (state) => state.isShopper);
