export const promoViewAnalytics = {
	tealium_event: 'media_view',
	interaction_source: 'page',
} as const;

export const promoClickAnalytics = {
	tealium_event: 'media_click',
	interaction_source: 'page',
} as const;

export class TealiumPromoList {
	media_label: string[] = [];
	media_category: string[] = [];
	media_carousel_slot: string[] = [];
	media_destination_link: string[] = [];
}
