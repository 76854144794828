import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { authInitialise, authRefresh } from './auth.actions';
import { selectEmail, selectLoggedIn } from './auth.selectors';
import { AuthResponse } from '@woolworthsnz/trader-api';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
	public isLoggedIn$ = this.store.select(selectLoggedIn);
	public email$ = this.store.select(selectEmail);

	constructor(private store: Store) {}

	/**
	 * Logs the user out
	 */
	public logOut(): void {
		this.store.dispatch(AuthActions.userLogOut());
	}

	/**
	 * Initialises the user's authentication token
	 */
	public initialise(): void {
		this.store.dispatch(authInitialise());
	}

	/**
	 * Refreshes the user's authentication token
	 */
	public refreshAuthToken(): void {
		this.store.dispatch(authRefresh());
	}

	public getUserAuthInfoRetryComplete(authResponse: AuthResponse): void {
		this.store.dispatch(AuthActions.getUserAuthInfoRetryComplete(authResponse));
	}
}
