<h1>Communication preferences</h1>
<form [formGroup]="form">
	<section formGroupName="email">
		<form-switch formControlName="email" id="ss-preference-email">
			<h2 aria-label="Receive email communications" i18n>Email</h2>
		</form-switch>
		<div *ngIf="emailSelected; else emailDescription" @height formGroupName="emailOptions">
			<div class="email-update">
				<p i18n>
					Emails are sent to <b>{{ emailAddress }}</b>
				</p>
				<button
					cdxButton
					class="update update--margin"
					[startSlotActive]="true"
					startSlotSize="small"
					[inline]="true"
					(click)="updateEmail()"
					fillStyle="link"
					i18n
					aria-label="Update email address"
				>
					<cdx-svg-icon name="edit" start fill="green"></cdx-svg-icon>Update
				</button>
			</div>

			<form-input inputType="checkbox" formControlName="myCountdown">
				<label label for="myCountdown">
					<span>
						<strong aria-label="myCountdown weekly email:" i18n>Woolworths NZ weekly email</strong>
						<br />
						<span i18n>Personalised offers, recipe inspiration, and news about your favourite store.</span>
					</span>
				</label>
			</form-input>
			<div *ngIf="myCountdownSelected" class="myCountdown-preferred" @height>
				<p i18n *ngIf="preferredStore$ | async; let preferredStore; else: noStoreSelected">
					Your favourite store is set to <strong>{{ preferredStore }}</strong
					>, and you will receive this email every <strong>{{ preferredDay$ | async }}</strong
					>.
				</p>
				<ng-template #noStoreSelected>
					<p i18n>
						Set your favourite store for a more personalised experience. You get this email every
						<strong>{{ preferredDay$ | async }}</strong
						>.
					</p>
				</ng-template>
				<button
					(click)="editEmailPreference()"
					cdxButton
					fillStyle="link"
					i18n
					[cdxTrackEvent]="{
						event: trackingEvent.NotificationEvent,
						type: notificationType.Link,
						name: 'Edit Woolworths preferences'
					}"
				>
					Edit Woolworths preferences
				</button>
			</div>
			<form-input inputType="checkbox" formControlName="promotions">
				<label label for="promotions">
					<span>
						<strong aria-label="Woolworths promotions:" i18n>Woolworths promotions</strong>
						<br />
						<span i18n>Fuel discounts, targeted offers, product promotions, and more</span>
					</span>
				</label>
			</form-input>
			<form-input inputType="checkbox" formControlName="onlineShopping">
				<label label for="onlineShopping">
					<span>
						<strong aria-label="Online shopping:" i18n>Online shopping</strong>
						<br />
						<span i18n>Receive emails from our online shopping team</span>
					</span>
				</label>
			</form-input>
			<form-input inputType="checkbox" formControlName="customerFeedback">
				<label label for="customerFeedback">
					<span>
						<strong aria-label="Customer feedback:" i18n>Customer feedback</strong>
						<br />
						<span i18n>Feedback, market research, and surveys</span>
					</span>
				</label>
			</form-input>
		</div>
		<ng-template #emailDescription>
			<p @height i18n>
				We will not send you any promotional emails, but you’ll continue to get transactional and service emails, such
				as delivery and order confirmations.
			</p>
		</ng-template>
	</section>
	<hr />
	<section>
		<form-switch formControlName="sms" id="ss-preference-sms">
			<h2 aria-label="Receive Text/SMS communications" i18n>Text/SMS</h2>
		</form-switch>
		<div class="sms-description">
			<div *ngIf="form.get('sms')?.value; else noSms" @height>
				<p i18n>
					Texts are sent to <strong>{{ phoneNumber }}</strong> this includes fuel and
					product promotions
				</p>
				<button
					class="update update--margin"
					cdxButton
					[startSlotActive]="true"
					startSlotSize="small"
					[inline]="true"
					fillStyle="link"
					(click)="updateSmsPreference()"
					[cdxTrackEvent]="{
						event: trackingEvent.NotificationEvent,
						type: notificationType.Link,
						name: 'update mobile number'
					}"
				>
					<cdx-svg-icon name="edit" start fill="green"></cdx-svg-icon>Update
				</button>
			</div>
			<ng-template #noSms>
				<p i18n @height>We will continue to send transactional texts, such as delivery and order confirmations.</p>
			</ng-template>
		</div>
	</section>
	<ng-container *ngIf="isOver18$ | async">
		<hr />
		<section formGroupName="personal">
			<h2 aria-label="Set personal preferences" i18n>Personal preferences</h2>
			<p i18n class="personal-description">This will apply to all communications.</p>
			<form-input inputType="checkbox" formControlName="alcoholFree">
				<label label for="alcoholFree">
					<span>
						<strong aria-label="I'm alcohol free:" i18n>I'm alcohol free</strong>
						<br />
						<span i18n>We won't send you any communications about alcohol</span>
					</span>
				</label>
			</form-input>
		</section>
	</ng-container>
</form>
