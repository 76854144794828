import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'srPunctuation',
	standalone: true
})
export class SrPunctuationPipe implements PipeTransform {
	/**
	 * Adds a period to the end of the given input.
	 * If input is `null`, `undefined` or empty after trimming returns `null`
	 */
	transform(value?: string | null): string | null {
		if (!value || !value?.length) {
			return null;
		}

		const trimmed = value.trim();
		if (!trimmed.length) {
			return null;
		}

		return trimmed.endsWith('.') ? trimmed : `${trimmed}.`;
	}
}
