import { Action, createReducer, on } from '@ngrx/store';
import {
	authInitialise,
	authInitialiseComplete,
	authInitialiseFailed,
	authRefresh,
	authRefreshComplete,
	authRefreshFailed,
	getUserAuthInfoRetryComplete,
	userLoggedOut,
	userLogOut,
} from './auth.actions';
import { AuthResponse } from '@woolworthsnz/trader-api';

export interface AuthState {
	initialising: boolean;
	isLoggedIn: boolean;
	isShopper: boolean;
	email?: string;
	error?: any;
}

export const initialState: AuthState = {
	initialising: true,
	isLoggedIn: false,
	isShopper: false,
	email: '',
};

const mapAuthResponseToState = (state: AuthState, authResponse: AuthResponse): AuthState => ({
	...state,
	initialising: false,
	isShopper: authResponse.isShopper ?? false,
	isLoggedIn: authResponse.isLoggedIn ?? false,
	email: authResponse.email,
});

const authStateReducer = createReducer(
	initialState,
	on(userLoggedOut, (state: AuthState): AuthState => ({ ...state, initialising: false, isLoggedIn: false })),
	on(authInitialise, (state: AuthState): AuthState => ({ ...state, initialising: true, isLoggedIn: false })),
	on(
		authInitialiseComplete,
		(state: AuthState, authResponse): AuthState => mapAuthResponseToState(state, authResponse)
	),
	on(authInitialiseFailed, (state: AuthState, { error }): AuthState => ({ ...state, initialising: false, error })),
	on(authRefresh, (state: AuthState): AuthState => ({ ...state, initialising: true, isLoggedIn: false })),
	on(authRefreshComplete, (state: AuthState, authResponse): AuthState => mapAuthResponseToState(state, authResponse)),
	on(authRefreshFailed, (state: AuthState, { error }): AuthState => ({ ...state, initialising: false, error })),
	on(userLogOut, (state: AuthState): AuthState => ({ ...state, isLoggedIn: false })),
	on(
		getUserAuthInfoRetryComplete,
		(state: AuthState, authResponse): AuthState => mapAuthResponseToState(state, authResponse)
	)
);
export const authReducer = (state: AuthState | undefined, action: Action): AuthState => authStateReducer(state, action);
