import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppSettingsService, CustomWindow, WINDOW, WithPageMeta } from '@woolworthsnz/styleguide';
import { BehaviorSubject, Observable } from 'rxjs';
import { selectIsShopper, userLogOut } from '../../../common';
import { AccountLinks } from '../../types';
import { getProfile, selectFirstName } from '../../state/profile';
import { myAccountNav } from '../../../app.settings';
@WithPageMeta({ pageTitle: 'account', metaTitle: 'account' })
@Component({
	selector: 'ss-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
	firstName$: Observable<string>;
	links$ = new BehaviorSubject<AccountLinks[]>([]);

	isShopper$: Observable<boolean | null>;


	constructor(
		private appSettingsService: AppSettingsService,
		private store: Store,
		@Inject(WINDOW) private window: CustomWindow
	) {
		this.firstName$ = this.store.select(selectFirstName);
		this.isShopper$ = this.store.select(selectIsShopper);
	}
	ngOnInit(): void {
		this.store.dispatch(getProfile());
		const nav = myAccountNav;
		this.links$.next(nav);
	}

	handleLogout() {
		const frame = document.getElementById('keycloakIframe') as HTMLIFrameElement;
		frame?.contentWindow?.postMessage('logoutContext', '*');
		this.store.dispatch(userLogOut());
	}
}
