import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
const VALID_OLD_ONECARD_DIGITS = 12;
const error = { pattern: true };

// gs1 checksumn calculation https://www.gs1.org/services/how-calculate-check-digit-manually
export const createGS1Checksum = (onecardDigits: string[]): number => {
	let multiplier = 3;
	const firstTwelveDigits = onecardDigits.slice(0, 12);
	return firstTwelveDigits.reduce((result: any, digit: any) => {
		multiplier = 4 - multiplier;
		return result + parseInt(digit, 10) * multiplier;
	}, 0);
};

export const validateGS1Card = (
	onecardNumber: string
): {
	pattern: boolean;
} | null => {
	const onecardDigits = onecardNumber.split('');
	if (onecardDigits.length !== 13) {
		return error;
	}
	const checksum = createGS1Checksum(onecardDigits);
	const nearestSum = Math.ceil(checksum / 10) * 10;
	const checkDigit = onecardDigits[12];

	return nearestSum - checksum === parseInt(checkDigit, 10) ? null : error;
};

export const validateOldOnecard = (
	onecardNumber: string
): {
	pattern: boolean;
} | null => {
	const onecardDigits = onecardNumber.split('');
	const firstTwoDigits = onecardNumber.substr(0, 2);
	const oneCardLength = onecardDigits.length;
	const onecardPrefixDigit = parseInt(firstTwoDigits, 10);

	const validTwoDigitRanges = [
		[14, 24],
		[50, 54],
		[70, 74],
	];
	const isInRange = validTwoDigitRanges.find(
		(range) => onecardPrefixDigit >= range[0] && onecardPrefixDigit <= range[1]
	);

	const isValid = (!!isInRange || onecardPrefixDigit === 95) && oneCardLength === VALID_OLD_ONECARD_DIGITS;

	return isValid ? null : error;
};

export const isGS1Card = (cardNumber: string): boolean => {
	const firstTwoDigits = cardNumber.substr(0, 2);
	return firstTwoDigits === '94';
};

export const onecardValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
	const value = control.value;
	if (!value) {
		return null;
	}
	const oncecardNumber = value.replace(/[-| ]/g, '');
	return isGS1Card(oncecardNumber) ? validateGS1Card(oncecardNumber) : validateOldOnecard(oncecardNumber);
};
