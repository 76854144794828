<cdx-slotted-modal [size]="modalSize" (close)="close()">
	<h1 modal-header i18n="@@emailPreferencesModal-title">Woolworths preferences</h1>
	<div modal-content [formGroup]="formGroup">
		<p i18n="@@emailPreferencesModal-subtitle">Select your preferences for your weekly email from Woolworths.</p>
		<hr />
		<fieldset
			cdxFieldset
			[divider]="false"
			legend="Shop in-store or Online?"
			[hideLegend]="true"
			[i18n]="'@@howWhereWhen-deliveryOrPickup'"
		>
			<div class="emailPreferencesModal-shopType">
				<form-selection-tile
					inputId="method-inStore"
					iconName="location-pin"
					formControlName="shopType"
					[inputValue]="storeType.InStore"
					label="I shop in-store"
				>
				</form-selection-tile>
				<form-selection-tile
					inputId="method-online"
					iconName="delivery-truck"
					formControlName="shopType"
					[inputValue]="storeType.Online"
					label="I shop online"
				>
				</form-selection-tile>
			</div>
		</fieldset>
		<div class="emailPreferencesModal-area" *ngIf="formGroup.get('shopType')?.value === storeType.InStore" @height>
			<label i18n="@@emailPreferencesModal-area" for="area">at</label>
			<form-dropdown
				*ngIf="areas$ | async; let areas"
				formControlName="area"
				initialText="Select your region"
				[fullWidth]="true"
				[constrainWidth]="false"
				[list]="areas"
			></form-dropdown>
			<form-dropdown
				*ngIf="stores$ | async; let stores"
				initialText="Select your store"
				formControlName="store"
				[fullWidth]="true"
				[constrainWidth]="false"
				[list]="stores"
			></form-dropdown>
		</div>
		<div class="emailPreferencesModal-dayOfWeek">
			<label i18n="@@emailPreferencesModal-dayOfWeek" for="dayOfWeek">and would like to receive my Woolworths email on</label>
			<form-dropdown
				*ngIf="days$ | async; let days"
				formControlName="dayOfWeek"
				[fullWidth]="true"
				[constrainWidth]="false"
				[list]="days"
			></form-dropdown>
		</div>
	</div>
	<button
			cdxButton
			modal-footer-primary
			(click)="save()"
			i18n="@@emailPreferencesModal-saveChanges"
			[disabled]="!formGroup.valid"
			[buttonState]="(updating$ | async) ? buttonState.busy : buttonState.default"
			[cdxTrackEvent]="trackingData"
	>
		Save changes
	</button>
</cdx-slotted-modal>
