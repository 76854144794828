<cdx-slotted-modal
    (close)="close()"
    center="true"
    size="default"
    class="celebratory-modal"
    padding="none"
    verticalPadding="none"
>
    <div modal-header class="celebratory-modal__header">
        <edr-heading
            level="5"
            [text]="'Nice! You earned a \n $15 voucher'"
        />
    </div>
    <div modal-content class="celebratory-modal__body">
        <img src="/assets/images/everyday-rewards/rewards-rocket.svg" alt="Rewards Voucher" />
        <p>
            Your Rewards voucher is ready to be spent at any Woolworths or participating bp, or save it for later.
        </p>
    </div>
    <div modal-footer-primary class="celebratory-modal__footer">
        <button cdxButton cdkFocusInitial (click)="close()">Got it</button>
    </div>
</cdx-slotted-modal>