import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SanitizeHtmlPipe } from '../../../directives/sanitize-html.directive';
import { AccordionItemComponent } from '../accordion-item/accordion-item.component';
import { NgIf, NgFor } from '@angular/common';

interface AccordionItem {
	id: number;
	heading: string;
	content: string;
}

@Component({
	selector: 'cdx-accordion',
	styleUrls: ['./accordion.component.scss'],
	template: `
		<ng-container *ngIf="items">
			<cdx-accordion-item
				*ngFor="let item of items; trackBy: trackByFn"
				[heading]="item.heading"
				[analyticsID]="analyticsID"
			>
				<div [innerHTML]="item.content | sanitizeHtml"></div>
			</cdx-accordion-item>
		</ng-container>
		<ng-content></ng-content>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, NgFor, AccordionItemComponent, SanitizeHtmlPipe]
})
export class AccordionComponent {
	@HostBinding('class.accordion') class = true;

	@Input() analyticsID: string;

	@Input() set items(value: AccordionItem[] | string) {
		if (typeof value === 'string') {
			try {
				this._items = JSON.parse(value);
			} catch {
				// eslint-disable-next-line no-console
				console.warn('Accordion items not valid JSON string');
			}
		} else {
			this._items = value;
		}
	}

	get items(): string | AccordionItem[] {
		return this._items;
	}

	_items: AccordionItem[];

	trackByFn = (_: any, item: any): any => item.heading;
}
