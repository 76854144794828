import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StatefulService } from './stateful.service';
import { ApiService, HttpArgs } from './api.service';
import { AppSettingsService } from './app-settings.service';
import { RouterNavigationService } from './router-navigation.service';
import { MetaTagSettingResponse } from '@woolworthsnz/trader-api';

export interface SiteManagementState {
	metaTagSetting?: MetaTagSettingResponse | null;
}

@Injectable({ providedIn: 'root' })
export class SiteManagementService extends StatefulService<SiteManagementState> {
	private metaTagSettingsEndpoint: string;

	constructor(
		private apiService: ApiService,
		private appSettingsService: AppSettingsService,
		private location: Location,
		private routerNavigationService: RouterNavigationService
	) {
		super(<SiteManagementState>{});

		this.metaTagSettingsEndpoint = this.appSettingsService.getEndpoint('metaTagSettings', 1);
	}

	fetchCurrentPageMetaTagSettings$(): Observable<MetaTagSettingResponse | null> {
		return this.fetchMetaTagSettings$(this.location.path(false));
	}

	fetchMetaTagSettings$(url: string): Observable<MetaTagSettingResponse | null> {
		const baseUrl = this.routerNavigationService.getBaseUrl(url);

		// Construct the Http Args required by the API service
		const httpArgs: HttpArgs = {
			params: {
				url: decodeURI(baseUrl),
			},
		};

		// If blank, request meta tag settings for the root '/'
		if (httpArgs.params.url.trim() === '') {
			httpArgs.params.url = '/';
		}

		return this.apiService.get(this.metaTagSettingsEndpoint, httpArgs).pipe(
			tap((metaTagSetting) => {
				// Make sure the image value is set as an absolute URL
				if (metaTagSetting && metaTagSetting.image) {
					metaTagSetting.image = this.getAbsoluteUrl(metaTagSetting.image);
				}

				this.setState({ metaTagSetting });
			})
		);
	}

	/**
	 * If the URL value is relative, then make it an absolute URL by prefixing it with the protocol and host name
	 * @param url - The URL that can potentially be a relative URL
	 */
	getAbsoluteUrl(url: string): string {
		// If the image Url has been configured with a relative path, make sure it's fully qualified with the host name
		if (url.startsWith('/') && window?.location) {
			return `${window.location.protocol}//${window.location.hostname}${url}`;
		}

		return url;
	}
}
