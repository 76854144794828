import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	FieldsetComponent,
	RadioGroupComponent,
	RadioOption,
	SelectionTileComponent,
	TimeslotSelectionComponent,
} from '@woolworthsnz/form';
import {
	ButtonComponent,
	SvgIconComponent,
	FeatureService,
	ShopperService,
	ShopperState,
	AppSettingsService,
} from '@woolworthsnz/styleguide';
import {
	ContextResponse,
	ExpressFulfilmentAvailabilityStatusResponse,
	FulfilmentWindowSummarySlot,
	OrderViewModel,
	ShellResponse,
	SlotsOffering,
} from '@woolworthsnz/trader-api';
import equal from 'fast-deep-equal';
import { distinctUntilChanged } from 'rxjs/operators';
import { displayDateAtServer, getExpressSlotDescription, getExpressSlotLabel } from '../../helpers';
import { FulfilmentState, FulfilmentStoreService } from '../../services';
import { PickupType } from '../../ui-models';
import { FulfilmentExpressSlotSelectionComponent } from '../fulfilment-express-slot-selection/fulfilment-express-slot-selection.component';
import { NgIf, NgFor } from '@angular/common';
import AvailabilityStatusEnum = ExpressFulfilmentAvailabilityStatusResponse.AvailabilityStatusEnum;

@UntilDestroy()
@Component({
	selector: 'fulfilment-time-selection',
	templateUrl: 'fulfilment-time-selection.component.html',
	styleUrls: ['./fulfilment-time-selection.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FieldsetComponent,
		FormsModule,
		ReactiveFormsModule,
		NgIf,
		FulfilmentExpressSlotSelectionComponent,
		NgFor,
		TimeslotSelectionComponent,
		SvgIconComponent,
		RadioGroupComponent,
		ButtonComponent,
		SelectionTileComponent,
	],
})
export class FulfilmentTimeSelectionComponent implements OnInit {
	@Input() controlName: string;
	@Input() formGroup: FormGroup;
	@Input() isExpressPickup = false;
	@Input() expressSlotStatus: ExpressFulfilmentAvailabilityStatusResponse.AvailabilityStatusEnum;
	@Input() timeSlotsHeadingText: string;
	@Input() timeSlotsHeadingPricingText: string;
	@Input() slotsHeadingPricingText: string;
	@Output() openModalClicked = new EventEmitter<any>();
	@Output() openPickupPointInformationModal = new EventEmitter<any>();
	@Output() openDeliveryFeesModal = new EventEmitter<boolean>();

	private _date: string;
	private _allSlots: FulfilmentWindowSummarySlot[];
	private _selectedSlot?: FulfilmentWindowSummarySlot;
	isPickupTypeEnabled: boolean;
	isLockerPickupFeatureEnabled = false;
	shopperPreferLockerPickup?: boolean;
	isFirstTimeShopperFeatureEnabled = false;
	isFirstTimeShopper = false;
	isPickupSlotOfferingsFeatureEnabled = false;
	isExpressPickupSlotOfferingsFeatureEnabled = false;
	fulfilmentMethod?: OrderViewModel.DeliveryMethodEnum;
	expressFulfilmentSettings: ShellResponse['expressFulfilmentSettings'];
	displaySlotOfferings = false;
	displayExpressSlotOfferings = false;

	set selectedSlot(s: FulfilmentWindowSummarySlot | undefined) {
		this._selectedSlot = s;
		this.updateForm();
	}
	// whenever the slots get set always update the selected slot
	set allSlots(fulfillmentWindowSummarySlots: FulfilmentWindowSummarySlot[]) {
		this._allSlots = fulfillmentWindowSummarySlots;
		this.selectedSlot = this.allSlots?.find(
			(slot) => slot.status === FulfilmentWindowSummarySlot.StatusEnum.Selected
		);
	}

	get allSlots(): FulfilmentWindowSummarySlot[] {
		return this._allSlots;
	}

	get standardSlots(): FulfilmentWindowSummarySlot[] {
		// Closed standard slots are not displayed
		return this.allSlots?.filter(
			(slot) =>
				!slot.isExpress &&
				slot.status !== FulfilmentWindowSummarySlot.StatusEnum.Closed &&
				slot.status !== FulfilmentWindowSummarySlot.StatusEnum.Full
		);
	}

	get firstExpressSlot(): FulfilmentWindowSummarySlot | undefined {
		return this.allSlots?.find((slot) => slot.isExpress);
	}

	@Input()
	set date(d: string) {
		this._date = d;
		this.updateForm();
	}

	get date(): string {
		return this._date;
	}

	constructor(
		private fulfilmentStoreService: FulfilmentStoreService,
		private cdr: ChangeDetectorRef,
		private controlContainer: ControlContainer,
		private featureService: FeatureService,
		private shopperService: ShopperService,
		private appSettingsService: AppSettingsService
	) {}

	pickupTypeOptions(slot: FulfilmentWindowSummarySlot): RadioOption[] {
		// isLockerAvailable will be undefined if Locker Capacity is NOT populated in Site Management.
		if (slot.isLockerAvailable === false) {
			return [
				{
					value: PickupType.Locker,
					label: 'eLockers are full - try another slot!',
					enabled: false,
				},
				{
					value: PickupType.InStore,
					label: 'In-store Pick up',
					enabled: true,
				},
			];
		}

		return [
			{
				value: PickupType.Locker,
				label: 'Self service eLocker',
			},
			{
				value: PickupType.InStore,
				label: 'In-store Pick up',
			},
		];
	}

	ngOnInit(): void {
		this.featureService.state$.pipe(untilDestroyed(this)).subscribe(() => {
			this.isLockerPickupFeatureEnabled = this.featureService.isFeatureEnabled(
				ContextResponse.EnabledFeaturesEnum.EnableLockerPickup
			);

			this.isFirstTimeShopperFeatureEnabled = this.featureService.isFeatureEnabled(
				ContextResponse.EnabledFeaturesEnum.FirstTimeShopper
			);

			this.isPickupSlotOfferingsFeatureEnabled = this.featureService.isFeatureEnabled(
				ContextResponse.EnabledFeaturesEnum.PickupSlotOfferings
			);

			this.isExpressPickupSlotOfferingsFeatureEnabled = this.featureService.isFeatureEnabled(
				ContextResponse.EnabledFeaturesEnum.ExpressPickupSlotOfferings
			);

			this.cdr.markForCheck();
		});

		this.fulfilmentStoreService.state$
			.pipe(
				untilDestroyed(this),
				distinctUntilChanged((a: FulfilmentState, b: FulfilmentState) => equal(a, b))
			)
			.subscribe((fulfilmentState: FulfilmentState) => {
				this.fulfilmentMethod = fulfilmentState.method;
				if (!fulfilmentState.daySlots) {
					return;
				}
				this.allSlots = (fulfilmentState.daySlots as any)[
					fulfilmentState.clientSelectedDate?.toString() || fulfilmentState.selectedDate?.toString() || ''
				]?.filter((fulfillmentWindowSummarySlot: FulfilmentWindowSummarySlot) =>
					[
						FulfilmentWindowSummarySlot.StatusEnum.Available,
						FulfilmentWindowSummarySlot.StatusEnum.ClosingSoon,
						FulfilmentWindowSummarySlot.StatusEnum.Selected,
						FulfilmentWindowSummarySlot.StatusEnum.Full,
						FulfilmentWindowSummarySlot.StatusEnum.Closed,
					].includes(<FulfilmentWindowSummarySlot.StatusEnum>fulfillmentWindowSummarySlot.status)
				);

				this.isPickupTypeEnabled =
					fulfilmentState.method === 'Pickup' &&
					(fulfilmentState?.locker?.storeSupportsLockerPickup ?? false);

				this.shopperPreferLockerPickup = fulfilmentState?.locker?.isLockerPickup;
				this.displaySlotOfferings =
					this.isPickupSlotOfferingsFeatureEnabled && fulfilmentState.method === 'Pickup';
				this.displayExpressSlotOfferings =
					this.displaySlotOfferings && this.isExpressPickupSlotOfferingsFeatureEnabled;

				this.updateForm();
			});

		// find out if current shopper is First Time Shopper
		this.shopperService.state$
			.pipe(
				untilDestroyed(this),
				distinctUntilChanged((a: ShopperState, b: ShopperState) => equal(a, b))
			)
			.subscribe((shopperState: ShopperState) => {
				this.isFirstTimeShopper = shopperState.orderCount === '0';
			});

		this.appSettingsService.state$.pipe(untilDestroyed(this)).subscribe((appSettingsState) => {
			this.expressFulfilmentSettings = appSettingsState.expressFulfilmentSettings;
		});

		this.fulfilmentStoreService.setState({
			pickupStoreType: this.getPickupStoreType(this.allSlots),
		});
	}

	getPickupStoreType(slots: FulfilmentWindowSummarySlot[]): SlotsOffering.ValueEnum | undefined {
		if (this.fulfilmentMethod !== 'Pickup' || !slots) {
			return undefined;
		}

		for (const slot of slots) {
			if (slot.offerings) {
				if (slot.offerings.some((offering) => offering.value === SlotsOffering.ValueEnum.DriveUp)) {
					return SlotsOffering.ValueEnum.DriveUp;
				}

				if (slot.offerings.some((offering) => offering.value === SlotsOffering.ValueEnum.DriveThrough)) {
					return SlotsOffering.ValueEnum.DriveThrough;
				}
			}
		}

		return undefined;
	}

	updateForm(): void {
		if (this._selectedSlot && this.date) {
			this.formGroup.patchValue({
				slot: `${this.date}~${this._selectedSlot.id}`,
			});
		} else {
			this.formGroup.patchValue({
				slot: null,
			});
		}
		this.cdr.markForCheck();
	}

	displayPickupTypeSelector(slot: FulfilmentWindowSummarySlot): boolean {
		if (!this.isPickupTypeEnabled) {
			return false;
		}

		if (this.isFirstTimeShopperFeatureEnabled && this.isFirstTimeShopper) {
			return false;
		}

		const control = this.controlContainer?.control?.get(this.controlName);
		const isSlotSelected = slot.id === this.getIdForSlot(control?.value);
		return isSlotSelected;
	}

	getSlotDiscountText(slot: FulfilmentWindowSummarySlot): string | undefined {
		if (!slot.discount) {
			return;
		}
		if (slot.discount % 1 === 0) {
			return `$${slot.discount} off delivery`;
		}
		return `$${slot.discount.toFixed(2)} off delivery`;
	}

	getIdForSlot(slotName: string): number | undefined {
		if (!slotName || !slotName.includes('~')) {
			return undefined;
		}
		return Number(slotName.split('~')[1]);
	}

	getLabelForSlot(slot: FulfilmentWindowSummarySlot): string {
		return `${displayDateAtServer(slot.start)?.format('h:mma')} - ${displayDateAtServer(slot.end)?.format(
			'h:mma'
		)}`;
	}

	getSlotStatus(slot: FulfilmentWindowSummarySlot): FulfilmentWindowSummarySlot.StatusEnum | null {
		if (slot.status === FulfilmentWindowSummarySlot.StatusEnum.ClosingSoon) {
			return FulfilmentWindowSummarySlot.StatusEnum.ClosingSoon;
		}
		return null;
	}

	getSlotStatusIcon(slot: FulfilmentWindowSummarySlot): string | null {
		switch (slot.status) {
			case FulfilmentWindowSummarySlot.StatusEnum.ClosingSoon:
				return 'alarm';
			default:
				return null;
		}
	}

	getSlotStatusIconFill(slot: FulfilmentWindowSummarySlot): string | null {
		switch (slot.status) {
			case FulfilmentWindowSummarySlot.StatusEnum.ClosingSoon:
				return 'warning';
			default:
				return null;
		}
	}

	openPickupHelpModal(): void {
		this.openModalClicked.emit();
	}

	openPickupPointInfoModal(): void {
		this.openPickupPointInformationModal.emit();
	}

	pickupTypeDefaultSelection(slot: FulfilmentWindowSummarySlot): string {
		// if InStore is the only option, return it.
		if (slot.isLockerAvailable === false) {
			return PickupType.InStore;
		}

		// when there are mutiple options (since code runs to here),
		//  we need to use the shopper's latest chose option as default one for different time-slot.
		// shopper's latest chose option is stored in this.formGroup.get('pickupType')?.value,
		// and it can be undefined if shopper didn't click any time-slot yet or didn't change any option.
		if (this.formGroup.get('pickupType')?.value !== undefined) {
			return this.formGroup.get('pickupType')?.value?.toString();
		}

		// when it's the first click the shopper make on page, use their preference from latest order.
		return this.shopperPreferLockerPickup !== false ? PickupType.Locker : PickupType.InStore;
	}

	displayLockerAvailableBadge(slot: FulfilmentWindowSummarySlot): boolean {
		if (this.isFirstTimeShopperFeatureEnabled && this.isFirstTimeShopper) {
			return false;
		}

		return slot.isLockerAvailable || false;
	}

	pickupOfferingsDefaultSelection(slot: FulfilmentWindowSummarySlot): string {
		const offerings = this.convertOfferingsToRadioOptions(slot.offerings as []);

		const preferredOfferings = [
			SlotsOffering.ValueEnum.DirectToBoot,
			SlotsOffering.ValueEnum.DriveUp,
			SlotsOffering.ValueEnum.DriveThrough,
		];

		for (const preferredOffering of preferredOfferings) {
			const matchingOffering = offerings.find((o) => o.value === preferredOffering.toString() && o.enabled);
			if (matchingOffering) {
				return matchingOffering.value;
			}
		}

		return '';
	}

	pickupOfferingOptions(slot: FulfilmentWindowSummarySlot): RadioOption[] {
		if (slot.offerings && slot.offerings.length > 0) {
			return this.convertOfferingsToRadioOptions(slot.offerings);
		}

		return [];
	}

	displayPickupOfferingsSelector(slot: FulfilmentWindowSummarySlot): boolean {
		const control = this.controlContainer?.control?.get(this.controlName);
		const isSlotSelected = slot.id === this.getIdForSlot(control?.value);
		return isSlotSelected;
	}

	convertOfferingsToRadioOptions(offerings: SlotsOffering[]): RadioOption[] {
		return (offerings ?? []).map((offering) => ({
			value: offering.value ? offering.value.toString() : '',
			label: offering.label || '',
			enabled: offering.available,
			description: offering.description,
		}));
	}

	openFeesModal(value: boolean): void {
		this.openDeliveryFeesModal.emit(value);
	}

	get isExpressDisabled(): boolean {
		return (
			this.expressSlotStatus !== AvailabilityStatusEnum.Available &&
			this.expressSlotStatus !== AvailabilityStatusEnum.ExpressWindowAlreadySelected
		);
	}

	get expressSlotlabel(): string {
		if (this.firstExpressSlot) {
			return getExpressSlotLabel(this.isExpressDisabled, this.expressFulfilmentSettings, this.fulfilmentMethod);
		}
		return 'Unavailable';
	}

	get expressSlotDescription(): string {
		if (this.firstExpressSlot) {
			return getExpressSlotDescription(
				this.firstExpressSlot,
				this.expressFulfilmentSettings,
				this.fulfilmentMethod
			);
		}
		return '';
	}
}
