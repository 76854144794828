import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostBinding,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgIf, NgTemplateOutlet, NgClass } from '@angular/common';

export enum InputSize {
	narrow = 'narrow',
	medium = 'medium',
	fullWidth = 'full-width',
	initial = 'initial',
}

@Component({
	selector: 'form-input, [cdxInput]',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [NgIf, NgTemplateOutlet, NgClass]
})
export class InputComponent implements ControlValueAccessor, AfterViewInit {
	get isCharInput(): boolean {
		return !this.isCheckbox && !this.isRadio;
	}

	get isCheckbox(): boolean {
		return this.inputType === 'checkbox';
	}

	get isRadio(): boolean {
		return this.inputType === 'radio';
	}

	get isTextarea(): boolean {
		return this.inputType === 'textarea';
	}
	@HostBinding('attr.data-unstyled')
	@Input()
	unstyled = false; // TODO: Check if used

	@Input() autocomplete: string;
	@Input() checked: boolean;
	@Input() colour: any;
	@Input() formControlName: string;
	@Input() id: string;
	@Input() inputType = 'text';
	@Input() inputValue = null;
	@Input() maxlength: string;
	@Input() name: string;
	@Input() placeholder: string;
	@Input() activeDescendant: string;
	@HostBinding('attr.data-size')
	@Input()
	size: InputSize = InputSize.initial;
	@Input() tabindex: number;
	@Input() selectOnClick = false;
	@Input() successIcon = false;
	@Input() maskingFn: (val: string) => any;
	@Input() inputMode: 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | null = null;
	@Input() readonly = false;

	@Input() rows: number;
	@Input() columns: number;

	/* eslint-disable @angular-eslint/no-output-native */
	@Output() keyup: EventEmitter<string> = new EventEmitter();
	@Output() keydown: EventEmitter<string> = new EventEmitter();
	@Output() blur: EventEmitter<Event> = new EventEmitter();
	@Output() focus: EventEmitter<Event> = new EventEmitter();
	/* eslint-enable @angular-eslint/no-output-native */

	@ViewChild('input', { read: ElementRef }) input: ElementRef;
	disabled: boolean | null;
	controlIsInvalid = false;
	ngControl: NgControl;

	onChangeCallback: (_: any) => void;
	onTouchedCallback: (_: any) => void;

	ngAfterViewInit(): void {
		if (!this.isCharInput) {
			this.input.nativeElement.checked = this.inputValue;
		}
	}

	onKeyup = (value: string | undefined): void => {
		this.keyup.emit(value);
		this.writeValue(value);
	};

	onKeydown = (value: string | undefined): void => {
		this.keydown.emit(value);
		this.writeValue(value);
	};

	onBlur = (event: FocusEvent): void => {
		this.blur.emit(event);
		this.onChange(event);
	};

	onFocus = (event: FocusEvent): void => {
		this.focus.emit(event);
		this.selectValue();
	};

	onChange = (event: any): void => {
		const returnValue = this.isCheckbox || this.isRadio ? event.target.checked : event.target.value;

		if (this.onChangeCallback) {
			this.onChangeCallback(returnValue);
		}
		if (this.onTouchedCallback) {
			this.onTouchedCallback(event);
		}
	};

	get nativeElement(): HTMLInputElement {
		return this.input.nativeElement;
	}

	onClick = (): boolean | null => (this.selectOnClick ? this.selectValue() : null);

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	selectValue = (): boolean => this.selectOnClick && this.input && this.input.nativeElement.select();

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled || null;
	}

	writeValue(value: any): void {
		if (!this.isCharInput && this.input) {
			this.input.nativeElement.checked = value;
		}

		if (this.maskingFn && value) {
			value = this.maskingFn(value);
		}

		this.inputValue = value;

		if (this.onChangeCallback) {
			this.onChangeCallback(value);
		}
	}
}
