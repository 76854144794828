import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const TOKEN_STORE_SERVICE = new InjectionToken<TokenStoreService>('token.store.service');

@Injectable({
	providedIn: 'root',
})
export abstract class TokenStoreService {
	abstract storeToken(token: string): void;

	abstract selectToken(): Observable<string | undefined>;
}
