import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ACCOUNT_PATH } from '@woolworthsnz/shop';
import {
	CustomWindow,
	DatalayerService,
	ModalRoutingService,
	NotificationType,
	TrackingEvent,
	WINDOW,
	WithPageMeta,
} from '@woolworthsnz/styleguide';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { routeNames } from '../../../app.routes';
import { EnvService } from '../../../common';
import { height } from '../../../common/animations/height';

import {
	preferencesPageLoaded,
	selectIsOnlineStore,
	selectPreferencesLoading,
	selectPreferenceValues,
	selectPreferredDayOfWeek,
	selectPreferredStoreId,
	updatePreference,
	updatePreferences,
} from '../../state/preferences';
import { getProfile, selectIsOver18, selectProfile, selectProfileLoading } from '../../state/profile';
import { selectAllStores } from '../../state/stores';
import { PreferenceType } from '../../types';

@UntilDestroy()
@WithPageMeta({ pageTitle: 'commsPrefs', metaTitle: 'commsPrefs' })
@Component({
	selector: 'ss-communication-preferences',
	templateUrl: './communication-preferences.component.html',
	styleUrls: ['./communication-preferences.component.scss'],
	animations: [height],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationPreferencesComponent implements OnInit {
	profileRoute = '';
	updateEmailRoute: string;

	form: UntypedFormGroup;

	preferredStore$: Observable<string | undefined | null>;
	preferredDay$: Observable<string>;
	isOver18$: Observable<boolean>;

	emailAddress: string | undefined = '';
	phoneNumber: string | undefined = '';

	trackingEvent = TrackingEvent;
	notificationType = NotificationType;

	get emailSelected(): boolean {
		return this.form.get('email.email')?.value ?? false;
	}

	get myCountdownSelected(): boolean {
		return this.form.get('email.emailOptions.myCountdown')?.value ?? false;
	}

	constructor(
		private fb: UntypedFormBuilder,
		private store: Store,
		private modalRoutingService: ModalRoutingService,
		private datalayerService: DatalayerService,
		private envService: EnvService,
		@Inject(WINDOW) private window: CustomWindow
	) {
		this.profileRoute = `${routeNames.account}/${routeNames.personalDetails}`;
		this.updateEmailRoute = `/${routeNames.account}/${routeNames.manageEmailPassword}`;

		this.form = this.fb.group({
			email: this.fb.group({
				email: [false],
				emailOptions: this.fb.group({
					myCountdown: false,
					promotions: false,
					onlineShopping: false,
					customerFeedback: false,
				}),
			}),
			sms: [false],
			personal: this.fb.group({
				alcoholFree: [false],
			}),
		});

		this.isOver18$ = this.store.select(selectIsOver18);
		this.preferredDay$ = this.store.select(selectPreferredDayOfWeek);
		this.preferredStore$ = combineLatest([
			this.store.select(selectIsOnlineStore),
			this.store.select(selectPreferredStoreId),
			this.store.select(selectAllStores),
		]).pipe(
			map(([isOnlineStore, preferredStoreId, stores]) => {
				if (isOnlineStore) return 'Online store';
				return stores.find((s) => s.simplifiedStoreId === preferredStoreId)?.name;
			})
		);
	}

	ngOnInit() {
		this.store.dispatch(getProfile());
		this.store.dispatch(preferencesPageLoaded());

		// on opt-in - enables/disables all suboptions options
		this.form
			.get('email.email')
			?.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged())
			.subscribe((emailsOn) =>
				this.form.get('email.emailOptions')?.setValue({
					myCountdown: emailsOn,
					promotions: emailsOn,
					onlineShopping: emailsOn,
					customerFeedback: emailsOn,
				})
			);

		this.setInitialFormState();
	}

	updateEmail() {
		this.window.location.assign(this.updateEmailRoute);
	}

	editEmailPreference() {
		this.modalRoutingService.open(routeNames.modals.outlet, routeNames.modals.emailPreferences);
	}

	updateSmsPreference() {
		this.window.location.assign(this.profileRoute);
	}

	private setInitialFormState() {
		this.store
			.select(selectProfileLoading)
			.pipe(
				untilDestroyed(this),
				filter((loading) => !loading),
				switchMap(() => this.store.select(selectProfile)),
				first()
			)
			.subscribe((profile) => {
				this.emailAddress = profile?.email;
				this.phoneNumber = profile?.mobilePhoneNumber;
			});

		// map store to form
		this.store
			.select(selectPreferencesLoading)
			.pipe(
				untilDestroyed(this),
				filter((loading) => !loading),
				switchMap(() => this.store.select(selectPreferenceValues)),
				first()
			)
			.subscribe((state) => {
				// set initial state from customer preferences
				this.form.setValue({
					email: {
						email: state[PreferenceType.AllowContactEmail],
						emailOptions: {
							myCountdown: state[PreferenceType.InterestWeeklySpecials],
							promotions: state[PreferenceType.InterestProduct],
							onlineShopping: state[PreferenceType.InterestOnlineShopping],
							customerFeedback: state[PreferenceType.InterestResearch],
						},
					},
					sms: state[PreferenceType.AllowContactSMS],
					personal: {
						alcoholFree: !state[PreferenceType.InterestAlcohol], // invert to get the opt-out (not interested) value
					},
				});

				// Start listening to form changes after initial value set
				this.listenToFormChanges();
			});
	}

	private listenToFormChanges() {
		this.subscribeValues(
			'email.email',
			(value) => ({
				id: PreferenceType.AllowContactEmail,
				value: value,
			}),
			'Email',
			true
		);

		this.subscribeValues(
			'email.emailOptions.myCountdown',
			(value) => ({
				id: PreferenceType.InterestWeeklySpecials,
				value,
			}),
			'myCountdown weekly email'
		);

		this.subscribeValues(
			'email.emailOptions.promotions',
			(value) => ({
				id: PreferenceType.InterestProduct,
				value,
			}),
			'Countdown promotions'
		);

		this.subscribeValues(
			'email.emailOptions.onlineShopping',
			(value) => ({
				id: PreferenceType.InterestOnlineShopping,
				value,
			}),
			'Online shopping'
		);

		this.subscribeValues(
			'email.emailOptions.customerFeedback',
			(value) => ({
				id: PreferenceType.InterestResearch,
				value,
			}),
			'Customer feedback'
		);

		this.subscribeValues('sms', (value) => ({ id: PreferenceType.AllowContactSMS, value }), 'Text/SMS', true);
		this.subscribeValues(
			'personal.alcoholFree',
			(value) => ({ id: PreferenceType.InterestAlcohol, value: !value }), // invert to get the opt-in value
			"I'm alcohol free"
		);
	}

	private subscribeValues(
		formPath: string,
		actionPayload: (formValue: boolean | string | number) => {
			id: PreferenceType;
			value: string | number | boolean;
		},
		trackingName?: string,
		isToggle = false
	) {
		// subscribes to the form changes and updates the preference
		this.form
			.get(formPath)
			?.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged())
			.subscribe((value) => {
				if (actionPayload(value).id === PreferenceType.AllowContactEmail) {
					const payload = {
						preferencesToUpdated: [actionPayload(value), { id: PreferenceType.EmailOptOut, value: !value }], // invert to get the opt-out value
					};
					this.store.dispatch(updatePreferences(payload));
				} else {
					this.store.dispatch(updatePreference(actionPayload(value)));
				}

				this.datalayerService.trackEvent(
					TrackingEvent.NotificationEvent,
					isToggle ? NotificationType.Switch : NotificationType.Checkbox,
					`${value ? 'pick' : 'unpick'} preference`,
					trackingName ?? formPath
				);
			});
	}
}
