<section [formGroup]="dobForm">
	<ng-content select="[label]"></ng-content>

	<input
		aria-label="Date of birth Day input"
		autocomplete="bday-day"
		id="{{ dayName }}"
		[attr.maxlength]="2"
		name="{{ dayName }}"
		placeholder="DD"
		type="tel"
		[formControlName]="dayName"
		(blur)="validateForm()"
		cdxNumeric
		#day
	/>

	<input
		aria-label="Date of birth Month input"
		autocomplete="bday-month"
		id="{{ monthName }}"
		[attr.maxlength]="2"
		name="{{ monthName }}"
		placeholder="MM"
		type="tel"
		[formControlName]="monthName"
		(blur)="validateForm()"
		cdxNumeric
		#month
	/>

	<input
		aria-label="Date of birth Year input"
		autocomplete="bday-year"
		id="{{ yearName }}"
		[attr.maxlength]="4"
		name="{{ yearName }}"
		type="tel"
		placeholder="YYYY"
		[formControlName]="yearName"
		(blur)="validateForm()"
		cdxNumeric
		#year
	/>

	<form-input [formControlName]="isOver18Name" ngDefaultControl inputType="checkbox">
		<label i18n="@@register-over18Label" [for]="isOver18Name" label>Are you over 18?</label>
	</form-input>

	<ng-content select="[validation]"></ng-content>
	<ng-content select="[cdxFormHelpText]"></ng-content>
</section>
