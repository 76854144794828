<ng-container *ngIf="content$ && content$ | async as content">
	<div class="panel-image" *ngIf="image" [attr.data-image-type]="imageType">
		<img [src]="image.url" [alt]="image.alt" />
	</div>

	<div class="panel-body">
		<h3 class="title">{{ content.title }}</h3>
		<p class="description">{{ content.description }}</p>
	</div>

	<a
		class="panel-cta"
		edr-button
		buttonStyle="block"
		[href]="content.ctaLink"
		(click)="trackViewYourBoostsLink(content.ctaText, content.title)"
	>
		{{ content.ctaText }}
	</a>
</ng-container>
