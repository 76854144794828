import { Pipe, PipeTransform } from '@angular/core';

/** Array of strings that are required to be upper cased
	 example: nz, aus, etc
	 Please keep the elements of this array in lowercase so that
	 these words are capitalised regardless of the input - Nz, nZ, nz etc. */
const wordsToCapitalize: string[] = ['nz'];

@Pipe({
	name: 'capitalizeSpecificWords',
	standalone: true
})
export class CapitalizeSpecificWordsPipe implements PipeTransform {
	transform(value?: string | null): string | undefined | null {
		if (!value?.length) {
			return value;
		}

		return value
			.split(' ')
			.map((word) => (wordsToCapitalize.includes(word.toLowerCase()) ? word.toUpperCase() : word))
			.join(' ');
	}
}
