<a
	maybeExternal
	[maybeExternalCurrentTab]="externalLinksInCurrentTab"
	href="{{ edrDashboardUrl }}"
	[cdxTrackEvent]="analyticsEvent"
	class="rewards-header"
	[attr.data-header-type]="headerType"
	[style.background-color]="backgroundColor"
>
	<edr-points-dial
		mini="true"
		[pointsBalance]="pointsBalance"
		[totalPointsRequired]="totalPointsRequired"
		[reversed]="headerType === pointsBalanceHeaderType.GlobalNavigation"
	/>
	<edr-rewards-notification
		[rewardsAvailable]="rewardsAvailable"
		[pointsRequired]="pointsRequired"
		[mini]="headerType === pointsBalanceHeaderType.GlobalNavigation"
		[redemptionPreference]="redemptionPreference"
	/>
</a>
