export function moveFromIndexToStartOfArray(inputArray: any[], index: number): void {
	if (index < 0 || index >= inputArray.length) {
		return;
	}
	inputArray.unshift(inputArray.splice(index, 1)[0]);
}

/**
 * Removes duplicates from an array
 */
export function mergeUnique<T>(arr1: T[], arr2: T[]): T[] {
	return arr1.concat(
		arr2.filter(function (item) {
			return arr1.indexOf(item) === -1;
		})
	);
}

export function isDefined<TValue>(value: TValue | null | undefined): value is TValue {
	return !(value === null || value === undefined);
}
