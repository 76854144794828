import { ErrorHandler, Injectable, Injector, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { WINDOW, CustomWindow } from './window.service';
import { LoggingService } from './logging.service';

@Injectable({
	providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
	error = new Subject();
	error$ = this.error.asObservable();
	loggingService: LoggingService;

	constructor(private injector: Injector, @Inject(WINDOW) private window: CustomWindow) {}

	handleError(e: any): void {
		// handle common when a user has kept the web application open for a long time, and there is a new generated hash for latest deployment
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if ((e.rejection && e.rejection.name === 'ChunkLoadError') || chunkFailedMessage.test(e.message)) {
			this.window.location.reload();

			return;
		}
		this.loggingService = this.injector.get(LoggingService);

		if (e.name !== 'HttpErrorResponse') {
			this.loggingService.trackException({ error: e });
		}

		this.loggingService.error(e);
		this.error.next(e);
	}
}
