import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { REQUEST } from '../services';
import { Request } from 'express';

export const FORWARD_HEADERS = new InjectionToken('forward_headers');
@Injectable()
export class SSRHeaderInterceptor implements HttpInterceptor {
	forwardHeaders = ['cookie', 'CDXQAAccess'];

	constructor(@Optional() @Inject(REQUEST) private httpRequest: Request) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.httpRequest) {
			// If optional request is provided, we are server side
			// set any forward headers on outgoing request to match original inbound request
			let headers = req.headers;
			this.forwardHeaders.forEach((h) => (headers = headers.set(h, this.httpRequest.get(h) || '')));

			// if no access token provided in request set it from the expected env var
			if (headers.get('CDXQAAccess') === '') {
				headers = headers.set('CDXQAAccess', process.env.CDXQA_ACCESS_TOKEN || '');
			}

			// always set X-Requested-With to a specific SSR header
			headers = headers.set('X-Requested-With', 'OnlineShopping.WebApp.SSR');

			req = req.clone({ headers });
		}
		return next.handle(req);
	}
}
