<div class="storeSelectionContent">
	<ng-container *ngIf="!isLoading; then storeSelection; else loading"></ng-container>
</div>

<ng-template #loading>
	<cdx-wapple-loading></cdx-wapple-loading>
</ng-template>

<ng-template #storeSelection>
	<div class="storeSelectionContent-dropdown">
		<form-dropdown
			data-cy="dropdownSelectRegion"
			class="storeSelectionContent-areaSelect"
			initialText="Select a region"
			labelText="Region"
			name="area-dropdown"
			[list]="areasForDropdown"
			[selectedValue]="selectedAreaId"
			(onSelect)="handleAreaSelect($event)"
			[constrainWidth]="false"
		></form-dropdown>
	</div>
	<div class="storeSelectionContent-list">
		<ng-container *ngIf="!showRecentlyUsedStores; then filteredStoreSelection"></ng-container>

		<ng-container *ngIf="showRecentlyUsedStores; then recentStoreSelection"></ng-container>
	</div>
</ng-template>

<ng-template #filteredStoreSelection>
	<span class="storeSelectionContent-sectionTitle">{{ selectedArea.name }} stores</span>
	<fulfilment-address-selector
		data-cy="selectedStoreAddress"
		(change)="changeAddress($event)"
		[shopperAddresses]="filteredStores"
	></fulfilment-address-selector>
</ng-template>

<ng-template #recentStoreSelection>
	<ng-container *ngIf="recentlyUsedStores.length">
		<span class="storeSelectionContent-sectionTitle">Recently used stores</span>
		<fulfilment-address-selector
			data-cy="recentlyUsedStores"
			(change)="changeAddress($event)"
			[shopperAddresses]="recentlyUsedStores"
		></fulfilment-address-selector>
	</ng-container>
</ng-template>
