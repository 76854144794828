<div>
	<div class="edr-spinner" [attr.data-color]="color">
		<div class="spinner">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
	<div class="edr-spinner-label"><ng-content></ng-content></div>
</div>
