import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InputSize, SuburbAutocompleteComponent, FormComponent } from '@woolworthsnz/form';
import { ButtonState, ButtonComponent, ModalRoutingService, ShopperService, ShopperState, WappleLoadingComponent } from '@woolworthsnz/styleguide';
import { ShopperAddress } from '@woolworthsnz/trader-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FulfilmentService, ManageAddressService } from '../../services';
import { SelectableAddress } from '../../ui-models';
import { AddressSelectorComponent } from '../address-selector/address-selector.component';
import { NgIf, AsyncPipe } from '@angular/common';

@UntilDestroy()
@Component({
	selector: 'fulfilment-delivery-address-selection',
	templateUrl: './delivery-address-selection.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, WappleLoadingComponent, AddressSelectorComponent, ButtonComponent, FormsModule, FormComponent, ReactiveFormsModule, SuburbAutocompleteComponent, AsyncPipe]
})
export class DeliveryAddressSelectionComponent implements OnInit {
	addresses$: Observable<SelectableAddress[]>;
	isShopper = false;
	isLoading = true;
	buttonState = ButtonState.default;
	suburbSelectForm: FormGroup;
	addAddressForm: FormGroup;
	inputSize = InputSize;

	@Input() addAddressRoute: string;
	@Input() modal: string;
	@Output() addressSelected = new EventEmitter<any>();

	constructor(
		private cdr: ChangeDetectorRef,
		private fb: FormBuilder,
		public manageAddressService: ManageAddressService,
		private fulfilmentService: FulfilmentService,
		private modalRoutingService: ModalRoutingService,
		private shopperService: ShopperService
	) { }

	ngOnInit(): void {
		this.suburbSelectForm = this.fb.group({});

		this.shopperService.state$.pipe(untilDestroyed(this)).subscribe((s: ShopperState) => {
			this.isShopper = Boolean(s.isShopper);
			this.isLoading = false;
		});

		this.addresses$ = this.manageAddressService.state$.pipe(
			map((state) => (state.addresses ?? []).map((address: ShopperAddress) => this.mapAddress(address)))
		);

		this.initFormAddAddressSubscription();

		this.manageAddressService.getAddresses();
	}

	initFormAddAddressSubscription(): void {
		this.addAddressForm = this.fb.group({}, { updateOn: 'blur' });
	}

	mapAddress(address: ShopperAddress): SelectableAddress {
		const builtAddress = this.manageAddressService.buildAddress(address.address);
		const subtitle = builtAddress.pop() || '';
		const title = builtAddress.join(', ');
		return {
			addressId: address.address?.externalAddressId || '',
			isSelected: address.isPrimary,
			title,
			subtitle,
		};
	}

	changeAddress(addressId: string): void {
		if (!addressId) {
			return;
		}

		this.fulfilmentService
			.changeSelectedDeliveryAddress(addressId)
			.pipe(untilDestroyed(this))
			.subscribe(this.handleSuccess);
	}

	handleSuccess = (): void => {
		this.addressSelected.emit();
	};

	handleSuburbSelection(): void {
		const id = this.suburbSelectForm.get('suburbId')?.value;

		if (!id) {
			return;
		}

		this.fulfilmentService.changeSelectedTraderSuburb(id).pipe(untilDestroyed(this)).subscribe(this.handleSuccess);
	}

	onAddNewAddress(): void {
		if (this.modal && this.addAddressRoute) {
			this.modalRoutingService.open(this.modal, this.addAddressRoute);
		}
	}
}
