import { Injector, NgModule } from '@angular/core';

/**
 * We need this to store a reference to the injector to reduce coupling with styleguide
 * If this decorator is used without importing styleguide the app must import this module
 */
@NgModule()
export class InjectorContainerModule {
	static injector: Injector;

	constructor(injector: Injector) {
		InjectorContainerModule.injector = injector;
	}
}
