import { transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { fadeInSteps, fadeOutSteps } from '../../../animations';

@Component({
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		'[@loaderAni]': 'true',
	},
	animations: [trigger('loaderAni', [transition(':enter', fadeInSteps), transition(':leave', fadeOutSteps)])],
	selector: 'cdx-wapple-loading',
	templateUrl: './wapple-loading.component.html',
	styleUrls: ['./wapple-loading.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class WappleLoadingComponent {
	@HostBinding('attr.aria-live') live = 'assertive';
	@HostBinding('attr.aria-label') ariaLabel = 'Loading, Please wait.';
}
