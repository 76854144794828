import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	getBalances,
	getBalancesComplete,
	getProfile,
	getProfileComplete,
	saveProfile,
	saveProfileComplete,
} from './profile.actions';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ProfileService } from '../../services';
import { saveProfileFailure } from '.';
import { of } from 'rxjs';
import { ApiService, AppSettingsService } from '@woolworthsnz/styleguide';
@Injectable()
export class ProfileEffects {
	onGetProfile$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(getProfile),
			mergeMap(() => this.profileService.getProfile()),
			map((profile) => getProfileComplete({ profile }))
		);
	});

	onSaveProfile$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(saveProfile),
			switchMap((action) =>
				this.profileService.saveProfile(action.profile).pipe(
					map(() => saveProfileComplete({ previousEmail: action.profile.email })),
					catchError((err) => {
						return of(saveProfileFailure({ errorMessage: err }));
					})
				)
			)
		);
	});

	getbalances$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(getBalances),
			switchMap(() =>
				this.apiService.get(`${this.appSettingsService.getSetting('bffApiUrl')}/v1/members/my/balances`).pipe(
					map((payload) => {
						console.log('saveForOccasionxx', payload);
						return getBalancesComplete({ saveForOccasion: payload });
					})
				)
			)
		);
	});

	constructor(
		private actions$: Actions,
		private profileService: ProfileService,
		private apiService: ApiService,
		private appSettingsService: AppSettingsService
	) {}
}
