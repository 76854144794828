<fieldset [formGroup]="formGroup">
	<div>
		<form-selection-tile
			class="vertical"
			inputId="{{ date }}~{{ slot?.id }}"
			attr.data-cy="timeslot-express"
			attr.data-ft="fullstory-express-pickup"
			inputValue="{{ date }}~{{ slot?.id }}"
			i18n="@@selection-time"
			[isDisabled]="isDisabled"
			[formControlName]="controlName"
			[successIcon]="false"
			[label]="label"
			[description]="description"
		>
			<img
				details
				alt="Online Supermarket: Online Grocery Shopping &amp; Free Recipes at countdown.co.nz"
				height="40"
				src="/assets/images/express/logo-wapple-green.svg"
				width="40"
			/>
		</form-selection-tile>
	</div>
</fieldset>
