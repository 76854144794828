<section class="landing">
	<div class="welcome">
		<h1>Account</h1>
		<h2>
			Kia ora<ng-container *ngIf="firstName$ | async; let firstName">, {{ firstName }}</ng-container>
		</h2>
	</div>
	<div>
		<ss-account-nav
			[links]="links$ | async"
			[isShopper]="isShopper$ | async"
			(signOut)="handleLogout()"
		></ss-account-nav>
	</div>
</section>
