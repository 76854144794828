/* eslint-disable @typescript-eslint/naming-convention */
import { isPlatformServer } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpUrlEncodingCodec } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoggingService } from './logging.service';
import { REQ_RECEIVE_TIME } from './server-state.service';

const baseHeaders = {
	'Content-Type': 'application/json',
	'X-Requested-With': 'OnlineShopping.WebApp',
	'Cache-Control': 'no-cache',
	Pragma: 'no-cache',
	Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
};

export default class CustomEncoder extends HttpUrlEncodingCodec {
	encodeKey(key: string): string {
		return encodeURIComponent(key);
	}

	encodeValue(value: string): string {
		return encodeURIComponent(value);
	}
}

export interface HttpArgs {
	params?: any;
	headers?: { [key: string]: string };
}

// TODO: Add logger
// TODO: This ideally would be a singleton service but cannot be due to the presence of the requestSent$ subject
@Injectable()
export class ApiService {
	requestSent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private http: HttpClient,
		@Inject(PLATFORM_ID) private platformId: Object,
		private loggingService: LoggingService,
		@Optional() @Inject(REQ_RECEIVE_TIME) private originalTime: number = 0
	) {}

	afterRequest = (): void => {
		this.requestSent$.next(false);
	};

	getDirect(endpoint: string, args?: HttpArgs): Observable<any> {
		const params = this.parseParams(args?.params);
		this.requestSent$.next(true);
		return this.http
			.get(endpoint, {
				params,
			})
			.pipe(finalize(this.afterRequest).bind(this));
	}

	get(endpoint: string, args?: HttpArgs): Observable<any> {
		const startTime = Date.now();
		this.loggingService.log(`Api Service GET on ${endpoint} at ${Date.now() - this.originalTime}ms`);
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);
		this.requestSent$.next(true);

		return this.http
			.get(endpoint, {
				params,
				headers: new HttpHeaders({ ...baseHeaders, ...headers }),
				withCredentials: true,
			})
			.pipe(
				finalize(() => {
					this.loggingService.log(`Api Service complete call to ${endpoint} in ${Date.now() - startTime}ms`);
					this.afterRequest();
				})
			);
	}

	getWithoutCredentials(endpoint: string, args?: HttpArgs): Observable<any> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);
		this.requestSent$.next(true);

		return this.http
			.get(endpoint, {
				params,
				headers: new HttpHeaders({ ...baseHeaders, ...headers }),
				withCredentials: false,
			})
			.pipe(finalize(this.afterRequest).bind(this));
	}

	getWithoutBaseHeaders<T>(endpoint: string, args?: HttpArgs): Observable<T> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);
		this.requestSent$.next(true);

		return this.http
			.get<T>(endpoint, {
				params,
				headers: new HttpHeaders(headers),
			})
			.pipe(finalize<T>(this.afterRequest).bind(this));
	}

	getBlob(endpoint: string, args?: HttpArgs): Observable<HttpResponse<Blob>> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);
		this.requestSent$.next(true);

		return this.http
			.get(endpoint, {
				params,
				headers: new HttpHeaders({ ...baseHeaders, ...headers }),
				withCredentials: true,
				responseType: 'blob' as 'json',
				observe: 'response',
			})
			.pipe(finalize<HttpResponse<any>>(this.afterRequest).bind(this));
	}

	/**
	 * Http GET returning the entire response instead of just the body parsed as JSON.
	 *
	 * This is done as a separate function instead of a parameter as the overloads in HttpClient are messing up
	 * the typing and the return type is more specific.
	 */
	getWithFullResponse(endpoint: string, args?: HttpArgs): Observable<HttpResponse<any>> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);
		this.requestSent$.next(true);

		if (isPlatformServer(this.platformId)) {
			baseHeaders['X-Requested-With'] = 'OnlineShopping.WebApp.SSR';
		}

		return this.http
			.get(`${endpoint}`, {
				params,
				headers: new HttpHeaders({ ...baseHeaders, ...headers }),
				withCredentials: true,
				observe: 'response',
			})
			.pipe(finalize<HttpResponse<any>>(this.afterRequest).bind(this));
	}

	post(endpoint: string, body: any, args?: HttpArgs): Observable<any> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);
		this.requestSent$.next(true);

		const httpOptions = {
			headers: new HttpHeaders({ ...baseHeaders, ...headers }),
			params,
		};

		return this.http.post(`${endpoint}`, body, httpOptions).pipe(finalize(this.afterRequest).bind(this));
	}

	put(endpoint: string, body: any, args?: HttpArgs): Observable<any> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);
		this.requestSent$.next(true);

		const httpOptions = {
			headers: new HttpHeaders({ ...baseHeaders, ...headers }),
			params,
		};

		return this.http.put(`${endpoint}`, body, httpOptions).pipe(finalize(this.afterRequest).bind(this));
	}

	/**
	 * Http PUT returning the entire response instead of just the body parsed as JSON.
	 *
	 * This is done as a separate function instead of a parameter as the overloads in HttpClient are messing up
	 * the typing and the return type is more specific.
	 */
	putWithFullResponse(endpoint: string, body: any, args?: HttpArgs): Observable<HttpResponse<any>> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);
		this.requestSent$.next(true);

		const httpOptions = {
			headers: new HttpHeaders({ ...baseHeaders, ...headers }),
			params,
		};

		const req = this.http.put(`${endpoint}`, body, {
			...httpOptions,
			observe: 'response',
		});
		req.pipe(finalize(this.afterRequest).bind(this));

		return req;
	}

	patch(endpoint: string, body: any, args?: HttpArgs): Observable<any> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);
		this.requestSent$.next(true);

		const httpOptions = {
			headers: new HttpHeaders({ ...baseHeaders, ...headers }),
			params,
		};

		return this.http.patch(`${endpoint}`, body, httpOptions).pipe(finalize(this.afterRequest).bind(this));
	}

	delete(endpoint: string, args?: HttpArgs): Observable<any> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);
		this.requestSent$.next(true);

		const httpOptions = {
			headers: new HttpHeaders({ ...baseHeaders, ...headers }),
			params,
			withCredentials: true,
		};

		return this.http.delete(`${endpoint}`, httpOptions).pipe(finalize(this.afterRequest).bind(this));
	}

	/**
	 * Http DELETE returning the entire response instead of just the body parsed as JSON.
	 *
	 * This is done as a separate function instead of a parameter as the overloads in HttpClient are messing up
	 * the typing and the return type is more specific.
	 */
	deleteWithFullResponse(endpoint: string, args?: HttpArgs): Observable<HttpResponse<any>> {
		const headers = (args && args.headers) || {};
		const params = this.parseParams(args?.params);
		this.requestSent$.next(true);

		const httpOptions = {
			headers: new HttpHeaders({ ...baseHeaders, ...headers }),
			params,
		};

		const req = this.http.delete(`${endpoint}`, { ...httpOptions, observe: 'response' });
		req.pipe(finalize(this.afterRequest).bind(this));

		return req;
	}

	parseParams(params: any): HttpParams {
		const flatParams = params ? this.flatten(params) : {};
		const cleanParams = this.clean(flatParams);
		const parsedDates = this.parseDates(cleanParams);

		return new HttpParams({
			fromObject: { ...parsedDates },
			encoder: new CustomEncoder(),
		});
	}

	/**
	 * Takes an object with empty or null items and removes them
	 *
	 * @param o: Object
	 */
	clean = (o: { [x: string]: any }): { [x: string]: any } => {
		Object.keys(o).forEach((k) => !o[k] && delete o[k]);
		return o;
	};

	/**
	 * Takes a nested object and flattens it into an array
	 *
	 * @param o: Object
	 */
	flatten = (o: any): [] =>
		Object.assign(
			{},
			...(function _flatten(internalO): any {
				if (!internalO) {
					return [];
				}
				return [].concat(
					...Object.keys(internalO).map((k) =>
						typeof internalO[k] === 'object' &&
						!Array.isArray(internalO[k]) &&
						!(internalO[k] instanceof Date)
							? _flatten(internalO[k])
							: { [k]: internalO[k] }
					)
				);
			})(o)
		);

	/**
	 * Converts any dates into the object into UTC ISO strings
	 *
	 * @param o: Object
	 */
	parseDates = (o: { [x: string]: any }): { [x: string]: any } => {
		Object.keys(o).forEach((k) => {
			o[k] = o[k] instanceof Date ? new Date(o[k]).toISOString() : o[k];
		});

		return o;
	};
}
