import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { EDRButtonComponent } from '../button/button.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { ImageProps } from '../../models';
import { combineLatest, map, Observable, startWith, Subject } from 'rxjs';
import { EverydayRewardsFacade } from '../../+state';
import { ShopperService } from '@woolworthsnz/styleguide';
import { TealiumUtagService } from '@woolworthsnz/analytics';

export type EDRNotificationPanelImageType = 'icon' | 'image';

interface NotificationPanelContent {
	title: string;
	description: string;
	ctaText: string;
	ctaLink: string;
}

@Component({
	selector: 'edr-notification-panel',
	templateUrl: './notification-panel.component.html',
	styleUrls: ['./notification-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [EDRButtonComponent, NgIf, AsyncPipe],
})
export class EDRNotificationPanelComponent implements OnInit, OnChanges {
	@Input() public image: ImageProps;
	@Input() public imageType: EDRNotificationPanelImageType;
	@Input() public title: string;
	@Input() public noOffersTitle: string;
	@Input() public description: string;
	@Input() public noOffersDescription: string;
	@Input() public ctaText: string;
	@Input() public noOffersCtaText: string;
	@Input() public ctaLink: string;
	@Input() public noOffersCtaLink: string;

	public content$: Observable<NotificationPanelContent> | undefined;

	private boostOffersCount = 0;
	private readonly contentChanged$ = new Subject<void>();

	constructor(
		private edrFacade: EverydayRewardsFacade,
		private shopperService: ShopperService,
		private tealiumUtagService: TealiumUtagService
	) {}

	public ngOnInit(): void {
		const isLoggedIn$ = this.shopperService.state$.pipe(map((state) => Boolean(state.isLoggedIn)));
		const boostOffersCount$ = this.edrFacade.boostOffersCount$;

		const combined$ = combineLatest([
			isLoggedIn$,
			boostOffersCount$.pipe(startWith(0)),
			this.contentChanged$.pipe(startWith(null)),
		]);

		this.content$ = combined$.pipe(
			map(([isLoggedIn, boostOffersCount]) => {
				if (!isLoggedIn) {
					return {
						title: this.title,
						description: this.description,
						ctaLink: this.ctaLink,
						ctaText: this.ctaText,
					};
				}

				const totalBoostOffers = boostOffersCount ?? this.boostOffersCount;

				// Assign to private variable to keep track. Not used in UI
				this.boostOffersCount = totalBoostOffers;

				const useNoOffers = totalBoostOffers < 1;
				return {
					title: this.hydrateText(useNoOffers ? this.noOffersTitle : this.title),
					description: this.hydrateText(useNoOffers ? this.noOffersDescription : this.description),
					ctaLink: this.hydrateText(useNoOffers ? this.noOffersCtaLink : this.ctaLink),
					ctaText: this.hydrateText(useNoOffers ? this.noOffersCtaText : this.ctaText),
				};
			})
		);
	}

	public ngOnChanges(): void {
		// Trigger content refresh
		this.contentChanged$.next();
	}

	public trackViewYourBoostsLink(linkText: string, trackingName: string): void {
		this.tealiumUtagService.link({
			tealium_event: 'select_content',
			content_type: 'CTA',
			content_name: trackingName,
			content_text: linkText,
			content_component: 'EDR notification panel',
		});
	}

	private hydrateText(text: string): string {
		const tagsToReplace: Array<{ tag: string; value: string }> = [
			{ tag: '{{OffersCount}}', value: this.boostOffersCount.toString() },
		];

		let hydratedString = text ?? '';

		for (const tag of tagsToReplace) {
			const regExp = new RegExp(tag.tag, 'gi'); // 'g' Replace all, 'i' case insensitive
			hydratedString = hydratedString.replace(regExp, tag.value);
		}
		return hydratedString;
	}
}
