<ng-template #unavailableContainer>
    <cdx-alert *ngIf="hasLoaded" [title]="noSlotsAvailable" [fullwidth]="true" icon="alarm"></cdx-alert>
</ng-template>

<cdx-alert
    [animate]="false"
    [description]="timeZoneOffsetWarningMessage"
    title="You are outside our timezone"
    type="warning"
    *ngIf="timeZoneOffset"
></cdx-alert>

<fieldset [formGroup]="formGroup" cdxFieldset [divider]="false" legend="Pick a Date" [hideLegend]="true" [i18n]="'@@howWhereWhen-deliveryOrPickup'" *ngIf="hasAvailableDays(); else unavailableContainer">
    <label>Next available slot:</label>
    <section #dayScroll>
        <div class="scrollWrapper" #dayScrollWrapper>
            <form-selection-tile *ngFor="let day of daySlots | keyvalue:keepOriginalOrder; let i = index" [id]="day.key" inputId="day-{{i}}" [formControlName]="controlName" [inputValue]="day.key" i18n="@@selection-dayDelivery" [isDisabled]="!hasAvailableSlots(day.value)"
                [successIcon]="false" [label]="getDisplayedDayOfWeek(day.key)">
                <time [attr.datetime]="day.key" details>{{getDisplayedDate(day.key, day.value)}}</time>
            </form-selection-tile>
        </div>
    </section>
</fieldset>
