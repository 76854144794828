<ng-container [ngSwitch]="level">
	<h1 *ngSwitchCase="1">
		<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
	</h1>
	<h2 *ngSwitchCase="2">
		<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
	</h2>
	<h3 *ngSwitchCase="3">
		<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
	</h3>
	<h4 *ngSwitchCase="4">
		<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
	</h4>
	<h5 *ngSwitchCase="5">
		<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
	</h5>
	<h6 *ngSwitchCase="6">
		<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
	</h6>
</ng-container>

<ng-template #contentTemplate>
	<span *ngIf="text" [innerHTML]="text" [class]="headingClass"></span>
	<ng-content></ng-content>
</ng-template>
