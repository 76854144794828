import { Injectable } from '@angular/core';
import { AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ApiService } from '@woolworthsnz/styleguide';
import { Observable, Subject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable()
export class FormService {
	errored: Subject<any> = new Subject();
	result: Subject<any> = new Subject();
	submitted: Subject<boolean> = new Subject();

	errored$: Observable<any> = this.errored.asObservable();
	result$: Observable<boolean> = this.result.asObservable();
	submitted$: Observable<boolean> = this.submitted.asObservable();

	constructor(private apiService: ApiService) {
		this.submitted$ = this.apiService.requestSent$;
	}

	addControlToParent(
		controlName: string,
		validators: ValidatorFn[],
		parentForm: FormGroup,
		asyncValidators: AsyncValidatorFn | AsyncValidatorFn[] = [],
		initialValue: any = '',
		removeAndCreate = false
	): void {
		if (!parentForm) {
			return;
		}

		// Remove the already existing same-name-control.
		// if we don't remove the already existing same-name-control,
		//	the initialValue in .addControl(...) below would not take effect.
		//
		// I believe for every new control to be added, we should try to
		//	remove the same-name-control first, but to be safe, just change
		//	the behavior of 'form-radio-group' now.
		if (removeAndCreate) {
			parentForm.removeControl(controlName);
		}

		parentForm.addControl(
			controlName,
			new FormControl(initialValue, {
				validators,
				asyncValidators,
			})
		);
	}

	submitForm(formAction: string, formValues: any): void {
		this.errored.next(false);
		this.apiService
			.post(formAction, formValues)
			.pipe(
				take(1),
				catchError((e) => {
					this.setError(e);
					return e;
				})
			)
			.subscribe((result) => this.result.next(result));
	}

	setError(error: any): void {
		this.errored.next(error);
	}

	unsetError(): void {
		this.errored.next(null);
	}
}
