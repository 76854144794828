import { Injectable } from '@angular/core';
import { ApiService } from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { EnvService } from '../../../common';
import { CustomerPreferences, PreferenceType } from '../../types';

@Injectable({
	providedIn: 'root',
})
export class PreferencesService {
	constructor(private api: ApiService, private envService: EnvService) {}

	getPreferences(): Observable<CustomerPreferences> {
		return this.api.get(`${this.envService.bffApiUrl}/v2/customer/preferences/me`, {});
	}

	updatePreferences(
		...preferences: { id: PreferenceType; value: boolean | string | number }[]
	): Observable<CustomerPreferences> {
		return this.api.post(`${this.envService.bffApiUrl}/v2/customer/preferences/me`, preferences);
	}

	unsubscribe(body: { email: string; version: string; hash: string }): Observable<boolean> {
		return this.api.post(`${this.envService.bffApiUrl}/v1/customerpreferences/unsubscribe`, body);
	}
}
