import { createAction, props } from '@ngrx/store';
import { CustomerPreference, PreferenceType } from '../../types';

export const preferencesPageLoaded = createAction('[Account Preferences] Preferences page loaded');

export const updatePreference = createAction(
	'[Account Preferences] Update preference',
	props<{ id: PreferenceType; value: string | boolean | number }>()
);

export const updatePreferences = createAction(
	'[Account Preferences] Update multiple preferences',
	props<{ preferencesToUpdated: { id: PreferenceType; value: string | boolean | number }[] }>()
);

export const updatePreferenceComplete = createAction(
	'[Account Preferences] Update preference loaded',
	props<{ preferences: CustomerPreference[] }>()
);

export const getPreferences = createAction('[Account Preferences] Get preferences');
export const getPreferencesComplete = createAction(
	'[Account Preferences] Get preferences loaded',
	props<{ preferences: CustomerPreference[] }>()
);

export const unsubscribe = createAction(
	'[Account Preferences] Unsubscribe',
	props<{ email: string; version: string; hash: string }>()
);
export const unsubscribed = createAction('[Account Preferences] Unsubscribed');
export const unsubscribeFailure = createAction(
	'[Account Preferences] Unsubscribe failure',
	props<{ errorMessage: string }>()
);
