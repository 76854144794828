import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
	selector: '[cdxPopupTarget]',
	exportAs: 'cdxPopupTarget',
	standalone: true
})
export class PopupTargetDirective {
	// Defaults to false to listen to onClick events.
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('ignoreClickEvent') ignoreClickEvent = false;
	@HostBinding('class') class: string;

	click = new Subject<void>();

	constructor(public elementRef: ElementRef) { }

	@HostListener('click')
	onClicked(): void {
		if (!this.ignoreClickEvent) {
			this.click.next();
		}
	}
}
