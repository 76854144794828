import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvalidTypeDirective } from '../../directives/invalid-type.directive';
import { InputErrorComponent } from '../input-error/input-error.component';
import { FormHelpTextComponent } from '../form-help-text/form-help-text.component';
import { NgClass, NgIf } from '@angular/common';
import { ButtonComponent } from '@woolworthsnz/styleguide';
import { InputWithIconButtonComponent } from '../input-with-icon-button/input-with-icon-button.component';

@Component({
	selector: 'form-password-input',
	templateUrl: './password-input.component.html',
	styleUrls: ['./password-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		InputWithIconButtonComponent,
		ButtonComponent,
		NgClass,
		NgIf,
		FormHelpTextComponent,
		InputErrorComponent,
		InvalidTypeDirective,
	],
})
export class PasswordInputComponent implements OnInit {
	@Input() showPasswordHelpText: boolean;
	@Input() passwordLabel: string;
	@Input() validationMessage = 'Please enter a valid password';
	type: 'text' | 'password' = 'password';
	parentFormGroup: FormGroup;

	get state(): 'Hide' | 'Show' {
		return this.type && this.type === 'text' ? 'Hide' : 'Show';
	}

	get buttonLabel(): string {
		return this.state + ' password';
	}

	constructor(private parent: FormGroupDirective) {}

	ngOnInit(): void {
		this.parentFormGroup = this.parent.form;
	}

	onButtonChange(event: { stopPropagation: () => void; preventDefault: () => void }): boolean {
		event.stopPropagation();
		event.preventDefault();
		return false;
	}

	toggleInputType(_: any): void {
		if (this.type && this.type === 'text') {
			this.type = 'password';
		} else {
			this.type = 'text';
		}
	}
}
