import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { EDRHeadingLevel } from '../../models';

/**
 * Example usage:
 * <edr-heading level="2">Heading 2</edr-heading>
 * <edr-heading level="3" style="heading--1">Heading 3 styled as heading 1</edr-heading>
 */
@Component({
	selector: 'edr-heading',
	templateUrl: './heading.component.html',
	styleUrls: ['./heading.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, NgSwitch, NgTemplateOutlet, NgSwitchCase],
})
export class EDRHeadingComponent {
	@Input() public level: EDRHeadingLevel = 1;
	@Input() public displayAs?: EDRHeadingLevel;
	@Input() public text = '';

	public get headingClass(): string {
		return `heading heading--${this.displayAs || this.level}`;
	}
}
