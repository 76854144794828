import { FormControl, FormGroup } from '@angular/forms';

export const charGroups = [/([a-z])/, /([A-Z])/, /([0-9])/, /([!@\#$%&/=?_.,:;\\-]|\s)/];

const passesCharMatchCriteria = (requiredGroupCount: any, input: any): boolean =>
	Boolean(input.filter((r: any) => r != null).length >= requiredGroupCount);

/**
 * Validate against Password rules set out in a configuration object
 */
export const passwordValidator =
	(minimumLength = 8, requiredGroupCount = 2, required = true) =>
	(control: FormGroup | FormControl): { [key: string]: boolean } | null => {
		const error = {
			passwordInvalid: true,
		};

		if (!control.value && !required) {
			return null;
		}

		const results: any = [];

		if (control.value.length < minimumLength) {
			return error;
		}

		charGroups.forEach((c) => {
			results.push(control.value.match(c));
		});

		if (!passesCharMatchCriteria(requiredGroupCount, results)) {
			return error;
		}

		return null;
	};
