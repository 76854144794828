import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService, LoggingService } from '@woolworthsnz/styleguide';
import { AddressService, FormService, SuburbService } from '../../services';
import { InputSize } from '../input/input.component';
import { ManualAddressComponent } from '../manual-address/manual-address.component';
import { ValidatedAddressComponent } from '../validated-address/validated-address.component';
import { NgIf } from '@angular/common';

@Component({
	selector: 'form-address-capture',
	template: `
		<h2 i18n="@@addressCapture-title" class="heading--3" *ngIf="title">
			{{ title }}
		</h2>
		<p i18n="@@addressCapture-supplementary" class="text--small" *ngIf="description">
			{{ description }}
		</p>
		<ng-container [formGroup]="parentFormGroup">
			<form-address-validated
				*ngIf="!isManualEntry"
				[formGroup]="parentFormGroup"
				[size]="size"
				[layout]="layout"
				[showDeliveryZone]="showDeliveryZone"
				(switchToManualEntryEmitter)="switchToManualEntry()"
			></form-address-validated>
			<form-address-manual
				*ngIf="isManualEntry"
				[formGroup]="parentFormGroup"
				[showPlaceHolders]="showPlaceHolders"
				[showDeliveryZone]="showDeliveryZone"
				(switchToValidatedEntryEmitter)="switchToValidatedEntry()"
			>
			</form-address-manual>
		</ng-container>
	`,
	styleUrls: ['./address-capture.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [AddressService, SuburbService, ApiService],
	standalone: true,
	imports: [NgIf, FormsModule, ReactiveFormsModule, ValidatedAddressComponent, ManualAddressComponent],
})
export class AddressCaptureComponent implements OnInit {
	@HostBinding('attr.align')
	@Input()
	align: 'left' | 'center';

	@Input() size: InputSize;
	@Input() layout: 'narrow' | 'medium' | 'large' = 'medium';
	@Input() title: string;
	@Input() description: string;
	@Input() showDeliveryZone = true;
	@Input() showPlaceHolders = false;

	isManualEntry = false;
	parentFormGroup: FormGroup;

	constructor(
		public parent: FormGroupDirective,
		public formService: FormService,
		private loggingService: LoggingService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.parentFormGroup = this.parent.form;
	}

	switchToValidatedEntry(): void {
		this.loggingService.trackEvent('UI:Account:Any:SwitchedToValidatedEntry', {});

		this.isManualEntry = false;
	}

	switchToManualEntry(): void {
		this.loggingService.trackEvent('UI:Account:Any:SwitchedToManualEntry', {});

		this.isManualEntry = true;
	}
}
