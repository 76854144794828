import { Injectable, TemplateRef } from '@angular/core';
import { AppSettingsService, LocalStorageService, ModalOverlayService } from '@woolworthsnz/styleguide';
import { combineLatest, filter, map, Observable, of, take, tap } from 'rxjs';
import { EverydayRewardsFacade } from '../+state';
import { EdrBalanceResponseV2 } from '@woolworthsnz/trader-api';

export const EDR_REDEEMABLE_VOUCHERS_VALUE_STORAGE_KEY = 'edr-redeemableVouchersValue';

@Injectable({
	providedIn: 'root',
})
export class EDRCelebratoryModalService {
	private redeemableRewardsVouchersValue$: Observable<number>;

	constructor(
		private modalOverlayService: ModalOverlayService,
		private localStorage: LocalStorageService,
		private edrFacade: EverydayRewardsFacade,
		private appSettingsService: AppSettingsService
	) {
		this.redeemableRewardsVouchersValue$ = this.edrFacade.edrBalance$.pipe(
			map((balance) => balance?.pointsAsVoucherCount ?? -1)
		);
	}

	/**
	 * Conditionally opens edr celebratory modal
	 */
	public tryOpenCelebratoryModal(templateRef: TemplateRef<any>): void {
		combineLatest([
			this.redeemableRewardsVouchersValue$,
			of(parseInt(this.localStorage.getItem(EDR_REDEEMABLE_VOUCHERS_VALUE_STORAGE_KEY) || '0', 10)),
			this.edrFacade.redemptionPreference$.pipe(
				map((preference) => preference === EdrBalanceResponseV2.RedemptionPreferenceEnum.SpendNow)
			),
		])
			.pipe(
				tap(([redeemableVouchersValue]) => {
					// Only save if this is not the default value from the constructor, otherwise that default value overwrites any previously stored value
					if (redeemableVouchersValue !== -1) {
						this.localStorage.setItem(
							EDR_REDEEMABLE_VOUCHERS_VALUE_STORAGE_KEY,
							redeemableVouchersValue.toString()
						);
					}
				}),
				filter(
					([redeemableVouchersValue, storedVouchersValue, isSpendNow]) =>
						!this.appSettingsService.getSetting('isEmbeddedInApp') && // Don't show modal if web app is embedded in the mobile app
						redeemableVouchersValue > storedVouchersValue && // Only show the modal if the redeemable vouchers value has increased
						isSpendNow
				),
				take(1)
			)
			.subscribe(() => {
				this.openRewardsModal(templateRef);
			});
	}

	private openRewardsModal(templateRef: TemplateRef<any>): void {
		this.modalOverlayService.setState({ hasBackdrop: false });
		this.modalOverlayService.open({ templateRef });
	}
}
