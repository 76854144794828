<global-nav
	[mainNav]="mainNav"
	[mobileNav]="mobileNav"
	(logout)="handleLogout()"
	[trolleyLink]="trolleyLink"
	[timeslotRoute]="timeslotRoute"
	[externalLinksInCurrentTab]="true"
	[disableNewNavItemIndication]="true"
	[class.nav-experiment-v1]="isNavExperiment7VariationActive$ | async"
></global-nav>

<div class="wrapper">
	<main>
		<router-outlet></router-outlet>
	</main>
</div>
<router-outlet name="contentfooter"></router-outlet>
<global-nav-footer></global-nav-footer>
<cdx-svg-definitions></cdx-svg-definitions>
<cdx-modal-view></cdx-modal-view>
