import { Action, createReducer, on } from '@ngrx/store';
import {
	getBalancesComplete,
	getProfile,
	getProfileComplete,
	saveProfile,
	saveProfileComplete,
	saveProfileFailure,
} from './profile.actions';
import { CustomerProfile } from '../../types';

export interface ProfileState {
	loading: boolean;
	updating: boolean;
	profile?: CustomerProfile;
	errorMessage: string;
	updateSuccess: boolean;
	updateFailure: boolean;
	previousEmail?: string;
	saveForOccasion?: SaveForOccasion;
}

export interface SaveForOccasion {
	accountStatus?: string;
	redemptionPreference?: string;
	totalPointsBalance?: number;
	isPointsRedeemable?: boolean;
	redeemablePointsBalance?: number;
	redeemablePointsAsVoucherDollars?: number;
	redeemablePointsAsVoucherCount?: number;
	pointsAsVoucherDollars?: number;
	pointsAsVoucherCount?: number;
	pointsUntilNextReward?: number;
	pointsPerReward?: number;
	pointsBalance?: number;
}

export const initialProfileState: ProfileState = {
	loading: true,
	updating: false,
	errorMessage: '',
	updateSuccess: false,
	updateFailure: false,
	saveForOccasion: {
		accountStatus: 'ACTIVE',
		redemptionPreference: 'SpendNow',
		totalPointsBalance: 0,
		isPointsRedeemable: false,
		redeemablePointsBalance: 0,
		redeemablePointsAsVoucherDollars: 0,
		redeemablePointsAsVoucherCount: 0,
		pointsAsVoucherDollars: 0,
		pointsAsVoucherCount: 0,
		pointsUntilNextReward: 2000,
		pointsPerReward: 2000,
		pointsBalance: 0,
	},
};

const _profileReducer = createReducer(
	initialProfileState,
	on(getProfile, (state): ProfileState => ({ ...state, loading: true, updateSuccess: false, updateFailure: false })),
	on(
		getProfileComplete,
		(state, { profile }): ProfileState => ({ ...state, profile, loading: false, previousEmail: profile.email })
	),
	on(
		getBalancesComplete,
		(state, { saveForOccasion }): ProfileState => ({
			...state,
			saveForOccasion: saveForOccasion,
			updateSuccess: false,
			updateFailure: false,
		})
	),
	on(
		saveProfile,
		(state, { profile }): ProfileState => ({
			...state,
			profile,
			updating: true,
			updateSuccess: false,
			updateFailure: false,
		})
	),
	on(
		saveProfileComplete,
		(state, action: any): ProfileState => ({
			...state,
			updating: false,
			previousEmail: action.previousEmail,
			errorMessage: '',
			updateSuccess: true,
			updateFailure: false,
		})
	),
	on(saveProfileFailure, (state, { errorMessage }): ProfileState => {
		return { ...state, errorMessage: errorMessage, updateSuccess: false, updateFailure: true };
	})
);

export function profileReducer(state: ProfileState | undefined, action: Action) {
	return _profileReducer(state, action);
}
