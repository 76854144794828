import { createFeature, createReducer, on } from '@ngrx/store';
import { getFeaturesComplete } from './features.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface Feature {
	id: string;
	enabled: boolean;
}

export interface FeaturesState extends EntityState<Feature> {
	loading: boolean;
}

export const adapter: EntityAdapter<Feature> = createEntityAdapter<Feature>();

export const initialFeaturesState: FeaturesState = adapter.getInitialState({
	loading: true,
});

export const featuresFeature = createFeature({
	name: 'features',
	reducer: createReducer(
		initialFeaturesState,
		on(getFeaturesComplete, (state, { features }) => adapter.setAll(features, { ...state, loading: false }))
	),
});

export const { name, reducer } = featuresFeature;
