<section class="accountNav">
	<div class="accountNav-group" *ngFor="let group of filteredLinks; trackBy: trackByGroupFn; last as isLast">
		<h3 class="accountNav-header">
			<cdx-svg-icon *ngIf="group.icon" fill="currentColor" display="inline" [name]="group.icon"> </cdx-svg-icon>
			<span>{{ group.label }}</span> <span class="new" *ngIf="group.showAsNew"> NEW </span>
		</h3>
		<ul>
			<li *ngFor="let link of group.items; trackBy: trackByLinkFn">
				<a
					*ngIf="link.external && !link.hidden; else routed"
					[href]="getExternalUrl(link)"
					target="{{link.openNew?'_blank':'_self'}}"
					class="accountNav-link {{ link.cssClass }}"
					[cdxTrackEvent]="{
						event: trackingEvent.NotificationEvent,
						type: notificationType.Link,
						name: group.label,
						value: link.label
					}"
				>
					<span>{{ link.label }}</span>
					<span class="icons">
						<img class="ext-link" src="/assets/externalLink.svg" alt="{{ group.label }}" *ngIf="link.openNew"/>
						<cdx-svg-icon fill="currentColor" display="inline" name="chevron-right" size="small"  *ngIf="!link.openNew"> </cdx-svg-icon>
						<span class="new" *ngIf="link.showAsNew"> NEW </span>
					</span>
				</a>
				<ng-template #routed>
					<a
						*ngIf="!link.hidden"
						[routerLink]="link.url"
						class="accountNav-link {{ link.cssClass }}"
						[cdxTrackEvent]="{
							event: trackingEvent.NotificationEvent,
							type: notificationType.Link,
							name: group.label,
							value: link.label
						}"
					>
						<span>{{ link.label }}</span>
						<span class="icons">
							<cdx-svg-icon fill="currentColor" display="inline" name="chevron-right" size="small"> </cdx-svg-icon>
							<span class="new" *ngIf="link.showAsNew"> NEW </span>
						</span>
					</a>
				</ng-template>
			</li>
			<li *ngIf="isLast">
				<a
					cdxButton
					fillStyle="link"
					class="accountNav-signOut"
					(click)="signOut.next()"
					i18n="@@accountNav-signOut"
					[cdxTrackEvent]="{
						event: trackingEvent.NotificationEvent,
						type: notificationType.Link,
						name: group.label,
						value: 'Sign out'
					}"
				>
					Sign out
				</a>
			</li>
		</ul>
	</div>
</section>
