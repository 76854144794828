import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as dayjs from 'dayjs';
import { ProfileState } from './profile.reducer';

export const profileFeatureKey = 'profile';

export const selectProfileState = createFeatureSelector<ProfileState>(profileFeatureKey);

export const selectProfileLoading = createSelector(selectProfileState, (state) => state.loading);

export const selectProfile = createSelector(selectProfileState, (state) => state.profile);

export const selectFullName = createSelector(selectProfile, (profile) =>
	profile?.firstName ? `${profile?.firstName} ${profile?.lastName}` : ''
);

export const selectDob = createSelector(selectProfile, (profile) => (profile ? profile.dateOfBirth : ''));

export const selectIsOver18 = createSelector(selectProfile, (profile) =>
	profile?.dateOfBirth === null ? false : dayjs().diff(profile?.dateOfBirth, 'year') >= 18
);

export const selectFirstName = createSelector(selectProfile, (profile) =>
	profile?.firstName ? `${profile?.firstName}` : ''
);

export const selectEmail = createSelector(selectProfile, (profile) => (profile ? profile.email : ''));

export const selectPreviousEmail = createSelector(selectProfileState, (state) => state.previousEmail);

export const selectSaveComplete = createSelector(selectProfileState, (state) => state.updating);

export const selectSaveFailure = createSelector(selectProfileState, (state) => state.errorMessage);
export const selectUpdateSuccess = createSelector(selectProfileState, (state) => state.updateSuccess);

export const selectUpdateEmailFailure = createSelector(selectProfileState, (state) => state.updateFailure);

export const selectSaveForOccasion = createSelector(selectProfileState, (state) => state.saveForOccasion);

