import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';

export enum PageVisibilityState {
	hidden = 'hidden',
	visible = 'visible',
}

@Injectable({
	providedIn: 'root',
})
export class PageVisibilityService implements OnDestroy {
	visibility$ = new Subject<DocumentVisibilityState>();

	constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(DOCUMENT) private document: Document) {
		if (isPlatformServer(this.platformId)) {
			return;
		}
		this.setupVisibilityListener();
	}

	ngOnDestroy(): void {
		this.document.removeEventListener('visibilitychange', this.handleVisibilityChange);
	}

	handleVisibilityChange = (): void => {
		const visibilityState = this.document.visibilityState;
		if (visibilityState) {
			this.visibility$.next(visibilityState);
		} else {
			if (this.document.hidden) {
				this.visibility$.next(PageVisibilityState.hidden);
			} else {
				this.visibility$.next(PageVisibilityState.visible);
			}
		}
	};

	private setupVisibilityListener(): void {
		this.document.addEventListener('visibilitychange', this.handleVisibilityChange);
	}
}
