import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddressService, DropdownComponent, ListItem } from '@woolworthsnz/form';
import { convertSentenceToBreakOnCommas, moveFromIndexToStartOfArray, WappleLoadingComponent } from '@woolworthsnz/styleguide';
import { StoreAddress, StoreAddressesResponse, StoreArea } from '@woolworthsnz/trader-api';
import { first } from 'rxjs/operators';
import { FulfilmentService, FulfilmentState, FulfilmentStoreService } from '../../services';
import { SelectableAddress } from '../../ui-models';
import { AddressSelectorComponent } from '../address-selector/address-selector.component';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
	selector: 'fulfilment-pickup-store-selection',
	templateUrl: './pickup-store-selection.component.html',
	styleUrls: ['./pickup-store-selection.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, WappleLoadingComponent, DropdownComponent, AddressSelectorComponent]
})
export class PickupStoreSelectionComponent implements OnInit {
	areas: StoreArea[];
	selectedAreaId = -1;
	selectedArea: StoreArea;
	filteredStores: SelectableAddress[];
	recentlyUsedStores: SelectableAddress[];
	isLoading = true;
	showRecentlyUsedStores = true;
	selectedStoreName = '';

	@Output() storeSelected = new EventEmitter();

	constructor(
		private addressService: AddressService,
		private fulfilmentService: FulfilmentService,
		private fulfilmentStoreService: FulfilmentStoreService,
		private cdr: ChangeDetectorRef
	) { }

	ngOnInit(): void {
		this.addressService
			.getPickupAddresses()
			.pipe(untilDestroyed(this))
			.subscribe((r: StoreAddressesResponse) => {
				this.isLoading = false;
				if (r) {
					this.areas = r.storeAreas || [];
					this.recentlyUsedStores = r.recentlyPickedUpStoreAddresses?.map(this.mapAddress) || [];
					this.updateSelectedStoreInAllArrays();
					this.cdr.markForCheck();
				}
			});

		this.fulfilmentStoreService.state$.pipe(untilDestroyed(this)).subscribe((r: FulfilmentState) => {
			if (r) {
				this.selectedStoreName = r.address || '';
				this.updateSelectedStoreInAllArrays();
				this.cdr.markForCheck();
			}
		});
	}

	get areasForDropdown(): ListItem[] {
		return this.areas.map((s: StoreArea) => ({
			value: s.id,
			text: s.name,
		}));
	}

	handleAreaSelect(event: any): void {
		const area = this.areas.find((a) => a.id?.toString() === event);
		if (area) {
			this.selectedAreaId = event;
			this.selectedArea = area;
			this.filteredStores = area.storeAddresses?.map(this.mapAddress) || [];
			this.updateSelectedStoreInAllArrays();
			this.showRecentlyUsedStores = false;
		}
	}

	mapAddress(address: StoreAddress): SelectableAddress {
		return {
			addressId: address.id?.toString() || '',
			subtitle: convertSentenceToBreakOnCommas(address.address || ''),
			title: address.name || '',
		};
	}

	changeAddress(addressId: string): void {
		if (!addressId) {
			return;
		}

		// It's passed as a string so we parse it back to a number here
		const addressIdInt = Number.parseInt(addressId, 10);
		if (isNaN(addressIdInt)) {
			return;
		}

		this.fulfilmentService
			.changeSelectedPickupStore(addressIdInt)
			.pipe(first())
			.subscribe(() => {
				this.handleSuccess();
			});
	}

	handleSuccess = (): void => {
		this.storeSelected.emit();
	};

	updateSelectedStoreInAllArrays(): void {
		this.updateSelectedStoreInArray(this.recentlyUsedStores);
		this.updateSelectedStoreInArray(this.filteredStores);
		// Add the others here as they are implemented
	}

	updateSelectedStoreInArray(inputArray: SelectableAddress[]): void {
		const selected = inputArray?.findIndex((store) => store.title === this.selectedStoreName);
		if (selected > -1) {
			inputArray[selected].isSelected = true;
			moveFromIndexToStartOfArray(inputArray, selected);
		}
	}
}
