import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { CustomWindow, WINDOW } from './window.service';
import { NavigationStart, Router } from '@angular/router';
import { take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DynatraceService {
	actionId: number;
	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(WINDOW) private window: CustomWindow,
		private router: Router
	) {
		this.init();
	}

	init(): void {
		if (isPlatformServer(this.platformId)) {
			return;
		}
		const actionListener = (actionId: number, starttime: number, isRootAction: boolean): void => {
			if (isRootAction) {
				this.actionId = actionId;
			}
		};
		this.router?.events?.pipe(take(1)).subscribe((routerEvent) => {
			if (!this.window?.dtrum) {
				return;
			}

			if (routerEvent instanceof NavigationStart) {
				this.window.dtrum.addEnterActionListener(actionListener);
				this.window.dtrum.removeEnterActionListener(actionListener);
			}
		});
	}

	addProperties(properties?: Record<string, any>): void {
		this.window.dtrum?.addActionProperties(this.actionId, null, null, properties);
	}

	reportError(error: Error): void {
		if (!this.window?.dtrum) {
			return;
		}
		this.window.dtrum.reportCustomError(`Trader-${error.name}`, error.toString());
	}

	reportBizEvent(name: string, data: {}): void {
		if (!this.window?.dtrum) {
			return;
		}
		this.window.dynatrace.sendBizEvent(name, data);
	}

	triggerAction(actionName: string, properties?: Record<string, any>): void {
		if (!this.window?.dtrum) {
			return;
		}
		const actionId = this.window.dtrum.enterAction(actionName);

		if (!!properties) {
			this.window.dtrum?.addActionProperties(actionId, null, null, properties);
		}

		this.window.dtrum.leaveAction(actionId);
	}
}
