import { EnvService } from './env.service';
import { Provider } from '@angular/core';

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		__env: any;
	}
}
window.__env = window.__env || {};

export const EnvServiceFactory = () => {
	const envService = new EnvService();

	const windowEnv = window?.__env || {};

	if (windowEnv.traderApiUrl) envService.traderApiUrl = windowEnv.traderApiUrl;
	if (windowEnv.baseUrl) envService.baseUrl = windowEnv.baseUrl;
	if (windowEnv.bffApiUrl) envService.bffApiUrl = windowEnv.bffApiUrl;
	if (windowEnv.countdownBaseUrl) envService.countdownBaseUrl = windowEnv.countdownBaseUrl;
	if (windowEnv.edrBaseUrl) envService.edrBaseUrl = windowEnv.edrBaseUrl;
	if (windowEnv.featureServiceApiUrl) envService.featureServiceApiUrl = windowEnv.featureServiceApiUrl;
	if (windowEnv.storeServiceUrl) envService.storeServiceUrl = windowEnv.storeServiceUrl;
	if (windowEnv.ssoServiceUrl) envService.ssoServiceUrl = windowEnv.ssoServiceUrl;
	if (windowEnv.idpHint) envService.idpHint = windowEnv.idpHint;
	return envService;
};

export const EnvServiceProvider: Provider = {
	provide: EnvService,
	useFactory: EnvServiceFactory,
	deps: [],
};
