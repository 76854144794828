import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Directive, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { CustomWindow, WINDOW } from '../services';

/**
 * Apply on an overlay to block scrolling page body
 * will scroll block on-init, then remove the block behaviour when destroyed
 */
@Directive({
	selector: '[cdxScrollBlock]',
	standalone: true
})
export class ScrollBlockDirective implements OnInit, OnDestroy {
	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(DOCUMENT) private document: Document,
		@Inject(WINDOW) private window: CustomWindow,
		private renderer: Renderer2
	) { }

	ngOnInit(): void {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		this.renderer.setStyle(this.document.body, 'top', `-${this.window.scrollY}px`);
		this.renderer.setStyle(this.document.body, 'position', 'fixed');
		this.renderer.setStyle(this.document.body, 'width', '100vw');
		this.renderer.setStyle(this.document.documentElement, 'scroll-behavior', 'auto');
	}

	ngOnDestroy(): void {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		// restore positioning
		this.renderer.removeStyle(this.document.body, 'position');
		this.renderer.removeStyle(this.document.body, 'width');

		// restore scroll position
		const scrollY = parseInt(this.document.body.style.top, 10) || 0;
		this.window.scroll(0, scrollY * -1);

		this.renderer.removeStyle(this.document.body, 'top');
		this.renderer.removeStyle(this.document.documentElement, 'scroll-behavior');
	}
}
