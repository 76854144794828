import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	QueryList,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	ApiService,
	AlertComponent,
	ScrollIntoViewDirective,
	GlobalErrorHandlerService,
} from '@woolworthsnz/styleguide';
import { ValidationError, ValidationErrorModel } from '@woolworthsnz/trader-api';
import { Observable } from 'rxjs';
import { FormService } from '../../services';
import { InputComponent } from '../input/input.component';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
	exportAs: 'cdxForm',
	selector: 'form-form, [cdxForm]',
	template: `
		<cdx-alert
			[type]="error?.type"
			[description]="error?.errors"
			[title]="error?.message"
			*ngIf="error && errorStyle === 'default'"
			scrollIntoView
		>
		</cdx-alert>
		<ng-content></ng-content>
	`,
	styleUrls: ['./form.component.scss'],
	providers: [ApiService, FormService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, AlertComponent, ScrollIntoViewDirective],
})
export class FormComponent implements OnInit {
	@ContentChildren(InputComponent) inputs: QueryList<InputComponent>;

	// TODO: Add some form of style for discreet. Currently it just doesnt show the alert
	@Input() errorStyle: 'default' | 'discreet' = 'default';
	@Input() formGroup: FormGroup;
	@Input() formAction: string;
	@Output() formSubmitted: EventEmitter<any> = new EventEmitter();
	@Output() formReset: EventEmitter<any> = new EventEmitter();
	@Output() submissionSuccess: EventEmitter<any> = new EventEmitter();

	canSubmit = false;
	error?: {
		type?: string;
		message: string;
		errors?: Array<ValidationError>;
	};

	submitted$: Observable<boolean>;
	errored$: Observable<any>;
	completed$: Observable<boolean>;

	constructor(
		public formService: FormService,
		public globalErrorService: GlobalErrorHandlerService,
		private ref: ChangeDetectorRef
	) {}

	@Input() transformer: (any: any) => any = (value) => value;

	@HostListener('submit')
	onSubmit(): void {
		if (this.formGroup.valid) {
			this.formSubmitted.emit();

			if (this.formAction) {
				this.formService.submitForm(this.formAction, this.transformer(this.formGroup.getRawValue()));
			}
		} else {
			this.validateAllFormFields(this.formGroup);
		}
	}

	@HostListener('reset')
	onReset(): void {
		this.formReset.emit();
	}

	ngOnInit(): void {
		this.errored$ = this.formService.errored$;
		this.submitted$ = this.formService.submitted$;

		this.errored$.pipe(untilDestroyed(this)).subscribe(this.onError);

		if (this.formGroup) {
			this.formGroup.statusChanges.pipe(untilDestroyed(this)).subscribe(this.onFormStatusChanges);
		}

		this.formService.result$.pipe(untilDestroyed(this)).subscribe(this.onFormResult);
	}

	// Ensure this stays as an arrow function so
	// that the context of `this` isn't lost
	// https://stackoverflow.com/questions/49868488/angular-httpclient-error-handling-requiring-zone-run-to-update-ui
	onError = (err: { error: ValidationErrorModel }): void => {
		if (!err) {
			this.error = undefined;
		} else {
			const { error } = err;
			this.error = {
				type: 'error',
				message: error?.message || '',
				errors: error?.errors,
			};
		}

		this.ref.markForCheck();
	};

	onFormResult = (result: any): void => {
		this.submissionSuccess.emit(result);
	};

	onFormStatusChanges = (status: any): void => {
		this.canSubmit = status === 'VALID';

		if (!(<any>this.ref)['destroyed']) {
			this.ref.markForCheck();
		}
	};

	validateAllFormFields(formGroup: FormGroup): void {
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field);

			if (control instanceof FormControl) {
				control.markAsTouched();
				control.markAsDirty();
				(<EventEmitter<any>>control.statusChanges).emit(control.status);
				(<EventEmitter<any>>control.valueChanges).emit(control.value);
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}
}
