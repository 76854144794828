<ng-content select="[badge]"></ng-content>
<input
	[id]="inputId"
	[name]="controlName"
	type="radio"
	#input
	(click)="onInputClick($event)"
	(change)="onInteractionEvent($event)"
	[checked]="checked"
	[disabled]="disabled"
/>
<label [for]="inputId" class="label">
	<div class="label-title">
		<cdx-svg-icon class="label-titleCheckbox" [name]="checkboxIcon" [fill]="checkboxFill"></cdx-svg-icon>
		<cdx-svg-icon class="label-titleIcon" [name]="iconName" [fill]="iconFill"></cdx-svg-icon>
		<h3 class="label-titleContent">
			{{ title }}
		</h3>
	</div>
	<div class="label-content" [ngClass]="{ 'label-content--leftAlign': align === 'left' }" *ngIf="subtitle || description">
		<h4 *ngIf="subtitle">{{ subtitle }}</h4>
		<p *ngIf="description">{{ description }}</p>
		<ng-content></ng-content>
	</div>
</label>
