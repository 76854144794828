<button
	cdxButton
	tabindex="0"
	fillStyle="secondaryOutline"
	endSlotSize="extra-small"
	[endSlotActive]="true"
	(click)="showDropdown()"
	(keydown)="onKeyDown($event)"
	[attr.aria-haspopup]="'listbox'"
	#dropdownButton
	[attr.aria-label]="'filter groups' + label"
	class="selectButton"
	attr.data-cy="selectButton"
>
	{{ label }}
	<cdx-svg-icon name="chevron-bottom" size="extra-small" fill="dark" end></cdx-svg-icon>
</button>

<ng-template cdkPortal cdkTrapFocus #content="cdkPortal">
	<section class="dropdown-container" role="listbox" #list tabindex="0" (keydown)="onKeyDown($event)">
		<ng-content select="form-select-option"></ng-content>
	</section>
</ng-template>
