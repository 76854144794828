import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { OptionListBaseComponent } from '../option-list-base/option-list-base.component';
import { OptionListItemComponent } from '../option-list-item/option-list-item.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
	selector: 'cdx-option-list',
	template: `
		<ul *ngIf="listItems" role="listbox">
			<cdx-option-list-item
				*ngFor="let item of listItems; let index = index"
				[itemLabel]="item[displayKey]"
				class="list-group-item"
				(click)="onClick(index)"
				[id]="getItemId(index)"
			>
				{{ item[displayKey] }}
			</cdx-option-list-item>
			<cdx-option-list-item class="list-group-item" [noPadding]="true">
				<ng-content></ng-content>
			</cdx-option-list-item>
		</ul>
	`,
	styleUrls: ['./option-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, NgFor, OptionListItemComponent],
})
export class OptionListComponent extends OptionListBaseComponent {
	@ViewChildren(OptionListItemComponent) items: QueryList<OptionListItemComponent>;
	@Input() listItems: any[];

	@Input() displayKey: string;

	@Output() optionSelect: EventEmitter<any> = new EventEmitter();

	getItem(index: number): any {
		return this.listItems[index];
	}
}
