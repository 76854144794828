<ul class="addressList" [ngClass]="{ 'addressList--withMaxHeight': withScroll }">
    <li class="addressList-item" *ngFor="let shopperAddress of shopperAddresses">
        <button
                cdxButton
                align="left"
                size="large"
                fillStyle="flat"
                class="addressList-button"
                [fullWidth]="true"
                [endSlotActive]="true"
                [disabled]="shopperAddress.isSelected"
                (click)="changeAddress(shopperAddress)"
                [id]="'address-selection-button--' + shopperAddress.addressId"
        >
            <strong class="addressList-title">{{ shopperAddress.title }}</strong>
            <span [innerHTML]="shopperAddress.subtitle" class="addressList-subtitle"></span>
            <span *ngIf="shopperAddress.openingHours"><strong>open</strong> {{ shopperAddress.openingHours }}</span>
            <cdx-svg-icon
                    name="tick"
                    fill="dark"
                    size="small"
                    *ngIf="shopperAddress.isSelected"
                    end></cdx-svg-icon>
        </button>
    </li>
    <li *ngIf="bottomSlotActive" class="addressList-bottomSlot">
        <ng-content select="[bottom]"></ng-content>
    </li>
</ul>
