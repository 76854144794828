import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import {
	AnalyticsCollectionType,
	TealiumUtagService,
	getAnalyticsData,
	productGridConstants,
} from '@woolworthsnz/analytics';
import { promoViewAnalytics } from '../constants';
import { AnalyticsClass, ANALYTICS_PROVIDER } from '../injection-tokens/analytics.token';
import { DatalayerService, FeatureService, ImpressionIntersectionService, LoggingService } from '../services';
import {
	ImpressionTrackingBehaviourType,
	ProductImpressionAndViewItemListArgs,
	ProductImpressionArgs,
	PromoImpressionArgs,
	PromoTileImpressionArgs,
	RecipeImpressionArgs,
	PromoCPPImpressionArgs,
	TealiumEventEnum,
	PromoBannerImpressionArgs,
} from '../ui-models';
import { ContextResponse } from '@woolworthsnz/trader-api';

type ImpressionArgs =
	| PromoImpressionArgs
	| ProductImpressionArgs
	| RecipeImpressionArgs
	| PromoTileImpressionArgs
	| ProductImpressionAndViewItemListArgs
	| PromoCPPImpressionArgs
	| PromoBannerImpressionArgs;

export class ImpressionArgsFactory {
	static createCarouselPromoImpressionArgs(
		item: any,
		carouselName: string | null | undefined,
		position: number
	): PromoImpressionArgs {
		return ImpressionArgsFactory.createPromoImpressionArgs(item, 'Carousel', carouselName, position);
	}

	static createPromoImpressionArgs(
		item: any,
		creative: string,
		carouselName: string | null | undefined,
		position: number
	): PromoImpressionArgs {
		return {
			id: item.id,
			name: item.name || item.text,
			creative,
			imagePath: item.serializedProperties?.image || item.image || item.imagePath,
			link: item.serializedProperties?.link || item.link,
			carouselName,
			position,
		};
	}

	// Domain specific helpers shouldn't live here.
	// If you're looking for a helper to generate ImpressionArgs for recipes you can import { createRecipeImpressionArgs } from '@woolworthsnz/recipe'
}

/**
 * DOCUMENTATION FOR PERFORMANCE COMPARING AN INTERSECTION OBSERVER INSTANCE PER ELEMENT OR USING A SHARED ONE ACROSS MULTIPLE ELEMENTS
 * https://www.bennadel.com/blog/3954-intersectionobserver-api-performance-many-vs-shared-in-angular-11-0-5.htm
 */
/**
 * @description Apply this to element which needs to track impressions in the datalayer based on the element's visibility.
 * Each time the element is more than 50% visible a new promotion impression should be sent.
 *
 * The tracking data needs to be supplied to the directive, e.g.:
 * Promotion Impression:
 * <div [cdxTrackImpression]="{id: '123', name: 'In Season', creative: 'Carousel', position: 1}"></a>
 *
 * Product Impressions:
 * <div [cdxTrackImpression]="{products: this.products, carouselName: 'Blue Bird Carousel'}"></a>
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[cdxTrackImpression]',
	standalone: true,
})
export class TrackImpressionDirective implements OnInit, OnDestroy {
	@Input() set cdxTrackImpression(val: ImpressionArgs) {
		this.args = val;
	}

	/**
	 * Use a shared instance of the intersection observer.
	 */
	@Input() useSharedIntersectionObserver = true;
	/**
	 * Prevent sending a default impression if the IntersectionObserver API is not supported
	 */
	@Input() preventFallbackImpression = false;

	/**
	 * Tracking behaviour controls how often impressions are tracked
	 *  - TrackOnFirstIntersectionOnly - An impression is only tracked ONCE when an element becomes visible. (DEFAULT)
	 *  - TrackOnEachIntersection - An impression will be tracked EACH TIME an element becomes visible, e.g. when a user scrolls element up & down in / out of view.
	 *  - Disabled - No impression will be tracked for this element.
	 */
	@Input() trackingBehaviour: ImpressionTrackingBehaviourType =
		ImpressionTrackingBehaviourType.TrackOnFirstIntersectionOnly;

	@Input() department = '';
	@Input() aisle = '';
	@Input() shelf = '';
	private args?: ImpressionArgs;
	private observer?: IntersectionObserver;
	private isVisible = false;
	enableCartologyGA4EventTracking = false;

	constructor(
		private el: ElementRef,
		private dataLayerService: DatalayerService,
		private impressionIntersectionService: ImpressionIntersectionService,
		private loggingService: LoggingService,
		private tealiumUtagService: TealiumUtagService,
		private featureService: FeatureService,
		@Optional() @Inject(ANALYTICS_PROVIDER) private tealiumService: AnalyticsClass
	) {}

	static isPromoImpression(args: ImpressionArgs): args is PromoImpressionArgs {
		return 'creative' in args;
	}

	static isProductImpression(args: ImpressionArgs): args is ProductImpressionArgs {
		return 'product' in args;
	}

	static isRecipeImpression(args: ImpressionArgs): args is RecipeImpressionArgs {
		return 'recipe_name' in args;
	}

	static isPromoTileImpression(args: ImpressionArgs): args is PromoTileImpressionArgs {
		return 'promotionName' in args;
	}

	static isViewItemList(args: ImpressionArgs): args is ProductImpressionAndViewItemListArgs {
		return 'view-item-list' in args;
	}

	static isCPPImpression(args: ImpressionArgs): args is PromoCPPImpressionArgs {
		return 'cpp_product' in args;
	}

	static isBannerImpression(args: ImpressionArgs): args is PromoBannerImpressionArgs {
		return 'bannerPromotionName' in args;
	}

	ngOnInit(): void {
		if (this.trackingBehaviour === ImpressionTrackingBehaviourType.Disabled) {
			return;
		}

		if (this.impressionIntersectionService.canIntersect()) {
			// Wait for 500 milliseconds to page load to settle before we initialize the intersection observer
			setTimeout(() => {
				this.initObserver();
			}, 500);
		} else {
			// Requested not to send default promo impression if the IntersectionObserver API is not supported
			if (this.preventFallbackImpression) {
				return;
			}
			// Track promo impression regardless on load
			this.trackImpression();
		}

		this.enableCartologyGA4EventTracking = this.featureService.isFeatureEnabled(
			ContextResponse.EnabledFeaturesEnum.EnableCartologyGA4EventTracking
		);
	}

	ngOnDestroy(): void {
		this.disconnect();
	}

	initObserver(): void {
		if (!this.canIntersect()) {
			this.loggingService.trackEvent('UI:TrackImpression:initObserver', {
				error: 'The intersection observer API is not available',
			});
			return;
		}
		if (this.args) {
			if (this.useSharedIntersectionObserver) {
				this.addToSharedObserver();
			} else {
				this.initInternalObserver();
			}
		}
	}

	intersectionCallback = (entries: IntersectionObserverEntry[]): void => {
		if (entries) {
			entries.forEach((entry: IntersectionObserverEntry) => {
				if (entry.isIntersecting) {
					if (this.trackingBehaviour === ImpressionTrackingBehaviourType.Disabled) {
						return this.disconnect();
					}

					if (
						!this.isVisible &&
						entry.intersectionRatio >= this.impressionIntersectionService.defaultVisibleThreshold
					) {
						this.isVisible = true;
						this.trackImpression();

						// If we're only tracking an impression once, then we can disconnect the element from the observer
						if (this.trackingBehaviour === ImpressionTrackingBehaviourType.TrackOnFirstIntersectionOnly) {
							return this.disconnect();
						}
					}

					if (entry.intersectionRatio < this.impressionIntersectionService.defaultVisibleThreshold) {
						// Reset visibility.  We want to send a new impression once it was visible, but has now become invisible
						this.isVisible = false;
					}
				} else if (this.isVisible) {
					// Reset visibility.  We want to send a new impression once it was visible, but has now become invisible
					this.isVisible = false;
				}
			});
		}
	};

	private canIntersect(): boolean {
		return this.impressionIntersectionService.canIntersect();
	}

	private disconnect(): void {
		if (this.canIntersect()) {
			if (this.useSharedIntersectionObserver) {
				this.impressionIntersectionService.remove(this.el.nativeElement);
			} else {
				this.disconnectInternalObserver();
			}
		}
	}

	private disconnectInternalObserver(): void {
		if (this.observer) {
			this.observer.disconnect();
			this.observer = undefined;
		}
	}

	private addToSharedObserver(): void {
		this.impressionIntersectionService.add(this.el.nativeElement, this.intersectionCallback);
	}

	private initInternalObserver(): void {
		// Disconnect any existing intersection observers
		if (this.observer) {
			this.disconnectInternalObserver();
		}

		// We want to be notified when it's invisible and when it's at least 50% visible
		this.observer = new IntersectionObserver(this.intersectionCallback, {
			threshold: [
				this.impressionIntersectionService.defaultHiddenThreshold,
				this.impressionIntersectionService.defaultVisibleThreshold,
			],
		});
		this.observer.observe(this.el.nativeElement);
	}

	/**
	 * This is the MAIN method which will track impressions
	 * @private
	 */
	private trackImpression(): void {
		if (this.args) {
			if (TrackImpressionDirective.isPromoImpression(this.args)) {
				this.trackPromoImpression();
			} else if (TrackImpressionDirective.isBannerImpression(this.args)) {
				this.trackBannerImpression();
			} else if (TrackImpressionDirective.isRecipeImpression(this.args)) {
				this.trackRecipeImpression();
			} else if (TrackImpressionDirective.isPromoTileImpression(this.args)) {
				this.trackPromoTileImpression();
			} else {
				this.trackProductImpressions();
				this.trackViewItemList();
				this.trackViewPromotionCPP();
			}
		}
	}

	private trackPromoTileImpression(): void {
		const promotionTileImpressionData = <PromoTileImpressionArgs>this.args;
		if (promotionTileImpressionData) {

			this.tealiumUtagService.link(
				{
					event: 'view_promotion',
					tealium_event: 'view_promotion',
					ecommerce: {
						creative_name: promotionTileImpressionData.creativeName,
						creative_slot: promotionTileImpressionData.creativeSlot,
						promotion_id: promotionTileImpressionData.promotionId,
						promotion_name: promotionTileImpressionData.promotionName,
					},
				},
				true
			);
		}
	}

	private trackBannerImpression(): void {
		const promoBannerImpressionData = <PromoBannerImpressionArgs>this.args;
		if (promoBannerImpressionData) {
			this.dataLayerService.trackPromoImpression({
				id: promoBannerImpressionData.bannerPromotionId,
				name: promoBannerImpressionData.bannerPromotionName,
				creative: promoBannerImpressionData.creativeName360,
				imagePath: promoBannerImpressionData.imagePath,
				link: promoBannerImpressionData.link,
				carouselName: promoBannerImpressionData.carouselName,
				position: promoBannerImpressionData.creativeSlot ?? '',
			});

			this.tealiumUtagService.link({
				tealium_event: 'view_promotion',
				ecommerce: {
					creative_name: promoBannerImpressionData.creativeName ?? '',
					creative_slot: promoBannerImpressionData.creativeSlot ?? '',
					promotion_id: promoBannerImpressionData.bannerPromotionId,
					promotion_name: promoBannerImpressionData.bannerPromotionName,
				},
			});
		}
	}

	private trackPromoImpression(): void {
		const promoImpressionData = <PromoImpressionArgs>this.args;
		if (promoImpressionData) {
			this.dataLayerService.trackPromoImpression(promoImpressionData);

			this.tealiumService.link({
				...promoViewAnalytics,
				media_label: [`${promoImpressionData.imagePath}`],
				media_category: [`${promoImpressionData.name}`],
				media_type: `${promoImpressionData.creative}`,
				media_carousel_slot: [`${promoImpressionData.position}`],
				media_destination_link: [`${promoImpressionData.link}`],
			});
		}
	}

	private trackRecipeImpression(): void {
		this.tealiumService.collect(AnalyticsCollectionType.RecipeStampImpression, this.args);
	}

	private trackProductImpressions(): void {
		if (
			this.args &&
			(<ProductImpressionAndViewItemListArgs>this.args).productImpressionList !== undefined &&
			(<ProductImpressionAndViewItemListArgs>this.args).productImpressionList.product !== undefined
		) {
			const productArgs = (<ProductImpressionAndViewItemListArgs>this.args).productImpressionList;
			const product = productArgs.product;

			// Promotion products will not be send in the productImpression event
			if (productArgs.product.type === 'PromotionalCarousel') {
				return;
			}

			this.dataLayerService.addProductImpressionsToBatch([product], productArgs.carouselName);
			this.tealiumService.collect(AnalyticsCollectionType.ProductImpression, {
				...productGridConstants,
				...getAnalyticsData([product], this.department, this.aisle, this.shelf),
			});

			if (this.enableCartologyGA4EventTracking && productArgs.carouselName === 'Online Sample Remarketing') {
				const viewPromotionTealiumEvent = this.dataLayerService.mapProductsToRemarketingTealiumEvent([product]);
				this.tealiumUtagService.link(viewPromotionTealiumEvent, true);
			}
		} else if (
			this.args &&
			<ProductImpressionArgs>this.args !== undefined &&
			(<ProductImpressionArgs>this.args).product !== undefined
		) {
			const productArgs = <ProductImpressionArgs>this.args;
			const product = productArgs.product;

			// Promotion products will not be send in the productImpression event
			if (productArgs.product.type === 'PromotionalCarousel') {
				return;
			}

			this.dataLayerService.addProductImpressionsToBatch([product], productArgs.carouselName);
			this.tealiumService.collect(AnalyticsCollectionType.ProductImpression, {
				...productGridConstants,
				...getAnalyticsData([product], this.department, this.aisle, this.shelf),
			});

			if (this.enableCartologyGA4EventTracking && productArgs.carouselName === 'Online Sample Remarketing') {
				const viewPromotionTealiumEvent = this.dataLayerService.mapProductsToRemarketingTealiumEvent([product]);
				this.tealiumUtagService.view(viewPromotionTealiumEvent);
			}
		}
	}

	private trackViewPromotionCPP(): void {
		if (this.args && (<ProductImpressionAndViewItemListArgs>this.args).viewPromotionCPP !== undefined) {
			const itemListArgs = (<ProductImpressionAndViewItemListArgs>this.args).viewPromotionCPP;
			if (itemListArgs) {
				const itemsDetail = itemListArgs.items.map((item) => ({
					item_id: item.itemId,
					item_name: item.itemName,
					index: item.index,
					item_brand: item.itemBrand,
					item_category: item.itemCategory,
					item_category2: item.itemCategory2,
					item_category3: item.itemCategory3,
					item_list_id: item.itemListId,
					item_list_name: item.itemListName,
					item_variant: item.itemVariant,
				}));
				if (itemsDetail) {
					this.tealiumUtagService.link(
						{
							event: TealiumEventEnum.ViewPromotion,
							tealium_event: 'view_promotion',
							ecommerce: {
								creative_name: itemListArgs.creativeName,
								creative_slot: itemListArgs.creativeSlot,
								promotion_id: itemListArgs.promotionId,
								promotion_name: itemListArgs.promotionName,
								items: [...itemsDetail],
							},
						},
						true
					);
				}
			}
		}
	}

	private trackViewItemList(): void {
		if (
			this.args &&
			(<ProductImpressionAndViewItemListArgs>this.args).viewItemList !== undefined &&
			(<ProductImpressionAndViewItemListArgs>this.args).viewItemList.items !== undefined
		) {
			const itemListArgs = (<ProductImpressionAndViewItemListArgs>this.args).viewItemList;
			if (itemListArgs) {
				this.tealiumUtagService.addViewItemsToBatch(itemListArgs.items, itemListArgs.carouselName);
				const itemsDetail = itemListArgs.items.map((item) => ({
					item_id: item.itemId.toString(),
					item_name: item.itemName,
					index: item.index,
					promotion_product_tags: item.promotionProductTags,
					discount: item.discount,
					item_brand: item.itemBrand,
					item_category: item.itemCategory,
					item_category_2: this.aisle,
					item_category_3: this.shelf,
					item_list_id: item.itemListId,
					item_list_name: item.itemListName,
					item_variant: item.itemVariant,
					price: item.price,
					availability_status: item.availabilityStatus,
					has_shopper_notes: item.hasShopperNotes,
					filter_types: item.filterTypes,
					each_kg: item.eachKg,
				}));

				this.tealiumUtagService.collectViewItems(AnalyticsCollectionType.ViewItemList, {
					event: 'view_item_list',
					tealium_event: 'view_item_list',
					item_list_id: itemListArgs.pageItemListId,
					item_list_name: itemListArgs.pageItemListName,
					...itemsDetail,
				});
			}
		}
	}
}
