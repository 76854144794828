import { trigger, transition, style, group, animate } from '@angular/animations';
import { THEME_CONSTANTS } from '@woolworthsnz/styleguide';

export const height = trigger('height', [
	transition(':enter', [
		style({ height: '0px', overflow: 'hidden' }),
		group([
			animate(
				`${THEME_CONSTANTS.transitions.short} ${THEME_CONSTANTS.easings['in-quart']}`,
				style({
					height: '*',
				})
			),
		]),
	]),
	transition(':leave', [
		style({
			height: '*',
			overflow: 'hidden',
		}),
		group([
			animate(
				`${THEME_CONSTANTS.transitions.short} ${THEME_CONSTANTS.easings['in-quart']}`,
				style({
					height: '0px',
				})
			),
		]),
	]),
]);
