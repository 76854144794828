import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackingData } from '../../../../';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';
import { TrackEventDirective } from '../../../directives/track-event.directive';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'cdx-back-nav',
	template: `
		<a class="backNav" [routerLink]="location" [cdxTrackEvent]="trackingData">
			<cdx-svg-icon size="small" name="arrow-left" fill="currentColor"></cdx-svg-icon>
			<span class="backNav-text"><ng-content></ng-content></span>
		</a>
	`,
	styleUrls: ['./back-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterLink, TrackEventDirective, SvgIconComponent]
})
export class BackNavComponent {
	@Input()
	location: string;
	@Input() trackingData: TrackingData;
}
