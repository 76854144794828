<ng-container [formGroup]="parentFormGroup">
  <form-input-with-icon-button
    formControlName="password"
    maxlength="255"
    input
    [inputType]="type"
  >
    <button
      btn
      id="showPassword"
      fillStyle="flat"
      type="button"
      [ngClass]="{ hidePassword: type === 'text' }"
      name="buttonLabel"
      [attr.aria-label]="buttonLabel"
      cdxButton
      (click)="toggleInputType($event)"
      (blur)="onButtonChange($event)"
      (change)="onButtonChange($event)"
    >
      &nbsp;
    </button>
    <label i18n="@@{{ page }}-passwordLabel" for="password" label>
      {{ passwordLabel }}
    </label>
    <p
      *ngIf="showPasswordHelpText"
      cdxFormHelpText
      i18n="@@{{ page }}-passwordHelpText"
    >
      Must contain at least 8 characters and contain at least 2 of either
      uppercase, lowercase, numeric or special characters.
    </p>
    <form-input-error validation control="password">
      <p [cdxInvalidType]="'*'" i18n="@@{{ page }}-invalidPasswordMessage">
       {{validationMessage}}
      </p>
    </form-input-error>
  </form-input-with-icon-button>
</ng-container>
