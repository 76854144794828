import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, delay, filter, withLatestFrom } from 'rxjs/operators';
import { ButtonState } from '../ui-models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Sets `subject$` behaviour subject's value to the supplied value whenever `trigger$` emits and the given predicate is truthy
 * for `subject$`'s value. Optionally debounces all emissions.
 *
 * example using marble syntax with an initial `subject$` `a` and reset state of `a`:
 * ```
 * calls to `subject$.next()`:  ---b-c------
 * `trigger$` emissions:        ---------1--
 * `subject$` value:            a--b-c---a--
 * ```
 */
export function resetBehaviourSubjectOnTrigger<T>(
	subject$: BehaviorSubject<T>,
	trigger$: Observable<any>,
	instance: any,
	resetToState: T,
	predicate: (value: T) => boolean,
	debounce?: number
): void {
	trigger$
		.pipe(
			takeUntilDestroyed(instance),
			withLatestFrom(subject$),
			debounceTime(debounce ?? 0),
			filter(([, subjectState]) => predicate(subjectState))
		)
		.subscribe(() => {
			subject$.next(resetToState);
		});
}

export interface ButtonResetOptions {
	resetToState?: ButtonState;
	debounce?: number;
}

/**
 * Resets the given `ButtonState` observable to default when the supplied `trigger$` emits and
 * the current button state is `ButtonState.current`.
 *
 * @param buttonState$ Holds the button state, usually bound to a button's `state`.
 * @param trigger$ Observable that emits when the state should be checked and potentially reset
 * @param instance Pass `this` from the component, decorated with `@UntilDestroy()`
 * @param options Optionally override the reset state, condition for resetting and set a debounce time
 */
export function resetButtonStateOnTrigger(
	buttonState$: BehaviorSubject<ButtonState>,
	trigger$: Observable<any>,
	instance: any,
	options?: ButtonResetOptions
): void {
	const resetToState = options?.resetToState ?? ButtonState.default;
	const debounce = options?.debounce ?? 0;
	resetBehaviourSubjectOnTrigger(
		buttonState$,
		trigger$,
		instance,
		resetToState,
		(buttonState: ButtonState) => buttonState === ButtonState.completed,
		debounce
	);
}

export interface ButtonDelayedResetOptions {
	delayDuration?: number;
	resetToState?: ButtonState;
	predicate?: (value: ButtonState) => boolean;
}

/**
 * Resets the given `ButtonState` observable to default after the button emits `ButtonState.default` and a
 * set duration has passed.
 *
 * @param buttonState$ Holds the button state, usually bound to a button's `state`.
 * @param instance Pass `this` from the component, decorated with `@UntilDestroy()`
 * @param options Optionally override the delay (default 3000ms), the reset state and the condition for resetting.
 */
export function resetButtonStateAfterDelay(
	buttonState$: BehaviorSubject<ButtonState>,
	instance: any,
	options?: ButtonDelayedResetOptions
): void {
	const delayDuration = options?.delayDuration ?? 3000;
	const resetToState = options?.resetToState ?? ButtonState.default;
	const predicate = options?.predicate ?? ((buttonState: ButtonState) => buttonState === ButtonState.completed);
	buttonState$.pipe(takeUntilDestroyed(instance), filter(predicate), delay(delayDuration)).subscribe(() => {
		buttonState$.next(resetToState);
	});
}
