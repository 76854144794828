import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressCaptureComponent, FormComponent as FormComponent_1, FormComponent, InputSize } from '@woolworthsnz/form';
import { ButtonState, ShopperService } from '@woolworthsnz/styleguide';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { tap } from 'rxjs/operators';
import { FulfilmentService, ManageAddressService } from '../../services';
import { customerDetailsUpdated } from '../../constants';

@Component({
	selector: 'fulfilment-add-delivery-address',
	templateUrl: './add-delivery-address.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, FormComponent_1, ReactiveFormsModule, AddressCaptureComponent]
})
export class AddDeliveryAddressComponent implements OnInit {
	@ViewChild('form') form: FormComponent;

	@Output() add = new EventEmitter<void>();
	@Output() formError = new EventEmitter<void>();

	addAddressForm: FormGroup;
	inputSize = InputSize;
	buttonState = ButtonState.default;

	constructor(
		private fb: FormBuilder,
		public manageAddressService: ManageAddressService,
		private fulfilmentService: FulfilmentService,
		private tealiumService: TealiumUtagService,
		private shopperService: ShopperService
	) { }

	ngOnInit(): void {
		this.addAddressForm = this.fb.group({}, { updateOn: 'blur' });
	}

	addAddress(): void {
		if (!this.addAddressForm || !this.addAddressForm.getRawValue()) {
			return;
		}
		this.buttonState = ButtonState.busy;
		const { addressStreet1, addressStreet2, addressProviderId, suburbId, unit } = this.addAddressForm.getRawValue();
		const body = {
			addressProviderId,
			addressStreet1,
			addressStreet2,
			suburbId,
			unit,
		};
		this.manageAddressService
			.addAddress(body)
			.pipe(tap(this.fulfilmentService.getTimeslots))
			.subscribe(
				(addresses) => {
					this.buttonState = ButtonState.default;
					this.add.emit();
					this.manageAddressService.updateShopperAddresses(addresses);
					this.tealiumService.link({
						...customerDetailsUpdated,
						...this.shopperService.getAnalyticsData(),
					});
				},
				() => {
					this.buttonState = ButtonState.errored;
					this.formError.emit();
				}
			);
	}
}
