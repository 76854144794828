<fieldset
	[formGroup]="formGroup"
	cdxFieldset
	[divider]="false"
	legend="Delivery or Pick up?"
	[hideLegend]="true"
	[i18n]="'@@howWhereWhen-deliveryOrPickup'"
>
	<div>
		<div>
			<form-selection-tile
				data-cy="selectionTileDelivery"
				inputId="method-delivery"
				[iconName]="getIconName('delivery-truck', 'Delivery')"
				[formControlName]="controlName"
				inputValue="Delivery"
				i18n="@@selection-methodDelivery"
				[successIcon]="true"
				label="Delivery"
			>
			</form-selection-tile>
		</div>
		<div>
			<form-selection-tile
				data-cy="selectionTilePickup"
				inputId="method-pickup"
				[iconName]="getIconName('pick-up', 'Pickup')"
				[formControlName]="controlName"
				inputValue="Pickup"
				i18n="@@selection-methodPickup"
				[successIcon]="true"
				label="Pick up"
			>
			</form-selection-tile>
		</div>
	</div>
</fieldset>
