import { FocusKeyManager } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { ButtonComponent, ModalOverlayRef, ModalOverlayService, SvgIconComponent } from '@woolworthsnz/styleguide';
import { Parent } from '../select-base.component';
import { SelectOptionComponent } from '../select-option/select-option.component';
import { PortalModule } from '@angular/cdk/portal';

@Component({
	selector: 'form-select',
	templateUrl: 'select.component.html',
	styleUrls: ['./select.component.scss'],
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	providers: [{ provide: Parent, useExisting: forwardRef(() => SelectComponent) }, ModalOverlayService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ButtonComponent, SvgIconComponent, PortalModule],
})
export class SelectComponent implements AfterViewInit {
	@ContentChildren(SelectOptionComponent)
	children: QueryList<SelectOptionComponent>;

	@Input() label: string;

	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onChange: EventEmitter<string[] | string> = new EventEmitter();

	@ViewChild('dropdownButton', { read: ElementRef }) dropdownButton: ElementRef;
	@ViewChild('list', { read: ElementRef }) list: ElementRef;
	@ViewChild('content') contentTemplate: TemplateRef<any>;

	overlayRef?: ModalOverlayRef;
	selectedOption: SelectOptionComponent;

	private _multiple = false;
	private _selected: string[] = [];
	private keyManager: FocusKeyManager<SelectOptionComponent>;

	constructor(private modalOverlayService: ModalOverlayService) {}

	get multiple(): boolean {
		return this._multiple;
	}

	get selected(): string[] {
		return this._selected;
	}

	get options(): SelectOptionComponent[] {
		return this.children.toArray();
	}

	get selectedAll(): boolean {
		return this.selected.length === this.options.length;
	}

	get hasExpanded(): boolean | undefined {
		return this.overlayRef?.hasAttached();
	}

	@Input()
	set selected(value) {
		this._selected = Array.isArray(value) ? value : [].concat(value);
	}

	@Input()
	set multiple(value: boolean) {
		this._multiple = coerceBooleanProperty(value);
	}

	ngAfterViewInit(): void {
		const width = this.dropdownButton.nativeElement.clientWidth;

		this.modalOverlayService.setState({
			element: this.dropdownButton,
			isConnectedElement: true,
			backdropClass: 'overlay--transparent',
			scrollStrategy: 'reposition',
			width,
		});

		this.keyManager = new FocusKeyManager(this.options).withVerticalOrientation().withWrap();
	}

	show(): void {
		this.overlayRef = this.modalOverlayService.open({
			templateRef: this.contentTemplate,
			skipTracking: true,
		});

		this.syncWidth();
	}

	hideAndFocus(): void {
		if (this.overlayRef) {
			this.overlayRef.close();
		}
		setTimeout(() => this.dropdownButton.nativeElement.focus(), 0);
	}

	showDropdown(): void {
		this.show();
		if (!this.options.length) {
			return;
		}
		this.keyManager?.setFirstItemActive();
	}

	selectOption(option: SelectOptionComponent | null): void {
		if (!option) {
			return;
		}

		this.keyManager.setActiveItem(option);
		const optionValue = option?.value;

		if (!optionValue) {
			return;
		}

		if (option.selectAll) {
			this.selected = this.selectedAll ? [] : this.options.map((o) => o.value);
		} else {
			if (this.selected.indexOf(optionValue) === -1) {
				this.selected.push(option.value);
			} else {
				this.selected.splice(this.selected.indexOf(optionValue), 1);
			}
		}

		this.selectedOption = option;
		this.onChange.emit(this.selected);
		this.hideAndFocus();
	}

	onKeyDown(event: KeyboardEvent): void {
		if (['Enter', ' ', 'ArrowDown', 'Down', 'ArrowUp', 'Up'].indexOf(event.key) > -1) {
			if (!this.hasExpanded) {
				this.showDropdown();
				return;
			}
			if (!this.options.length) {
				event.preventDefault();
				return;
			}
		}

		if (event.key === 'Enter' || event.key === ' ') {
			this.selectOption(this.keyManager.activeItem);
		} else if (event.key === 'Escape' || event.key === 'Esc') {
			this.hideAndFocus();
		} else if (['ArrowUp', 'Up', 'ArrowDown', 'Down'].indexOf(event.key) > -1) {
			this.keyManager.onKeydown(event);
		}
	}

	private syncWidth(): void {
		if (!this.overlayRef) {
			return;
		}
		const width = this.dropdownButton.nativeElement.clientWidth;
		this.modalOverlayService.setState({ width });
	}
}
