import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routeNames } from '../app.routes';
import { BaseAccountComponent } from './pages/base-account/base-account.component';
import { CommunicationPreferencesComponent } from './pages/communication-preferences/communication-preferences.component';
import { HomeComponent } from './pages/home/home.component';
import { PersonalDetailsComponent } from './pages/personal-details/personal-details.component';
import { AuthGuard, FeatureFlag } from '../common';
import { ManageEmailPasswordComponent } from './pages/manage-email-password/manage-email-password.component';

const routes: Routes = [
	{
		path: '',
		component: BaseAccountComponent,
		children: [
			{
				path: routeNames.personalDetails,
				component: PersonalDetailsComponent,
				canActivate: [AuthGuard],
			},
			{
				path: routeNames.manageEmailPassword,
				component: ManageEmailPasswordComponent,
				canActivate: [AuthGuard],
			},
			{
				path: routeNames.communicationPreferences,
				component: CommunicationPreferencesComponent,
				canActivate: [AuthGuard],
			},
			{
				path: '',
				component: HomeComponent,
				data: {
					hideBack: true,
				},
				canActivate: [AuthGuard],
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AccountRoutingModule {}
