import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Provide an array of breadcrumb items
 */
export const BREADCRUMBS = new InjectionToken<BreadcrumbProviderService>('breadcrumbs');

export interface BreadcrumbProviderService {
	state$: Observable<{ breadcrumbs: BreadcrumbItem[] }>;
}

export interface BreadcrumbItem {
	text: string;
	routeUrl?: string;
	queryParams: any;
}

export function buildBreadcrumbAsUrlTree(
	text: string,
	routeUrl: any,
	queryParams: any = { dasFilter: '' }
): BreadcrumbItem {
	delete queryParams.dasFilter;

	return {
		text,
		routeUrl,
		queryParams,
	};
}

export function buildBreadcrumbAsQueryParams(text: string, routeUrl: string, queryParams: any): BreadcrumbItem {
	return {
		text,
		routeUrl,
		queryParams,
	};
}
