<cdx-slotted-modal size="medium" verticalPadding="none" (close)="close({ shouldReplaceUrl: true })">
	<h1 modal-header i18n="@@pickupTypeInformation-mainHeading">
		<span *ngIf="!isInReviewOrder; else reviewOrderLockerTitle">How does Pick up work?</span>
		<ng-template #reviewOrderLockerTitle>Self service eLocker Pick up</ng-template>
	</h1>
	<div modal-content>
		<section class="inStore" *ngIf="!isInReviewOrder">
			<h4 i18n="@@pickupTypeInformation-inStoreHeading">In-store</h4>
			<p i18n="@@pickupTypeInformation-inStoreContent">Go to the in-store Pick up point to collect your order.</p>
		</section>
		<section class="locker">
			<h4 i18n="@@pickupTypeInformation-lockerHeading" *ngIf="!isInReviewOrder">Self service eLocker</h4>
			<div class="locker-info">
				<cdx-svg-icon name="sms" fill="dark"></cdx-svg-icon>
				<div>
					<h5 i18n="@@pickupTypeInformation-lockerHeading">Receive SMS Pick up code</h5>
					<p i18n="@@pickupTypeInformation-lockerContent">Sent when order is ready for pick up.</p>
				</div>
			</div>
			<div class="locker-info">
				<cdx-svg-icon name="location-pin" fill="dark"></cdx-svg-icon>
				<div>
					<h5 i18n="@@pickupTypeInformation-lockerHeading">Go to self service eLockers</h5>
					<p i18n="@@pickupTypeInformation-lockerContent">Follow the on-site pick up signage.</p>
				</div>
			</div>
			<div class="locker-info">
				<cdx-svg-icon name="locker-screen" fill="dark"></cdx-svg-icon>
				<div>
					<h5 i18n="@@pickupTypeInformation-lockerHeading">Enter the Pick up code</h5>
					<p i18n="@@pickupTypeInformation-lockerContent">This is the 5 digit number in the SMS.</p>
				</div>
			</div>
			<div class="locker-message" i18n="@@pickupTypeInformation-lockerContent">
				<p>
					In some situations your order may be moved to in store Pick up. You'll be notified of the final collection point via
					SMS.
				</p>
				<ng-container *ngIf="!(supportsByob$ | async)"
					><p>BYO bags are not available for eLockers at this store.</p>
				</ng-container>
			</div>
		</section>
	</div>
</cdx-slotted-modal>
