import { FormGroup, FormGroupDirective, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounce, distinctUntilChanged } from 'rxjs/operators';
import { interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormService } from '../../services';
import { ChangeDetectionStrategy, Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SuburbAutocompleteComponent } from '../suburb-autocomplete/suburb-autocomplete.component';
import { NgIf } from '@angular/common';
import { ButtonComponent, NumericDirective } from '@woolworthsnz/styleguide';
import { InvalidTypeDirective } from '../../directives/invalid-type.directive';
import { InputErrorComponent } from '../input-error/input-error.component';
import { FormOptionalTextComponent } from '../form-optional-text/form-optional-text.component';
import { InputComponent } from '../input/input.component';
import { FieldsetComponent } from '../fieldset/fieldset.component';

@UntilDestroy()
@Component({
	selector: 'form-address-manual',
	templateUrl: './manual-address.component.html',
	styleUrls: ['./manual-address.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [FormService],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		FieldsetComponent,
		ButtonComponent,
		InputComponent,
		FormOptionalTextComponent,
		InputErrorComponent,
		InvalidTypeDirective,
		NumericDirective,
		NgIf,
		SuburbAutocompleteComponent,
	],
})
export class ManualAddressComponent implements OnInit, OnDestroy {
	@Input() showPlaceHolders = false;
	@Input() parentFormGroup: FormGroup;
	@Input() showDeliveryZone = true;

	@Output() switchToValidatedEntryEmitter: EventEmitter<any> = new EventEmitter();

	title = 'Enter address manually';
	formControls = [
		{
			name: 'manualUnit',
			validators: [],
		},
		{
			name: 'manualStreetAddress',
			validators: [Validators.required],
		},
		{
			name: 'manualSuburb',
			validators: [Validators.required],
		},
		{
			name: 'manualTownCity',
			validators: [Validators.required],
		},
		{
			name: 'manualPostcode',
			validators: [Validators.required],
		},
		{
			name: 'addressStreet1',
			validators: [],
		},
		{
			name: 'addressStreet2',
			validators: [],
		},
	];

	constructor(public parent: FormGroupDirective, public formService: FormService) {}

	ngOnInit(): void {
		this.parentFormGroup = this.parent.form;

		this.formControls.forEach((f) => {
			this.formService.addControlToParent(f.name, f.validators, this.parentFormGroup);
		});

		this.parentFormGroup.valueChanges
			.pipe(
				debounce(() => interval(300)),
				distinctUntilChanged(),
				untilDestroyed(this)
			)
			.subscribe((e) => {
				this.buildManualAddressLines(e);
			});
	}

	ngOnDestroy(): void {
		this.formControls.forEach((f) => {
			this.parentFormGroup.removeControl(f.name);
		});
	}

	buildManualAddressLines(e: any): void {
		this.parentFormGroup.patchValue({
			addressStreet1: `${e.manualUnit} ${e.manualStreetAddress}`.trim(),
			addressStreet2: `${e.manualSuburb} ${e.manualTownCity} ${e.manualPostcode}`.trim(),
		});
	}

	switchToAutocomplete(): void {
		this.switchToValidatedEntryEmitter.emit(true);
	}
}
