import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormService } from '../../services';
import { InputSize, InputComponent } from '../input/input.component';
import { AddressAutocompleteComponent } from '../address-autocomplete/address-autocomplete.component';
import { FormOptionalTextComponent } from '../form-optional-text/form-optional-text.component';
import { NgIf } from '@angular/common';
import { FieldsetComponent } from '../fieldset/fieldset.component';

@UntilDestroy()
@Component({
	selector: 'form-address-validated',
	template: `
		<ng-container [formGroup]="parentFormGroup">
			<fieldset
				cdxFieldset
				[layout]="layout"
				[hideLegend]="true"
				[legend]="title"
				[i18n]="'@@fieldset-addressCapture'"
			>
				<form-input autocomplete="off" formControlName="unit" maxlength="55" *ngIf="false">
					<!-- TODO: Add back when unit is implemented -->
					<label i18n="@@{{ page }}-unitlabel" for="unit" label>
						Unit, floor, building name <span cdxOptionalText></span>
					</label>
				</form-input>
				<form-address-autocomplete
					(switchToManualEntryEmitter)="switchToManualEntryHandler()"
					[size]="size"
				></form-address-autocomplete>
			</fieldset>
		</ng-container>
	`,
	styleUrls: ['./validated-address.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [FormService],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		FieldsetComponent,
		NgIf,
		InputComponent,
		FormOptionalTextComponent,
		AddressAutocompleteComponent,
	],
})
export class ValidatedAddressComponent implements OnInit, OnDestroy {
	@Input() parentFormGroup: FormGroup;
	@Input() size: InputSize;
	@Input() layout: 'narrow' | 'medium' | 'large' = 'medium';
	@Input() showDeliveryZone = true;

	@Output() switchToManualEntryEmitter: EventEmitter<any> = new EventEmitter();

	title = 'Find your address';

	constructor(public parent: FormGroupDirective, public formService: FormService) {}

	ngOnInit(): void {
		this.parentFormGroup = this.parent.form;

		this.formService.addControlToParent('unit', [], this.parentFormGroup);
	}

	ngOnDestroy(): void {
		this.parentFormGroup.removeControl('unit');
		this.parentFormGroup.updateValueAndValidity();
	}

	switchToManualEntryHandler(): void {
		this.switchToManualEntryEmitter.emit();
	}
}
