import {
	ACCOUNT_DELIVERY_ADDRESSES_PATH,
	ACCOUNT_MY_ORDERS_PATH,
	ACCOUNT_MY_SUBSCRIPTIONS,
	ACCOUNT_OLS_PREFERENCES_PATH,
	ACCOUNT_PATH,
	DELIVERYSUBSCRIPTION_PLANS_PATH,
	SPECIALS_PATH,
	STORE_LOCATOR_PATH,
} from '@woolworthsnz/shop';
import { EmbeddedNavigationItem, THEME_CONSTANTS } from '@woolworthsnz/styleguide';
import { NavigationItem, NavigationItemGroup } from '@woolworthsnz/trader-api';
import { AccountLinks } from './account/types';
import { routeNames } from './app.routes';
import { EnvServiceFactory } from './common';

const envService = EnvServiceFactory();

export interface Meta {
	title: string;
	embeddedTitle: string;
	description?: string;
	url?: string;
	image?: string;
}

export const quickNav = [
	{
		url: `${envService.countdownBaseUrl}/about-us/contact-us`,
		text: 'Contact',
		isAbsolute: true,
	},
	{
		url: `${envService.countdownBaseUrl}/store-finder`,
		text: 'Stores',
		isAbsolute: true,
	},
];

export const main = [
	{
		url: '/',
		text: 'Home',
	},
	{
		url: '/shop/browse',
		text: 'Browse',
		subnav: true,
	},
	{
		url: '/shop/specials',
		text: 'Specials',
		subnav: false,
	},
	{
		url: '/recipes',
		text: 'Recipes',
		subnav: false,
	},
	{
		url: '/lists/myfavourites',
		text: 'My Favourites',
	},
];

export const embedded: EmbeddedNavigationItem[] = [
	{
		url: '/',
		text: 'Shop',
		exact: true,
	},
	{
		url: '/shop/browse/departments',
		text: 'Browse',
	},
	{
		url: '/shop/specials',
		text: 'Specials',
	},
	{
		url: '/lists/myfavourites',
		text: 'My Favourites',
	},
];

// This gets appended with the main nav and other dynamic stuff
export const mobileNav = [
	{
		url: '/shop/editsearchlist',
		text: 'Shopping List',
	},
	{
		url: '/shop/accountdetails',
		text: 'My Account',
	},
	{
		url: '/store-finder',
		text: 'Store Locator',
	},
	{
		url: '/shop/faqs',
		text: 'Help',
	},
	{
		url: '/shop/content/mycountdownapp',
		text: 'myCountdown App',
	},
	{
		url: '/about-us/contact-us',
		text: 'Contact',
	},
];

export const myAccountNav: AccountLinks[] = [
	{
		label: 'Online shopping',
		icon: 'trolley',
		items: [
			{
				url: `${envService.countdownBaseUrl}/${ACCOUNT_PATH}/${ACCOUNT_MY_ORDERS_PATH}`,
				label: 'My orders',
				shopper: true,
				external: true,
			},
			{
				url: `${envService.countdownBaseUrl}/${ACCOUNT_PATH}/${ACCOUNT_MY_SUBSCRIPTIONS}`,
				label: 'Delivery Saver',
				shopper: true,
				external: true,
			},
			{
				url: `${envService.countdownBaseUrl}/${ACCOUNT_PATH}/${ACCOUNT_DELIVERY_ADDRESSES_PATH}`,
				label: 'Delivery addresses',
				shopper: true,
				external: true,
			},
			// not shopper
			{
				url: `${envService.countdownBaseUrl}/shop/register`,
				label: 'Register for online shopping',
				shopper: false,
				external: true,
			},
		],
	},
	{
		label: 'Everyday Rewards',
		icon: 'onecard',
		showAsNew: true,
		items: [
			{
				url: `/everyday-rewards/dashboard`,
				label: 'Dashboard',
			},
			{
				url: `${envService.countdownBaseUrl}/shop/everyday-rewards-boosts`,
				label: 'Boosts',
				external: true,
			},

			{
				url: `${envService.edrBaseUrl}/account/points-and-activity`,
				label: 'View points and transactions',
				external: true,
				openNew: true,
			},
			{
				url: `${envService.edrBaseUrl}/account/card`,
				label: 'Manage your Rewards card',
				external: true,
				openNew: true,
			},
		],
	},
	{
		label: 'Preferences and account details',
		icon: 'user',
		items: [
			{
				url: `/${routeNames.account}/${routeNames.personalDetails}`,
				label: 'Personal details',
			},
			{
				url: `/${routeNames.account}/${routeNames.manageEmailPassword}`,
				label: 'Manage email and password',
			},
			{
				url: `${envService.countdownBaseUrl}/${ACCOUNT_PATH}/${ACCOUNT_OLS_PREFERENCES_PATH}`,
				label: 'Online shopping preferences',
				shopper: true,
				external: true,
			},
			{
				url: `/${routeNames.account}/${routeNames.communicationPreferences}`,
				label: 'Communication preferences',
			},
		],
	},
];

export const onesiteFooterNav: NavigationItemGroup[] = [
	{
		label: 'Save at Countdown',
		items: [
			{
				id: 0,
				label: 'Specials',
				url: `${envService.countdownBaseUrl}/${SPECIALS_PATH}`,
			},
			{
				id: 1,
				label: 'Mailer',
				url: `${envService.countdownBaseUrl}/shop/content/weeklymailer`,
			},
			{
				id: 2,
				label: 'Delivery saver',
				url: `${envService.countdownBaseUrl}/${DELIVERYSUBSCRIPTION_PLANS_PATH}`,
			},
			{
				id: 3,
				label: 'Boosts',
				url: `${envService.countdownBaseUrl}/info/save/boosts`,
			},
			{
				id: 4,
				label: 'More ways to save',
				url: `${envService.countdownBaseUrl}/info/save`,
			},
		],
	},
	{
		label: 'Stores and services',
		items: [
			{
				id: 0,
				label: 'Store locations and hours',
				url: `${envService.countdownBaseUrl}/${STORE_LOCATOR_PATH}`,
			},
			{
				id: 1,
				label: 'Online shopping',
				url: `${envService.countdownBaseUrl}/info/online-shopping`,
			},
			{
				id: 2,
				label: 'Pharmacy',
				url: `${envService.countdownBaseUrl}/info/services/pharmacy`,
			},
			{
				id: 3,
				label: 'Gift cards',
				url: `${envService.countdownBaseUrl}/info/services/gift-cards`,
			},
			{
				id: 4,
				label: 'More services',
				url: `${envService.countdownBaseUrl}/info/services`,
			},
		],
	},
	{
		label: 'Customer service',
		items: [
			{
				id: 0,
				label: 'My orders',
				url: `${envService.countdownBaseUrl}/${ACCOUNT_PATH}/${ACCOUNT_MY_ORDERS_PATH}`,
			},
			{
				id: 1,
				label: 'Manage account',
				url: `/${ACCOUNT_PATH}`,
			},
			{
				id: 2,
				label: 'FAQs',
				url: `${envService.countdownBaseUrl}/shop/content/faqs`,
			},
			{
				id: 3,
				label: 'Refund policy',
				url: `${envService.countdownBaseUrl}/info/policies/refund-policy`,
			},
			{
				id: 4,
				label: 'Contact us',
				url: `${envService.countdownBaseUrl}/about-us/contact-us`,
			},
		],
	},
	{
		label: 'About Countdown',
		items: [
			{
				id: 0,
				label: 'About us',
				url: `${envService.countdownBaseUrl}/info/about-us`,
			},
			{
				id: 1,
				label: 'Community and environment',
				url: `${envService.countdownBaseUrl}/info/community-and-environment`,
			},
			{
				id: 2,
				label: 'Careers',
				url: `${envService.countdownBaseUrl}/info/careers`,
			},
			{
				id: 3,
				label: 'News and media',
				url: `${envService.countdownBaseUrl}/info/news-and-media-releases`,
			},
			{
				id: 4,
				label: 'Wholesale supply',
				url: `${envService.countdownBaseUrl}/info/about-us/about-woolworths-nz`,
			},
		],
	},
];

export const legalBarNav: NavigationItem[] = [
	{
		id: 0,
		label: 'Liquor licenses',
		url: `${envService.countdownBaseUrl}/shop/content/liquor-licences`,
	},
	{
		id: 1,
		label: 'Pay remediation',
		url: `${envService.countdownBaseUrl}/shop/content/holidays-act-remediation`,
	},
	{
		id: 2,
		label: 'Privacy centre',
		url: `${envService.countdownBaseUrl}/info/privacy`,
	},
	{
		id: 3,
		label: 'Terms & conditions',
		url: `${envService.countdownBaseUrl}/about-us/terms-and-conditions`,
	},
];

export const actions = [
	{
		url: '/shop/accountdetails',
		text: 'My Account',
	},
	{
		url: '/shop/help',
		text: 'Help',
	},
	{
		url: '/about-us/contact-us',
		text: 'Contact',
	},
];

export class AppSettings {
	static breakpoints = THEME_CONSTANTS.breakpoints;
	static navs = {
		main,
		mobileNav,
		embedded,
		quickNav,
		myAccountNav,
		onesiteFooterNav,
		legalBarNav,
		actions,
	};
	static endpoints = {
		// Misc
		address: 'addresses',
		logout: 'shopper-logouts',
		productDetails: 'products',
		search: 'products',
		session: 'session',
		shell: 'shell',
		metaTagSettings: 'settings/meta-tags',
		// Addresses
		pickupAddresses: 'addresses/pickup-addresses',
		externalAddress: 'addresses/external-address',

		// Orders
		cancelPastOrderChange: 'orders/my/past/cancel-change',
		getLastOrderChangeContext: 'orders/my/past/last-order-context',
		getOrderChangeContext: 'orders/my/past/order-change-context',
		// Products
		productSearchSuggestions: 'products/search-suggestions',

		// Recipes
		recipes: 'recipes',
		recipeSearchSuggestions: 'recipes/search-suggestions',

		// Dynamic content
		dynamicContent: 'dynamic-content',

		// Fulfilment
		postMethod: `fulfilment/my/methods`,

		// Addresses
		addresses: 'addresses',
		locations: 'locations',

		// auth
		oidcSignInEndpoint: 'bff/initiate-oidc-signin',
		oidcSignOutEndpoint: 'bff/logout',
		// get-user
		getUserEndpoint: 'bff/get-user',

		// product shelves
		productShelves: 'products/shelves',

		// Loyalty - Everyday Rewards
		loyalty: 'loyalty',
	};

	static meta: { [key: string]: Meta } = {
		default: {
			title: `Online Supermarket: Online Grocery Shopping &amp; Free Recipes at countdown.co.nz`,
			embeddedTitle: `Shop online`,
			description: `Onecard is changing to Everyday Rewards. Register or sign in today to shop, earn points and enjoy Rewards vouchers and fuel discounts.`,
			url: 'https://account.countdown.co.nz',
			image: 'https://www.countdown.co.nz/Images/Shared/facebook-like-icon.jpg',
		},
		dashboard: {
			title: `Dashboard - Woolworths`,
			embeddedTitle: `Dashboard`,
		},
		everydayRewards: {
			title: 'Everyday Rewards, Previously Onecard | Woolworths',
			embeddedTitle: 'Everyday Rewards',
		},
		commsPrefs: {
			title: 'Preference Hub - Countdown',
			embeddedTitle: 'Preference Hub',
		},
		personalDetails: {
			title: 'Personal Details',
			embeddedTitle: 'Personal Details',
		},
		account: {
			title: 'Account',
			embeddedTitle: 'Account',
		},
	};
}
