import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnInit,
	ViewChild,
	Output,
	EventEmitter,
} from '@angular/core';

import { ValidationError } from '@woolworthsnz/trader-api';
import { IconFill, IconSize, SVGIcon } from '../../../4_atoms/components';
import { AlertType } from '../../../ui-models';
import { SvgIconComponent } from '../../../4_atoms/components/svg-icon/svg-icon.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
	selector: 'cdx-alert, [cdxAlert]',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, SvgIconComponent, NgFor]
})
export class AlertComponent implements OnInit {
	@ViewChild('content', { static: true }) content: ElementRef;

	@Output() closeEmitter: EventEmitter<boolean> = new EventEmitter(false);

	// not camelCase due to using this as a custom element
	@Input() fullwidth = false;
	@Input() iconsize: IconSize = 'default';
	@Input() get candismiss(): boolean {
		return this._canDismiss;
	}
	set candismiss(value: boolean | string) {
		if (typeof value === 'string') {
			this._canDismiss = value.toLowerCase().trim() === 'true';
		} else {
			this._canDismiss = value;
		}
	}
	private _canDismiss = false;

	@Input() showCta = false;
	@Input() showTitle = true;
	@Input() title: string;
	@Input() inlineLink = false;
	@Input() withMargin = true;

	@HostBinding('attr.cananimate')
	@Input()
	animate = true;

	_alertType: AlertType | IconFill = AlertType.info;
	_description: string | string[] | ValidationError[];

	_icon: SVGIcon | string | false = SVGIcon.Alert;

	dismissed = false;

	get showAlert(): boolean {
		return !this.dismissed;
	}

	get role(): 'alertdialog' | 'alert' {
		// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_alert_role#Notes
		return this.candismiss ? 'alertdialog' : 'alert';
	}

	get type(): AlertType | IconFill {
		return this._alertType;
	}

	@Input() set type(alertType: AlertType | IconFill) {
		this._alertType = alertType ? alertType : AlertType.error;
	}

	get icon(): string | false {
		return this._icon;
	}

	@Input() set icon(icon: SVGIcon | string | false) {
		this._icon = icon;
	}

	get description(): string | string[] | ValidationError[] {
		return this._description;
	}

	@Input() set description(description: string | string[] | ValidationError[]) {
		if (!description) {
			return;
		}
		this._description = [].concat(<any>description);
	}

	alertLabelId = 'alertLabel';
	alertDescriptionId: string | undefined;

	@Input() showIcon = true;

	constructor(private cdr: ChangeDetectorRef) { }

	ngOnInit(): void {
		this.alertDescriptionId = this.description && this.description.length ? 'alertDescription' : undefined;
	}

	closeAlert(): void {
		this.dismissed = true;
		this.closeEmitter.emit(true);
		this.cdr.markForCheck();
	}

	hasMessage = (): boolean =>
		this.content.nativeElement.childNodes.length > 0 &&
		Boolean(this.content.nativeElement.childNodes[0].textContent.trim());
}
