import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

/**
 * note displayDateAtServer returns a representation of the date with server supplied offset only
 * because dayjs parses dates according to either local (default) or utc. Neither is useful if we
 * want to display dates according to a different TZ. It is actually a different point in time to
 * the original passed in date and should only be used for display (not for calc)
 */
export const displayDateAtServer = (date?: string): Dayjs | null => {
	dayjs.extend(utc);
	if (!date) {
		return null;
	}
	return dayjs(date).utcOffset(parseInt(date.split('+')[1].split(':')[0], 10));
};
