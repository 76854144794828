export class StyleGuideConfig {
	minimumAge: number;
	password: {
		minLength: number;
		requiredCharGroups: number;
	};
	suburbEndpoint = '';
	addressEndpoint = '';
	experimentsEndpoint = '';
	colors: { [key: string]: string };
	breakpoints: { [key: string]: string };
	environment?: { [key: string]: any };
}
