import { ESCAPE } from '@angular/cdk/keycodes';
import {
	AfterContentInit,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	SimpleChange,
	ViewChild,
} from '@angular/core';
import {
	BreakPointService,
	LocalStorageService,
	ModalOverlayRef,
	ModalOverlayService,
	ModalOverlayState,
} from '../../../services';
import { ButtonComponent } from '../../../4_atoms/components/button/button.component';
import { ModalComponent } from '../../../5_molecules/components/modal/modal.component';
import { NgIf, NgStyle } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// Useful numbers for setting modal positioning
export enum ModalSizes {
	defaultSizeMaxWidthInPixels = 304, // from base-modal.scss
	modalCutoutMarginInPixels = 20,
	pointerMarginInPixels = 16,
}

@Component({
	selector: 'cdx-cutout-modal',
	templateUrl: './cutout-modal.component.html',
	styleUrls: ['./cutout-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, NgStyle, ModalComponent, ButtonComponent],
})
export class CutoutModalComponent implements OnInit, OnChanges, AfterViewInit, AfterContentInit {
	@Input() target: ElementRef<HTMLElement>;
	@Input() size: 'default' | 'medium' | 'large' | 'full' = 'default';
	@Input() mode: 'circle' | 'rect' = 'circle';
	@Input() cutout: boolean;
	@Input() cutoutWidth: number;
	@Input() cutoutHeight: number;
	@Input() cutoutRadius: number;
	@Input() radius = 50;
	@Input() storageKey: string;
	@Input() arrowDirection: 'left' | 'right' = 'left';
	@Input() offsetTarget: { left?: number; top?: number } = {};
	@Input() modalHorizontalAdjustment = 0;
	@Input() hideCutoutOnMobileAndTablet = true;
	@Input() hideGotItButton = false;

	@ViewChild('modalContent') modalContent: ElementRef<HTMLElement>;

	modal: ModalOverlayRef;
	pointerSize = 12;
	padding = 16;
	viewportMobileOrTablet: boolean;

	private _targetElementBoundingBox: DOMRect;
	modalState$ = this.modalOverlayService.state$.pipe(takeUntilDestroyed());
	isSmallDevice$ = this.breakpointService.isSmallDevice$.pipe(takeUntilDestroyed());

	get cutoutLeft(): number | undefined {
		if (!this._targetElementBoundingBox) {
			return undefined;
		}

		return (
			this._targetElementBoundingBox.right -
			(this._targetElementBoundingBox.right - this._targetElementBoundingBox?.left || 0) +
			this.radius +
			(this.offsetTarget.left || 0)
		);
	}

	get cutoutTop(): number | undefined {
		if (!this._targetElementBoundingBox) {
			return undefined;
		}

		return (
			this._targetElementBoundingBox.top +
			(this._targetElementBoundingBox.bottom - this._targetElementBoundingBox.top) / 2 +
			+(this.offsetTarget.top || 0)
		);
	}

	get pointerPos(): { left?: string; right?: string } {
		const position = this.mode === 'circle' ? this.radius - this.pointerSize : ModalSizes.pointerMarginInPixels;
		return { [this.arrowDirection]: `${position}px` };
	}

	get modalLeft(): number | undefined {
		if (!this._targetElementBoundingBox) {
			return undefined;
		}
		if (this.viewportMobileOrTablet && this.hideCutoutOnMobileAndTablet) {
			return undefined;
		}

		// Allow fine tuning of the modal postition.
		const modalPostitionAdjustment: number = parseInt(this.modalHorizontalAdjustment.toString(), 10) || 0; // Javascript cohersing to string. Ensure we are wokring with a number.
		const targetOffset = (this.offsetTarget.left || 0) + modalPostitionAdjustment;

		const targetLeft = this._targetElementBoundingBox?.left + targetOffset || 0;

		if (this.arrowDirection === 'left') {
			return targetLeft;
		}
		if (!this.modalContent) {
			return 0;
		}

		const modalContentRect = this.modalContent?.nativeElement.getBoundingClientRect();
		const left = targetLeft - modalContentRect.width;
		const offset = this.mode === 'circle' ? this.radius * 2 : this.cutoutWidth;

		return left + offset;
	}

	get modalTop(): number | undefined {
		if (!this._targetElementBoundingBox) {
			return undefined;
		}
		if (this.viewportMobileOrTablet && this.hideCutoutOnMobileAndTablet) {
			return undefined;
		}

		return (this.cutoutTop || 0) + this.radius + ModalSizes.modalCutoutMarginInPixels;
	}

	constructor(
		private modalOverlayService: ModalOverlayService,
		private localStorageService: LocalStorageService,
		private breakpointService: BreakPointService,
		private cdr: ChangeDetectorRef
	) {}

	@HostListener('document:keydown', ['$event'])
	onKeydown(event: KeyboardEvent): void {
		// eslint-disable-next-line @typescript-eslint/tslint/config
		if (event.keyCode === ESCAPE) {
			this.closeModal();
		}
	}

	@HostListener('window:resize')
	onWindowResize(): void {
		this._targetElementBoundingBox = this.target?.nativeElement.getBoundingClientRect();
		this.cdr.markForCheck();
	}

	ngOnInit(): void {
		this.modalState$.subscribe((m) => (this.modal = m.modal));
		this.isSmallDevice$.subscribe((isSmall) => {
			this.viewportMobileOrTablet = isSmall;
			this.cdr.markForCheck();
		});
	}

	ngOnChanges(c: { target: SimpleChange }): void {
		if (c.target?.currentValue) {
			const { currentValue } = c.target;

			const bondingElement = currentValue.nativeElement
				? currentValue.nativeElement
				: currentValue.elementRef.nativeElement;
			this._targetElementBoundingBox = bondingElement.getBoundingClientRect();
			this.cdr.markForCheck();
		}
	}

	ngAfterViewInit(): void {
		this.cdr.markForCheck();
	}

	ngAfterContentInit(): void {
		// This is so we can get the latest bounding box details of the modal
		// So we can offset it when using right direction
		this.cdr.markForCheck();
	}

	closeModal(): void {
		this.updateState();
		this.modal.close();
	}

	updateState(): void {
		this.modalOverlayService.setState(new ModalOverlayState());
		if (this.storageKey) {
			this.localStorageService.setItem(this.storageKey, 'true');
		}
	}
}
