import { FocusableOption } from '@angular/cdk/a11y';
import { Component, HostBinding, HostListener, Injector, Input, Optional } from '@angular/core';
import { Parent, SelectBaseComponent } from '../select-base.component';
import { SelectComponent } from '../select/select.component';
import { NgIf, NgTemplateOutlet } from '@angular/common';

let uniqueId = 0;
@Component({
	selector: 'form-select-option',
	template: `
		<ng-container *ngIf="note">
			<hr />
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</ng-container>
		<ng-container *ngIf="!note">
			<input [id]="id" type="checkbox" tabindex="-1" *ngIf="multiple" [attr.checked]="isSelected ? true : null" />
			<label [for]="id"
				><span><ng-container *ngTemplateOutlet="content"></ng-container></span
			></label>
		</ng-container>
		<ng-template #content>
			<ng-content></ng-content>
		</ng-template>
	`,
	styleUrls: ['./select-option.component.scss'],
	standalone: true,
	imports: [NgIf, NgTemplateOutlet],
})
export class SelectOptionComponent extends SelectBaseComponent implements FocusableOption {
	@HostBinding('attr.aria-label') arialLabel = this.value;
	@HostBinding('class.active') active = false;
	@HostBinding('tabindex') tabIndex = -1;
	@HostBinding('attr.role') role = 'option';
	@HostBinding('attr.aria-selected') ariaSelected = this.isSelected;

	@Input()
	id = `uniqueId_${uniqueId++}`;

	@HostBinding('class.note')
	@Input()
	note = false;

	@Input() selectAll: boolean;

	@Input()
	set value(value: string) {
		this._inputValue = value;
	}

	get value(): string {
		return this._inputValue ? this._inputValue : (this.el.nativeElement.innerText as string)?.trim();
	}
	// eslint-disable-next-line @typescript-eslint/naming-convention
	_inputValue: string;

	@HostListener('click', ['$event'])
	onClick(event: UIEvent): void {
		// we dont need to handle onclick event for note option
		// generally note is some text or link
		if (this.note) {
			return;
		}
		this.select.selectOption(this);

		event.stopPropagation();
		event.preventDefault();
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent): void {
		if (event.key === 'Tab') {
			this.select.hideAndFocus();
		}
	}

	getLabel(): string {
		return this.value;
	}

	setActiveStyles(): void {
		this.active = true;
	}

	setInactiveStyles(): void {
		this.active = false;
	}

	focus(): void {
		this.el.nativeElement.focus();
	}

	get multiple(): boolean {
		return this.select.multiple;
	}

	get isSelected(): boolean {
		if (this.selectAll) {
			return this.select.selectedAll;
		}

		return this.select.selected.indexOf(this.value) !== -1;
	}

	get select(): SelectComponent {
		return <any>this.parent;
	}

	constructor(protected injector: Injector, @Optional() parent: Parent) {
		super(injector, parent);
	}
}
