import { ComponentPortal } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import { ApplicationRef, Inject, Injectable, isDevMode, NgZone, Optional } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
	AppSettingsService,
	GenericModalComponent,
	LoggingService,
	ModalEvent,
	ModalOverlayService,
	REQ_RECEIVE_TIME,
} from '@woolworthsnz/styleguide';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { combineLatest, Subject, Subscription, timer } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { distinctUntilFulfilmentChanged } from '../helpers';
import { FulfilmentStoreService } from './fulfilment-store.service';
import { FulfilmentService } from './fulfilment.service';
import { deliveryTimeSlotTitle, deliveryTimeSlotButton } from '../constants';
import { Router } from '@angular/router';

dayjs.extend(customParseFormat);

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class SlotExpiryService {
	reservationTimeoutInMinutes: number;
	closingSoonAlertPeriodInMinutes: number;
	closingNowAlertPeriodInMinutes: number;

	slotTime: dayjs.Dayjs;
	slotCloseTimerSubscription: Subscription;
	slotExpiryTimerSubscription: Subscription;
	isExpressSlot: boolean;

	hasBeenStable$ = new Subject<void>();
	doClosingTimer$ = new Subject<void>();
	doExpiryTimer$ = new Subject<void>();

	private initialExpiryTimerStateEnabled = true;

	constructor(
		private modalOverlayService: ModalOverlayService,
		private appSettingsService: AppSettingsService,
		private loggingService: LoggingService,
		private fulfilmentService: FulfilmentService,
		private fulfilmentStoreService: FulfilmentStoreService,
		private ngZone: NgZone,
		private applicationRef: ApplicationRef,
		private _location: Location,
		private _router: Router,
		@Optional() @Inject(REQ_RECEIVE_TIME) private originalTime: number = 0
	) {
		combineLatest([
			this.hasBeenStable$,
			this.fulfilmentStoreService.state$.pipe(distinctUntilFulfilmentChanged()),
		]).subscribe(([_, state]) => {
			if (state.startTime) {
				this.init();
			}
		});

		// Both of these create timers so must be done after the application initially stabilises
		// https://angular.io/api/core/ApplicationRef#isstable-examples-and-caveats
		combineLatest([this.hasBeenStable$, this.doClosingTimer$]).subscribe(() => {
			if (isDevMode()) {
				this.loggingService.log('Activating reservationClosingTimer');
			}
			this.doClosingTimer();
		});

		combineLatest([this.hasBeenStable$, this.doExpiryTimer$]).subscribe(() => {
			if (!this.initialExpiryTimerStateEnabled) {
				return;
			}

			if (isDevMode()) {
				this.loggingService.log('Activating slotExpiryTimer');
			}

			this.doExpiryTimer();
		});

		this.applicationRef.isStable.pipe(first((stable) => stable)).subscribe(() => {
			this.loggingService.log(`App is now stable after ${Date.now() - this.originalTime}ms`);
			this.hasBeenStable$.next();
		});
	}

	init(): void {
		this.reservationTimeoutInMinutes = this.appSettingsService.getTimeout('reservationTimeout');
		this.closingSoonAlertPeriodInMinutes = this.appSettingsService.getTimeout('closingSoon');
		this.closingNowAlertPeriodInMinutes = this.appSettingsService.getTimeout('closingNow');

		if (!this.reservationTimeoutInMinutes) {
			this.loggingService.trackException({ error: new Error('No reservation timer set') });
		}

		this.doExpiryTimer$.next();
	}

	addTodaysDateToCutOffTime = (cutOffTime: string): Dayjs =>
		// Dates won't parse unless there is a space between the time and the PM/AM
		dayjs(`${dayjs().format('YYYY-MM-DD')} ${cutOffTime.replace('AM', ' AM').replace('PM', ' PM')}`);

	// TODO: [POD-6082] use aux routes for modals
	createModal = (
		title: string,
		type: 'warning' | 'error',
		description?: string
	): ComponentPortal<GenericModalComponent> =>
		new ComponentPortal(
			GenericModalComponent,
			null,
			this.modalOverlayService.createInjector({
				title,
				description,
				icon: type === 'warning' ? 'wall-clock' : 'alert',
				iconFill: type,
				ctaAction: this.modalOverlayService.close,
			})
		);
	clearClosingTimer = (): void => {
		this.slotCloseTimerSubscription?.unsubscribe();
	};

	clearExpiryTimer = (): void => {
		this.slotExpiryTimerSubscription?.unsubscribe();

		// This handles a race condition where if we haven't initialised
		// the timers then this code can run before the timers have been
		// setup. We can check this value during setup to ensure we don't
		// enable the timers at all.
		this.initialExpiryTimerStateEnabled = false;
	};

	clearTimeslot = (): void => {
		this.fulfilmentService.clearTimeslot();
	};

	/**
	 * Sets up the closing timer to listen for slotCloseTimerIsActive$ Observable
	 * If the observable becomes active, it then runs through the various taps to
	 * ascertain how much time is left and what actions to perform.
	 */
	doClosingTimer(): void {
		this.slotCloseTimerSubscription = timer(0, 1000)
			.pipe(
				map(this.toRemainingMinutes),
				tap(this.handleClosingTimerAtZero),
				tap(this.handleClosingTimerAtClosingSoon),
				tap(this.handleClosingTimerAtClosingNow)
			)
			.subscribe();
	}

	doExpiryTimer(): void {
		this.clearExpiryTimer();
		const expiry = dayjs().add(this.reservationTimeoutInMinutes, 'minute');

		this.slotExpiryTimerSubscription = timer(0, 1000)
			.pipe(
				tap(() => {
					if (dayjs().isAfter(expiry)) {
						this.handleReservationExpiry();
					}
				})
			)
			.subscribe();
	}

	getAlertContent = (alert: string): string => this.appSettingsService.getMessage(alert);

	getExpressAlertContent = (alert: string): string | undefined =>
		this.appSettingsService.getExpressFulfilmentMessage(alert);

	/**
	 * Gets called by ContextInterceptor to orchestrate timers
	 * @param slotCutOffTime: The time up until you can book a slot
	 * @param isSlotToday: If the slot is today. Closing timer does not need to fire if it is not today
	 * */
	handleReservationTimers(slotCutOffTime: string, isSlotToday: boolean, isExpressSlot?: boolean): void {
		// If timers are currently running we want to stop those timers and clear the observables
		if (this.slotExpiryTimerSubscription) {
			this.loggingService.log(`clearing timers`);

			this.clearClosingTimer();
			this.clearExpiryTimer();
		}

		this.loggingService.log(`starting timers ${slotCutOffTime}, ${isSlotToday}`);

		// If we have a slotCutOffTime then we should trigger the expiry timer and the closing timer
		if (!slotCutOffTime) {
			return;
		}
		this.slotTime = this.addTodaysDateToCutOffTime(slotCutOffTime);
		this.isExpressSlot = isExpressSlot || false;

		// Trigger timers to run again
		this.handleExpiryTimer();
		this.handleReservationClosingTimer(isSlotToday);
	}

	// Resets the Expiry Timer
	handleExpiryTimer = (): void => {
		this.initialExpiryTimerStateEnabled = true;
		this.doExpiryTimer$.next();
	};

	handleReservationExpiry = (): void => {
		this.openGenericModal({
			buttonText: deliveryTimeSlotButton.delivery_timeslot_expired,
			iconText: 'error',
			title: deliveryTimeSlotTitle.delivery_timeslot_expired,
			description: this.getAlertContent('reservationExpired'),
			ctaActionCallback: this.timeSlotExpiredCTA,
			hasCancelButton: false,
		});
		this.clearTimeslotAndTimers();
	};

	/**
	 * @param isSlotToday: If slot is not today we don't need a closing timer
	 */
	handleReservationClosingTimer = (isSlotToday: boolean): void => {
		if (!isSlotToday) {
			return;
		}

		this.doClosingTimer$.next();
	};

	openModal = (componentPortal: ComponentPortal<GenericModalComponent>): void => {
		// We are outside angular because of the rxjs involved in triggering this, we want this to run back in angular zone
		this.ngZone.run(() => {
			this.modalOverlayService.open({
				eventType: ModalEvent.updatedFulfilmentTimeslot,
				templateRef: componentPortal,
			});
		});
	};

	openGenericModal = ({
		buttonText,
		iconText,
		title,
		description,
		ctaActionCallback,
		hasCancelButton,
		closeActionCallback,
	}: {
		buttonText: string;
		iconText: string;
		title: string;
		description: string;
		ctaActionCallback: Function;
		hasCancelButton: boolean;
		closeActionCallback?: Function;
	}): void => {
		// We have added the new method is temperary to keep both old style and new style
		// Since the impact is large handled it separate
		// once all the models are unique in design then we can remove the new method
		this.modalOverlayService.openGenericModal({
			buttonText,
			hasCancelButton,
			icon: iconText === 'warning' ? 'wall-clock' : 'alert',
			title,
			description: description,
			fitContent: false,
			skipTracking: true,
			isButtonPrimary: true,
			isNewTextStyle: true,
			ctaAction: ctaActionCallback,
			closeAction: closeActionCallback,
		});
	};
	toRemainingMinutes = (): number => dayjs(this.slotTime).diff(dayjs(), 'minute');

	private handleClosingTimerAtClosingNow = (t: number): void => {
		const timeIsOnOrAfterClosingNowPeriod = t <= this.closingNowAlertPeriodInMinutes;
		const timeIsZero = t <= 0;
		if (
			timeIsOnOrAfterClosingNowPeriod &&
			!timeIsZero &&
			!this.fulfilmentStoreService.state.currentReservationAlertsFired.closingNow
		) {
			if (this.isExpressSlot) {
				this.openModal(
					this.createModal('Express is closing now', 'warning', this.getExpressAlertContent('closingNow'))
				);
			} else {
				this.openGenericModal({
					buttonText: deliveryTimeSlotButton.delivery_timeslot_expired,
					iconText: 'warning',
					title: deliveryTimeSlotTitle.delivery_timeslot_expired,
					description: this.getAlertContent('closingNow'),
					ctaActionCallback: this.timeSlotExpiredCTA,
					hasCancelButton: false,
				});
			}
			this.fulfilmentStoreService.setState({
				currentReservationAlertsFired: {
					...this.fulfilmentStoreService.state.currentReservationAlertsFired,
					closingNow: true,
				},
			});
		}
	};

	private handleClosingTimerAtClosingSoon = (t: number): void => {
		const timeIsOnOrAfterClosingSoonAlertPeriod = t <= this.closingSoonAlertPeriodInMinutes;
		const timeIsMoreThanClosingNowPeriod = t > this.closingNowAlertPeriodInMinutes;
		if (
			timeIsOnOrAfterClosingSoonAlertPeriod &&
			timeIsMoreThanClosingNowPeriod &&
			!this.fulfilmentStoreService.state.currentReservationAlertsFired.closingSoon
		) {
			if (this.isExpressSlot) {
				this.openModal(
					this.createModal('Express closing soon!', 'warning', this.getExpressAlertContent('closingSoon'))
				);
			} else {
				this.openGenericModal({
					buttonText: deliveryTimeSlotButton.delivery_timeslot_expired,
					iconText: 'warning',
					title: deliveryTimeSlotTitle.delivery_timeslot_expired,
					description: this.getAlertContent('closingSoon'),
					ctaActionCallback: this.timeSlotExpiredCTA,
					hasCancelButton: false,
				});
			}

			this.fulfilmentStoreService.setState({
				currentReservationAlertsFired: {
					...this.fulfilmentStoreService.state.currentReservationAlertsFired,
					closingSoon: true,
				},
			});
		}
	};

	private handleClosingTimerAtZero = (t: number): void => {
		// If the timer is at 0 we need to clear the timeslot AND expiry timer
		// If we don't the expiry timer keeps flashing up
		const timeIsZero = t <= 0;
		if (timeIsZero && !this.fulfilmentStoreService.state.currentReservationAlertsFired.closed) {
			if (this.isExpressSlot) {
				this.openModal(
					this.createModal('Express is now closed', 'error', this.getExpressAlertContent('closed'))
				);
			} else {
				this.openGenericModal({
					buttonText: deliveryTimeSlotButton.delivery_timeslot_expired,
					iconText: 'warning',
					title: deliveryTimeSlotTitle.delivery_timeslot_expired,
					description: this.getAlertContent('closed'),
					ctaActionCallback: this.timeSlotExpiredCTA,
					hasCancelButton: false,
					closeActionCallback: this.timeSlotExpiredCTA,
				});
			}
			this.fulfilmentStoreService.setState({
				currentReservationAlertsFired: {
					...this.fulfilmentStoreService.state.currentReservationAlertsFired,
					closed: true,
				},
			});
			this.clearTimeslotAndTimers();
		}
	};

	private timeSlotExpiredCTA = (): void => {
		this.modalOverlayService.close();
		this.clearTimeslotAndTimers();

		this._router.navigateByUrl(`/bookatimeslot`);
	};

	private clearTimeslotAndTimers = (): void => {
		this.clearTimeslot();
		this.clearExpiryTimer();
		this.clearClosingTimer();
	};
}
