import { Action, createReducer, on } from '@ngrx/store';
import { authInitialise, authInitialiseComplete, userLoggedOut } from './auth.actions';

export interface AuthState {
	initialising: boolean;
	isLoggedIn: boolean;
	isShopper: boolean;
}

export const initialState: AuthState = {
	initialising: true,
	isLoggedIn: false,
	isShopper: false,
};

const _authReducer = createReducer(
	initialState,
	on(userLoggedOut, (state: AuthState): AuthState => ({ ...state, initialising: false, isLoggedIn: false })),
	on(authInitialise, (state: AuthState): AuthState => ({ ...state, initialising: true, isLoggedIn: false })),
	on(
		authInitialiseComplete,
		(state: AuthState, { isAuthenticated, isShopper }): AuthState => ({
			...state,
			initialising: false,
			isShopper,
			isLoggedIn: isAuthenticated,
		})
	)
);

export function authReducer(state: AuthState | undefined, action: Action) {
	return _authReducer(state, action);
}
