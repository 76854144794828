import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class EnvService {
	public traderApiUrl = '';
	public bffApiUrl = '';
	public baseUrl = '';
	public countdownBaseUrl = '';
	public featureServiceApiUrl = '';
	public storeServiceUrl = '';
	public ssoServiceUrl = '';
	public idpHint = '';
	public edrBaseUrl = '';
}
