import { createFeatureSelector, createSelector } from '@ngrx/store';
import { simplifiedStoreId } from '.';
import { StoreState, name, adapter } from './stores.reducer';

export const selectStores = createFeatureSelector<StoreState>(name);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors(selectStores);

export const selectStoresLoading = createSelector(selectStores, (state) => state.loading);

export const selectAllStores = createSelector(selectAll, (stores) => {
	return stores.map((store) => ({
		...store,
		simplifiedStoreId: simplifiedStoreId(store.id),
	}));
});

export const selectStoresByAreaId = (areaId: string) =>
	createSelector(selectAll, (stores) =>
		stores
			.filter((s) => s.state === areaId)
			.sort((a, b) => {
				// sort by name
				if (!a.name) return -1;
				if (!b.name) return 1;

				return a.name.localeCompare(b.name);
			})
			.map((s) => ({
				value: simplifiedStoreId(s.id),
				text: s.name,
			}))
	);

export const selectAreas = createSelector(selectAll, (stores) => {
	const areaIds = [...new Set(stores.map((s) => s.state).sort())];

	return areaIds.map((id) => ({
		value: id,
		text: areaMap[id as keyof typeof areaMap], // map 3 letter state code to full name
	}));
});

const areaMap = {
	AUK: 'Auckland',
	BOP: 'Bay of Plenty',
	CAN: 'Canterbury',
	GIS: 'Gisborne',
	HKB: "Hawke's Bay",
	MBH: 'Marlborough',
	MWT: 'Manawatu-Wanganui',
	NSN: 'Nelson',
	NTL: 'Northland',
	OTA: 'Otago',
	STL: 'Southland',
	TAS: 'Tasman',
	TKI: 'Taranaki',
	WKO: 'Waikato',
	WGN: 'Wellington',
	WTC: 'West Coast',
	CIT: 'Chatham Islands Territory',
};
