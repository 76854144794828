import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	HostBinding,
	Input,
	Output,
	TrackByFunction,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

export interface ListItem {
	value: any;
	text: any;
	disabled?: boolean;
}

// adds an iterator to id of the control so we don't have accessibility issues
let nextId = 0;

@Component({
	selector: 'form-dropdown',
	template: `
		<ng-container *ngIf="list?.length">
			<label *ngIf="labelText" [for]="id">{{ labelText }}</label>
			<div class="dropdownList" [attr.dropdown-constrain-width]="constrainWidth">
				<select [disabled]="disabled" [name]="id" (change)="onChange($event)" [id]="id">
					<option *ngIf="initialText" selected disabled hidden value="">{{ initialText }}</option>
					<option
						*ngFor="let item of list; trackBy: trackByFn"
						[attr.value]="item[valueKey]"
						[attr.selected]="item[valueKey] === selectedValue ? true : null"
						[disabled]="item[disabledKey]"
					>
						{{ item[displayKey] }}
					</option>
				</select>
			</div>
		</ng-container>
	`,
	styleUrls: ['./dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DropdownComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [NgIf, FormsModule, NgFor],
})
export class DropdownComponent implements ControlValueAccessor {
	@Input() name = 'dropdown';
	@Input() labelText: string;
	@Input() selectedValue: any;
	@Input() valueKey = 'value';
	@Input() displayKey = 'text';
	@Input() disabledKey = 'disabled';
	@Input() initialText: string;
	@Input() constrainWidth = true;
	@Input() disabled = false;

	@HostBinding('attr.fullWidth')
	@Input()
	fullWidth = false;

	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onSelect: EventEmitter<any> = new EventEmitter();

	options: ListItem[];
	uniqueId = nextId++;
	changeFn: (val: any) => void | undefined;

	constructor(private cdr: ChangeDetectorRef) {}

	trackByFn: TrackByFunction<any> = (_, item) => item[this.valueKey];

	get list(): any[] {
		return this.options;
	}

	@Input()
	set list(value: any[]) {
		this.options = value || [];
		if (typeof this.options[0] !== 'object' && typeof this.options[0] !== 'undefined') {
			this.options = this.options.map((option) => ({
				text: option,
				value: option,
			}));
		}
	}

	get id(): string {
		return `${this.name}-${this.uniqueId}`;
	}

	writeValue(obj: any): void {
		this.selectedValue = obj;
		this.cdr.markForCheck();
	}

	registerOnChange(fn: (val: any) => void): void {
		this.changeFn = fn;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	registerOnTouched(fn: any): void {
		/** noop */
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
		this.cdr.markForCheck();
	}

	onChange(event: { target: { value: any } }): void {
		this.onSelect.emit(event.target.value);
		if (this.changeFn) {
			this.selectedValue = event.target.value;
			this.changeFn(event.target.value);
			this.cdr.markForCheck();
		}
	}
}
