<ng-container *ngIf="boostDetails$ && (boostDetails$ | async) as boostDetails">
	<div class="boost-details-card" [attr.boosted]="boostDetails.isBoosted">
		<div class="boost-details-card__icon">
			<div class="boost-details-card__icon__circle">
				<img src="/assets/images/everyday-rewards/boost-rocket.svg" alt="Boost Rocket" />
			</div>
		</div>
		<div class="boost-details-card__body">
			<div class="boost-details-card__body__content">
				<p class="boost-details-card__body__content__header">
					{{boostDetails.isBoosted ? 'Boosted' : ''}} {{ boostOfferText({ points: boostDetails.points, value:
					boostDetails.value, type: boostDetails.rewardType }) }}
				</p>
				<p class="boost-details-card__body__content__text">
					<ng-container
						*ngTemplateOutlet="boostDetails.isBoosted ? boostedBodyTextTemplate : notBoostedBodyTextTemplate; context: boostDetails"
					/>
					<br />
					Ends {{boostDetails.endDate | date:'d MMM'}}. Limit {{boostDetails.limit}} per customer.
				</p>
			</div>
			<div class="boost-details-card__body__footer">
				<button class="boost-details-card__body__footer__cta" (click)="openBoostOfferDetails()">
					See details
				</button>

				<cdx-badge *ngIf="boostDetails.isBoosted" class="success" badgeText="Boosted"></cdx-badge>
			</div>
		</div>
	</div>
</ng-container>

<ng-template #notBoostedBodyTextTemplate> Add to cart for an automatic boost. </ng-template>

<ng-template #boostedBodyTextTemplate let-rewardType="rewardType">
	Shop and enjoy the {{ boostOfferType({ type: rewardType }) }}.
</ng-template>
