import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WINDOW } from './window.service';

/**
 *  This service sets a global css variable that can be used to always get the accurate
 *  innerheight of the window, regardless of whether that's on a mobile browser with
 *  the address bar visible.
 */

const debounceTime = 150;

@Injectable({
	providedIn: 'root',
})
export class WindowInnerHeightService {
	constructor(@Inject(WINDOW) private window: Window, @Inject(PLATFORM_ID) private platformId: Object) {}

	public initWindowInnerHeightCSSVariable(): void {
		if (!isPlatformServer(this.platformId)) {
			this.setWindowInnerHeightCSSVariable(this.getInnerHeight());
			this.handleWindowResize();
		}
	}

	private getInnerHeight(): number {
		return this.window.innerHeight;
	}

	private handleWindowResize(): void {
		let timeout: number;
		this.window.onresize = () => {
			clearTimeout(timeout);
			timeout = this.window.setTimeout(() => {
				this.setWindowInnerHeightCSSVariable(this.getInnerHeight());
			}, debounceTime);
		};
	}

	private setWindowInnerHeightCSSVariable(innerHeight: number): void {
		this.window.document.documentElement.style.setProperty('--window-inner-height', String(`${innerHeight}px`));
	}
}
