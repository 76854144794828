import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Request } from 'express';
import { BehaviorSubject } from 'rxjs';
import { AppSettingsService, REQUEST } from './app-settings.service';
import { CustomWindow, WINDOW } from './window.service';

export enum Device {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	MOBILE = 'mobile',
	// eslint-disable-next-line @typescript-eslint/naming-convention
	TABLETPORTRAIT = 'tablet-portrait',
	// eslint-disable-next-line @typescript-eslint/naming-convention
	TABLET = 'tablet',
	// eslint-disable-next-line @typescript-eslint/naming-convention
	DESKTOP = 'desktop',
	// eslint-disable-next-line @typescript-eslint/naming-convention
	LARGE = 'large',
	// eslint-disable-next-line @typescript-eslint/naming-convention
	XLARGE = 'xlarge',
}

@Injectable({
	providedIn: 'root',
})
export class BreakPointService {
	readonly device = new BehaviorSubject<Device>(Device.MOBILE);
	readonly device$ = this.device.asObservable();
	readonly isSmallDevice$ = new BehaviorSubject<boolean>(true);
	observableBreakPoints: { [key: string]: any };

	constructor(
		@Optional() @Inject(REQUEST) protected request: Request,
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(WINDOW) private window: CustomWindow,
		public breakpointObserver: BreakpointObserver,
		appSettingsService: AppSettingsService
	) {
		const breakpoints = appSettingsService.getBreakpoints();

		let ua: string;
		if (this.request) {
			// @ts-ignore - headers in the node world has no getter for keys
			ua = this.request.headers['user-agent'];

			if (ua && /mobile/i.test(ua)) {
				this.device.next(Device.MOBILE);
				this.isSmallDevice$.next(true);
			} else {
				this.device.next(Device.DESKTOP);
				this.isSmallDevice$.next(false);
			}
		}

		this.observableBreakPoints = {
			[`(max-width: ${parseInt(breakpoints.mobile, 10) - 1}px)`]: Device.MOBILE,
			[`(max-width: ${parseInt(breakpoints['tablet-portrait'], 10) - 1}px)`]: Device.TABLETPORTRAIT,
			[`(max-width: ${parseInt(breakpoints.tablet, 10) - 1}px)`]: Device.TABLET,
			[`(max-width: ${parseInt(breakpoints.desktop, 10) - 1}px)`]: Device.DESKTOP,
			[`(max-width: ${parseInt(breakpoints.large, 10) - 1}px)`]: Device.LARGE,
			[`(min-width: ${breakpoints.large})`]: Device.XLARGE,
		};
		this.breakpointObserver.observe(Object.keys(this.observableBreakPoints)).subscribe(this.identifyDevice);
	}

	identifyDevice = (state: BreakpointState): void => {
		if (state && state.matches) {
			const matchedBreakPoint = Object.keys(state.breakpoints).filter((key) => state.breakpoints[key]);
			this.device.next(this.observableBreakPoints[matchedBreakPoint[0]]);
			this.isSmallDevice$.next(this.isSmallDevice(this.observableBreakPoints[matchedBreakPoint[0]]));
		}
	};

	/**
	 * Check to see if the platform the browser is rendered on is a Personal Computer
	 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform
	 * @returns True if device is either Mac, Windows or Linux
	 */
	isPC(): boolean {
		if (this.window.navigator?.platform) {
			const platform = navigator.platform;
			return platform.indexOf('Mac') === 0 || platform.indexOf('Win') === 0 || platform.indexOf('Linux') === 0;
		}

		return false;
	}

	/**
	 * For the purposes of most of Countdown layout Mobile and Portrait tablets are treated the same... with TABLET then behaving like DESKTOP/LARGE/XLARGE. This is not always true and so not all components use this utility, but it's true enough for this to be useful.
	 * @param d The current device
	 * @returns
	 */
	private isSmallDevice(d: Device): boolean {
		return d === Device.MOBILE || d === Device.TABLETPORTRAIT;
	}
}
