import { BoostOfferResponse } from '@woolworthsnz/trader-api';
import { of } from 'rxjs';
import { boostOffersResponseMock } from './boost-offers-response.mock';

const mockOffer = {
	offer: { id: '1234' } as BoostOfferResponse,
	isBoosting: false,
	hasError: false,
};

export const mockProductOffers = boostOffersResponseMock.offers
	.filter((offer) => {
		return offer.displayLocation === 'PRODUCT_OFFERS';
	})
	.map((productOffer) => ({
		offer: productOffer,
		isBoosting: false,
		hasError: false,
	}));

export const mockOtherOffers = boostOffersResponseMock.offers
	.filter((offer) => offer.displayLocation === 'OTHER_OFFERS')
	.map((otherOffer) => ({
		offer: otherOffer,
		isBoosting: false,
		hasError: false,
	}));

export const mockOffers = [mockOffer];

export const mockBoostsFacade = {
	hasOffers$: of(true),
	boostOffers$: of(mockOffers),
	loadBoostOffers: (): void => {
		/* Mock fetch boost offers */
	},
	activateBoostOffer: (): void => {
		/* Mock activate a boost offer */
	},

	isLoadingOffers$: of(false),
	hasLoadedBoostOffers$: of(true),
	productOffers$: of(mockProductOffers),
	otherOffers$: of(mockOtherOffers),
	isAllBoosting$: of(false),
	isAllOffersBoosted$: of(false),
};
