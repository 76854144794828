import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActionsSubject, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { BehaviorSubject } from 'rxjs';
import { selectUnsubscribeFailure, unsubscribe, unsubscribed } from '../../state/preferences';
@UntilDestroy()
@Component({
	selector: 'ss-unsubscribe',
	templateUrl: './unsubscribe.component.html',
	styleUrls: ['./unsubscribe.component.scss'],
})
export class UnsubscribeComponent implements OnInit {
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private store: Store,
		private actionSubject: ActionsSubject
	) {}

	unsubscribed$ = new BehaviorSubject<boolean>(false);
	errored$ = new BehaviorSubject<boolean>(false);

	ngOnInit(): void {
		// if there is no email, version and hash qs params redirect to account home page
		if (
			this.route.snapshot.queryParamMap.get('email') === null &&
			this.route.snapshot.queryParamMap.get('version') == null &&
			this.route.snapshot.queryParamMap.get('hash') === null
		) {
			this.router.navigate(['/account']);
		}

		this.store.dispatch(
			unsubscribe({
				email: decodeURIComponent(this.route.snapshot.queryParamMap.get('email') ?? '').trim(),
				version: this.route.snapshot.queryParamMap.get('version') ?? '',
				hash: this.route.snapshot.queryParamMap.get('hash') ?? '',
			})
		);

		this.actionSubject.pipe(untilDestroyed(this), ofType(unsubscribed)).subscribe(() => this.unsubscribed$.next(true));

		this.store
			.select(selectUnsubscribeFailure)
			.pipe(untilDestroyed(this))
			.subscribe((errorMessage) => {
				this.errored$.next(errorMessage !== '');
			});
	}
}
