import { createAction, props } from '@ngrx/store';
import { CustomerProfile, SaveForOccasion } from '../../types';

export const getProfile = createAction('[Profile] Get profile');
export const getProfileComplete = createAction('[Profile] Get profile loaded', props<{ profile: CustomerProfile }>());

export const saveProfile = createAction('[Profile] Save profile', props<{ profile: CustomerProfile }>());
export const saveProfileComplete = createAction('[Profile] Save profile complete', props<{ previousEmail?: string }>());
export const saveProfileFailure = createAction('[Profile] Save profile failure', props<{ errorMessage: string }>());

export const getBalances = createAction('[Authentication] getBalances');

export const getBalancesComplete = createAction(
	'[Authentication] getBalances complete',
	props<{saveForOccasion:SaveForOccasion
	}>()
);
