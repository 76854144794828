import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationType, TrackingData, TrackingEvent, WithPageMeta } from '@woolworthsnz/styleguide';

@WithPageMeta({ pageTitle: 'account', metaTitle: 'account' })
@Component({
	selector: 'ss-base-account',
	template: `
		<cdx-back-nav location="." *ngIf="(hideBackButton$ | async) === false" [trackingData]="trackingData"
			>Account</cdx-back-nav
		>
		<router-outlet></router-outlet>
	`,
	styleUrls: ['./base-account.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseAccountComponent implements OnInit {
	hideBackButton$: Observable<boolean> | undefined;

	trackingData: TrackingData = {
		event: TrackingEvent.NotificationEvent,
		type: NotificationType.Link,
		name: 'back to account',
	};

	constructor(private route: ActivatedRoute, private router: Router) {}

	ngOnInit() {
		this.hideBackButton$ = this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			// startWith to check the initial load, as that doesn't fire a NavigationEnd
			startWith(() => this.shouldHideBack()),
			map(() => this.shouldHideBack())
		);
	}

	shouldHideBack() {
		return !!this.route.snapshot.firstChild?.data?.hideBack;
	}
}
