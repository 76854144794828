export enum ValidErrorTypes {
	ExceededMaxLength = 'ExceededMaxLength',
	InvalidFormat = 'InvalidFormat',
	OutOfValidRange = 'OutOfValidRange',
	Required = 'Required',
	InvalidManuelAddress = 'InvalidManuelAddress',
	InvalidAddressProviderId = 'InvalidAddressProviderId',
}

export interface ExceededMaxLength {
	type: ValidErrorTypes.ExceededMaxLength;
	maxLength: number;
}
export interface InvalidFormat {
	type: ValidErrorTypes.InvalidFormat;
	value: number;
	description?: string;
}
export interface OutOfValidRange {
	type: ValidErrorTypes.OutOfValidRange;
	value: number;
	range: {
		start: number;
		end: number;
	};
}
export interface Required {
	type: ValidErrorTypes.Required;
}

export type ValidatioError = ExceededMaxLength | InvalidFormat | OutOfValidRange | Required;

export interface InvalidValidatedAddress {
	type: ValidErrorTypes.InvalidAddressProviderId;
}
export interface InvalidManuelAddress {
	type: ValidErrorTypes.InvalidManuelAddress;
	streetAddress?: {
		addressStreet1?: ValidatioError;
		addressStreet2?: ValidatioError;
		addressUnit?: ValidatioError;
		location?: {
			city?: ValidatioError;
			suburbId?: ValidatioError;
			postCode?: ValidatioError;
		};
	};
}
export interface RegistrationErrorModel {
	companyName?: ValidatioError;
	address?: InvalidManuelAddress | InvalidValidatedAddress | Required;
}
