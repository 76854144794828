import { Injectable } from '@angular/core';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	ChildrenOutletContexts,
	NavigationExtras,
	PRIMARY_OUTLET,
	Router,
	UrlSegment,
} from '@angular/router';

export enum ModalButtonText {
	errorOk = 'Ok, got it',
}

@Injectable({
	providedIn: 'root',
})
export class ModalRoutingService {
	constructor(
		private router: Router,
		private contexts: ChildrenOutletContexts,
		private activatedRoute: ActivatedRoute
	) {}

	/**
	 * Searches up the route tree checking if any of the parents match the given outlet, meaning the activatedRoute passed in is also within the outlet
	 * @param modalOutlet the name of the outlet in the routing config
	 * @param activatedRoute the route to check
	 */
	isInModal(modalOutlet: string, activatedRoute: ActivatedRouteSnapshot): boolean {
		let routeSnapshot = activatedRoute;
		let isInModal = routeSnapshot.outlet === modalOutlet;
		while (routeSnapshot.parent) {
			if (routeSnapshot.parent.outlet === modalOutlet) {
				isInModal = true;
			}
			routeSnapshot = routeSnapshot.parent;
		}
		return isInModal;
	}

	/**
	 * Navigates to an auxiliary route for the given outlet and path, thus opening a modal
	 * @param modalOutlet the name of the outlet in the routing config
	 * @param modalRoutes the path to open in the outlet
	 */
	open(modalOutlet: string, ...modalRoutes: string[]): Promise<boolean> {
		return this.openWithOptions(modalOutlet, undefined, ...modalRoutes);
	}

	/**
	 * Navigates to an auxiliary route for the given outlet and path, thus opening a modal
	 * @param modalOutlet the name of the outlet in the routing config
	 * @param navigationExtras provides additional navigation options to be merged
	 * @param modalRoutes the path to open in the outlet
	 */
	openWithOptions(
		modalOutlet: string,
		navigationExtras: NavigationExtras | undefined,
		...modalRoutes: string[]
	): Promise<boolean> {
		const outletCommand = this.buildOutletCommand(modalOutlet, modalRoutes);
		return this.router.navigate([...this.getPrimaryOutletCurrentUrltree(modalOutlet), outletCommand], {
			queryParamsHandling: 'preserve',
			...navigationExtras,
		});
	}

	/**
	 * Close the auxiliary route for the given outlet
	 * If modalRoute is supplied only closes if the given outlet route matches the given route
	 *
	 * e.g. Given the open modal route is `(modal:modal/some-modal)` then `close('modal')` and `close('modal', 'some-modal')` will close the route,
	 * whilst `close('modal', 'some-other-modal')` will not.
	 * @param modalOutlet the name of the outlet in the routing config
	 * @param modalRoute the path of a specific modal you want to close
	 * @param navigationExtras provides additional navigation options to be merged
	 */
	close(modalOutlet: string, modalRoute?: string, navigationExtras?: NavigationExtras): void {
		if (modalRoute) {
			if (this.isOnRoute(modalOutlet, modalRoute)) {
				this.clearAuxiliaryRoute(modalOutlet, navigationExtras);
			}
		} else {
			this.clearAuxiliaryRoute(modalOutlet, navigationExtras);
		}
	}

	private buildOutletCommand(modalOutlet: string, urlPieces?: string[]): any {
		const outletCommand: any = { outlets: {} };
		if (urlPieces) {
			outletCommand.outlets[modalOutlet] = urlPieces;
		} else {
			outletCommand.outlets[modalOutlet] = null;
		}
		return outletCommand;
	}

	/**
	 *
	 * @returns the array of url segment strings representing the primary outlet path or [''](indicating not to alter primary route) if the outlet is a root context
	 */
	private getPrimaryOutletCurrentUrltree(modalOutlet: string): string[] {
		if (this.contexts.getContext(modalOutlet)) {
			// if the outlet being targeted exists in the ChildrenOutletContexts then it's a peer to primary outlet so don't need to construct the primary url tree. (This is a quirk of lazy loaded auxiliary outlets and will hopefully be fixed one day).
			return [''];
		}
		const primaryRouteSnapshot = this.router.routerState.root.children.find(
			(activatedRoute) => activatedRoute.outlet === PRIMARY_OUTLET
		)?.snapshot;

		return this.mapSegments(this.addChild(primaryRouteSnapshot, PRIMARY_OUTLET));
	}

	private addChild(r: ActivatedRouteSnapshot | undefined, outlet: string): UrlSegment[] {
		let outletPath = r?.url ?? [];
		if (r?.children && r.children.length > 0 && r.children[0].outlet === outlet) {
			outletPath = outletPath.concat(this.addChild(r.children[0], r.outlet));
		}
		return outletPath;
	}

	private mapSegments(url: UrlSegment[]): string[] {
		return url.map((urlSegment: UrlSegment) => urlSegment.path) ?? [];
	}

	private clearAuxiliaryRoute(modalOutlet: string, navigationExtras?: NavigationExtras): void {
		this.router.navigate(['', this.buildOutletCommand(modalOutlet)], {
			queryParamsHandling: 'preserve',
			...navigationExtras,
		});
	}

	private isOnRoute(modalOutlet: string, modalRoute: string): boolean {
		let leafRoute = this.searchTree(this.activatedRoute.snapshot, modalOutlet);
		if (leafRoute) {
			while (leafRoute.firstChild) {
				leafRoute = leafRoute.firstChild;
			}
			if (leafRoute.url[0].path === modalRoute) {
				return true;
			}
		}
		return false;
	}

	private searchTree(element: ActivatedRouteSnapshot, outletName: string): ActivatedRouteSnapshot | null {
		if (element.outlet === outletName) {
			return element;
		} else if (element.children !== null) {
			let i: number;
			let result: ActivatedRouteSnapshot | null = null;
			for (i = 0; result == null && i < element.children.length; i++) {
				result = this.searchTree(element.children[i], outletName);
			}
			return result;
		}
		return null;
	}
}
