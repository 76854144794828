import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { BOOSTS_FEATURE_KEY, BoostOffersState, BoostsState, OfferState } from './boosts.reducer';
import { WithRequiredProp } from '@woolworthsnz/styleguide';

export const selectBoostsState = createFeatureSelector<BoostsState>(BOOSTS_FEATURE_KEY);

export const selectOfferState = createSelector(selectBoostsState, (state: BoostsState) => state.offers);
export const selectHasOffers = createSelector(
	selectOfferState,
	(state: BoostOffersState) => !!state.value?.filter((offerState) => !!offerState.offer).length
);
export const selectIsLoadingOffers = createSelector(selectOfferState, (state: BoostOffersState) => state.isLoading);
export const selectHasLoadedOffers = createSelector(selectOfferState, (state: BoostOffersState) => state.hasLoaded);

/**
 * Select all offers, including ones that don't have the OfferResponse data loaded.
 */
export const selectOfferListIncludingPartial = createSelector(
	selectOfferState,
	(state: BoostOffersState): OfferState[] | undefined => state.value
);
export const selectOfferIncludingPartial = (id: string): MemoizedSelector<object, OfferState | undefined> =>
	createSelector(selectOfferListIncludingPartial, (offers: OfferState[] | undefined): OfferState | undefined =>
		offers?.find((offerState) => offerState.id === id)
	);

/**
 * Select only offers that have the OfferResponse data (which is needed to display an offer)
 */
export const selectOffers = createSelector(
	selectOfferState,
	(state: BoostOffersState): WithRequiredProp<OfferState, 'offer'>[] | undefined =>
		state.value.filter((offerState): offerState is WithRequiredProp<OfferState, 'offer'> => !!offerState.offer)
);

export const selectOffer = (id: string): MemoizedSelector<object, WithRequiredProp<OfferState, 'offer'> | undefined> =>
	createSelector(
		selectOffers,
		(
			offers: WithRequiredProp<OfferState, 'offer'>[] | undefined
		): WithRequiredProp<OfferState, 'offer'> | undefined => offers?.find((offerState) => offerState.id === id)
	);

export const selectIsBoostOfferActivated = (id: string): MemoizedSelector<object, boolean> =>
	createSelector(selectOfferIncludingPartial(id), (offer: OfferState | undefined) => !!offer?.isActivated);

export const selectActivatedBoostOfferIdList: MemoizedSelector<object, string[]> = createSelector(
	selectOfferListIncludingPartial,
	(offerList) => offerList?.filter((offer: OfferState) => offer.isActivated).map((offerState) => offerState.id) || []
);

export const selectIsAllOffersBoosted = createSelector(
	selectOfferState,
	(state: BoostOffersState) => !!state.value && state.value.every((item) => item.isActivated)
);

export const selectIsAllBoosting = createSelector(
	selectOfferState,
	(state: BoostOffersState) => !!state.value && state.value.every((offer) => offer.isBoosting)
);

export const selectProductOffers: MemoizedSelector<object, WithRequiredProp<OfferState, 'offer'>[] | undefined> =
	createSelector(selectOffers, (state) => state?.filter((item) => item.offer.displayLocation === 'PRODUCT_OFFERS'));

export const selectOtherOffers: MemoizedSelector<object, WithRequiredProp<OfferState, 'offer'>[] | undefined> =
	createSelector(selectOffers, (state) => state?.filter((item) => item.offer.displayLocation === 'OTHER_OFFERS'));
