<h1 i18n="@@personalDetails-title">Personal details</h1>

<form *ngIf="formGroup" [formGroup]="formGroup" (submit)="save()">
	<fieldset cdxFieldset [i18n]="'@@personalDetails-fieldset'" legend="Personal details" [hideLegend]="true">
		<dl class="personalDetails-header">
			<div class="personalDetails-headerRow">
				<dt i18n="@@personalDetails-name">Name:</dt>
				<dd class="personalDetails-name">{{ name$ | async }}</dd>
			</div>
			<div class="personalDetails-headerRow">
				<dt i18n="@@personalDetails-dob">Date of birth:</dt>
				<dd>{{ dob$ | async | date: 'dd MMMM y' }}</dd>
			</div>
		</dl>
		<p class="personalDetails-helpLink">
			<a
				[routerLink]="['', { outlets: { modal: modalRoute } }]"
				i18n="@@personalDetails-changeModal"
				[cdxTrackEvent]="{
					event: trackingEvent.NotificationEvent,
					type: notificationType.Link,
					name: 'how to change details'
				}"
				>How do I change this?</a
			>
		</p>
		<form-input formControlName="mobilePhone" inputMode="text" placeholder="e.g. 021234567" maxlength="12">
			<label i18n="@@personalDetails-mobilePhoneLabel" for="mobilePhone" label>Mobile phone</label>
			<form-input-error validation control="mobilePhone">
				<p [cdxInvalidType]="'*'" i18n="@@personalDetails-phoneFormatValidation">
					Please provide a valid NZ phone number
				</p>
			</form-input-error>
		</form-input>
		<form-input formControlName="homePhone" inputType="text" placeholder="e.g. 095123456" maxlength="12">
			<label i18n="@@personalDetails-homePhoneLabel" for="homePhone" label>Home phone</label>
			<form-input-error validation control="homePhone">
				<p [cdxInvalidType]="'*'" i18n="@@personalDetails-phoneFormatValidation">
					Please provide a valid NZ phone number
				</p>
			</form-input-error>
		</form-input>
		<button
			[disabled]="!!formGroup.invalid"
			cdxButton
			i18n="@@personalDetails-updateDetailsButton"
			type="submit"
			[buttonState]="(buttonState$ | async) || buttonState.default"
			[cdxTrackEvent]="{
				event: trackingEvent.NotificationEvent,
				type: notificationType.Button,
				name: 'update details'
			}"
		>
			Update details
			<ng-container completed>Details updated</ng-container>
		</button>
		<cdx-notification
			iconName="alert"
			colour="warning"
			content="Failed to update user details, please try again later"
			[canShow]="!!(showErrorNotification$ | async)"
		>
		</cdx-notification>
	</fieldset>
</form>
