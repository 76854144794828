import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HOW_WHERE_WHEN_PATH, ShellService, VIEW_TROLLEY_PATH } from '@woolworthsnz/shop';
import { AppSettingsService, CustomWindow, FlagKey, FlagService, WINDOW } from '@woolworthsnz/styleguide';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { appLoaded, authRefresh, EnvService, userLogOut } from './common';
import { createInstance } from '@optimizely/optimizely-sdk';

@Component({
	selector: 'ss-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
	mainNav: { url: string; text: string; subnav?: boolean }[];
	mobileNav: { url: string; text: string }[];
	timeslotRoute = `/${HOW_WHERE_WHEN_PATH}`;
	trolleyLink = VIEW_TROLLEY_PATH;
	isNavExperiment7VariationActive$: Observable<boolean>;

	constructor(
		private store: Store,
		private envService: EnvService,
		private appSettingsService: AppSettingsService,
		private shellService: ShellService,
		@Inject(WINDOW) private window: CustomWindow,
		private flagService: FlagService
	) {
		this.mobileNav = this.appSettingsService.getSetting('navs').mobileNav || [];
		this.mainNav = this.appSettingsService.getSetting('navs').main || [];
		this.isNavExperiment7VariationActive$ = this.flagService.someFlagVariationActive(FlagKey.globalNavExperiment, [
			'v1_a',
			'v1_b',
			'v1_c',
		]);

		const sdkKey = this.window.BOOTSTRAP_DATA?.optimizelyEnvironment;
		this.window.optimizelyClient = createInstance({ sdkKey });
	}

	ngOnInit() {
		this.window.addEventListener('message', this.handleMessage.bind(this));
		this.store.dispatch(appLoaded());
	}

	ngAfterViewInit() {
		this.shellService.getApplicationShell().pipe(take(1)).subscribe();
	}

	handleLogout() {
		const frame = document.getElementById('keycloakIframe') as HTMLIFrameElement;
		frame?.contentWindow?.postMessage('logoutContext', '*');
		this.store.dispatch(userLogOut());
	}

	ngOnDestroy() {
		window.removeEventListener('message', this.handleMessage);
	}

	/* eslint-disable @typescript-eslint/no-explicit-any */
	handleMessage(event: any) {
		//the only message we're interested in is refreshContext from trader or the silent login check from our own iframe
		// if (event.origin !== `${this.envService.traderApiUrl}` && event.origin !== this.window.location.origin) {
		// 	return;
		// }
		if (event.data === 'refreshContext') {
			console.log('refreshContext');
			this.shellService.getApplicationShell().pipe(take(1)).subscribe();
		} else if (event.data === 'silentLoginCheckComplete') {
			console.log('silentLoginCheckComplete');
			this.store.dispatch(authRefresh());
		}
	}
}
