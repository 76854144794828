import { Injectable } from '@angular/core';
import { ApiService } from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { EnvService } from '../../../common';
import { Store } from '../../types/stores/store';

@Injectable({
	providedIn: 'root',
})
export class StoresService {
	constructor(private api: ApiService, private envService: EnvService) {}

	getStores(): Observable<{ siteDetail: { site: Store }[] }> {
		return this.api.get(`${this.envService.storeServiceUrl}/api/v1/sites`);
	}
}
