<fieldset
  cdxFieldset
  [formGroup]="parentFormGroup"
  [legend]="title"
  [i18n]="'@@register-onecardAdvertisementScreenReaderLabel'"
>
  <p i18n="@@register-onecardAdvertisementParagraph">
    See everything you've bought before and earn rewards.
  </p>
  <form-input
    #input
    autocomplete="off"
    formControlName="loyaltyCard"
    inputType="tel"
    placeholder="9480 - 5678 - 90123"
  >
    <label i18n="@@register-loyaltyCardLabel" for="loyaltyCard" label>
      Your Onecard <span cdxOptionalText></span>
    </label>
    <form-input-error validation control="loyaltyCard">
      <p
        [cdxInvalidType]="'*'"
        i18n="@@register-invalidOnecardNumberErrorMessage"
      >
        Please enter a valid Onecard Number.
      </p>
    </form-input-error>
  </form-input>
</fieldset>
