import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'edr-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EDRSpinnerComponent {
	@Input() public color: 'primary--orange' | 'secondary--charcoal' | 'secondary--mid-grey' = 'primary--orange';
}
