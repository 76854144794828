import { HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const isStatusCode =
	(statusCode: number) =>
	<T>(source: Observable<HttpResponse<T>>) =>
		source.pipe(
			map((res) => res.status === statusCode),
			catchError((err) => of(err instanceof HttpResponse ? err.status === statusCode : false))
		);

/**
 * Returns the response if the responses status code matches, otherwise throws an error with the response
 * @param statusCode
 */
export const enforceStatusCode =
	(statusCode: number) =>
	<T>(source: Observable<HttpResponse<T>>) =>
		source.pipe(
			map((res) => (res.status === statusCode ? res : throwError(res))),
			catchError((err) => (err instanceof HttpResponse ? of(err) : throwError(err)))
		);

export function isAbsoluteLink(url: string | undefined): boolean {
	if (!url) {
		return false;
	}

	const urlInLowerCase = url?.toLocaleLowerCase();

	return urlInLowerCase.startsWith('https://') || urlInLowerCase.startsWith('http://');
}
