import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export type IconFill =
	| 'currentColor'
	| 'dark'
	| 'gray'
	| 'light'
	| 'green'
	| 'light-green'
	| 'light-gray'
	| 'alert'
	| 'error'
	| 'warning'
	| 'success'
	| 'info'
	| 'new';
export type IconSize =
	| 'tiny'
	| 'extra-small'
	| 'very-small'
	| 'small'
	| 'default'
	| 'medium'
	| 'medium-large'
	| 'large'
	| 'extra-large'
	| 'huge';

@Component({
	selector: 'cdx-svg-icon',
	template: `
		<svg
			version="1.1"
			viewbox="0 0 32 32"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
		>
			<use attr.href="#{{ name }}" attr.xlink:href="#{{ name }}"></use>
		</svg>
	`,
	styleUrls: ['./svg-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class SvgIconComponent {
	@Input()
	name: string;

	@HostBinding('attr.display')
	@Input()
	display: 'inline' | 'block' = 'block';

	@HostBinding('attr.fill')
	@Input()
	fill?: IconFill;

	@HostBinding('attr.size')
	@Input()
	size?: IconSize;
}
