import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	HostListener,
	Inject,
	Input,
	OnInit,
	Optional,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	ButtonComponent,
	BreakOnCommaPipe,
	MaybeExternalLinkDirective,
	PopupTargetDirective,
	PopupComponent,
	SvgIconComponent,
	TrackEventDirective,
	BreakPointService,
	Device,
	LocalStorageService,
	NotificationType,
	ShopperService,
	TrackingEvent,
	TRADER_BASE_URL,
} from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FulfilmentState, FulfilmentStoreService } from '../../services';
import { NgIf, AsyncPipe, DatePipe } from '@angular/common';

@UntilDestroy()
@Component({
	selector: 'fulfilment-bar',
	template: `
		<cdx-popup
			popupActiveClass="popupOpen"
			*ngIf="hasFulfilmentInfo$ | async; else loggedOut"
			[popupTracking]="{ name: 'hww', value: 'hww popup' }"
			popupId="fulfillmentBarPopup"
		>
			<a
				href="javascript:void(0);"
				class="newFulfilmentBar"
				[class.newFulfilmentBar--disabled]="disabled"
				cdxPopupTarget
			>
				<cdx-svg-icon
					class="newFulfilmentBar-icon"
					fill="currentColor"
					[name]="globalNavFulfilmentIcon"
					size="small"
				></cdx-svg-icon>
				<cdx-svg-icon
					class="newFulfilmentBar-icon newFulfilmentBar-icon--desktop"
					fill="currentColor"
					[name]="globalNavFulfilmentIcon"
					size="default"
				></cdx-svg-icon>
				<div class="newFulfilmentBar-details">
					<p class="newFulfulmentBar-method">
						<strong data-cy="method-type">
							{{ isExpressSlot ? 'Express ' + fulfilmentMethod : fulfilmentMethod
							}}<span class="newFulfilmentBar-separator">:&nbsp;</span>
						</strong>
					</p>
					<p class="newFulfilmentBar-address">
						{{ shortAddress }}
					</p>
					<a
						*ngIf="(isMobile$ | async) && !this.disabled"
						class="newFulfilmentBar-change"
						i18n="@@fulfilmentBar-changeLink"
					>
						Change
						<cdx-svg-icon name="chevron-right" size="extra-small" fill="green"></cdx-svg-icon>
					</a>
				</div>
			</a>
			<section class="newFulfilmentBar-modal" cardContent>
				<p *ngIf="selectedDate && startTime && !isExpressSlot">
					<strong>
						{{ isSlotToday ? 'Today' : (selectedDate | date: 'EEEE') }}, {{ startTime }} - {{ endTime }}
					</strong>
				</p>
				<p [innerHTML]="address | breakOnComma"></p>
				<a
					[href]="timeslotRouteUrl"
					cdxButton
					maybeExternal
					[maybeExternalCurrentTab]="externalLinksInCurrentTab"
					fillStyle="outline"
					i18n="@@fulfilmentBar-changeLink"
					aria-label="Change delivery/pickup details"
					size="small"
					[cdxTrackEvent]="{
						event: trackingEvent.NotificationEvent,
						type: notificationType.Global,
						name: 'hww',
						value: 'logged_in'
					}"
				>
					Change
				</a>
			</section>
		</cdx-popup>

		<ng-template #loggedOut>
			<a
				class="newFulfilmentBar"
				[href]="timeslotRouteUrl"
				maybeExternal
				[maybeExternalCurrentTab]="externalLinksInCurrentTab"
				[cdxTrackEvent]="{
					event: trackingEvent.NotificationEvent,
					type: notificationType.Global,
					name: 'hww',
					value: 'logged_out'
				}"
			>
				<cdx-svg-icon
					class="newFulfilmentBar-icon"
					fill="currentColor"
					name="shopping-bags"
					size="small"
				></cdx-svg-icon>
				<cdx-svg-icon
					class="newFulfilmentBar-icon newFulfilmentBar-icon--desktop"
					fill="currentColor"
					name="shopping-bags"
					size="default"
				></cdx-svg-icon>
				<div class="newFulfilmentBar-details">
					<p i18n="@@fulfilmentBar-methodTypeLoggedOut" data-cy="method-type">Pick up or delivery?</p>
				</div>
			</a>
		</ng-template>
	`,
	styleUrls: ['./fulfilment-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, PopupComponent, PopupTargetDirective, SvgIconComponent, MaybeExternalLinkDirective, ButtonComponent, TrackEventDirective, AsyncPipe, DatePipe, BreakOnCommaPipe]
})
export class FulfilmentBarComponent implements OnInit {
	@Input() timeslotRoute = '';
	@Input() externalLinksInCurrentTab = false;

	@Input()
	@HostBinding('attr.aria-disabled')
	@HostBinding('class.disabled')
	disabled = false;

	trackingEvent = TrackingEvent;
	notificationType = NotificationType;

	address: string;
	method: 'Pickup' | 'Delivery' = 'Pickup';

	selectedDate: Date | string;
	isSlotToday: Boolean;
	startTime: string;
	endTime: string;
	device: Device;
	isExpressSlot = false;

	hasFulfilmentInfo$: Observable<boolean | undefined>;
	isMobile$: Observable<boolean>;

	get shortAddress(): string {
		if (!this.address.includes(',')) {
			return this.address;
		}

		// return first part of the address if its multi-part
		return this.address?.substring(0, this.address.indexOf(','));
	}

	get fulfilmentIcon(): string {
		return this.method === 'Delivery' ? 'delivery-truck' : 'pick-up';
	}

	get fulfilmentMethod(): string {
		return this.method === 'Delivery' ? 'Delivery' : 'Pick up';
	}

	get globalNavFulfilmentIcon(): string {
		if (this.isExpressSlot) {
			return this.method === 'Delivery' ? 'express-delivery-truck' : 'express-shopping-bags';
		}

		return this.method === 'Delivery' ? 'truck-delivery' : 'shopping-bags';
	}

	get timeslotRouteUrl(): string {
		return `${this.traderBaseUrl ?? ''}/${this.timeslotRoute}`;
	}

	constructor(
		private cdr: ChangeDetectorRef,
		private fulfilmentStoreService: FulfilmentStoreService,
		private breakPointService: BreakPointService,
		private router: Router,
		private shopperService: ShopperService,
		private localStorageService: LocalStorageService,
		@Optional() @Inject(TRADER_BASE_URL) private traderBaseUrl: string
	) {
		this.isMobile$ = this.breakPointService.device$.pipe(map((device) => device === Device.MOBILE));
	}

	// The whole bar should be clickable, but only on mobile
	@HostListener('click') onComponentClick(): void {
		if (this.device === Device.MOBILE) {
			this.router.navigate([this.timeslotRoute]);
		}
	}

	ngOnInit(): void {
		this.fulfilmentStoreService.state$.pipe(untilDestroyed(this)).subscribe((fulfilmentState: FulfilmentState) => {
			this.address = fulfilmentState.address || '';
			this.method = fulfilmentState.method === 'Courier' ? 'Delivery' : fulfilmentState.method || 'Delivery';
			this.endTime = fulfilmentState.endTime || '';
			this.startTime = fulfilmentState.startTime || '';
			this.selectedDate = fulfilmentState.selectedDate || '';
			this.isSlotToday = Boolean(fulfilmentState.isSlotToday);
			this.isExpressSlot = fulfilmentState.expressFulfilment?.isExpressSlot || false;

			this.cdr.markForCheck();
		});

		this.hasFulfilmentInfo$ = this.shopperService.state$.pipe(
			map((shopperState) => shopperState.isLoggedIn || this.localStorageService.getItem('fulfilmentMethod'))
		);

		this.breakPointService.device$.pipe(untilDestroyed(this)).subscribe((device) => {
			this.device = device;
		});
	}
}
