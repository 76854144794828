import { Component, ChangeDetectionStrategy, HostBinding, Input, OnInit } from '@angular/core';

@Component({
	selector: 'form-fieldset, [cdxFieldset]',
	template: `
		<legend [class.sr-only]="hideLegend" i18n="i18n">{{ legend }}</legend>
		<ng-content></ng-content>
	`,
	styleUrls: ['./fieldset.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class FieldsetComponent implements OnInit {
	@HostBinding('attr.data-layout')
	@Input()
	layout: 'narrow' | 'medium' | 'large' = 'medium';

	@HostBinding('class.fieldset--withDivider')
	@Input()
	divider = false;

	@Input() hideLegend = false;
	@Input() i18n: string;
	@Input() legend: string;

	ngOnInit(): void {
		if (!this.i18n || !this.legend) {
			// eslint-disable-next-line no-console
			console.error('legend and internationalisation are mandatory for fieldset');
		}
	}
}
