import {
	ExpressFulfilmentAvailabilityStatusResponse,
	ExpressFulfilmentSettingsResponse,
	FulfilmentWindowSummarySlot,
	OrderViewModel,
} from '@woolworthsnz/trader-api';
import DeliveryMethodEnum = OrderViewModel.DeliveryMethodEnum;
import AvailabilityStatusEnum = ExpressFulfilmentAvailabilityStatusResponse.AvailabilityStatusEnum;
import dayjs from 'dayjs';

/**
 * Checks whether the fulfilment method is Delivery. The fulfilment method can come through
 * as a string or a number depending on the source. For example, the order API returns 0 for Courier.
 */
function isDelivery(fulfilmentMethod: DeliveryMethodEnum | number | undefined): boolean {
	if (typeof fulfilmentMethod !== 'number') {
		return fulfilmentMethod === DeliveryMethodEnum.Courier;
	}
	return fulfilmentMethod === 0;
}

export const getExpressFulfilmentMethodLabel = (fulfilmentMethod: DeliveryMethodEnum | number | undefined): string =>
	isDelivery(fulfilmentMethod) ? 'Delivery' : 'Pick up';

export const getExpressFee = (
	fulfilmentMethod: DeliveryMethodEnum | number | undefined,
	expressFulfilmentSettings: ExpressFulfilmentSettingsResponse | undefined
): number | undefined =>
	isDelivery(fulfilmentMethod)
		? expressFulfilmentSettings?.expressDeliveryFee
		: expressFulfilmentSettings?.expressPickUpFee;

export const getMinimumTimeForExpressOrdersToBeReady = (
	fulfilmentMethod: DeliveryMethodEnum | number | undefined,
	expressFulfilmentSettings: ExpressFulfilmentSettingsResponse | undefined
): number =>
	isDelivery(fulfilmentMethod)
		? expressFulfilmentSettings?.minimumTimeForExpressDeliveryOrdersToBeReady || 90
		: expressFulfilmentSettings?.minimumTimeForExpressPickupOrdersToBeReady || 60;

export const getExpressSlotDescription = (
	slot: FulfilmentWindowSummarySlot,
	expressFulfilmentSettings: ExpressFulfilmentSettingsResponse | undefined,
	fulfilmentMethod?: OrderViewModel.DeliveryMethodEnum
): string => {
	if (
		slot.status === AvailabilityStatusEnum.NoExpressWindows ||
		slot.status === AvailabilityStatusEnum.WindowLimitExceeded
	) {
		return 'Our team is currently busy';
	}

	const expressFulfilmentTime = getMinimumTimeForExpressOrdersToBeReady(fulfilmentMethod, expressFulfilmentSettings);
	const orderReadyTime = dayjs(new Date()).add(expressFulfilmentTime, 'minute').format('h:mm a');

	return `Order now for ${orderReadyTime} ${getExpressFulfilmentMethodLabel(fulfilmentMethod).toLowerCase()}`;
};

export const getExpressSlotLabel = (
	disabled: boolean,
	expressFulfilmentSettings: ExpressFulfilmentSettingsResponse | undefined,
	fulfilmentMethod?: OrderViewModel.DeliveryMethodEnum
): string => {
	if (disabled) {
		return 'Unavailable';
	}

	const expressPickupTime = getMinimumTimeForExpressOrdersToBeReady(fulfilmentMethod, expressFulfilmentSettings);
	return `${expressPickupTime} min express`;
};
