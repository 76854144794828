<ng-container [formGroup]="parentFormGroup">
	<fieldset cdxFieldset [legend]="title" [i18n]="'@@fieldset-addressCapture'">
		<button cdxButton fillStyle="link" (click)="switchToAutocomplete()" class="searchAddressBtn">
			Back to address search
		</button>
		<form-input
			autocomplete="off"
			formControlName="manualUnit"
			maxlength="55"
			[placeholder]="showPlaceHolders ? '302, Floor 3' : ''"
		>
			<label i18n="@@{{ page }}-unitlabel" for="manualUnit" label>
				Unit, floor, building name <span cdxOptionalText></span>
			</label>
		</form-input>
		<form-input
			formControlName="manualStreetAddress"
			maxlength="200"
			autocomplete="address-line1"
			[placeholder]="showPlaceHolders ? 'Main Street' : ''"
			input
			required
		>
			<label i18n="@@register-suburbLabel" for="manualStreetAddress" label> Street address </label>

			<form-input-error validation control="manualStreetAddress">
				<p [cdxInvalidType]="'required'" i18n="@@register-suburbRequiredErrorMessage">
					Please enter your address.
				</p>
			</form-input-error>
		</form-input>
		<form-input
			formControlName="manualSuburb"
			maxlength="125"
			autocomplete="address-level3"
			[placeholder]="showPlaceHolders ? 'Suburb' : ''"
			input
			required
		>
			<label i18n="@@register-suburbLabel" for="manualSuburb" label> Suburb </label>

			<form-input-error validation control="manualSuburb">
				<p [cdxInvalidType]="'required'" i18n="@@register-suburbRequiredErrorMessage">
					Please enter your suburb.
				</p>
			</form-input-error>
		</form-input>
		<form-input
			formControlName="manualTownCity"
			maxlength="125"
			autocomplete="locality administrative-area"
			[placeholder]="showPlaceHolders ? 'Town/City' : ''"
			input
			required
		>
			<label i18n="@@register-towncityLabel" for="manualTownCity" label> Town / city </label>

			<form-input-error validation control="manualTownCity">
				<p [cdxInvalidType]="'required'" i18n="@@register-townRequiredErrorMessage">
					Please enter your town / city.
				</p>
			</form-input-error>
		</form-input>
		<form-input
			cdxNumeric
			formControlName="manualPostcode"
			maxlength="4"
			autocomplete="postal-code"
			input
			size="narrow"
			type="tel"
			[placeholder]="showPlaceHolders ? '3212' : ''"
			required
		>
			<label i18n="@@register-postcodeLabel" for="manualPostcode" label> Postcode </label>

			<form-input-error validation control="manualPostcode">
				<p [cdxInvalidType]="'required'" i18n="@@register-postcodeRequiredErrorMessage">
					Please enter your postcode.
				</p>
			</form-input-error>
		</form-input>
		<form-suburb-autocomplete *ngIf="showDeliveryZone"></form-suburb-autocomplete>
	</fieldset>
</ng-container>
