import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';
import { AppSettingsService } from './app-settings.service';

@Injectable({ providedIn: 'root' })
export class RouterNavigationService {
	/**
	 * An observable which will emit changes when the base URL has changed
	 * @returns Observable<string>
	 */
	get baseUrl$(): Observable<string> {
		return this.baseUrlSubject.asObservable();
	}

	/**
	 * An observable which will emit changes when the filtered URL has changed
	 * - This filter out URL changes related to modal routing events as well as jump '#' anchor links
	 * @returns Observable<string>
	 */
	get filteredUrl$(): Observable<string> {
		return this.filteredUrlSubject.asObservable();
	}

	private previousBaseUrl: string;
	private baseUrlSubject = new Subject<string>();

	private previousFilteredUrl: string;
	private readonly modalOutletName: string;
	private filteredUrlSubject = new Subject<string>();
	private readonly modalRouteRegEx: RegExp;

	constructor(private router: Router, private location: Location, private appSettingsService: AppSettingsService) {
		this.modalOutletName = appSettingsService.getSetting('modalOutletName');
		// TODO should be able to use the actual routing events to figure out if an aux route is involved rather than regex url matching
		this.modalRouteRegEx = new RegExp(`\\(.*${this.modalOutletName}:`);

		// Start tracking from the current URL
		this.previousFilteredUrl = this.location.path(false);
		this.previousBaseUrl = this.getBaseUrl(this.location.path());

		// Initialize subject observables
		this.initBaseUrlChangedObservable();
		this.initFilteredUrlChangedObservable();
	}

	/**
	 * Returns the base URL value
	 * Base URL = the URL value before any '?' or '#' characters
	 * @example getBaseUrl('/shop/content/easter?name=bunny') // returns '/shop/content/easter'
	 *
	 * @param url a URL which might include query parameters or jump links
	 * @returns the base URL value
	 */
	getBaseUrl(url: string): string {
		// Strip any query parameters
		url = url.split('?')[0];

		// Strip any jump to anchor links
		url = url.split('#')[0];

		return url;
	}

	/**
	 * Subscribes to router navigation end events.  It filters out any route changes that's related to:
	 *  - Where the URL before any jump '#' anchor links are the same
	 *  - Where the previous Url was related to a modal route, e.g. https://www.countdown.co.nz/bookatimeslot(modal:change-pick-up-store)
	 *  - Where the new target URL will be a route to display a modal dialog
	 *  Only if none of those conditions are met will it emit a new base URL value
	 */
	private initFilteredUrlChangedObservable(): void {
		this.router.events
			.pipe(
				takeWhile((e) => !!e),
				filter((ev): ev is NavigationEnd => ev instanceof NavigationEnd),
				filter((ev) => {
					const targetPath = ev.urlAfterRedirects.split('#')[0];
					const currentPath = this.previousFilteredUrl?.split('#')[0];

					// Update the previous url
					this.previousFilteredUrl = targetPath;

					// ignore route changes going from a modal route
					if (currentPath && currentPath.match(this.modalRouteRegEx)) {
						return false;
					}
					// ignore route changes going to a modal route
					if (targetPath && targetPath.match(this.modalRouteRegEx)) {
						return false;
					}
					return targetPath !== currentPath;
				})
			)
			.subscribe((ev) => this.filteredUrlSubject.next(ev.urlAfterRedirects));
	}

	/**
	 * Subscribes to router navigation end events.  It filters out any route changes that's related to:
	 *  - Where the previous base URL is the same as the new base URL
	 *    - Base URL = the URL value before any '?' or '#' characters
	 *  - Where the new target URL will be a route to display a modal dialog
	 *  Only if none of those conditions are met will it emit a new base URL value
	 */
	private initBaseUrlChangedObservable(): void {
		this.router.events
			.pipe(
				takeWhile((e) => !!e),
				filter((ev): ev is NavigationEnd => ev instanceof NavigationEnd),
				filter((ev) => {
					const targetBaseUrl = this.getBaseUrl(ev.urlAfterRedirects);
					const currentBaseUrl = this.previousBaseUrl;

					// ignore route changes for displaying a modal dialog
					if (ev.urlAfterRedirects && ev.urlAfterRedirects.match(this.modalRouteRegEx)) {
						return false;
					}

					// Update the previous url
					this.previousBaseUrl = targetBaseUrl;

					// Ignore route changes where the base URL is the same
					return targetBaseUrl !== currentBaseUrl;
				})
			)
			.subscribe((ev) => this.baseUrlSubject.next(ev.urlAfterRedirects));
	}
}
