import { NgModule } from '@angular/core';
import { Subject } from 'rxjs';
import { AppSettingsService, RouterNavigationService, SiteManagementService } from '../services';
import { InjectorContainerModule } from './injector-container.module';

export const withPageMetaTestSubject = new Subject();

/**
 * Use this if you need to extend these overrides in your test file.
 */
export const withPageMetaAppSettingsServiceMock = {
	getSetting: () => '',
	getEndpoint: () => '',
	getPageTitle: () => 'Title',
	getMetaTitle: () => 'MetaTitle',
	getMetaDescription: () => 'MetaDescription',
	getMetaImage: () => 'MetaImage',
	getMetaUrl: () => 'MetaUrl',
};

/**
 * Import this module in unit tests which are testing components with the '@WithPageMeta' decorator
 */
@NgModule({
	imports: [InjectorContainerModule],
	providers: [
		{
			provide: SiteManagementService,
			useValue: {
				fetchCurrentPageMetaTagSettings$: () => withPageMetaTestSubject.asObservable(),
				fetchMetaTagSettings$: () => withPageMetaTestSubject.asObservable(),
			},
		},
		{
			provide: RouterNavigationService,
			useValue: {
				baseUrl$: withPageMetaTestSubject.asObservable(),
				filteredUrl$: withPageMetaTestSubject.asObservable(),
			},
		},
		{
			provide: AppSettingsService,
			useValue: withPageMetaAppSettingsServiceMock,
		},
	],
})
export class WithPageMetaTestingModule {}
