import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	forwardRef,
	HostBinding,
	Input,
	ViewChild,
} from '@angular/core';
import { SVGIcon, SvgIconComponent } from '@woolworthsnz/styleguide';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgIf, NgClass } from '@angular/common';

@Component({
	selector: 'form-detailed-selection-tile',
	templateUrl: './detailed-selection-tile.component.html',
	styleUrls: ['./detailed-selection-tile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DetailedSelectionTileComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [SvgIconComponent, NgIf, NgClass],
})
export class DetailedSelectionTileComponent implements ControlValueAccessor {
	@Input() controlName: string;
	@Input() inputValue: string;
	@Input() inputId: string;
	@Input() iconName: SVGIcon;
	@Input() title = '';
	@Input() subtitle = '';
	@Input() description = '';
	@Input() align: 'title' | 'left' = 'title';

	@HostBinding('attr.aria-label')
	get ariaLabel(): string | null {
		return this.subtitle || this.title || null;
	}
	// eslint-disable-next-line @typescript-eslint/naming-convention
	@ViewChild('input') _inputElement: ElementRef<HTMLInputElement> | undefined;

	get iconFill(): 'green' | 'gray' {
		return this.checked ? 'green' : 'gray';
	}

	get checkboxIcon(): SVGIcon.RoundCheckedFilled | SVGIcon.RoundOutline {
		return this.checked ? SVGIcon.RoundCheckedFilled : SVGIcon.RoundOutline;
	}

	get checkboxFill(): 'green' | 'light-gray' {
		return this.checked ? 'green' : 'light-gray';
	}

	private _onChangeFn: any;
	private _onTouchedFn: any;

	private _checked = false;
	private _disabled = false;

	@HostBinding('class.checked')
	@Input()
	get checked(): boolean {
		return this._checked;
	}
	set checked(value: boolean) {
		if (value !== this.checked) {
			this._checked = value;
			this.cdr.markForCheck();
		}
	}

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}
	set disabled(value: boolean) {
		const newValue = coerceBooleanProperty(value);

		if (newValue !== this.disabled) {
			this._disabled = newValue;
			this.cdr.markForCheck();
		}
	}

	constructor(private cdr: ChangeDetectorRef) {}

	registerOnChange(fn: any): void {
		this._onChangeFn = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouchedFn = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	writeValue(value: any): void {
		this.checked = value === this.inputValue;
	}

	onInputClick(event: Event): void {
		event.stopPropagation();

		if (!this.disabled) {
			if (this._onChangeFn) {
				this._onChangeFn(this.inputValue);
			}
			if (this._onTouchedFn) {
				this._onTouchedFn();
			}
		}
	}

	onInteractionEvent(event: Event): void {
		event.stopPropagation();
	}
}
