<form-autocomplete
	(typedValue)="fetchAddress($event)"
	[searchResults]="searchResults"
	[formGroup]="parentFormGroup"
	displayKey="value"
	[idPrefix]="suggestionIdentifierPrefix"
	valueKey="id"
	(optionSelect)="handleAddressSelection($event)"
	(optionFocus)="handleAddressFocus($event)"
>
	<form-input-with-icon-button
		formControlName="addressStreet1"
		maxlength="200"
		[autocomplete]="identifier"
		[id]="identifier"
		[name]="identifier"
		input
		[size]="size"
		placeholder="e.g. 32 Main Street, Suburb, Town/City"
		[activeDescendant]="focussedSuggestion"
	>
		<button
			*ngIf="hasCrossIcon"
			btn
			class="clearInputButton"
			fillStyle="flat"
			type="button"
			name="clearInput"
			attr.aria-label="clear Input"
			cdxButton
			(mousedown)="clearInput()"
			(click)="focusInput()"
		>
			&nbsp;
		</button>
		<label i18n="@@register-suburbLabel" [for]="identifier" label> Find your street address </label>
		<form-input-error validation control="addressStreet1">
			<p [cdxInvalidType]="'addressNotSelected'" i18n="@@register-suburbRequiredErrorMessage">
				<span *ngIf="typedValue">No exact match found for {{ typedValue }}</span>
			</p>
			<p [cdxInvalidType]="'required'" i18n="@@register-suburbRequiredErrorMessage">
				Please enter a street address
			</p>
		</form-input-error>
	</form-input-with-icon-button>

	<button
		align="left"
		cdxButton
		footer
		fillStyle="flat"
		fullWidth="true"
		footerButton="true"
		(click)="switchToManualEntry()"
	>
		Can't find your address?
	</button>
</form-autocomplete>

<address attr.aria-live="polite" *ngIf="addressSummary?.length" attr.aria-label="Selected address">
	<div *ngFor="let address of addressSummary">{{ address }}</div>
</address>
<button
	cdxButton
	class="manualAddressLink"
	fillStyle="flat"
	fullWidth="true"
	(click)="switchToManualEntry()"
	*ngIf="showManualAdressLink"
>
	Enter address manually
</button>
