<h1>Manage email and password</h1>
<ng-container>
    <form [formGroup]="emailPasswordForm" class="form-content reset-email-form" (submit)="save()">
        <div>
            <h2>Change Email</h2>
            <p *ngIf="showParagraph">
                Updating your email address here will update your login email for Woolworths and Everyday Rewards.
            </p>
            <cdx-alert
                    class="reset-email-error"
                    type="error"
                    [title]="changeEmailErrorTitle"
                    [description]="changeEmailErrorMsg"
                    [candismiss]="true"
                    data-testid="updateEmailError"
                    *ngIf="updateEmailFailed$ | async">
            </cdx-alert>
            <cdx-alert
                    class="reset-email-success"
                    type="success"
                    icon="tick-sign"
                    [title]="changeEmailSuccessTitle"
                    [description]="changeEmailSuccessMsg"
                    [candismiss]="true"
                    data-testid="updateEmailSuccess"
                    *ngIf="updateSuccess$ | async"
            >
            </cdx-alert>

            <fieldset
                    class="reset-email-fieldset"
                    cdxFieldset
                    [divider]="false"
                    [i18n]="'@@register-enterEmailLabel'"
            >
                <form-input
                        class="reset-email-input"
                        autocomplete="reset-email"
                        formControlName="changeEmailInput"
						#changeEmailInput
                        data-cy="'changeEmailInput'"
                        inputType="email"
                        data-testid="changeEmailInput"
                        fullWidth="true"
                        placeholder="Please enter email address"
                >
                    <label for="changeEmailInput" label>Email</label>
                </form-input>
                <form-input-error validation control="changeEmailInput" data-cy="'email_err'">
                    <p [cdxInvalidType]="'*'" i18n="@@register-invalidEmail" data-testid="emailError">
                        Please enter a valid email address.
                    </p>
                </form-input-error>
            </fieldset>
        </div>

        <div class="form-actions">
            <button
                    cdxButton
                    class="submit-button"
                    value="Submit"
                    tabindex="0"
                    data-cy="'submit'"
                    data-testid="updateEmailButton"
                    [disabled]="!enableEmail"
            >
                Update email
            </button>
        </div>
    </form>
</ng-container>
<ng-container>
    <h2>Reset Password</h2>
    <p>
        You can <span class="reset-password-link" (click)="resetPassword()" data-testid="resetPasswordLink">reset
        your password here</span>
    </p>
</ng-container>

