import { of } from 'rxjs';

export const mockAuthFacade = {
	isLoggedIn$: of(true),
	email$: of('test@user.com'),
	logOut: (): void => {
		/* Mock Log Out */
	},
	initialise: (): void => {
		/* Mock Initialise */
	},
	refreshAuthToken: (): void => {
		/* Mock Refresh Auth Token */
	},
};
