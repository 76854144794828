import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction } from '@angular/core';
import { NotificationType, TrackingEvent } from '@woolworthsnz/styleguide';
import { AccountLinks, AccountSubLinks } from '../../types';

@Component({
	selector: 'ss-account-nav',
	templateUrl: './account-nav.component.html',
	styleUrls: ['./account-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountNavComponent {
	@Input() links: AccountLinks[] | null = [];
	@Input() isShopper: boolean | null = false;
	@Output() signOut = new EventEmitter<void>();

	trackingEvent = TrackingEvent;
	notificationType = NotificationType;

	trackByGroupFn: TrackByFunction<AccountLinks> = (_, group) => group.label;
	trackByLinkFn: TrackByFunction<AccountSubLinks> = (_, link) => link.url;

	get filteredLinks() {
		return (
			this.links?.map((link) => ({
				...link,
				items: link.items.filter((li) => this.shouldAppear(li)),
			})) || []
		);
	}

	getExternalUrl(link: AccountSubLinks) {
		return `${link.url}`;
	}

	private shouldAppear(link: AccountSubLinks) {
		return (
			(link.shopper === undefined || link.shopper === this.isShopper)
		);
	}
}
