import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { routeNames } from '../../app.routes';
import { authInitialise } from '../state';
import { Store } from '@ngrx/store';

@Injectable()
export class CredentialInterceptor implements HttpInterceptor {
	constructor(private router: Router, private store: Store) {}

	private handleAuthError(err: HttpErrorResponse): Observable<HttpEvent<unknown>> {
		if (err.status === 401 || err.status === 403) {
			this.store.dispatch(authInitialise());
			this.router.navigateByUrl(routeNames.account);
			return EMPTY;
		}
		return throwError(err);
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (['PUT', 'POST'].includes(request.method)) {
			request = request.clone({
				withCredentials: true,
			});
		}
		return next.handle(request).pipe(catchError((err) => this.handleAuthError(err)));
	}
}
