import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, createFeature } from '@ngrx/store';
import { updatePreferences } from '.';
import { CustomerPreference } from '../../types';
import {
	getPreferencesComplete,
	unsubscribeFailure,
	updatePreference,
	updatePreferenceComplete,
} from './preferences.actions';

export interface PreferenceState extends EntityState<CustomerPreference> {
	loading: boolean;
	updating: boolean;
	errorMessage: string;
}

export const adapter: EntityAdapter<CustomerPreference> = createEntityAdapter<CustomerPreference>();

export const initialState: PreferenceState = adapter.getInitialState({
	loading: true,
	updating: false,
	errorMessage: '',
});

export const accountPreferencesFeature = createFeature({
	name: 'accountPreferences',
	reducer: createReducer(
		initialState,
		on(updatePreference, (state): PreferenceState => ({ ...state, updating: true })),
		on(updatePreferences, (state): PreferenceState => ({ ...state, updating: true })),
		on(
			updatePreferenceComplete,
			(state, { preferences }): PreferenceState => adapter.setMany(preferences, { ...state, updating: false })
		),
		on(
			getPreferencesComplete,
			(state, { preferences }): PreferenceState => adapter.setAll(preferences, { ...state, loading: false })
		),
		on(unsubscribeFailure, (state, { errorMessage }): PreferenceState => ({ ...state, errorMessage: errorMessage }))
	),
});

export const { name, reducer } = accountPreferencesFeature;
