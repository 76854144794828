import { Highlightable } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'cdx-option-list-item',
	template: `
		<li
			[id]="id"
			[class.disabled]="disabled"
			[ngClass]="{ disabled: disabled, noPadding: noPadding }"
			role="option"
		>
			<ng-content></ng-content>
		</li>
	`,
	styleUrls: ['./option-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass],
})
export class OptionListItemComponent implements Highlightable {
	@Input() disabled = false;
	@Input() itemLabel: string;
	@Input() noPadding = false;
	@Input() id: string;

	private active = false;

	@HostBinding('class.active')
	get isActive(): boolean {
		return this.active;
	}

	setActiveStyles(): void {
		this.active = true;
	}

	setInactiveStyles(): void {
		this.active = false;
	}

	getLabel(): string {
		return this.itemLabel || '';
	}
}
