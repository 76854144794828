import { Inject, Injectable } from '@angular/core';
import {
	Client,
	createInstance,
	EventTags,
	OptimizelyDecision,
	OptimizelyUserContext,
	setLogLevel,
	UserAttributes,
} from '@optimizely/optimizely-sdk';
import { ContextResponse } from '@woolworthsnz/trader-api';
import { switchMap, take, tap, filter, throwError, from, Observable, map } from 'rxjs';
import { FeatureService } from '../feature.service';
import { LoggingService } from '../logging.service';
import { CustomWindow, WINDOW } from '../window.service';
import { FlagStore } from './flag.store';
import { FlagKey } from './flag-key.enum';
import { DatalayerService } from '../datalayer.service';
import { CookieAppSettingsService } from '../cookie-app-settings.service';
import { UserAttributesService } from './user-attributes.service';
import { AATestForFeatureExperimentationVariants } from './experiment-data/aa-test-for-feature-experimentation';

const OPTIMIZELY_TIMEOUT = 3000;
const NO_ACTIVE_VARIATION = 'off';
const DATA_LAYER_EVENT_VALUE = 'campaign-decided-fullstack';

type VariationKey = string | 'off';

/**
 * This service implements Optimizely Feature Experimentation (previously Optimizely Full Stack)
 *
 * Usage:
 * 1. Setup your experiment in the Optimizely dashboard
 * 2. Add the key of your flag to the FlagKey enum
 * 3. In your component or whereever you need it, call one of the public methods of this class with the flag key to get the desired info
 *
 * Bucketing:
 * If the user matches the audience conditions, the user is bucketed based on their user id (which comes from the cookie 'appsettings-browserSessionId').
 * Once bucketed, the result is persisted on Optimizely's side so a user will always see the variation.
 *
 * Force bucketing:
 * When testing or developing an flag/experiment it can be useful to force yourself into a specific variation.
 * Add this query parameter to the url to force bucket yourself: ?force-variations=flagKey/variationKey,anotherFlagKey/control
 *
 * Audiences:
 * You can add an audience rule to your flag, which will only enable the flag for a specific audience. You can easily create your own
 * audience based on any of the user attributes, or use one of the predefined audiences such as 'Logged in users', or 'Mobile app users'.
 *
 * Force attributes:
 * When testing or developing a flag/experiment it can be useful to force yourself into a specific audience. To do so you can force/override
 * any user attributes that will be sent to Optimizely to determine in what audience you are.
 * Add this query parameter to the url to force bucket yourself: ?force-attributes=attributeName/attributeValue,anotherAttribute/value
 *
 * Debugging:
 * If you need to debug anything related to Optimizely you can customise the log level by passing in a query param.
 * The log levels are: NOTSET, DEBUG, INFO, WARNING, ERROR (default).
 * This will work in all environments: ?optimizely-log-level=DEBUG
 *
 * Pro tips:
 * - When setting up the code for your flag/experiment, write your code in such a way that it will be easy to clean up afterwards. also for
 * someone who doesn't know anything about the experiment. Try to keep your experiment code as isolated as possible.
 * - Always use 'control' as the key for your control variation.
 * - Using the predefined 'Off' variation is tricky as it will set decision.enabled to false, thus potentially having uninteded effects on metrics.
 * - Use the word variation (and not variant) to avoid confusion and mix-ups. They mean the same thing, but just for consistency.
 * - Try to keep all experiment/flag data in the folder experiment-data so it's easier to clean up when the experiment is done.
 */
@Injectable({
	providedIn: 'root',
})
export class FlagService {
	private optimizelyClient: Client | null | undefined;
	private user: OptimizelyUserContext | null | undefined;
	// For A/A Experiment
	private prevUserId: string;

	constructor(
		private readonly store: FlagStore,
		@Inject(WINDOW) private window: CustomWindow,
		private cookieAppSettingsService: CookieAppSettingsService,
		private featureService: FeatureService,
		private dataLayerService: DatalayerService,
		private loggingService: LoggingService,
		private userAttributesService: UserAttributesService
	) {}
	/**
	 * This is currently called in the app.startup.service to speed things up when a flag decision needs to be made
	 */
	public init(): void {
		const isFeatureEnabled = this.featureService
			.isEnabled(ContextResponse.EnabledFeaturesEnum.Optimizely)
			.pipe(take(1));

		const sdkKey = this.window?.BOOTSTRAP_DATA?.optimizelyEnvironment;
		if (!!this.optimizelyClient || !sdkKey) {
			return;
		}

		isFeatureEnabled
			.pipe(
				tap((isEnabled) => {
					this.store.updateEnabled(isEnabled);
					if (!isEnabled) {
						this.store.updateInitialised();
					}
				}),
				filter(Boolean),
				switchMap(() => {
					setLogLevel(this.userAttributesService.getLogLevel());
					this.store.loadUserAttributes();
					this.optimizelyClient = createInstance({
						sdkKey,
						eventBatchSize: 10,
						eventFlushInterval: 1000,
					});
					if (!this.optimizelyClient) {
						const errorMessage = 'Error creating optimizelyClient instance';
						this.handleError(errorMessage);
						return throwError(() => new Error(errorMessage));
					}

					return from(this.optimizelyClient.onReady({ timeout: OPTIMIZELY_TIMEOUT })).pipe(
						map(({ success, reason }) => {
							if (!!reason) {
								throw new Error(reason);
							}
							return success;
						})
					);
				}),
				switchMap(() => this.userAttributesService.getAttributes()),
				take(1)
			)
			.subscribe({
				next: (userAttributes) => {
					const userId = this.cookieAppSettingsService.getBrowserSessionId();
					this.user = (this.optimizelyClient as Client).createUserContext(userId, userAttributes);
					this.applyUserAttributes();
					this.applyForcedVariations();
					this.store.updateInitialised();
					if (!!this.user && !!this.optimizelyClient && userId !== this.prevUserId) {
						this.logDataForAATestExperiment(userId, this.optimizelyClient);
					}
				},
				error: (error: string) => {
					this.handleError(error);
					this.store.updateInitialised();
				},
			});
	}

	/**
	 * Returns 'off' when flag is not active or if Optimizely failed to initialise, otherwise returns the variation key
	 *
	 * Example usage:
	 * Component class:
	 * public myFlagVariation$: Observable<string> = this.flagService.getVariationKey('myFlagExperiment');
	 *
	 * Template:
	 * <ng-container *ngIf="myFlagVariation$ | async as myFlagVariation; else loading">
	 *   <div *ngIf="myFlagVariation === 'v1'">This is variation 1</div>
	 *   <div *ngIf="myFlagVariation === 'v2'">This is variation 2</div>
	 * 	 <div *ngIf="myFlagVariation === 'control' || myFlagVariation === 'off'">This is the control variation</div>
	 * </ng-container>
	 * <ng-template #loading>Optionally display content or spinner while loading<ng-template>
	 */
	public getVariationKey<T = VariationKey>(flagKey: FlagKey): Observable<T> {
		return this.getFlagDecision(flagKey).pipe(
			map((decision) => (decision?.variationKey || NO_ACTIVE_VARIATION) as T)
		);
	}

	public getVariables<T = OptimizelyDecision['variables']>(flagKey: FlagKey): Observable<T> {
		return this.getFlagDecision(flagKey).pipe(map((decision) => decision?.variables as T));
	}

	public isFlagEnabled(flagKey: FlagKey): Observable<boolean> {
		return this.getFlagDecision(flagKey).pipe(map((decision) => !!decision?.enabled));
	}

	public isInactiveOrControl(flagKey: FlagKey): Observable<boolean> {
		return this.getFlagDecision(flagKey).pipe(
			map((decision) => !decision?.variationKey || decision.variationKey === 'control')
		);
	}

	public someFlagVariationActive(flagKey: FlagKey, variationKeys: string[]): Observable<boolean> {
		return this.getFlagDecision(flagKey).pipe(
			map((decision) => !!decision?.variationKey && variationKeys.includes(decision.variationKey))
		);
	}

	/**
	 * Returns decision from state if it exists there, otherwise calls the decide method.
	 * Also makes sure the flag activation is tracked when decide is called.
	 * @param flagKey
	 * @returns OptimizelyDecision that contains bucketing information
	 * @returns null when the user is not bucketed for this flag (for example: when Optimizely failed to initialise or is not enabled)
	 */
	public getFlagDecision(flagKey: FlagKey): Observable<OptimizelyDecision | null> {
		return this.store.state$.pipe(
			filter((state) => state.initialised),
			take(1),
			map((state) => {
				if (!state.enabled || state.error) {
					return null;
				}
				if (!!state.flags && state.flags[flagKey]) {
					return state.flags[flagKey] as OptimizelyDecision | null;
				}
				const decision = this.user?.decide(flagKey) || null;

				if (!!decision?.variationKey) {
					this.trackExperiment({ flagKey, variationKey: decision.variationKey });
				}
				this.store.addFlag({ flagKey, decision });
				return decision;
			})
		);
	}

	/**
	 * Use this method to track any metrics for your experiment. This allows you to track the experiment results
	 * in the Optimizely dashboard.
	 */
	public trackEvent({ eventKey, eventTags }: { eventKey: string; eventTags?: EventTags }): void {
		if (!this.user?.getUserId) {
			return;
		}
		this.optimizelyClient?.track(eventKey, this.user?.getUserId(), this.user?.getAttributes(), eventTags);
	}

	private trackExperiment({ flagKey, variationKey }: { flagKey: FlagKey; variationKey: string }): void {
		this.dataLayerService.pushToDatalayer({
			event: DATA_LAYER_EVENT_VALUE,
			optimizelyDimensionValue: `${flagKey}:${variationKey}`,
		});
	}

	private handleError(error: string): void {
		this.store.updateError(error);
		this.loggingService.error(error);
	}

	private applyUserAttributes(): void {
		this.store.selectUserAttributes$.pipe(take(1)).subscribe((userAttributes) => {
			if (!this.user || !userAttributes) {
				return;
			}
			this.updateUserAttributes(userAttributes);
		});
	}

	private updateUserAttributes(userAttributes: UserAttributes): void {
		Object.keys(userAttributes).forEach((key) => {
			this.user?.setAttribute(key, userAttributes[key]);
		});
	}

	private applyForcedVariations(): void {
		this.userAttributesService.getForcedVariations().forEach(({ flagKey, variationKey }) => {
			this.user?.setForcedDecision({ flagKey }, { variationKey });
		});
	}

	private logDataForAATestExperiment(userIdInLocal: string, optimizelyClient: Client): void {
		this.getVariationKey(FlagKey.aaTestForFeatureExperimentation)
			.pipe(
				take(1),
				filter(
					(variationKey) =>
						variationKey === AATestForFeatureExperimentationVariants.Control ||
						variationKey === AATestForFeatureExperimentationVariants.VariationOne ||
						variationKey === AATestForFeatureExperimentationVariants.VariationTwo
				)
			)
			.subscribe((variationKey) => {
				// We need to log here for the A/A experiment in order to track if the Optimizely data is consistent to avoid this double charging issue.
				/* eslint-disable no-console */
				console.log(
					`Optimizely Feature Experimentation - Starting A/A Test - ${
						variationKey.charAt(0).toUpperCase() + variationKey.slice(1).replace('_', ' ')
					}`
				);
				const userId = this.user?.getUserId() ?? '';
				console.log(`Optimizely Feature Experimentation - User Id from Optimizely SDK: ${userId}`);
				console.log(`Optimizely Feature Experimentation - User Id in local storage / cookie: ${userIdInLocal}`);
				console.log(
					`Optimizely Feature Experimentation - Revision: ${
						optimizelyClient?.getOptimizelyConfig()?.revision
					}`
				);
				/* eslint-enable no-console */
				this.prevUserId = userId;
			});
	}
}
