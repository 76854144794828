import { BACKSPACE, DELETE, ENTER, ESCAPE, SHIFT, TAB } from '@angular/cdk/keycodes';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	OnInit,
	AfterViewInit,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	UntypedFormControl,
	UntypedFormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppSettingsService, NumericDirective } from '@woolworthsnz/styleguide';
import dayjs from 'dayjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { dateValidator } from '../../validators';
import { InputComponent } from '../input/input.component';

@UntilDestroy()
@Component({
	selector: 'form-dob-input',
	templateUrl: './dob-input.component.html',
	styleUrls: ['./dob-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: DobInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: DobInputComponent,
			multi: true,
		},
	],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, NumericDirective, InputComponent],
})
export class DobInputComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator {
	@ViewChild('day', { static: true }) dayField: ElementRef;
	@ViewChild('month', { static: true }) monthField: ElementRef;
	@ViewChild('year', { static: true }) yearField: ElementRef;

	onTouched: Function;

	get dayName(): string {
		return 'dobDay';
	}

	get monthName(): string {
		return 'dobMonth';
	}

	get yearName(): string {
		return 'dobYear';
	}

	get isOver18Name(): string {
		return 'isOver18';
	}

	get isOver18(): boolean {
		return this.dobForm.valid && Number(this.calculateAge()) >= 18;
	}

	constructor(private appSettingsService: AppSettingsService) {}
	// eslint-disable-next-line @typescript-eslint/member-ordering
	dobForm = new UntypedFormGroup(
		{
			[this.dayName]: new UntypedFormControl('', {
				validators: [Validators.required, Validators.min(1), Validators.max(31)],
			}),
			[this.monthName]: new UntypedFormControl('', {
				validators: [Validators.required, Validators.min(1), Validators.max(12)],
			}),
			[this.yearName]: new UntypedFormControl('', {
				validators: [Validators.required, Validators.min(1901), Validators.max(new Date().getFullYear())],
			}),
		},
		{ validators: <any>dateValidator(this.appSettingsService.getSetting('styleGuideConfig')?.minimumAge) }
	);

	@HostListener('keyup', ['$event.target.name', '$event.target.value', '$event.keyCode'])
	handleKeyUp(name: string, value: string | any[], keyCode: number): void {
		if (
			value.length < 2 ||
			name === this.yearName ||
			[DELETE, BACKSPACE, TAB, ESCAPE, ENTER, SHIFT].indexOf(keyCode) !== -1
		) {
			return;
		}

		if (name === this.dayName) {
			this.monthField.nativeElement.focus();
		} else if (name === this.monthName) {
			this.yearField.nativeElement.focus();
		}
	}

	calculateAge(): number {
		const dob = dayjs()
			.date(this.dobForm.get(this.dayName)?.value)
			.month(this.dobForm.get(this.monthName)?.value - 1)
			.year(this.dobForm.get(this.yearName)?.value);

		return Math.floor(Math.abs(dob.diff(dayjs(), 'year', true)));
	}

	ngOnInit(): void {
		this.dobForm.registerControl(this.isOver18Name, new UntypedFormControl({ value: null, disabled: true }));
	}

	ngAfterViewInit(): void {
		this.dobForm.valueChanges
			.pipe(untilDestroyed(this), distinctUntilChanged(), skip(1))
			.subscribe(() => this.updateOver18Checkbox());
	}

	updateOver18Checkbox(): void {
		// If the form is currently unchecked (and valid and age is over 18), then go ahead and check it
		if (this.dobForm.valid && !this.isOver18CheckboxChecked() && this.isOver18) {
			this.dobForm.controls[this.isOver18Name].patchValue(true, { emitEvent: false });
			// eslint-disable-next-line brace-style
		}
		// If the form is checked (or invalid or under 18), then go ahead and uncheck it
		else if (this.isOver18CheckboxChecked() && (this.dobForm.invalid || !this.isOver18)) {
			this.dobForm.controls[this.isOver18Name].patchValue(false, { emitEvent: false });
		}
	}

	isOver18CheckboxChecked(): boolean {
		return this.dobForm.controls[this.isOver18Name].value;
	}

	writeValue(val: any): void {
		if (val) {
			this.dobForm.setValue(val, { emitEvent: true });
		}
	}

	registerOnChange(fn: any): void {
		this.dobForm.valueChanges.subscribe(fn);
	}

	registerOnTouched(fn: Function): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		isDisabled ? this.dobForm.disable() : this.dobForm.enable();
	}

	validateForm(): void {
		this.dobForm.updateValueAndValidity();
	}

	validate(): ValidationErrors | null {
		return this.dobForm.valid ? null : this.dobForm.errors;
	}
}
