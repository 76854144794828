import { createReducer, on, Action } from '@ngrx/store';
import * as BoostsActions from './boosts.actions';
import { BoostOfferResponse } from '@woolworthsnz/trader-api';
import { BoostOfferStatus } from '../models';

export const BOOSTS_FEATURE_KEY = 'boosts';

export interface BoostOffersState {
	isLoading: boolean;
	isUpdating: boolean;
	hasError: boolean;
	hasLoaded: boolean;
	value: OfferState[];
}

export interface OfferState {
	id: string;
	offer?: BoostOfferResponse;
	isActivated: boolean;
	isBoosting: boolean;
	hasError: boolean;
}

export interface BoostsState {
	offers: BoostOffersState;
}

export const boostsInitialState: BoostsState = {
	offers: {
		isLoading: false,
		isUpdating: false,
		hasError: false,
		hasLoaded: false,
		value: [],
	},
};

/**
 * Loops over the list of activated order id's and mapping that to OfferState so we can merge it into the offers array.
 */
export function mergeActivatedBoostOffers(state: BoostsState, activatedOfferIdList: string[]): OfferState[] {
	return activatedOfferIdList.map((offerId) => {
		const offerInState: OfferState | undefined = state.offers.value.find((offerState) => offerState.id === offerId);
		return offerInState
			? {
					...offerInState,
					isActivated: true,
					isBoosting: false,
					hasError: false,
					...(offerInState.offer
						? {
								offer: {
									...offerInState.offer,
									offerStatus: BoostOfferStatus.Activated,
								},
						  }
						: {}),
			  }
			: {
					id: offerId,
					isBoosting: false,
					hasError: false,
					isActivated: true,
			  };
	});
}

function mergeActivatedBoostOfferList(state: BoostsState, activatedOfferIdList: string[]): OfferState[] {
	const updatedOffers = mergeActivatedBoostOffers(state, activatedOfferIdList);
	const res = updatedOffers.reduce((acc, curr) => {
		const originalPos = acc.findIndex((originalOfferState) => originalOfferState.id === curr.id);

		if (originalPos === -1) {
			return [...acc, curr];
		}
		const result = [...acc];
		result.splice(originalPos, 1, curr);

		return result;
	}, state.offers.value);

	return res;
}

/**
 * FYI:
 * Generally the boost offers are loaded into the state with the loadBoostOffersComplete action.
 * But when a product is added to the cart by the user it may also have autoboosted one or more offerIds.
 * We then update the state to indicate that this offerId has been boosted, but at that point we don't have the BoostOfferResponse
 * for this offerId, unless the boost offers list was loaded beforehand.
 * Don't worry, the selectors have this scenario covered and will filter out boosts without BoostOfferResponse.
 */
const reducer = createReducer(
	boostsInitialState,
	on(
		BoostsActions.loadFirstTimeBoost,
		(state: BoostsState): BoostsState => ({
			...state,
		})
	),
	on(
		BoostsActions.loadBoostOffers,
		(state: BoostsState): BoostsState => ({
			...state,
			offers: { ...state.offers, isLoading: true, hasError: false },
		})
	),
	on(
		BoostsActions.loadBoostOffersComplete,
		(state: BoostsState, { offers }): BoostsState => ({
			...state,
			offers: {
				...state.offers,
				value: offers.map((offer) => ({
					offer,
					id: offer.id,
					isBoosting: false,
					hasError: false,
					isActivated: offer.offerStatus !== BoostOfferStatus.NotActivated,
				})),
				isLoading: false,
				hasLoaded: true,
				hasError: false,
			},
		})
	),
	on(
		BoostsActions.loadBoostOffersFailed,
		(state: BoostsState): BoostsState => ({
			...state,
			offers: { ...state.offers, isLoading: false, hasLoaded: true, hasError: true },
		})
	),
	on(
		BoostsActions.loadBoostOffer,
		(state: BoostsState): BoostsState => ({
			...state,
			offers: { ...state.offers, isLoading: true, hasError: false },
		})
	),
	on(BoostsActions.loadBoostOfferComplete, (state: BoostsState, { offer }): BoostsState => {
		const updatedOfferState = [...state.offers.value];
		const mappedOffer = {
			offer: { ...offer },
			id: offer.id,
			isActivated: offer.offerStatus !== BoostOfferStatus.NotActivated,
			isBoosting: false,
			hasError: false,
		};
		const targetOffer = updatedOfferState.find((x) => x.offer?.id === offer.id);
		if (targetOffer) {
			updatedOfferState.splice(updatedOfferState.indexOf(targetOffer), 1, mappedOffer);
		} else {
			updatedOfferState.push(mappedOffer);
		}

		return {
			...state,
			offers: {
				...state.offers,
				value: updatedOfferState,
				isLoading: false,
				hasLoaded: true,
				hasError: false,
			},
		};
	}),
	on(
		BoostsActions.loadBoostOfferFailed,
		(state: BoostsState): BoostsState => ({
			...state,
			offers: { ...state.offers, isLoading: false, hasLoaded: true, hasError: true },
		})
	),
	on(
		BoostsActions.activateBoostOffer,
		(state: BoostsState, { id }): BoostsState => ({
			...state,
			offers: {
				...state.offers,
				value: state.offers.value.map((item) =>
					item.id === id ? { ...item, isBoosting: true, hasError: false } : item
				),
			},
		})
	),
	on(
		BoostsActions.activateBoostOfferComplete,
		(state: BoostsState, { activatedOfferIdList }): BoostsState => ({
			...state,
			offers: {
				...state.offers,
				value: mergeActivatedBoostOfferList(state, activatedOfferIdList),
			},
		})
	),
	on(
		BoostsActions.markBoostOffersAsActivated,
		(state: BoostsState, { activatedOfferIdList }): BoostsState => ({
			...state,
			offers: {
				...state.offers,
				value: mergeActivatedBoostOfferList(state, activatedOfferIdList),
			},
		})
	),
	on(
		BoostsActions.activateBoostOfferFailed,
		(state: BoostsState, { id }): BoostsState => ({
			...state,
			offers: {
				...state.offers,
				value: state.offers.value.map((item) =>
					item.id === id ? { ...item, isBoosting: false, hasError: true } : { ...item }
				),
			},
		})
	),
	// Can we get rid of this?
	on(
		BoostsActions.markBoostOfferAsActivated,
		(state: BoostsState, { id }): BoostsState => ({
			...state,
			offers: {
				...state.offers,
				value: state.offers.value.map((item) =>
					item.offer?.id === id
						? { ...item, offer: { ...item.offer, offerStatus: BoostOfferStatus.Activated } }
						: item
				),
			},
		})
	),
	on(
		BoostsActions.activateAllBoostOffers,
		(state: BoostsState): BoostsState => ({
			...state,
			offers: {
				...state.offers,
				value: state.offers.value.map((item) =>
					!item.isActivated ? { ...item, isBoosting: true, hasError: false } : { ...item }
				),
				isUpdating: true,
				hasError: false,
			},
		})
	),
	on(
		BoostsActions.activateAllBoostOffersComplete,
		(state: BoostsState): BoostsState => ({
			...state,
			offers: {
				...state.offers,
				value: state.offers.value.map((item) => ({
					...item,
					...(item.offer
						? {
								offer: {
									...item.offer,
									offerStatus: BoostOfferStatus.Activated,
								},
						  }
						: {}),
					isActivated: true,
					isBoosting: false,
					hasError: false,
				})),
				isUpdating: false,
				hasError: false,
			},
		})
	),
	on(
		BoostsActions.activateAllBoostOffersFailed,
		(state: BoostsState): BoostsState => ({
			...state,
			offers: {
				...state.offers,
				value: state.offers.value.map((item) =>
					!item.isActivated ? { ...item, isBoosting: false, hasError: true } : { ...item }
				),
				isUpdating: false,
				hasError: true,
			},
		})
	)
);

export function boostsReducer(state: BoostsState | undefined, action: Action): BoostsState {
	return reducer(state, action);
}
