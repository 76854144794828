import { Store } from '@ngrx/store';
import {
	AppSettingsService,
	CookieAppSettingsService,
	CustomWindow,
	FlagService,
	ShopperService,
	WINDOW,
	WindowInnerHeightService,
} from '@woolworthsnz/styleguide';
import { EnvService } from '.';
import { authInitialise } from '../state';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { FulfilmentService } from '@woolworthsnz/fulfilment';
import { EDRStartupService } from '@woolworthsnz/everyday-rewards';

export const startupFactory =
	(
		store: Store,
		envService: EnvService,
		appSettingsService: AppSettingsService,
		flagService: FlagService,
		router: Router,
		tealiumService: TealiumUtagService,
		fulfilmentService: FulfilmentService,
		shopperService: ShopperService,
		cookieAppSettingsService: CookieAppSettingsService,
		window: CustomWindow,
		windowInnerHeightService: WindowInnerHeightService,
		edrStartupService: EDRStartupService
	) =>
	() => {
		const traderApiUrl = envService.traderApiUrl;
		const bffApiUrl = envService.bffApiUrl;
		appSettingsService.setState({
			apiUrl: traderApiUrl,
			bffApiUrl: bffApiUrl,
			tokenAuthExclusions: [
				'/api/v1/shell',
				'/api/v1/products/search-suggestions',
				// TODO CO-11399 fix mobile api auth and remove from this list
				'https://customer-preferences.qa.cdx.nz/api/v2',
				envService.storeServiceUrl,
				envService.ssoServiceUrl,
			],
		});

		cookieAppSettingsService.setBrowserSessionId();
		flagService.init();
		store.dispatch(authInitialise());

		if (window && window.BOOTSTRAP_DATA && window.BOOTSTRAP_DATA.Tealium) {
			tealiumService.setConfig(window.BOOTSTRAP_DATA.Tealium);
		}
		router.events.subscribe((routerEvent) => {
			if (routerEvent instanceof NavigationEnd) {
				tealiumService.view({
					interaction_source: 'page',
					page_previous: cookieAppSettingsService.getCookieSetting('previousUrl') || '',
					page_currency: 'NZD',
					page_path: routerEvent.url,
					...fulfilmentService.getAnalyticsData(),
					...shopperService.getAnalyticsData(),
				});
				cookieAppSettingsService.setCookieSetting('previousUrl', routerEvent.url);
			}
		});
		windowInnerHeightService.initWindowInnerHeightCSSVariable();

		// Fetch global store state for Everyday Rewards
		edrStartupService.init();
	};

export const StartupServiceProvider: Provider = {
	provide: APP_INITIALIZER,
	useFactory: startupFactory,
	deps: [
		Store,
		EnvService,
		AppSettingsService,
		FlagService,
		Router,
		TealiumUtagService,
		FulfilmentService,
		ShopperService,
		CookieAppSettingsService,
		WINDOW,
		WindowInnerHeightService,
		EDRStartupService,
	],
	multi: true,
};
