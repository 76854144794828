import { InjectionToken } from '@angular/core';
import { AnalyticsCollectionType } from '@woolworthsnz/analytics';
export const ANALYTICS_PROVIDER = new InjectionToken<AnalyticsClass>('ANALYTICS_PROVIDER');

export interface AnalyticsClass {
	link: (data?: any) => void;
	view: (data?: any) => void;
	collect: (type: AnalyticsCollectionType, data?: any) => void;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export class StubUtagService implements AnalyticsClass {
	link(): void {}
	view(): void {}
	collect(): void {}
}
/* eslint-enable @typescript-eslint/no-empty-function */
