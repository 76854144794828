import { Injectable, Inject } from '@angular/core';
import { WINDOW, CustomWindow } from './window.service';
import { BridgeEvent } from '../ui-models/BridgeEvent';
@Injectable({
	providedIn: 'root',
})
export class NativeBridgeService {
	constructor(@Inject(WINDOW) private window: CustomWindow) {}

	broadcastNativeEvent(eventName: BridgeEvent, eventObject: any): void {
		this.window.webkit?.messageHandlers?.AppEventBus?.postMessage({
			type: eventName,
			payload: eventObject,
			location: this.window?.location?.href,
		});
		this.window.AppEventBus?.postMessage(
			JSON.stringify({ type: eventName, payload: eventObject, location: this.window?.location?.href })
		);
	}
}
