export class FireworksParticle {
	public color: string;
	public vx: number;
	public vy: number;

	private readonly gravity = 0.05;

	private w: number;
	private h: number;
	private x: number;
	private y: number;
	private alpha: number;

	constructor(xPoint: number, yPoint: number) {
		this.w = this.h = Math.random() * 4 + 1;

		this.x = xPoint - this.w / 2;
		this.y = yPoint - this.h / 2;

		this.vx = (Math.random() - 0.5) * 10;
		this.vy = (Math.random() - 0.5) * 10;

		this.alpha = Math.random() * 0.5 + 0.5;
	}

	public move(): boolean {
		// updates position
		this.x += this.vx;
		this.vy += this.gravity;
		this.y += this.vy;
		this.alpha -= 0.01;

		// when particle is offscreen or invisible (alpha 0) then return false so it can be removed
		if (this.x <= -this.w || this.x >= screen.width || this.y >= screen.height || this.alpha <= 0) {
			return false;
		}
		return true;
	}

	public draw(c: CanvasRenderingContext2D): void {
		c.save();
		c.beginPath();

		c.translate(this.x + this.w / 2, this.y + this.h / 2);
		c.arc(0, 0, this.w, 0, Math.PI * 2);
		c.fillStyle = this.color;
		c.globalAlpha = this.alpha;

		c.closePath();
		c.fill();
		c.restore();
	}
}
