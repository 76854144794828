import { isPlatformBrowser } from '@angular/common';
import { Directive, HostBinding, HostListener, Inject, Input, OnChanges, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isAbsoluteLink } from '../../helpers/http.helpers';
import { CustomWindow, WINDOW } from '../../services';

/**
 * Pass true to maybeExternalCurrentTab to open external links in current tab: [maybeExternalCurrentTab]="true"
 * Please note: if you're binding an anchor to an Observable, use [attr.href] instead of [href]
 */
@Directive({
	selector: 'a[maybeExternal], a[cdxMaybeExternal]',
	standalone: true
})
export class MaybeExternalLinkDirective implements OnChanges {
	@Input() maybeExternalCurrentTab: boolean;

	@HostBinding('attr.target') targetAttr = '';
	@Input() target: string;

	@HostBinding('attr.href') hrefAttr = '';
	@Input() href: string;

	constructor(
		@Inject(PLATFORM_ID) private platformId: string,
		@Inject(WINDOW) public window: CustomWindow,
		private router: Router
	) { }

	@HostListener('click', ['$event'])
	clicked(event: Event): void {
		if (!isAbsoluteLink(this.href)) {
			this.router.navigateByUrl(this.href);

			event.preventDefault();
		}
	}

	ngOnChanges(): void {
		this.hrefAttr = this.href;

		if (!this.href) {
			this.targetAttr = '';
			return;
		}

		if (this.maybeExternalCurrentTab) {
			this.targetAttr = '_self';
		} else if (this.isLinkExternal()) {
			this.targetAttr = '_blank';
		} else if (this.target) {
			this.targetAttr = this.target;
		}
	}

	isLinkExternal(): boolean {
		return (
			isPlatformBrowser(this.platformId) &&
			isAbsoluteLink(this.href) &&
			!this.href.includes(this.window.location.hostname)
		);
	}
}
