import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldsetComponent, SelectionTileComponent } from '@woolworthsnz/form';
import { AppSettingsService, AlertComponent } from '@woolworthsnz/styleguide';
import { FulfilmentWindowSummarySlot, NonTradingDaySummaryResponse } from '@woolworthsnz/trader-api';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import equal from 'fast-deep-equal';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { displayDateAtServer } from '../../helpers';
import { keepOriginalOrder } from '../../helpers/keep-original-object-order';
import { FulfilmentState, FulfilmentStoreService } from '../../services';
import { DaySlots } from '../../ui-models';
import { NgIf, NgFor, KeyValuePipe } from '@angular/common';

dayjs.extend(calendar);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

class StrictFulfilmentState extends FulfilmentState {
	daySlots: DaySlots;
}

@UntilDestroy()
@Component({
	selector: 'fulfilment-date-selection',
	templateUrl: 'fulfilment-date-selection.component.html',
	styleUrls: ['./fulfilment-date-selection.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, AlertComponent, FieldsetComponent, FormsModule, ReactiveFormsModule, NgFor, SelectionTileComponent, KeyValuePipe]
})
export class FulfilmentDateSelectionComponent implements OnInit, AfterViewInit {
	@ViewChild('dayScroll') dayScrollSection: ElementRef;
	@ViewChild('dayScrollWrapper') dayScrollWrapper: ElementRef;

	@Input() controlName: string;
	@Input() formGroup: FormGroup;

	hasLoaded: boolean;
	daySlots: DaySlots;
	method: string;
	timeZoneOffset = false;
	timeZoneOffsetDuration: number;
	serverTime: dayjs.Dayjs;
	nonTradingDays: Map<string, string> = new Map<string, string>();

	private scrolledOnStartUp$: Subject<boolean> = new Subject();

	keepOriginalOrder = keepOriginalOrder;

	get timeZoneOffsetWarningMessage(): string {
		return `You are ${Math.abs(this.timeZoneOffsetDuration)} hours ${this.timeZoneOffsetDuration < 0 ? 'in front of' : 'behind'
			} store timezone - dates and slots are always displayed according to the store's timezone.`;
	}

	constructor(
		private fulfilmentStoreService: FulfilmentStoreService,
		private appSettingsService: AppSettingsService,
		private cdr: ChangeDetectorRef
	) { }

	ngOnInit(): void {
		this.fulfilmentStoreService.state$.pipe(untilDestroyed(this)).subscribe((fulfilmentState: FulfilmentState) => {
			this.method = fulfilmentState.method || 'Courier';
		});

		this.fulfilmentStoreService.state$
			.pipe(
				untilDestroyed(this),
				filter((state: FulfilmentState) => !!state.daySlots),
				map((state: FulfilmentState) => state as StrictFulfilmentState),
				distinctUntilChanged((a, b) => equal(a, b))
			)
			.subscribe((fulfilmentState: StrictFulfilmentState) => {
				this.checkTimeZoneOffset(fulfilmentState.serverTimeZoneOffset);
				this.updateFormSelectionDays(fulfilmentState.daySlots, fulfilmentState.selectedDate);
				this.updateNonTradingDays(fulfilmentState.nonTradingDays);
				this.cdr.markForCheck();
			});
	}

	ngAfterViewInit(): void {
		this.fulfilmentStoreService.state$
			.pipe(
				untilDestroyed(this),
				takeUntil(this.scrolledOnStartUp$),
				filter((fulfilmentState: FulfilmentState) => !!fulfilmentState.daySlots)
			)
			.subscribe((fulfilmentState: FulfilmentState) => {
				this.scrollToSelectedDate(fulfilmentState.clientSelectedDate || '');
			});
	}

	updateNonTradingDays(nonTradingDays: NonTradingDaySummaryResponse[] | undefined): void {
		Object.keys(this.daySlots || {}).forEach((date) => {
			const nonTradingDay = nonTradingDays?.find((day) => dayjs(date).isSame(day.date as string, 'day'));
			if (nonTradingDay?.displayName) {
				this.nonTradingDays.set(date, nonTradingDay?.displayName);
			}
		});
	}

	updateFormSelectionDays(daySlots: DaySlots, selectedDate?: string | Date): void {
		this.daySlots = daySlots;

		if (!this.formGroup?.controls['day']?.value && !selectedDate && (daySlots as any)[Object.keys(daySlots || {})[0]]) {
			this.formGroup.patchValue({
				day: Object.keys(daySlots || {})[0],
			});
		}
		this.hasLoaded = true;
	}

	checkTimeZoneOffset = (serverTimeZoneOffset: string): void => {
		const localTimeZoneOffset = dayjs().format('Z');
		if (localTimeZoneOffset !== serverTimeZoneOffset) {
			this.timeZoneOffset = true;
			// calculate diff
			const serverMinsOffset = parseInt(serverTimeZoneOffset.split('+')[1].split(':')[0], 10) * 60;
			const localMinsOffset = dayjs().utcOffset();
			const diff = (serverMinsOffset - localMinsOffset) / 60;
			this.timeZoneOffsetDuration = diff;
		}
	};

	hasAvailableDays = (): boolean => this.daySlots && Object.keys(this.daySlots).length > 0;

	get noSlotsAvailable(): string {
		return this.method === 'Pickup'
			? this.appSettingsService.getMessage('timeslotSelectionMessagePickupNoSlotsAvailable')
			: this.appSettingsService.getMessage('timeslotSelectionMessageDeliveryNoSlotsAvailable');
	}

	scrollToSelectedDate(selectedDate: string | Date): void {
		// need to let the stack complete before scroll
		if (selectedDate) {
			setTimeout(() => {
				const children = this.dayScrollWrapper.nativeElement.children;
				const target = children[selectedDate.toString()];
				if (target) {
					this.dayScrollSection.nativeElement.scrollLeft = target.offsetLeft;
					this.scrolledOnStartUp$.next(true);
				}
			});
		}
	}

	getDisplayedDayOfWeek = (date: string): string | undefined => {
		const serverDate = displayDateAtServer(date);
		return (
			this.getNonTradingDayLabel(date) ||
			(this.timeZoneOffset
				? serverDate?.format('dddd')
				: dayjs(date).calendar(undefined, {
					sameDay: '[Today]',
					nextDay: '[Tomorrow]',
					nextWeek: 'dddd',
					sameElse: 'dddd',
				}))
		);
	};

	getFormattedDate = (date: string): string | undefined =>
		this.timeZoneOffset ? displayDateAtServer(date)?.format('Do MMMM') : dayjs(date).format('Do MMMM');

	getDisplayedDate = (date: string, slots: FulfilmentWindowSummarySlot[]): string | undefined =>
		this.hasAvailableSlots(slots) ? this.getFormattedDate(date) : 'No slots available';

	getNonTradingDayLabel = (date: string): string | undefined => this.nonTradingDays?.get(date);

	hasAvailableSlots = (slots: FulfilmentWindowSummarySlot[]): boolean => slots.length > 0;
}
