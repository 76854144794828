import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from '../form/form.component';
import { AlertComponent, GlobalErrorHandlerService, ScrollIntoViewDirective } from '@woolworthsnz/styleguide';
import { ValidationError } from '@woolworthsnz/trader-api';
import { ValidatioError, RegistrationErrorModel, ValidErrorTypes } from './error-models';
import { untilDestroyed } from '@ngneat/until-destroy';
import { FormService } from '../../services';

interface ErrorModal {
	type: 'error';
	message: string;
	errors: { message: string }[];
}

const defaultError: ErrorModal = {
	type: 'error',
	message: 'Sorry, our system is acting up',
	errors: [
		{
			message:
				"We've had a technical snag here. Try again in a bit or contact our customer care team on 0800 40 40 40.",
		},
	],
};

@Component({
	exportAs: 'cdxCustomForm',
	selector: 'form-custom, [cdxCustomForm]',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, AlertComponent, ScrollIntoViewDirective],
	template: `<cdx-alert
			[type]="error?.type"
			[description]="error?.errors"
			[title]="error?.message"
			[candismiss]="true"
			class="newAlert"
			*ngIf="error && errorStyle === 'default'"
			scrollIntoView
		>
		</cdx-alert>
		<ng-content></ng-content>`,
})
export class CustomFormComponent extends FormComponent implements AfterViewInit {
	constructor(
		public formService: FormService,
		public globalErrorService: GlobalErrorHandlerService,
		ref: ChangeDetectorRef
	) {
		super(formService, globalErrorService, ref);
	}
	ngAfterViewInit(): void {
		this.errored$.pipe(untilDestroyed(this)).subscribe(this.onRegistrationError);
	}

	onRegistrationError = (err: { error: RegistrationErrorModel | undefined }): void => {
		if (!err) {
			this.error = undefined;
		} else {
			const { error } = err;
			this.error = {
				...defaultError,
				errors: error ? this.formatErrorMessage(error) : defaultError.errors,
			};
		}
	};

	formatErrorMessage(errorObject: RegistrationErrorModel): ValidationError[] {
		const flattenedErrorObject = this.flattenObjectAndExtractErrors(errorObject);
		return Object.keys(flattenedErrorObject).map((key) => ({
			field: key,
			message: this.getMessageFromKey(key, flattenedErrorObject[key].type),
		}));
	}

	flattenObjectAndExtractErrors = (
		obj: RegistrationErrorModel | ValidatioError
	): {
		[key: string]: ValidatioError;
	} => {
		const NODE_ERRORS: string[] = [
			ValidErrorTypes.ExceededMaxLength,
			ValidErrorTypes.InvalidFormat,
			ValidErrorTypes.OutOfValidRange,
			ValidErrorTypes.Required,
			ValidErrorTypes.InvalidAddressProviderId,
		];
		const flattened: {
			[key: string]: ValidatioError;
		} = {};

		Object.keys(obj).forEach((key) => {
			const value: ValidatioError = obj[key as keyof (RegistrationErrorModel | ValidatioError)];
			if (
				typeof value === 'object' &&
				value !== null &&
				!Array.isArray(value) &&
				!NODE_ERRORS.includes(value['type'])
			) {
				Object.assign(flattened, this.flattenObjectAndExtractErrors(value));
			} else if (typeof value !== 'string') {
				flattened[key] = value;
			} else {
				return;
			}
		});

		return flattened;
	};

	getMessageFromKey(key: string, errorType: string): string {
		switch (errorType) {
			case ValidErrorTypes.ExceededMaxLength:
				return `The ${key} field has exceeded maximum length.`;
			case ValidErrorTypes.InvalidAddressProviderId:
				return `The ${key} field has an invalid address.`;
			case ValidErrorTypes.InvalidFormat:
				return `The ${key} field has an invalid format.`;
			case ValidErrorTypes.OutOfValidRange:
				return `The ${key} field is invalid.`;
		}
		return `Invalid information.`;
	}
}
