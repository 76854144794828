import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InputErrorComponent } from '../components/input-error/input-error.component';

@UntilDestroy()
@Directive({
	selector: '[formInvalidType], [cdxInvalidType]',
	standalone: true,
})
export class InvalidTypeDirective implements OnInit {
	@Input('cdxInvalidType') type: string;
	@Input() ignoreWhileUntouched: boolean;

	injectedView: any;

	constructor(private inputError: InputErrorComponent, private el: ElementRef) {}

	ngOnInit(): void {
		this.el.nativeElement.classList.add('form-errorText--hide');
		this.inputError.controlStatus$.pipe(untilDestroyed(this)).subscribe(() => {
			// allows you to reset the form without validation errors appearing since the control status will emit
			// TODO: improve functionality via CO-9268
			if (this.ignoreWhileUntouched && this.inputError.formControl?.untouched) {
				return;
			}

			this.setVisible();
		});
	}

	private setVisible(): void {
		const hasError = this.inputError.getErrorType(this.type);
		// below code has been written to make it work in IE11
		// toggle or replace wouldnt work in all browsers
		if (hasError) {
			this.el.nativeElement.classList.remove('form-errorText--hide');
			this.el.nativeElement.classList.add('form-errorText');
		} else {
			this.el.nativeElement.classList.add('form-errorText--hide');
			this.el.nativeElement.classList.remove('form-errorText');
		}
	}
}
