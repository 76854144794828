import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormModule } from '@woolworthsnz/form';
import { StyleguideModule } from '@woolworthsnz/styleguide';
import {
	AddDeliveryAddressComponent,
	AddressSelectorComponent,
	DeliveryAddressSelectionComponent,
	DeliverySaverLinkComponent,
	ExpressFulfilmentDeliveryComponent,
	FulfilmentBarComponent,
	FulfilmentDateSelectionComponent,
	FulfilmentDeliveryComponent,
	FulfilmentExpressSlotSelectionComponent,
	FulfilmentMethodComponent,
	FulfilmentTimeSelectionComponent,
	PickupStoreSelectionComponent,
	PickupTypeModalComponent,
} from './components';

const components = [
	AddDeliveryAddressComponent,
	AddressSelectorComponent,
	DeliveryAddressSelectionComponent,
	DeliverySaverLinkComponent,
	FulfilmentBarComponent,
	FulfilmentDateSelectionComponent,
	FulfilmentDeliveryComponent,
	ExpressFulfilmentDeliveryComponent,
	FulfilmentExpressSlotSelectionComponent,
	FulfilmentMethodComponent,
	FulfilmentTimeSelectionComponent,
	PickupStoreSelectionComponent,
	PickupTypeModalComponent,
];

@NgModule({
    exports: [...components],
    imports: [CommonModule, FormModule, OverlayModule, PortalModule, ReactiveFormsModule, RouterModule, StyleguideModule, ...components]
})
export class FulfilmentLibModule {}
