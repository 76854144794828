import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { IntersectionService } from '../services/intersection.service';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'img[deferLoad]',
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'(error)': 'onImageError()',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'(load)': 'isLoading = false',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'[class.image--loading]': 'isLoading',
	},
	providers: [IntersectionService],
	standalone: true
})
export class DeferLoadDirective implements AfterViewInit, OnChanges {
	@Input() fallback: string;
	@Input() src: string;
	@Input() sizes: string;
	@Input() srcset: string;

	isLoading = true;
	errored = false;

	constructor(private el: ElementRef, private renderer: Renderer2, private intersectionService: IntersectionService) { }

	ngOnChanges(): void {
		if (this.intersectionService.observer) {
			return;
		}
		this.intersectionService.initIntersect({ cb: this.loadImage, condition: true, el: this.el });
	}

	ngAfterViewInit(): void {
		if (this.intersectionService.observer) {
			return;
		}
		this.intersectionService.initIntersect({ cb: this.loadImage, condition: true, el: this.el });
	}

	loadImage = (): void => {
		// For some reason Angular HostBindings dont play nice in directives
		this.renderer.setProperty(this.el.nativeElement, 'src', this.src);

		if (this.srcset) {
			this.renderer.setProperty(this.el.nativeElement, 'srcset', this.srcset);
		}

		if (this.sizes) {
			this.renderer.setProperty(this.el.nativeElement, 'sizes', this.sizes);
		}
	};

	onImageError(): void {
		if (this.errored) {
			return;
		}

		this.renderer.setProperty(this.el.nativeElement, 'src', this.fallback);

		if (this.srcset) {
			this.renderer.setProperty(this.el.nativeElement, 'srcset', '');
		}

		if (this.sizes) {
			this.renderer.setProperty(this.el.nativeElement, 'sizes', '');
		}

		this.errored = true;
	}
}
