import { FormGroup } from '@angular/forms';

/**
 * check validity of the inserted date.
 *
 * @param insertedDate date object from the input
 * @param control the input element
 */
const validateDateFormat = (insertedDate: Date, control: FormGroup): boolean => {
	// Check validity of input, d/m/y will return NaN if date is invalid by input.
	if (isNaN(insertedDate.getMonth()) || isNaN(insertedDate.getFullYear()) || isNaN(insertedDate.getDate())) {
		return false;
	}

	// Limit the year of birth from 1901 to 9999.
	// using control's value because getFullYear() will always return a 4 digit year value.
	if (control.controls.dobYear.value < 1901 || control.controls.dobYear.value > 9999) {
		return false;
	}

	// Check impossible dates by month differences.
	// getDate() will continue the counting to next month if the day input exceeds the available days for the month.
	if (insertedDate.getMonth() !== control.controls.dobMonth.value - 1) {
		return false;
	}

	if (insertedDate.getTime() > Date.now()) {
		return false;
	}

	return true;
};

/**
 * Compare current date with input date, by policy user must be 10 years or older in order to be able to register.
 *
 * @param insertedDate date object from the input
 */
const validateUnderage = (minimumAge: number, insertedDate: Date): boolean => {
	const dateZero = new Date(new Date().getFullYear() - minimumAge, new Date().getMonth(), new Date().getDate());
	return dateZero.getTime() >= insertedDate.getTime();
};

export const dateValidator = (minimumAge = 10) => (control: FormGroup): { [key: string]: boolean } | null => {
	if (control.invalid) {
		return { dateInvalid: true };
	}

	if (
		control.controls.dobDay.value == null ||
		control.controls.dobMonth.value == null ||
		control.controls.dobYear.value == null
	) {
		return { dateInvalid: true };
	}

	const insertedDate = new Date(
		control.controls.dobYear.value,
		control.controls.dobMonth.value - 1,
		control.controls.dobDay.value
	);

	if (!validateDateFormat(insertedDate, control)) {
		return { dateInvalid: true };
	} else if (!validateUnderage(minimumAge, insertedDate)) {
		return { underage: true };
	}

	return null;
};
