import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, createFeature } from '@ngrx/store';
import { Store } from '../../types/stores/store';
import { getStores, getStoresCompleted } from './stores.actions';

export interface StoreState extends EntityState<Store> {
	loading: boolean;
}

export const adapter: EntityAdapter<Store> = createEntityAdapter<Store>();

export const initialState: StoreState = adapter.getInitialState({
	loading: true,
});

export const storesFeature = createFeature({
	name: 'stores',
	reducer: createReducer(
		initialState,
		on(getStores, (state): StoreState => ({ ...state, loading: true })),
		on(getStoresCompleted, (state, { stores }): StoreState => adapter.setMany(stores, { ...state, loading: false }))
	),
});

export const { name, reducer } = storesFeature;
