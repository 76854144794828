import { Directive, HostListener } from '@angular/core';
import {
	A,
	C,
	HOME,
	RIGHT_ARROW,
	V,
	X,
	NINE,
	ZERO,
	NUMPAD_ZERO,
	NUMPAD_NINE,
	DELETE,
	BACKSPACE,
	TAB,
	ESCAPE,
	ENTER,
} from '@angular/cdk/keycodes';

const { PERIOD, DECIMAL } = { PERIOD: 190, DECIMAL: 110 };
@Directive({
	selector: '[cdxNumeric]',
	standalone: true
})
export class NumericDirective {
	@HostListener('keydown', ['$event', '$event.keyCode', '$event.ctrlKey', '$event.metaKey', '$event.shiftKey'])
	handleKeyDown(e: any, keyCode: number, ctrlKey?: boolean, cmdKey?: boolean, shiftKey?: boolean): void {
		if (
			[DELETE, BACKSPACE, TAB, ESCAPE, ENTER].indexOf(keyCode) !== -1 ||
			(keyCode === A && (ctrlKey === true || cmdKey === true)) || // Allow: (Ctrl | Cmd) +A
			(keyCode === C && (ctrlKey === true || cmdKey === true)) || // Allow: (Ctrl | Cmd) +C
			(keyCode === V && (ctrlKey === true || cmdKey === true)) || // Allow: (Ctrl | Cmd) +V
			(keyCode === X && (ctrlKey === true || cmdKey === true)) || // Allow: (Ctrl | Cmd) +X
			(keyCode >= HOME && keyCode <= RIGHT_ARROW) || // Home, End, Left, Right
			keyCode === PERIOD ||
			keyCode === DECIMAL
		) {
			return;
		}

		if (shiftKey || ((keyCode < ZERO || keyCode > NINE) && (keyCode < NUMPAD_ZERO || keyCode > NUMPAD_NINE))) {
			e.preventDefault();
		}
	}
}
