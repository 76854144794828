import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostBinding,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { NgClass } from '@angular/common';

@Component({
	selector: 'form-switch',
	template: `
		<label [attr.for]="inputId">
			<span><ng-content></ng-content></span>
			<input
				type="checkbox"
				[id]="inputId"
				#input
				[attr.aria-checked]="checked"
				[disabled]="disabled"
				(click)="onInputClick($event)"
				(change)="onInteractionEvent($event)"
			/>
			<div
				class="switch-wrapper"
				[attr.size]="size"
				[ngClass]="{ 'switch--checked': checked, 'switch--disabled': disabled }"
			>
				<svg class="switch-circle" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
					<circle cx="10" cy="10" r="10" fill="white" />
				</svg>
			</div>
		</label>
	`,
	styleUrls: ['./switch.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: forwardRef(() => SwitchComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass],
})
export class SwitchComponent implements ControlValueAccessor {
	private _uniqueId = `ss-switch-${uuidv4()}`;
	private _checked = false;
	private _disabled = false;

	@Input() id: string = this._uniqueId;

	@HostBinding('attr.size')
	@Input()
	size: 'small' | 'large' = 'small';

	@Input()
	get checked(): boolean {
		return this._checked;
	}
	set checked(value: boolean) {
		if (value !== this.checked) {
			this._checked = value;
			this.cdr.markForCheck();
		}
	}

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}
	set disabled(value: unknown) {
		const newValue = coerceBooleanProperty(value);

		if (newValue !== this._disabled) {
			this._disabled = newValue;
			this.cdr.markForCheck();
		}
	}

	@Output() readonly switchChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	// eslint-disable-next-line @typescript-eslint/naming-convention
	@ViewChild('input') _inputElement: ElementRef<HTMLInputElement> | undefined;

	get inputId(): string {
		return `${this.id || this._uniqueId}-input`;
	}

	/* eslint-disable @typescript-eslint/no-empty-function */
	private _controlValueAccessorChangeFn: (value: boolean) => void = () => {};
	// eslint-disable-next-line @typescript-eslint/naming-convention
	_onTouched: () => unknown = () => {};
	/* eslint-enable @typescript-eslint/no-empty-function */

	constructor(private cdr: ChangeDetectorRef) {}

	registerOnChange(fn: (value: boolean) => void): void {
		this._controlValueAccessorChangeFn = fn;
	}

	registerOnTouched(fn: () => unknown): void {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	writeValue(value: unknown): void {
		this.checked = !!value;
	}

	toggle(): void {
		this.checked = !this.checked;
	}

	onInputClick(event: Event): void {
		event.stopPropagation();

		if (!this.disabled) {
			this.toggle();

			this._emitChangeEvent();
		}
	}

	onInteractionEvent(event: Event): void {
		// We always have to stop propagation on the change event.
		// Otherwise the change event, from the input element, will bubble up and
		// emit its event object to the `change` output.
		event.stopPropagation();
	}

	private _emitChangeEvent(): void {
		this._controlValueAccessorChangeFn(this.checked);
		this.switchChange.emit(this.checked);

		// Assigning the value again here is redundant, but we have to do it in case it was
		// changed inside the `change` listener which will cause the input to be out of sync.
		if (this._inputElement) {
			this._inputElement.nativeElement.checked = this.checked;
		}
	}
}
