import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EDRButtonComponent, EDRHeadingComponent } from '../../../../components';
import { ButtonComponent, ModalOverlayService, SlottedModalComponent } from '@woolworthsnz/styleguide';
import { edrConfig } from '../../../../config';

@Component({
	selector: 'edr-celebratory-modal',
	templateUrl: 'celebratory-modal.component.html',
	styleUrls: ['celebratory-modal.component.scss'],
	standalone: true,
	imports: [SlottedModalComponent, EDRHeadingComponent, EDRButtonComponent, ButtonComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EDRCelebratoryModalComponent {
	public edrPartnersUrl = `${edrConfig.url}/partners`;

	constructor(private modalOverlayService: ModalOverlayService) {}

	public close(): void {
		this.modalOverlayService.close();
	}
}
