import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class StatefulService<T> {
	_initialState: T;

	state$: BehaviorSubject<T>;

	protected constructor(public initialState: T) {
		this.initState(initialState);
		this._initialState = initialState;
	}

	get state(): T {
		return this.state$.getValue();
	}

	setState(newState: Partial<T>): void {
		const state = !newState
			? this._initialState
			: {
				...this.state$.value,
				...newState,
			};

		this.dispatch(state);
	}

	/**
	 * Returns a value from the state as an observable.
	 * @example select('isLoggedIn') // returns Observable<boolean | undefined>
	 * // This would replace
	 * state$.pipe(map(state => state.isLoggedIn))
	 *
	 * @param key a property from the state
	 * @returns
	 */
	select<TKey extends keyof T>(key: TKey): Observable<T[TKey]> {
		return this.state$.pipe(map((s) => s[key]));
	}

	private initState(initialState: T): void {
		this.state$ = new BehaviorSubject(initialState);
	}

	private dispatch(state: T): void {
		this.state$.next({ ...state });
	}
}
