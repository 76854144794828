import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TruncatedCurrencyPipe } from '@woolworthsnz/styleguide';
import { I18nPluralPipe, NgIf } from '@angular/common';
import { EdrBalanceResponseV2 } from '@woolworthsnz/trader-api';

@Component({
	selector: 'edr-rewards-notification',
	templateUrl: './rewards-notification.component.html',
	styleUrls: ['./rewards-notification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [I18nPluralPipe, TruncatedCurrencyPipe, NgIf],
})
export class EDRRewardsNotificationComponent {
	@Input() rewardsAvailable = 0;
	@Input() pointsRequired = 0;
	@Input() voucherValue = 15;
	@Input() mini = false;
	@Input() redemptionPreference: EdrBalanceResponseV2.RedemptionPreferenceEnum =
		EdrBalanceResponseV2.RedemptionPreferenceEnum.SpendNow;

	public get vouchersText(): string {
		return this.redemptionPreference === EdrBalanceResponseV2.RedemptionPreferenceEnum.SpendNow
			? 'vouchers to spend'
			: 'vouchers saved';
	}
}
