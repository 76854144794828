import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SvgIconComponent } from '@woolworthsnz/styleguide';
import { NgClass, NgIf } from '@angular/common';

@UntilDestroy()
@Component({
	selector: 'form-selection-tile',
	template: `
		<ng-content select="[badge]"></ng-content>
		<input
			[id]="inputId"
			[name]="formControlName"
			type="radio"
			(change)="onChange()"
			(blur)="onTouched($event)"
			[checked]="checked"
			[disabled]="isDisabled"
		/>
		<label [for]="inputId" [ngClass]="{ tick: successIcon }">
			<cdx-svg-icon *ngIf="iconName" [name]="iconName" fill="currentColor" size="default"></cdx-svg-icon>
			<div>
				<h2>{{ label }}</h2>
				<p *ngIf="description">{{ description }}</p>
			</div>
			<ng-content select="[details]"></ng-content>
		</label>
	`,
	styleUrls: ['./selection-tile.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: forwardRef(() => SelectionTileComponent),
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, NgIf, SvgIconComponent],
})
export class SelectionTileComponent implements ControlValueAccessor {
	@Input() label: string;
	@Input() description: string;
	@Input() i18n: string;
	@Input() inputValue: string;
	@Input() inputId: string;
	@Input() formControlName: string;
	@Input() iconName: string;
	@Input() successIcon: boolean;
	@Input() isDisabled = false;

	checked: boolean;

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	propagateChange: any = () => {};
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	propagateTouched: any = () => {};

	onChange(): void {
		if (!this.isDisabled) {
			this.propagateChange(this.inputValue);
		}
	}

	onTouched(v: any): void {
		if (!this.isDisabled) {
			this.propagateTouched(v);
		}
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.propagateTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	writeValue(val: any): void {
		if (val === this.inputValue) {
			this.checked = true;
		}
	}
}
