import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import {
	FormGroup,
	FormGroupDirective,
	ValidatorFn,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { RegEx } from '@woolworthsnz/styleguide';
import { FormService } from '../../services/form.service';
import { onecardValidator } from '../../validators';
import { InputComponent } from '../input/input.component';
import { InvalidTypeDirective } from '../../directives/invalid-type.directive';
import { InputErrorComponent } from '../input-error/input-error.component';
import { FormOptionalTextComponent } from '../form-optional-text/form-optional-text.component';
import { FieldsetComponent } from '../fieldset/fieldset.component';

@Component({
	selector: 'form-add-onecard',
	templateUrl: './add-onecard.component.html',
	styleUrls: ['./add-onecard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [FormService],
	standalone: true,
	imports: [
		FieldsetComponent,
		FormsModule,
		ReactiveFormsModule,
		InputComponent,
		FormOptionalTextComponent,
		InputErrorComponent,
		InvalidTypeDirective,
	],
})
export class AddOneCardComponent implements OnInit {
	@Input() group: FormGroup;

	@ViewChild('input', { static: true }) input: InputComponent;

	paragraph = `See everything you've bought before and earn rewards.`;
	parentFormGroup: FormGroup;
	title = `Shop faster with Onecard`;

	constructor(public parent: FormGroupDirective, public formService: FormService) {}

	ngOnInit(): void {
		this.parentFormGroup = this.parent.form;

		this.formService.addControlToParent('loyaltyCard', this.onecardValidators, this.parentFormGroup);
	}

	@HostListener('keyup', ['$event'])
	updateInputValue(event: any): void {
		this.input.writeValue(event.target.value.replace(/(\d{4})(\d{4})(\d{1,5})/, '$1-$2-$3'));
	}

	get onecardValidators(): ValidatorFn[] {
		return [Validators.pattern(RegEx.LOYALTY_CARD), onecardValidator];
	}
}
