import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService, AppSettingsService, CustomWindow, WINDOW } from '@woolworthsnz/styleguide';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { authInitialise, authInitialiseComplete, authRefresh, userLogOut } from './auth.actions';
import { EnvService } from '../../services';

@Injectable()
export class AuthEffects {
	onAuthInit$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(authInitialise, authRefresh),
			switchMap(() =>
				this.apiService
					.get(`${this.appSettingsService.getSetting('bffApiUrl')}/auth/getuser`)
					.pipe(map((payload) => authInitialiseComplete(payload)))
			)
		);
	});

	onLogOut$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(userLogOut),
			switchMap(
				() =>
					(this.window.location.href = `${this.appSettingsService.getSetting('bffApiUrl')}/auth/logout?returnUrl=${
						this.envService.countdownBaseUrl
					}`)
			)
		);
	});

	constructor(
		private actions$: Actions,
		private appSettingsService: AppSettingsService,
		private envService: EnvService,
		private apiService: ApiService,
		@Inject(WINDOW) private window: CustomWindow
	) {}
}
