import { Injectable } from '@angular/core';
import { ApiService, AppSettingsService, LoggingService, StatefulService } from '@woolworthsnz/styleguide';
import {
	BonusProductSelectedRequest,
	BonusProductsResponse,
	CategoryResponse,
	ContextResponse,
	OrderValidationResponse,
	PastOrderUpdateRequest,
	PastOrderUpdateResponse,
	ProductResponse,
	ReviewOrderResponse,
	ReviewOrderTotalComponent,
	ReviewOrderViewModel,
	TotalComponentsWithMessages,
} from '@woolworthsnz/trader-api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BasketService, BasketState } from './basket.service';

export class OrderState implements ReviewOrderResponse {
	pricePolicy?: string;
	termsLink?: string;
	order?: ReviewOrderViewModel;
	items?: Array<CategoryResponse>;
	disclaimerAccepted? = false;
	isSuccessful?: boolean;
	rootUrl?: string;
	context?: ContextResponse;
	messages?: Array<string>;
	updatedFromServer? = false;
}

@Injectable({
	providedIn: 'root',
})
export class OrderService extends StatefulService<OrderState> {
	constructor(
		private apiService: ApiService,
		private appSettingsService: AppSettingsService,
		private basketService: BasketService,
		private loggingService: LoggingService
	) {
		super(new OrderState());
	}

	getActiveOrder(): Observable<ReviewOrderResponse> {
		return this.apiService.get(this.appSettingsService.getEndpoint('getOrder')).pipe(
			tap((res) => {
				this.setState(<OrderState>{
					...res,
					updatedFromServer: true,
				});

				// Update basket state with only in stock items
				if (res.items) {
					const newBasketState: BasketState = {
						items: res.items,
						areAllAllowSubstitutesSelected: res.items.every((categoryResponse: CategoryResponse) =>
							categoryResponse.products?.every((product: ProductResponse) => product.subsAllowed)
						),
					};
					this.basketService.setState(newBasketState);
				}
			})
		);
	}

	/**
	 * HOT update of order.totals state
	 */
	updateOrderTotals(): void {
		this.apiService.get(this.appSettingsService.getEndpoint('getOrderTotals')).subscribe(
			(res: TotalComponentsWithMessages) => {
				this.setState({
					order: {
						...(this.state.order as ReviewOrderViewModel),
						totals: res,
					},
				});
			},
			(err) => {
				this.loggingService.trackException(err);
			}
		);
	}

	/**
	 * HOT update of order.bonusProducts state
	 */
	updateBonusProducts(): void {
		this.apiService
			.get(this.appSettingsService.getEndpoint('bonusProducts'), {
				params: {
					cacheBuster: Date.now().toString(),
				},
			})
			.subscribe(
				(res: BonusProductsResponse) => {
					this.setState(<OrderState>{
						order: {
							...this.state.order,
							bonusProducts: res.bonusProducts,
						},
					});
				},
				(err) => {
					this.loggingService.trackException(err);
				}
			);
	}

	updateAcceptedDisclaimerStatus = (acceptDisclaimer: boolean): Observable<any> =>
		this.apiService.put(this.appSettingsService.getEndpoint('putOrderDisclaimer'), { acceptDisclaimer });

	updateBonusProductSelection(bonusProductSelectedRequest: BonusProductSelectedRequest): Observable<any> {
		return this.apiService.put(this.appSettingsService.getEndpoint('bonusProducts'), bonusProductSelectedRequest);
	}

	validateOrder = (): Observable<OrderValidationResponse> =>
		this.apiService.get(this.appSettingsService.getEndpoint('getOrderValidation'));

	updatePastOrder(pastOrderUpdateRequest: PastOrderUpdateRequest): Observable<PastOrderUpdateResponse> {
		return this.apiService.put(this.appSettingsService.getEndpoint('pastOrderUpdate'), pastOrderUpdateRequest);
	}

	getOrderItems(): CategoryResponse[] | undefined {
		return this.state.items;
	}

	getTotalOrderPrice(): number {
		return this.state.order?.totals?.components.find((total: ReviewOrderTotalComponent) => total.isTotal)?.value || 0;
	}
}
