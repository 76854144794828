import { of } from 'rxjs';
import { BoostOffersCountResponse, EdrBalanceResponseV2 } from '@woolworthsnz/trader-api';

export const mockEdrBalance: EdrBalanceResponseV2 = {
	accountStatus: 'Active',
	redemptionPreference: EdrBalanceResponseV2.RedemptionPreferenceEnum.SpendNow,
	totalPointsBalance: 2500,
	isPointsRedeemable: true,
	redeemablePointsBalance: 2000,
	redeemablePointsAsVoucherDollars: 15,
	redeemablePointsAsVoucherCount: 1,
	pointsAsVoucherDollars: 15,
	pointsAsVoucherCount: 1,
	pointsUntilNextReward: 500,
	pointsPerReward: 2000,
};

export const mockBoostOffersCount: BoostOffersCountResponse = {
	totalOffers: 5,
};

export const mockEdrFacade = {
	edrBalance$: of(mockEdrBalance),
	fetchingEdrBalance$: of(false),
	fetchedEdrBalance$: of(true),
	boostOffersCount$: of(mockBoostOffersCount.totalOffers),
	fetchingBoostOffersCount$: of(false),
	fetchedBoostOffersCount$: of(true),
	hasError$: of(false),
	fetchEdrBalance: (): void => {
		/* Mock Fetch EDR Balances */
	},
	fetchBoostOffersCount: (): void => {
		/* Mock Fetch Boost Offers Count */
	},
	redemptionPreference$: of(EdrBalanceResponseV2.RedemptionPreferenceEnum.SpendNow),
};
