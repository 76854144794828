import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	FormGroup,
	FormGroupDirective,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiService, ButtonComponent, ModalOverlayService } from '@woolworthsnz/styleguide';
import { AddressesSearchResponse, AddressSearchResult } from '@woolworthsnz/trader-api';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AddressService, FormService } from '../../services';
import { InputComponent, InputSize } from '../input/input.component';
import { InvalidTypeDirective } from '../../directives/invalid-type.directive';
import { InputErrorComponent } from '../input-error/input-error.component';
import { NgIf, NgFor } from '@angular/common';
import { InputWithIconButtonComponent } from '../input-with-icon-button/input-with-icon-button.component';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';

@UntilDestroy()
@Component({
	selector: 'form-address-autocomplete',
	templateUrl: './address-autocomplete.component.html',
	styleUrls: ['./address-autocomplete.component.scss'],
	providers: [ApiService, FormService, ModalOverlayService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		AutocompleteComponent,
		FormsModule,
		ReactiveFormsModule,
		InputWithIconButtonComponent,
		NgIf,
		ButtonComponent,
		InputErrorComponent,
		InvalidTypeDirective,
		NgFor,
	],
})
export class AddressAutocompleteComponent implements OnInit, OnDestroy {
	@Input() size: InputSize;

	@Output() switchToManualEntryEmitter: EventEmitter<any> = new EventEmitter();

	@ViewChild(InputComponent) inputRef: InputComponent;

	addressSummary: string[] = [];
	disabled = false;
	hasCrossIcon = false;
	parentFormGroup: FormGroup;
	searchResults: Array<AddressSearchResult> = [];
	showManualAdressLink = false;
	typedValue: string;
	identifier: string;
	isClearedInput = true;
	focussedSuggestion: string;
	suggestionIdentifierPrefix = 'addressAutocompleteSuggestion_';

	get addressFormControl(): AbstractControl<any, any> | null {
		return this.parentFormGroup.get('addressStreet1');
	}

	get addressProviderIdFormControl(): AbstractControl<any, any> | null {
		return this.parentFormGroup.get('addressProviderId');
	}

	constructor(
		public addressService: AddressService,
		private cdr: ChangeDetectorRef,
		private formService: FormService,
		public parent: FormGroupDirective,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.identifier = `chrome-bust-${dayjs().unix()}-addressStreet1`;

		this.parentFormGroup = this.parent.form;
		this.formService.addControlToParent(
			'addressStreet1',
			[Validators.required],
			this.parentFormGroup,
			this.validateAddress
		);

		this.formService.addControlToParent('addressProviderId', [Validators.required], this.parentFormGroup);

		this.addressService.result$.pipe(untilDestroyed(this)).subscribe(this.onFetchSuccess);

		this.addressService.error$.pipe(untilDestroyed(this)).subscribe(this.onFetchFailure);
	}

	ngOnDestroy(): void {
		this.parentFormGroup.removeControl('addressStreet1');
		this.parentFormGroup.removeControl('addressProviderId');
	}

	clearInput = (): void => {
		// this is a hack for safari where relatedtarget always null
		this.isClearedInput = true;
		this.inputRef.input.nativeElement.value = '';
		this.typedValue = '';
		this.parentFormGroup.controls['addressStreet1'].enable();
		this.addressSummary = [];
		this.hideCrossIcon();
	};

	focusInput = (): void => {
		this.inputRef.input.nativeElement.focus();
		this.resetAddressProviderId();
	};

	fetchAddress = (typedValue: any): void => {
		this.resetAddressProviderId();
		this.typedValue = typedValue;
		this.addressService.fetchAddress(typedValue);
	};

	handleAddressSelection(address: AddressSearchResult): void {
		this.addressService.selectAddress();
		this.typedValue = '';
		this.addressSummary = address.value?.split(',') || [];
		this.parentFormGroup.controls['addressProviderId'].patchValue(address.id);
		this.parentFormGroup.controls['addressStreet1'].patchValue(address.value);

		this.parentFormGroup.controls['addressStreet1'].updateValueAndValidity();
		this.parentFormGroup.controls['addressStreet1'].disable();
		this.showCrossIcon();
	}

	handleAddressFocus(event: any): void {
		this.focussedSuggestion = `${this.suggestionIdentifierPrefix}${event}`;
	}

	validateAddress = (
		control: AbstractControl
	): Observable<{
		addressNotSelected: boolean;
	} | null> =>
		this.addressService.addressSelected$.pipe(
			map((isSelected) => {
				this.showManualAdressLink = !control.pristine && !isSelected;
				return isSelected && control.value ? null : { addressNotSelected: true };
			}),
			take(1)
		);

	showCrossIcon = (): void => {
		this.hasCrossIcon = true;
	};

	hideCrossIcon = (): void => {
		this.hasCrossIcon = false;
	};

	onBlur(): void {
		this.isClearedInput = false;
	}

	onFetchSuccess = (result: AddressesSearchResponse): void => {
		const { addressSearchResults } = result;

		this.searchResults = addressSearchResults || [];
		this.cdr.markForCheck();
	};

	onFetchFailure = (error: any): void => {
		this.searchResults = [];

		throw Error(error.message);
	};

	resetAddressProviderId = (): void => {
		this.addressSummary = [];
		this.addressProviderIdFormControl?.patchValue(null);
		this.addressProviderIdFormControl?.clearValidators();
	};

	resetAddress = (): void => {
		this.addressFormControl?.patchValue('');
		this.addressFormControl?.markAsPristine();
		this.addressFormControl?.updateValueAndValidity();
	};

	switchToManualEntry = (): void => {
		this.switchToManualEntryEmitter.emit();
	};
}
