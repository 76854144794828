export enum PreferenceType {
	EmailOptOut = 'EmailOptOut',
	AllowContactEmail = 'AllowContactEmail',
	InterestWeeklySpecials = 'InterestWeeklySpecials',
	PreferredStore = 'PreferredStore',
	PreferredDayOfWeek = 'PreferredDayOfWeek',
	InterestAlcohol = 'InterestAlcohol',
	InterestOnlineShopping = 'InterestOnlineShopping',
	InterestProduct = 'InterestProduct',
	InterestResearch = 'InterestResearch',
	AllowContactSMS = 'AllowContactSMS',
	AllowContactPost = 'AllowContactPost',
	EDRSaveForOccasionDate = 'EDRSaveForOccasionDate',
}

export interface CustomerPreference {
	id: PreferenceType | string;
	value: string | boolean | number;
	lastModifiedUtc: string;
}

export interface CustomerPreferences {
	customer?: {
		scvId?: string | null;
		customerId?: number | null;
		shopperId?: number | null;
	};
	isDefaultCustomer?: boolean;
	preferences: CustomerPreference[];
}
