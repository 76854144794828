import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '../helpers';

@Pipe({
	name: 'join',
	standalone: true
})
export class JoinPipe implements PipeTransform {
	transform(value: Array<string | number | null>, separator = ',', lastSeparator?: string): string {
		const filteredValues = value.filter(isDefined);
		if (!lastSeparator) {
			return filteredValues.join(separator);
		}

		const valueClone = [...filteredValues];
		const last = valueClone.pop();
		const joined = valueClone.join(separator);
		if (valueClone.length && last) {
			return `${joined}${lastSeparator}${last}`;
		} else if (last) {
			return last.toString();
		}
		return '';
	}
}
