import { Directive, ElementRef, Injector, Optional } from '@angular/core';

export abstract class Parent {}
@Directive()
export abstract class SelectBaseComponent {
	public parent: SelectBaseComponent;
	public el: ElementRef;

	constructor(protected injector: Injector, @Optional() parent: Parent) {
		this.el = this.injector.get(ElementRef);
		this.parent = <SelectBaseComponent>parent;
	}
}
