<ng-container [formGroup]="parentFormGroup">
  <form-input
    autocomplete="email"
    [formControlName]="singInIdControlName"
    required
    size="full-width"
  >
    <label i18n="@@{{ page }}-emailLabel" for="email" label>
      {{ emailLabel }}
    </label>
    <form-input-error validation [control]="singInIdControlName">
      <p [cdxInvalidType]="'*'" i18n="@@{{ page }}-invalidEmailErrorMessage">
        {{emailValidationMessage}}
      </p>

      <ng-template select="[emailAdditionalValidation]"></ng-template>
    </form-input-error>
  </form-input>
  <form-password-input 
    [showPasswordHelpText]="showPasswordHelpText" 
    [passwordLabel]="passwordLabel" 
    [validationMessage]="passwordValidationMessage">
  </form-password-input>
  <ng-content></ng-content>
</ng-container>
