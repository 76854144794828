import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormModule } from '@woolworthsnz/form';
import { StyleguideModule } from '@woolworthsnz/styleguide';
import { AccountRoutingModule } from './account-routing.module';
import { AccountNavComponent, ChangePersonalDetailsModalComponent } from './components';
import { EmailPreferencesModalComponent } from './components/email-preferences-modal/email-preferences-modal.component';
import { BaseAccountComponent } from './pages/base-account/base-account.component';
import { CommunicationPreferencesComponent } from './pages/communication-preferences/communication-preferences.component';
import { HomeComponent } from './pages/home/home.component';
import { PersonalDetailsComponent } from './pages/personal-details/personal-details.component';
import { accountPreferencesFeature, PreferencesEffects } from './state/preferences';
import { ProfileEffects, profileFeatureKey, profileReducer } from './state/profile';
import { StoresEffects, storesFeature } from './state/stores';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { ManageEmailPasswordComponent } from './pages/manage-email-password/manage-email-password.component';

@NgModule({
	declarations: [
		HomeComponent,
		AccountNavComponent,
		CommunicationPreferencesComponent,
		BaseAccountComponent,
		EmailPreferencesModalComponent,
		PersonalDetailsComponent,
		ChangePersonalDetailsModalComponent,
		UnsubscribeComponent,
		ManageEmailPasswordComponent,
	],
	imports: [
		CommonModule,
		StyleguideModule,
		AccountRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		FormModule,
		StoreModule.forFeature(accountPreferencesFeature),
		StoreModule.forFeature(storesFeature),
		StoreModule.forFeature(profileFeatureKey, profileReducer),
		EffectsModule.forFeature([PreferencesEffects, StoresEffects, ProfileEffects]),
	],
	exports: [HomeComponent],
})
export class AccountModule {}
