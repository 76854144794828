import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ShopperService } from '@woolworthsnz/styleguide';
import { DeliveryFeeDto } from '@woolworthsnz/trader-api';
import { FulfilmentState, FulfilmentStoreService } from '../../services';
import { DeliverySaverLinkComponent } from '../delivery-saver-link/delivery-saver-link.component';
import { NgIf, NgFor } from '@angular/common';

@UntilDestroy()
@Component({
	selector: 'fulfilment-delivery',
	template: `
		<h3>{{ feeTitle }}</h3>
		<ng-container *ngIf="method === 'Delivery'">
			<fulfilment-delivery-saver-link></fulfilment-delivery-saver-link>
		</ng-container>
		<dl *ngIf="fees" class="deliveryCost-fees">
			<ng-container *ngFor="let fee of fees">
				<dt>{{ fee.orderValue }}</dt>
				<dd [innerHTML]="fee.deliveryFee"></dd>
			</ng-container>
		</dl>
		<span [innerHTML]="deliveryFeesText"></span>
	`,
	styleUrls: ['./fulfilment-delivery.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, DeliverySaverLinkComponent, NgFor],
})
export class FulfilmentDeliveryComponent implements OnInit {
	fees: DeliveryFeeDto[];
	deliveryFeesText: string;
	method: 'Pickup' | 'Delivery';

	constructor(
		private cdr: ChangeDetectorRef,
		private fulfilmentStoreService: FulfilmentStoreService,
		private shopperService: ShopperService
	) {}

	ngOnInit(): void {
		this.fulfilmentStoreService.state$.pipe(untilDestroyed(this)).subscribe((r: FulfilmentState) => {
			this.method = r.method === 'Courier' ? 'Delivery' : 'Pickup';
			this.fees = r.deliveryPass?.fees || [];
			this.deliveryFeesText = r.deliveryFeesNote || '';
			this.cdr.markForCheck();
		});
	}

	get feeTitle(): string {
		return this.method === 'Pickup' ? `Pick up fees` : `${this.method} fees`;
	}
}
