import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectionTileComponent } from '@woolworthsnz/form';
import { AppSettingsService } from '@woolworthsnz/styleguide';
import {
	ExpressFulfilmentAvailabilityStatusResponse,
	FulfilmentWindowSlot,
	OrderViewModel,
	ShellResponse,
} from '@woolworthsnz/trader-api';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { distinctUntilChanged } from 'rxjs/operators';
import { getExpressFulfilmentMethodLabel, getMinimumTimeForExpressOrdersToBeReady } from '../../helpers';
import { FulfilmentStoreService } from '../../services';
import AvailabilityStatusEnum = ExpressFulfilmentAvailabilityStatusResponse.AvailabilityStatusEnum;

dayjs.extend(customParseFormat);

@UntilDestroy()
@Component({
	selector: 'fulfilment-express-slot-selection',
	templateUrl: './fulfilment-express-slot-selection.component.html',
	styleUrls: ['./fulfilment-express-slot-selection.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, SelectionTileComponent]
})
export class FulfilmentExpressSlotSelectionComponent implements OnInit {
	@Input() slot?: FulfilmentWindowSlot;
	@Input() controlName: string;
	@Input() date: string;
	@Input() formGroup: FormGroup;
	@Input() expressSlotStatus: AvailabilityStatusEnum;

	fulfilmentMethod?: OrderViewModel.DeliveryMethodEnum;

	get fulfilmentMethodLabel(): string {
		return getExpressFulfilmentMethodLabel(this.fulfilmentMethod);
	}

	get isDisabled(): boolean {
		return (
			this.expressSlotStatus !== AvailabilityStatusEnum.Available &&
			this.expressSlotStatus !== AvailabilityStatusEnum.ExpressWindowAlreadySelected
		);
	}

	get label(): string {
		if (this.isDisabled) {
			return 'Unavailable';
		}

		const expressPickupTime = getMinimumTimeForExpressOrdersToBeReady(
			this.fulfilmentMethod,
			this.expressFulfilmentSettings
		);
		return `${expressPickupTime} min express`;
	}

	get description(): string {
		if (
			this.expressSlotStatus === AvailabilityStatusEnum.NoExpressWindows ||
			this.expressSlotStatus === AvailabilityStatusEnum.WindowLimitExceeded
		) {
			return 'Our team is currently busy';
		}

		const expressFulfilmentTime = getMinimumTimeForExpressOrdersToBeReady(
			this.fulfilmentMethod,
			this.expressFulfilmentSettings
		);
		const orderReadyTime = dayjs(new Date()).add(expressFulfilmentTime, 'minute').format('h:mm a');

		return `Order now for ${orderReadyTime} ${this.fulfilmentMethodLabel.toLowerCase()}`;
	}

	expressFulfilmentSettings: ShellResponse['expressFulfilmentSettings'];

	constructor(
		private cdr: ChangeDetectorRef,
		private appSettingsService: AppSettingsService,
		private fulfilmentStoreService: FulfilmentStoreService
	) { }

	ngOnInit(): void {
		this.appSettingsService.state$.pipe(untilDestroyed(this)).subscribe((appSettingsState) => {
			this.expressFulfilmentSettings = appSettingsState.expressFulfilmentSettings;
		});

		this.fulfilmentStoreService
			.select('method')
			.pipe(untilDestroyed(this), distinctUntilChanged())
			.subscribe((method) => {
				if (method) {
					this.fulfilmentMethod = method;
				}
				this.cdr.markForCheck();
			});
	}
}
