export const routeNames = {
	account: 'account',
	communicationPreferences: 'communication',
	personalDetails: 'personal-details',
	manageEmailPassword: 'manage-email-password',
	bridge: 'bridge',
	shop: 'shop',
	login: 'securelogin',
	unsubscribe: 'unsubscribe',
	everydayRewards: {
		base: 'everyday-rewards',
		dashboard: 'dashboard',
		// manage: 'TBC',
	},
	modals: {
		outlet: 'modal',
		emailPreferences: 'email-preferences',
		changePersonalDetails: 'change-personal-details',
	},
};
