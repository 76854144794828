import { Injectable, Optional, Inject, isDevMode } from '@angular/core';
import { MarkdownParseFn, MARKDOWN_PROVIDER } from '../injection-tokens/markdown-provider-token';

@Injectable({
	providedIn: 'root',
})
export class MarkdownService {
	constructor(@Optional() @Inject(MARKDOWN_PROVIDER) private markdownProvider?: MarkdownParseFn) { }

	public parse(value: string, options?: any): string {
		// no provider injected, should fall back to just return the value
		if (!this.markdownProvider) {
			if (isDevMode()) {
				// eslint-disable-next-line no-console
				console.error(`
          'MARKDOWN_PROVIDER' was not provided, please ensure you provide a markdown engine such as marked.
          This can be done in your module:

          ----
          import marked from 'marked';

          @NgModule({
            providers: [
              {
                provide: MARKDOWN_PROVIDER,
                useValue: marked,
              },
            ],
          })
          ----
        `);
			}

			return value;
		}

		return this.markdownProvider(value, options);
	}
}
