/**
 * Removes leading 9 from store ID if it exists
 * @param storeId
 * @returns
 */
export const simplifiedStoreId = (storeId: number | undefined): number | undefined => {
	if (!storeId) return undefined;

	const storeIdStr = storeId.toString();
	const storeIdAdjusted = storeIdStr.startsWith('9') ? storeIdStr.substring(1) : storeIdStr;

	return Number.parseInt(storeIdAdjusted, 10);
};
