import { Injectable } from '@angular/core';
import { ApiService } from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { EnvService } from '../../../common';
import { CustomerProfile } from '../../types';

@Injectable({
	providedIn: 'root',
})
export class ProfileService {
	constructor(private api: ApiService, private envService: EnvService) {}

	getProfile(): Observable<CustomerProfile> {
		return this.api.get(`${this.envService.bffApiUrl}/v1/customer/profile/me`);
	}

	saveProfile(customerProfile: CustomerProfile) {
		return this.api.put(`${this.envService.bffApiUrl}/v1/customer/profile/me`, customerProfile);
	}
}
