// TODO: TD raised POD-5964 to rename it to trolley
import { Injectable } from '@angular/core';
import { ApiService, AppSettingsService, LoggingService, StatefulService } from '@woolworthsnz/styleguide';
import {
	BasketTotalsResponse,
	ProductResponse,
	TrolleyItemResponse,
	UpdateTrolleyMetadataRequest,
	ViewTrolleyProductListGroup,
} from '@woolworthsnz/trader-api';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { UpdateOnlineSampleResponseRequest, OnlineSampleResponse } from '../models/online-samples.model';

export class BasketState implements BasketTotalsResponse {
	areAllAllowSubstitutesSelected?: boolean;
	subtotal?: string;
	savings?: string;
	deliveryFees?: string;
	bagFees?: string;
	totalIncludingDeliveryFees?: string;
	totalItems?: number;
	totalInStockItems?: number;
	items?: ViewTrolleyProductListGroup[];
	onlineSamples?: ProductResponse[];
	eligibilityForDeliverySubscriptionDiscount?: BasketTotalsResponse.EligibilityForDeliverySubscriptionDiscountEnum;
	paperPackingSlip?: boolean;
	onlineSampleResponsesError?: boolean;
	onlineSampleResponsesConfirmation?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class BasketService extends StatefulService<BasketState> {
	constructor(
		public apiService: ApiService,
		private appSettingsService: AppSettingsService,
		private loggingService: LoggingService
	) {
		super(new BasketState());
	}

	set paperPackingSlip(paperPackingSlip: boolean) {
		this.setState({ paperPackingSlip });
	}

	isBasketEmpty(): boolean {
		return this.state ? this.state.totalItems === 0 : true;
	}

	filterEmptyCategories = (categorisedProducts: ViewTrolleyProductListGroup[]): ViewTrolleyProductListGroup[] =>
		categorisedProducts.filter(
			(productListGroup: ViewTrolleyProductListGroup) => (productListGroup.products || []).length > 0
		);

	filterCategoriesInStock = (categorisedProducts: ViewTrolleyProductListGroup[]): ViewTrolleyProductListGroup[] =>
		categorisedProducts.filter(
			(productListGroup: ViewTrolleyProductListGroup) =>
				productListGroup.categoryType !== ViewTrolleyProductListGroup.CategoryTypeEnum.OutOfStock
		);

	getTrolleyItems = (): void => {
		this.apiService
			.get(`${this.appSettingsService.getEndpoint('trolley')}/my`, {})
			.pipe(take(1))
			.subscribe((response: any) => {
				this.setState({
					items: this.filterEmptyCategories(response.items),
					areAllAllowSubstitutesSelected: this.areAllAllowSubstitutionsChecked(response.items),
				});
			});
	};

	getOnlineSamples = (): void => {
		this.apiService
			.get(`${this.appSettingsService.getEndpoint('onlineSamples')}`, {})
			.pipe(take(1))
			.subscribe((response) => {
				this.setState({
					onlineSamples: response?.products?.items || [],
				});
			});
	};

	updateOnlineSamples = (request: UpdateOnlineSampleResponseRequest[]): void => {
		this.apiService
			.put(`${this.appSettingsService.getEndpoint('onlineSampleResponses')}`, request)
			.pipe(
				take(1),
				catchError((error) => {
					this.loggingService.trackException(error);
					// Re-fetch data when update failed to make sure the frontend samples are sync
					this.getOnlineSamples();
					this.setState({
						onlineSampleResponsesError: true,
						onlineSampleResponsesConfirmation: true,
					});
					return throwError(new Error('Update online samples failed'));
				})
			)
			.subscribe((response) => {
				if (response == null || response?.length === 0) {
					this.setState({
						onlineSampleResponsesError: true,
						onlineSampleResponsesConfirmation: true,
					});
					return;
				}
				const onlineSamples = this.state.onlineSamples || [];
				const updatedSamples = onlineSamples.map((sample: ProductResponse) => {
					const sampleInfo = response.find((i: OnlineSampleResponse) => i.id === sample.onlineSample?.responseId);
					if (!sampleInfo) {
						return sample;
					}
					const updatedSample = {
						...sample,
						onlineSample: {
							...sample.onlineSample,
							actionTaken: sampleInfo.actionTaken,
						},
					};
					return updatedSample;
				});

				this.setState({
					onlineSamples: [...updatedSamples],
					onlineSampleResponsesError: false,
					onlineSampleResponsesConfirmation: false,
				});
			});
	};

	clearTrolley = (): Observable<any> =>
		this.apiService.delete(`${this.appSettingsService.getEndpoint('trolley')}/my/items`).pipe(
			take(1),
			tap(() => {
				this.setState({
					items: [],
					onlineSamples: [],
				});
			}),
			catchError((error) => {
				this.loggingService.trackException(error);
				return throwError(error);
			})
		);

	removeTrolleyItem(sku: string): void {
		if (!this.state.items) {
			return;
		}

		const updatedItems = this.filterEmptyCategories(
			this.state.items.map((productListGroup: ViewTrolleyProductListGroup) => ({
				...productListGroup,
				products: productListGroup.products?.filter((product: ProductResponse) => product.sku !== sku),
			}))
		);

		if (updatedItems.length === 0) {
			this.setState({
				items: [],
				onlineSamples: [],
			});
			return;
		}

		this.setState({
			items: updatedItems,
		});

		if (this.isQualifyingProduct(sku)) {
			this.getOnlineSamples();
		}
	}

	globallyAllowSubstitutions = (): void => {
		this.apiService
			.post(this.getSubstitutionsEndpoint(), {})
			.pipe(catchError(this.handleSubstitutionError))
			.subscribe(() => this.updateSubstitutionsGlobally(true));
	};

	globallyDisallowSubstitutions = (): void => {
		this.apiService
			.delete(this.getSubstitutionsEndpoint())
			.pipe(catchError(this.handleSubstitutionError))
			.subscribe(() => this.updateSubstitutionsGlobally(false));
	};

	getSubstitutionsEndpoint = (): string => `${this.appSettingsService.getEndpoint('trolley')}/my/substitutions`;

	handleSubstitutionError = (error: any): Observable<never> => throwError(error);

	updateSubstitutionsGlobally = (subsAllowed: boolean): void => {
		if (!this.state.items) {
			return;
		}

		const updatedItems = this.filterEmptyCategories(
			this.state.items.map((productListGroup: ViewTrolleyProductListGroup) => ({
				...productListGroup,
				products: productListGroup.products?.map((product: ProductResponse) => ({
					...product,
					subsAllowed,
				})),
			}))
		);

		this.setState({
			items: updatedItems,
			areAllAllowSubstitutesSelected: this.areAllAllowSubstitutionsChecked(updatedItems),
		});
	};

	areAllAllowSubstitutionsChecked = (items: ViewTrolleyProductListGroup[]): boolean =>
		this.filterEmptyCategories(items).every((productListGroup: ViewTrolleyProductListGroup) =>
			productListGroup.products?.every((product: ProductResponse) => product.subsAllowed)
		);

	allowSubstitutionProductUpdate(sku: string, subsAllowed: boolean): void {
		if (!this.state.items) {
			return;
		}

		const updatedItems = this.filterEmptyCategories(
			this.state.items.map((productListGroup: ViewTrolleyProductListGroup) => ({
				...productListGroup,
				products: productListGroup.products?.map((product: ProductResponse) => ({
					...product,
					subsAllowed: product.sku === sku ? subsAllowed : product.subsAllowed,
				})),
			}))
		);
		this.setState({
			items: updatedItems,
			areAllAllowSubstitutesSelected: this.areAllAllowSubstitutionsChecked(updatedItems),
		});
	}

	updateAffectedProducts(affectedProducts: Map<string, TrolleyItemResponse>): void {
		if (!this.state.items) {
			return;
		}

		// TODO POD-6734 Update structure of items.products to Map to improve performance on large trolleys
		const updatedItems = this.filterEmptyCategories(
			this.state.items.map((productListGroup: ViewTrolleyProductListGroup) => ({
				...productListGroup,
				products: productListGroup.products?.map((product): ProductResponse => {
					if (product.sku) {
						const affectedProduct = affectedProducts.get(product.sku);
						if (affectedProduct?.item) {
							return affectedProduct.item;
						}
					}
					return product;
				}),
			}))
		);

		this.setState({
			items: updatedItems,
		});
	}

	setState(newState: BasketState): void {
		if (newState?.items) {
			newState.totalInStockItems = newState.items.reduce(this.countProducts, 0);
		}
		super.setState(newState);
	}

	updateTrolleyMetadata(request: UpdateTrolleyMetadataRequest): Observable<void> {
		return this.apiService.post(`${this.appSettingsService.getEndpoint('trolley')}/my/metadata`, request);
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	getAnalyticsData(): { cart_size: string; cart_subtotal: string } {
		return {
			cart_size: this.state.totalItems?.toString() || '',
			cart_subtotal: this.state.subtotal?.toString() || '',
		};
	}

	isQualifyingProduct(sku: string): boolean {
		const samples = this.state.onlineSamples;
		return (
			!!samples &&
			samples?.some(
				(sample) => sample && sample.onlineSample?.qualifyingProduct?.find((product) => product.sku === sku)
			)
		);
	}

	private countProducts = (acc: number, item: ViewTrolleyProductListGroup): number =>
		acc + (item.products?.length || 0);
}
