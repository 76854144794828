import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectionTileComponent, FieldsetComponent } from '@woolworthsnz/form';

@UntilDestroy()
@Component({
	selector: 'fulfilment-method-selection',
	templateUrl: 'fulfilment-method.component.html',
	styleUrls: ['./fulfilment-method.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FieldsetComponent, FormsModule, ReactiveFormsModule, SelectionTileComponent]
})
export class FulfilmentMethodComponent implements OnInit {
	@Input() controlName: string;

	@Input() formGroup: FormGroup;

	selected: string;

	ngOnInit(): void {
		this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => (this.selected = val[this.controlName]));
	}
	getIconName(iconName: string, val: string): string {
		return val === this.selected ? `${iconName}-filled` : iconName;
	}
}
