import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EDRSpinnerComponent } from '../spinner/spinner.component';
import { NgIf } from '@angular/common';

export type EDRButtonStyle = 'primary' | 'secondary' | 'link' | 'block';
export type EDRButtonSize = 'small' | 'medium' | 'large';

@Component({
	selector: 'edr-button, [edr-button]',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [EDRSpinnerComponent, NgIf],
})
export class EDRButtonComponent {
	@HostBinding('attr.button-style') @Input() public buttonStyle: EDRButtonStyle = 'primary';
	@HostBinding('attr.spinner-color') @Input() public loadingSpinnerColor:
		| 'primary--orange'
		| 'secondary--charcoal'
		| 'secondary--mid-grey' = 'primary--orange';
	@HostBinding('attr.size') @Input() public size = 'medium';
	@HostBinding('attr.reversed') @Input() public reversed = false;
	@HostBinding('attr.disabled') @Input() public disabled = false;
	@Input() public isLoading = false;
	@HostBinding('attr.tabIndex') public tabIndex = 0;
}
