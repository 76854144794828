import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppSettingsService } from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { selectAuthState, selectLoggedIn } from '../state';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(private store: Store, private appSettingsService: AppSettingsService, private window: Window) {}

	canActivate(): Observable<boolean> {
		return this.store.select(selectAuthState).pipe(
			filter(({ initialising }) => !initialising),
			tap(({ isLoggedIn }) => {
				if (!isLoggedIn) {
					this.window.location.href = `${this.appSettingsService.getSetting('bffApiUrl')}/auth/login?returnUrl=${
						this.window.location.href
					}`;
				}
			}),
			switchMap(() => this.store.select(selectLoggedIn))
		);
	}
}
