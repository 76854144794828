import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { IntersectionService } from '../services';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[scrollIntoView]',
	providers: [IntersectionService],
	standalone: true
})
export class ScrollIntoViewDirective implements AfterViewInit {
	constructor(private el: ElementRef, private intersectionService: IntersectionService) { }

	ngAfterViewInit(): void {
		this.intersectionService.initIntersect({ cb: this.doScroll, condition: false, el: this.el });
	}

	doScroll = (): void => {
		this.el.nativeElement.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
			inline: 'nearest',
		});
	};
}
