import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputComponent } from '../input/input.component';
import { InputAccessorDirective } from '../../directives/input-accessor.directive';
import { NgClass } from '@angular/common';

@Component({
	selector: 'form-input-with-icon-button',
	template: `
		<ng-content select="[label]"></ng-content>
		<div [ngClass]="{ 'has-error': controlIsInvalid }" class="inputContainer" [attr.readonly]="disabled">
			<ng-content select="[startBtn]"></ng-content>
			<input
				#input
				class="inputText"
				type="{{ inputType }}"
				id="{{ id || formControlName }}"
				name="{{ name || formControlName }}"
				attr.data-cy="{{ formControlName }}"
				placeholder="{{ placeholder }}"
				[attr.data-size]="size"
				[attr.data-colour]="colour"
				[attr.maxLength]="maxlength"
				[attr.readonly]="disabled"
				[attr.tabindex]="tabindex"
				[ngClass]="{
					successIcon: successIcon
				}"
				[value]="inputValue"
				(blur)="onBlur($event)"
				(change)="onChange($event)"
				(keyup)="onKeyup(input.value)"
				(focus)="onFocus(input.value)"
				(keydown)="onKeydown(input.value)"
				[attr.autocomplete]="autocomplete"
				[attr.autocorrect]="autocorrect"
				[attr.aria-activedescendant]="activeDescendant"
				formInputText
			/>
			<ng-content select="[btn]"></ng-content>
		</div>
		<ng-content select="[validation]"></ng-content>
		<ng-content select="[cdxFormHelpText]"></ng-content>
	`,
	styleUrls: ['./input-with-icon-button.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: forwardRef(() => InputWithIconButtonComponent),
			multi: true,
		},
		{
			provide: InputComponent,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: forwardRef(() => InputWithIconButtonComponent),
		},
	],
	standalone: true,
	imports: [NgClass, InputAccessorDirective]
})
export class InputWithIconButtonComponent extends InputComponent {
	@Input() inputType: 'text' | 'password' = 'text';
	@Input() autocomplete = 'disabled';
	@Input() autocorrect: string;
	@Input() activeDescendant: string;
}
