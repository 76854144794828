import { Component, OnInit } from '@angular/core';
import { AppSettingsService, ButtonComponent, SvgIconComponent } from '@woolworthsnz/styleguide';
import { FulfilmentState, FulfilmentStoreService } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterLink } from '@angular/router';

@UntilDestroy()
@Component({
	selector: 'fulfilment-delivery-saver-link',
	template: `
		<a cdxButton fillStyle="link" class="deliverySaver" [routerLink]="deliverySaverRouterLink">
			<span class="deliverySaver-text">Delivery Saver</span> - {{ deliverySaverText }}
			<cdx-svg-icon name="chevron-right" fill="green" size="extra-small"></cdx-svg-icon>
		</a>
	`,
	styleUrls: ['./delivery-saver-link.component.scss'],
	standalone: true,
	imports: [ButtonComponent, RouterLink, SvgIconComponent]
})
export class DeliverySaverLinkComponent implements OnInit {
	deliverySaverText = '';
	deliverySaverRouterLink: any[] = [];

	constructor(private appSettingsService: AppSettingsService, private fulfilmentStoreService: FulfilmentStoreService) { }

	ngOnInit(): void {
		// We need to null the modal outlet so that the link doesn't contain (modal:...) but if clicked from within a modal
		// Need to directly reference the primary outlet because https://github.com/angular/angular/issues/13523
		const deliverySaverLink = this.appSettingsService.getSetting('pageUrl').deliverySaver;
		const modalOutletToClose = this.appSettingsService.getSetting('modalOutletName');
		this.deliverySaverRouterLink = ['', { outlets: { primary: deliverySaverLink, [modalOutletToClose]: null } }];

		this.fulfilmentStoreService.state$.pipe(untilDestroyed(this)).subscribe((state: FulfilmentState) => {
			this.deliverySaverText = state.deliverySaverUpSellNote || '';
		});
	}
}
