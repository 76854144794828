import { AbstractControl, ValidatorFn } from '@angular/forms';

export class RegExHomePhone {
	static HOMEPHONE = `^((?!0900)(0|64)([34679]|2[0-9]|508|800))\\d{6,9}$`;
}

export const validateHomePhoneWithMask =
	(): ValidatorFn =>
	(control: AbstractControl): { [key: string]: any } | null => {
		const r = new RegExp(RegExHomePhone.HOMEPHONE);
		const value = control.value.replace(/ /g, '');
		// if the value is empty, return null (valid) because home phone is not required
		if (value === null || value === undefined || value === '') {
			return null;
		} else {
			return r.test(value) ? null : { invalidMobile: control.value };
		}
	};
