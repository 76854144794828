import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from '../../services';
import { NgIf, NgClass, NgFor } from '@angular/common';

export interface RadioOption {
	value: string;
	label: string;
	enabled?: boolean;
	description?: string;
}

@Component({
	selector: 'form-radio-group',
	template: `
		<ul
			[ngClass]="{
				'radioGroup--horizontal': orientation === 'horizontal',
				'radioGroup--textSmall': textSize === 'small'
			}"
			[attr.aria-label]="a11yGroupDescription"
			role="radiogroup"
			*ngIf="options"
		>
			<li [formGroup]="parentFormGroup" *ngFor="let option of options; let i = index; trackBy: optionByIndex">
				<ng-container>
					<input
						type="radio"
						[attr.disabled]="option.enabled === false ? true : null"
						[attr.name]="groupName"
						[attr.data-cy]="uniqueRadioId[i]"
						[formControlName]="groupName"
						[id]="uniqueRadioId[i]"
						[value]="option.value"
					/>
					<label [for]="uniqueRadioId[i]">{{ option.label }}</label>
					<p *ngIf="option.description">{{ option.description }}</p>
				</ng-container>
			</li>
		</ul>
	`,
	styleUrls: ['./radio-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, NgClass, NgFor, FormsModule, ReactiveFormsModule],
})
export class RadioGroupComponent implements OnInit {
	@Input() a11yGroupDescription: string;
	@Input()
	get defaultValue(): string | undefined {
		return this._defaultValue;
	}
	set defaultValue(val) {
		this._defaultValue = val;
		if (this.parentFormGroup) {
			this.parentFormGroup.get(this.groupName)?.setValue(val);
		}
	}
	private _defaultValue?: string;

	@Input()
	get groupName(): string {
		return this._groupName;
	}
	set groupName(val: string) {
		this._groupName = val;
		this.initForm();
	}
	private _groupName: string;

	@Input()
	get options(): RadioOption[] {
		return this._options;
	}

	set options(val) {
		this._options = val;
		this.initForm();
	}

	private _options: RadioOption[] = [];

	@Input() orientation: 'horizontal' | 'vertical' = 'vertical';
	@Input() textSize: 'small' | 'default' = 'default';
	@Input() style: 'chip' | 'default' = 'default';

	@HostBinding('class')
	get radioGroupStyleClass(): string {
		return this.style;
	}

	parentFormGroup: FormGroup;
	uniqueRadioId: string[] = [];

	constructor(private parent: FormGroupDirective, private formService: FormService) {}

	ngOnInit(): void {
		this.initForm();
	}

	populateUniqueIds(): void {
		for (let i = 0; i < this.options.length; i++) {
			this.uniqueRadioId[i] = this.getRadioName(i);
		}
	}

	optionByIndex = (index: number): number => index;

	private getRadioName = (i: number): string => `radio-${this.groupName}-${i + 1}`;

	private initForm(): void {
		if (!this.parentFormGroup && this.groupName && this.options?.length) {
			this.parentFormGroup = this.parent.form;
			this.populateUniqueIds();
			this.formService.addControlToParent(
				this.groupName,
				[],
				this.parentFormGroup,
				undefined,
				this.defaultValue,
				true
			);
		}
	}
}
