import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PreferenceType } from '../../types';
import { adapter, name, PreferenceState } from './preferences.reducer';

export const ONLINE_STORE_ID = 998;

export const selectPreferences = createFeatureSelector<PreferenceState>(name);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors(selectPreferences);

export const selectPreferencesLoading = createSelector(selectPreferences, (state) => state.loading);

export const selectPreferenceValues = createSelector(selectAll, (entities) =>
	entities.reduce((acc: Dictionary<string | number | boolean>, val) => {
		acc[val.id] = val.value;
		return acc;
	}, {})
);

export const selectPreferredDayOfWeek = createSelector(selectPreferenceValues, (values) => {
	const preferredDay = values[PreferenceType.PreferredDayOfWeek] as string;
	return preferredDay ? preferredDay : 'Monday';
});

export const selectEDRSaveForOccasionDate = createSelector(selectPreferenceValues, (values) => {
	const date = values[PreferenceType.EDRSaveForOccasionDate] as string;
	return date;
});

export const selectPreferredStoreId = createSelector(
	selectPreferenceValues,
	(values) => values[PreferenceType.PreferredStore] as number
);

export const selectIsOnlineStore = createSelector(selectPreferredStoreId, (storeId) => storeId === ONLINE_STORE_ID);

export const selectUpdatingPreference = createSelector(selectPreferences, (state) => state.updating);

export const selectUnsubscribeFailure = createSelector(selectPreferences, (state) => state.errorMessage);
