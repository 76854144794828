import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { updatePreferences } from '.';
import { PreferencesService } from '../../services';
import {
	getPreferences,
	getPreferencesComplete,
	preferencesPageLoaded,
	unsubscribe,
	unsubscribed,
	unsubscribeFailure,
	updatePreference,
	updatePreferenceComplete,
} from './preferences.actions';

@Injectable()
export class PreferencesEffects {
	onGetPreferences$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(getPreferences),
			mergeMap(() => this.preferencesService.getPreferences()),
			map((res) => getPreferencesComplete({ preferences: res.preferences }))
		);
	});

	onPreferenceUpdate$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(updatePreference),
			mergeMap((action) => this.preferencesService.updatePreferences({ id: action.id, value: action.value })),
			map((res) => updatePreferenceComplete({ preferences: res.preferences }))
		);
	});

	onPreferencesUpdate$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(updatePreferences),
			mergeMap((action) => this.preferencesService.updatePreferences(...action.preferencesToUpdated)),
			map((res) => updatePreferenceComplete({ preferences: res.preferences }))
		);
	});

	onPreferencesPageLoaded$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(preferencesPageLoaded),
			map(() => getPreferences())
		);
	});

	onUnsubscribe$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(unsubscribe),
			switchMap((action) =>
				this.preferencesService.unsubscribe({ email: action.email, version: action.version, hash: action.hash }).pipe(
					map(() => unsubscribed()),
					catchError((err) => {
						return of(unsubscribeFailure({ errorMessage: err }));
					})
				)
			)
		);
	});

	constructor(private actions$: Actions, private preferencesService: PreferencesService) {}
}
