import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationStart, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter, map, takeWhile } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class HistoryService {
	static maxHistoryStates = 100;

	private history: string[] = [];

	constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		this.router.events
			.pipe(
				takeWhile((e) => !!e),
				filter((event) => event instanceof NavigationStart),
				// https://github.com/Microsoft/TypeScript/issues/16069
				map((event) => event as NavigationStart)
			)
			.subscribe(({ url }) => {
				if (this.router.getCurrentNavigation()?.extras?.replaceUrl) {
					// if the router specifically wanted to replace the url we should also replace it in history
					this.history.pop();
				}
				// if the last history entry is the same as this one to be added, don't add it
				if (this.history[this.history.length - 1] === url) {
					return;
				}
				// otherwise add it to the history - up to the max
				this.history = [...this.history, url].slice(-HistoryService.maxHistoryStates);
			});
	}

	getHistory(): string[] {
		return this.history;
	}

	escapeRegExp(string: string): string {
		return string.replace(/[.*+?^${}()|[\]\\\/]/g, '\\$&'); // $& means the whole matched string
	}

	/**
	 * Tests a list of urls to see if they contain the passed url
	 * Ignores the querystring part of the url - so both urls /foo and /foo?bar tested against ['/foo'] would return true
	 * @param url the url to check
	 * @param list the list to check against
	 */
	urlInListIgnoringQueryString(url: string, list: string[]): boolean {
		return list.some((exclusion) => {
			const match = new RegExp(`^${this.escapeRegExp(exclusion)}(?:(?:\\?.*)$|$)`);
			return match.test(url);
		});
	}

	getPreviousUrl(excludes: string[] = []): string {
		const filteredHistory = this.history.filter((url) => !this.urlInListIgnoringQueryString(url, excludes));
		return filteredHistory[filteredHistory.length - 2] || '/';
	}

	getPreviousUrlInGuard(excludes: string[] = []): string {
		const filteredHistory = this.history.filter((url) => !this.urlInListIgnoringQueryString(url, excludes));
		return filteredHistory[filteredHistory.length - 1] || '/';
	}

	/**
	 * Regex on the urls in the current history as the Navigation end event checked in the constructor doesn't have the information needed to track when modals open and close.
	 */
	getPreviousNonModalUrl(excludes: string[] = []): string {
		const outlet = 'modal';
		return this.getPreviousNonModalUrlForOutlet(outlet, excludes);
	}

	getPreviousNonModalUrlForOutlet(outlet: string, excludes: string[] = []): string {
		if (outlet === PRIMARY_OUTLET) {
			// special case: Primary and our default modal outlet are peers
			outlet = 'modal';
		}
		const outletRegex = `\\(${outlet}:`;
		const outletFinder = new RegExp(outletRegex);
		const nonModalUrl = [...this.history]
			.reverse()
			.find((url) => !(url.match(outletFinder) || this.urlInListIgnoringQueryString(url, excludes)));

		if (!nonModalUrl) {
			// if there isn't a non modal url in history take the current url and strip the auxiliary route
			const arRegex = new RegExp(`(?:\\(|\\/\\/)${outlet}:.*?(?:\\/\\/|\\))`);
			const currentUrl = [...this.history].pop();
			if (currentUrl?.match(arRegex)) {
				return currentUrl.replace(arRegex, '').replace(/\/+$/, '');
			}
			return '/';
		}

		return nonModalUrl;
	}
}
